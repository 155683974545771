import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Image,
  Breadcrumb,
  Accordion,
  Modal,
  Button,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import MainLogo from "../../assets/img/logo.png";

import Applink from "../../include/applink/googleApple";
import MobappImage from "./../../assets/img/mob-app.png";
import ApiCall from "../../api/callApi";
import Innerheader from "../../include/header/innerHeader";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
const Image_URL = process.env.REACT_APP_Bucket_URL;

function TestList() {
  var { course_id, subject_id } = useParams();

  const [status, setStatus] = useState(1);
  const [data, setData] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [testData, setTestData] = useState({
    id: "",
    name: "",
    instruction: "",
  });
  const [instructionmodelStatus, SetInstructionModelStatus] = useState(false);
  const [instructions, SetInstructions] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    } else {
      getData();
    }
  }, []);

  function getData() {
    ApiCall(
      { course_id: course_id, subject_id: subject_id },
      "post",
      "test_list",
      testcallback
    );
  }

  const testcallback = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === "true") {
      // console.log(response.data.data);
      setData(response.data.data);
    } else {
      setStatus(0);
    }
  });

  const showInstructions = (id, name, instruction) => {
    setTestData({ id: id, name: name, instruction: instruction });
    SetInstructionModelStatus(true);
  };

  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }

  // const RouteChange = (e) =>{
  //     navigate('/search-result?keyword='+searchInput, { state: { course:searchInput }})
  // }

  return (
    <>
      {/* <Innerheader onSearch = {(e)=>RouteChange(e)}   ></Innerheader>  */}

      <section>
        <div className="container custom_container py-5">
          <Row>
            <Col lg={12} className="align-self-center">
              <Breadcrumb className="mb-1">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/my-courses">My Courses</Breadcrumb.Item>
                <Breadcrumb.Item className="text_gradient" active>
                  Mock Test
                </Breadcrumb.Item>
              </Breadcrumb>
              <Row>
                <Col md={12}>
                  <h3 className="mb-0 f-25mob">Mock Test</h3>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>

      <section className="trending-courses-area py-5">
        <Container>
          <Row>
            <Col lg={12} className="sidebar-area">
              <div className="widget widget-accordion-inner">
                {status == 0 ? (
                  <h6 className="mt-4 mb-3" style={{ margin: "5%" }}>
                    No Mock Test found in this course and subject!!!
                  </h6>
                ) : (
                  <Accordion defaultActiveKey="0">
                    {data.map((value, key) => {
                      return (
                        <Accordion.Item
                          className="mt-3"
                          eventKey="0"
                          key={key}
                          onClick={() => {
                            SetInstructions(value.instruction);
                            showInstructions(
                              value.id,
                              value.name,
                              value.instruction
                            );
                          }}
                        >
                          <Accordion.Header>
                            <i className="fa fa-book me-2"></i> {value.name}
                          </Accordion.Header>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal
        size="lg"
        id="switchpopup"
        show={instructionmodelStatus}
        onHide={() => SetInstructionModelStatus(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col md={12} className="switchlnguageox">
              <Image src={MainLogo} alt="img" className="mb-3 mb-sm-5" />
              <h4 className="f-25 mb-0 f-22mob">Instructions</h4>
              <div dangerouslySetInnerHTML={{ __html: instructions }} />

              <Button
                variant="base-light"
                className="w-100 mt-4"
                onClick={() => navigate("/play-test", { state: testData })}
              >
                Play Test
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TestList;
