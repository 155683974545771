import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Image } from "react-bootstrap";
const Image_URL = process.env.REACT_APP_Bucket_URL;
const Item = (props) => {
  return (
    <div className="item">
      <Link
        to={"/course-detail/" + props.item.course_url + "/" + props.item.id}
      >
        <div className="single-course-wrap">
          <div className="thumb">
            {props.item.live_status === 0 ? (
              <span className="cat cat-green" style={{ float: "right" }}>
                Live
              </span>
            ) : (
              <span className="cat cat-blue" style={{ float: "right" }}>
                Recorded
              </span>
            )}
            <Image
              src={`${Image_URL + "course/"}${props.item.image}`}
              alt={props.item.name}
            />
          </div>
          <div className="wrap-details">
            <h6>{props.item.name}</h6>
            <div className="price-wrap pt-0">
              <Row className="align-items-center">
                <Col xs={12} className="mb-2">
                  <span className="text-theme f-18 fw-semi-bold me-2">
                    <i class="fa fa-rupee"></i>
                    {props.item.price}
                  </span>
                  <del className="text-black-50 f-15">
                    <i class="fa fa-rupee"></i>
                    {props.item.discount}
                  </del>
                </Col>
                <Col xs={12} className="d-flex align-items-center">
                  <Image
                    src={Image_URL + "review1.png"}
                    className="thumbimg"
                    alt=""
                  />
                  <span className="f-11 mt-1 ms-1 text-muted fw-bold mob-linefacultyheight">
                    {props.item.teacher ? props.item.teacher : "Dhurina"}
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default Item;
