const Image_URL = process.env.REACT_APP_Bucket_URL;

// PRODUCTS
export const ExploreProducts = [
  {
    image: Image_URL + "website/product/product_image1.png",
    title: "Recent Courses",
    description: "Our Trending Courses",
    url: "/Trending-Courses",
  },
  {
    image: Image_URL + "website/product/product_image7.png",
    title: "High Quality",
    description: "Online Live Course",
    url: "/all-course",
  },
  {
    image: Image_URL + "website/product/product_image3.png",
    title: "Exam Based",
    description: "Free Study Material",
    url: "/free-study-material",
  },
  {
    image: Image_URL + "website/product/product_image4.png",
    title: "Top Rated",
    description: " E-Books & Notes",
    url: "/all-book",
  },
  {
    image: Image_URL + "website/product/product_image5.png",
    title: "Exam Based",
    description: "Online Mock Test",
    url: "/all-test-series",
  },
  {
    image: Image_URL + "website/product/product_image6.png",
    title: "Upcoming Exam",
    description: "Exam Based Blogs",
    url: "https://dhurina.net/en/",
  },
];

// RESOURCES
export const ResourceData = [
  // {
  //   image: Image_URL + "website/png/Res-magazine.png",
  //   title: "E- Magazine",
  // },
  {
    image: Image_URL + "website/Res-current-affairs.png",
    title: "Curent Affairs",
    url: "/curent-affair",
  },
  {
    image: Image_URL + "website/Res-tests.png",
    // title: "Test Challange",
    title: "Live Test",
    url: "/test",
  },
  {
    image: Image_URL + "website/Res-Quizes.png",
    title: "Ebooks",
    url: "/free-ebook",
  },
  {
    image: Image_URL + "website/Res-skills.png",
    title: "Boost Your Skill",
    url: "/boost_your_skill",
  },
  // {
  //   image: Image_URL + "website/png/Res-shorts.png",
  //   title: "Dhurina Shorts",
  // },
];

// FOOTER
export const Company = [
  { label: "About Us", url: "/about-us" },
  { label: "Contact Us", url: "/contactus/" },
  // {
  //   label: "Our Institutes",
  //   url: "#",
  // },
  { label: "Privacy Policy", url: "/privacy-policy" },
  { label: "Refund Policy", url: "/refund-policy" },
  { label: "Terms & Conditions", url: "/terms-condition" },
  { label: "Careers", url: "/careers" },
  { label: "Disclaimer", url: "/disclaimer" },
  // { label: "Courses", url: "#" },
];
export const Courses = [
  {
    label: "CET Exam",
    url: "https://dhurina.net/search-result?keyword=RAjasthan%20CET",
  },
  { label: "SSC", url: "https://dhurina.net/search-result?keyword=SSC" },
  { label: "Banking", url: "https://dhurina.net/search-result?keyword=ibps" },
  {
    label: "Police / SI",
    url: "https://dhurina.net/search-result?keyword=Rajasthan%20Police",
  },
  {
    label: "All State Pcs",
    url: "https://dhurina.net/search-result?keyword=Rpsc",
  },
  {
    label: "Teaching Exam",
    url: "https://dhurina.net/search-result?keyword=1st%20grade",
  },
  // { label: "Online Support", url: "#" },
];
export const Products = [
  { label: "Courses", url: "/all-course" },
  { label: "Test Series", url: "/all-test-series" },
  { label: "Notes", url: "/all-book" },
  // { label: "Books & Ebooks", url: "/:stateName/:id/all-books" },
  { label: "Our Offline Institutes", url: "/our-institutes" },
];
export const Coaching = [
  { label: "Rajasthan Exam", url: "https://dhurina.net/en/rajasthan/" },
  { label: "Bihar Exam", url: "https://dhurina.net/en/bihar/" },
  { label: "Haryana Exam", url: "https://dhurina.net/en/haryana/" },
  { label: "Jharkhand Exam", url: "https://dhurina.net/en/jharkhand/" },
  { label: "And other Exams", url: "https://dhurina.net/en/all-posts/" },
];

// export const MockTests = [
//   { label: "JAIIB/CAIIB", url: "#" },
//   { label: "CUET", url: "#" },
//   { label: "SSC CHSL", url: "#" },
//   { label: "SSC GD", url: "#" },
//   { label: "RRB NTPC", url: "#" },
//   { label: "RAS Subjective Test", url: "/ras-courses" },
// ];

// **************BPSC PAGE*********************

// Hero Bpsc
export const HeroBpsc = [
  {
    heading: "BPSC Study Material 2023, Study Plan, Notes",
    para: "Buy BPSC Study Material 2023 at Dhurina to get Comprehensive BPSC Study notes,BPSC Exam Tips,Study Plan at low price",
    Study: [
      { name: "Live Class", path: "#liveclass" },
      { name: "Videos", path: "#videos" },
      { name: "Tests", path: "#tests" },
      { name: "Ebooks", path: "#ebooks" },
      { name: "Books", path: "#books" },
    ],
  },
];

export const BpscTest = [
  {
    image: Image_URL + "website/svg/bpsc-icon.svg",
    Title: "BPSC",
    type: "SIDDHI Selection",
    desc: "Bihar SSC CGL Mains SIDDHI Selection Batch 2.0 Hinglish | Online Live Class By Dhurina",
    price: 1299,
    coupon: "BPSC",
  },
];
// BPSC Ebooks
export const BpscEbooks = [
  {
    image: Image_URL + "website/svg/bpsc-ebook.svg",
    Title: "BPSC",
    type: "Handwritten Notes",
    desc: "Bpsc SSC CGL Mains SIDDHI Selection Hand-Written Notes",
    price: 899,
    coupon: "BPSC10",
  },
];
export const AllEbook = [
  {
    image: Image_URL + "website/svg/bpsc-ebook.svg",
    Title: "BPSC",
    type: "Handwritten Notes",
    desc: "Bpsc SSC CGL Mains SIDDHI Selection Hand-Written Notes",
    price: 899,
    coupon: "BPSC10",
  },
  {
    image: Image_URL + "website/svg/bpsc-ebook.svg",
    Title: "BPSC",
    type: "Handwritten Notes",
    desc: "Bpsc SSC CGL Mains SIDDHI Selection Hand-Written Notes",
    price: 899,
    coupon: "BPSC10",
  },
  {
    image: Image_URL + "website/svg/bpsc-ebook.svg",
    Title: "BPSC",
    type: "Handwritten Notes",
    desc: "Bpsc SSC CGL Mains SIDDHI Selection Hand-Written Notes",
    price: 899,
    coupon: "BPSC10",
  },
  {
    image: Image_URL + "website/svg/bpsc-ebook.svg",
    Title: "BPSC",
    type: "Handwritten Notes",
    desc: "Bpsc SSC CGL Mains SIDDHI Selection Hand-Written Notes",
    price: 899,
    coupon: "BPSC10",
  },
  {
    image: Image_URL + "website/svg/bpsc-ebook.svg",
    Title: "BPSC",
    type: "Handwritten Notes",
    desc: "Bpsc SSC CGL Mains SIDDHI Selection Hand-Written Notes",
    price: 899,
    coupon: "BPSC10",
  },
];

// **************BIHAR PAGE*********************

// Hero Bihar
export const HeroBihar = [
  {
    heading: "BIHAR STUDY MATERIAL",
    para: "Buy BSSC Study Material 2023 at Dhurina to get Comprehensive BSSC Study notes,BSSC Exam Tips,Study Plan at low price",
    Study: [
      { name: "Live Class", path: "#liveclass" },
      { name: "Recorded Class", path: "#Recorded" },
      { name: "Tests", path: "#tests" },
      { name: "Ebooks", path: "#ebooks" },
      { name: "Books", path: "#books" },
    ],
  },
];

// Bihar Test
export const BiharTest = [
  {
    image: Image_URL + "website/svg/bpsc-icon.svg",
    Title: "Bihar",
    type: "Patna High Court",
    desc: "Bihar SSC CGL Mains SIDDHI Selection Batch 2.0 Hinglish | Online Live Class By Dhurina",
    price: 1299,
    coupon: "Bihar",
  },
];
// Bihar Ebooks
export const BiharEbooks = [
  {
    image: Image_URL + "website/svg/bpsc-ebook.svg",
    Title: "Bihar",
    type: "Patna High Court",
    desc: "Bihar SSC CGL Mains SIDDHI Selection Hand-Written Notes",
    price: 899,
    coupon: "Bihar10",
  },
];

// shorts
export const Short = [
  {
    image: Image_URL + "website/png/short-1-poster.png",
    resumeBtn: Image_URL + "website/svg/resume-icon.svg",
    para: "Rajasthan geo pade ya nahi..",
    viewIcon: Image_URL + "website/svg/view-icon.svg",
    views: "16K Views",
  },
  {
    image: Image_URL + "website/png/short-2-poster.png",
    resumeBtn: Image_URL + "website/svg/resume-icon.svg",
    para: "Ab hoga Ran ka aagaj",
    viewIcon: Image_URL + "website/svg/view-icon.svg",
    views: "11K Views",
  },
  {
    image: Image_URL + "website/png/short-3-poster.png",
    resumeBtn: Image_URL + "website/svg/resume-icon.svg",
    para: "REET 5 Din ki dvai by Sibhash Charan ",
    viewIcon: Image_URL + "website/svg/view-icon.svg",
    views: "181K Views",
  },
  {
    image: Image_URL + "website/png/short-4-poster.png",
    resumeBtn: Image_URL + "website/svg/resume-icon.svg",
    para: "Rajasthan geo pade ya nahi.. ",
    viewIcon: Image_URL + "website/svg/view-icon.svg",
    views: "16K Views",
  },
  {
    image: Image_URL + "website/png/short-5-poster.png",
    resumeBtn: Image_URL + "website/svg/resume-icon.svg",
    para: "Ab hoga Ran ka aagaj ",
    viewIcon: Image_URL + "website/svg/view-icon.svg",
    views: "11K Views",
  },
];
// **************SSC PAGE*********************
// hero ssc
export const TestHero = [
  {
    detail: "Mock Tests",
  },
  {
    detail: "Available in Hindi & English",
  },
  {
    detail: "Detailed solutions with short trick method",
  },
];

//SSC Mock Test
export const MockTest = [
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
];
//SSC Previous Year 10 tests
export const PreviousPapers = [
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
];

//SSC Previous Year 12 tests
export const PreviousPapers2 = [
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
];

//SSC Premium Test
export const Premium = [
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
  {
    title: "DP CONST 2020 27 NOV S1",
    expire: "Expires on 31 Dec 2023",
    ques: 100,
    marks: 100,
    time: "90 Mins",
  },
];
