import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Col,
  Row,
  Image,
  Form,
  Spinner,
  Alert,
} from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import Schoolheader from "../../include/header/schoolHeader";
import Footer from "../../include/footer/footer";
import Applink from "../../include/applink/googleApple";
import EducationIcon from "./../../assets/img/education.png";
import SchoolIcon from "./../../assets/img/schoolicon.png";
import StudentIcon from "./../../assets/img/studenticon.png";
import TeacherIcon from "./../../assets/img/teachericon.png";
import Schoolbanner from "./../../assets/img/school-top.png";
import SchoolJoin from "./../../assets/img/schooljoin.png";
import Skillo from "./../../assets/img/skill1.png";
import Skillt from "./../../assets/img/skill2.png";
import Skillth from "./../../assets/img/skill3.png";
import Skillf from "./../../assets/img/skill4.png";
import CertifiImage from "./../../assets/img/cert.jpg";
import MobappImage from "./../../assets/img/mob-app.png";
import ApiCall from "../../api/callApi";
import Loading from "../../components/Loader";
import AllCourse from "../utility/allCourse";
import BoardList from "../../components/school/board_list";
import { Helmet } from "react-helmet";

const certificateslideroptions = {
  loop: true,
  margin: 30,
  nav: true,
  dots: true,
  smartSpeed: 1500,
  navText: [
    "<i class='fa fa-arrow-left'></i>",
    "<i class='fa fa-arrow-right'></i>",
  ],
  responsive: {
    0: {
      items: 2,
      dots: true,
      margin: 10,
    },
    575: {
      items: 2,
      margin: 20,
      dots: true,
    },
    769: {
      items: 3,
      dots: true,
    },
    1440: {
      items: 3,
    },
  },
};

const Schoolhome = () => {
  const check_status = true;
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [boards, setBoards] = useState([]);
  const [show_succ, setShowSucc] = useState(false);
  const [show_fail, setShowFail] = useState(false);

  // Form Data
  const [type, setType] = useState("student");
  const [userclass, setUserClass] = useState("");
  const [userboard, setUserBoard] = useState("");
  const [username, setUserName] = useState("");
  const [userphone, setUserPhone] = useState("");
  const [message, setUserMessage] = useState("");
  const [submitStatus, setSubmitStatus] = useState(false);

  // const notify = (message) => toast.success(message, {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  // });

  // const { register, handleSubmit, watch, errors } = useForm();

  useEffect(() => {
    getData();
  }, []);

  function getData(params) {
    ApiCall("", "post", "school_home", school_home);
  }

  const school_home = useCallback((response) => {
    // console.log(response);
    if (response.data.status === 200) {
      setCourses(response.data.all_course);
      setBoards(response.data.boards);

      setLoading(false);
    } else {
      console.log("error");
    }
  }, []);

  function handleSubmitForm(e) {
    if (userclass === "" || userboard) {
      // notify('Please select userboard or userclass')
      return false;
    }
    e.preventDefault();
  }

  return (
    <>
      <Schoolheader></Schoolheader>
      <Helmet>
        <title>Dhurina - School Home</title>
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <link rel="canonical" href="https://dhurina.net/fetch-canonical-url" />
        <meta name="title" content="fetch title of the content" />
        <meta name="description" content="fetch description of the content" />
        <meta name="publish-date" content="2022-04-22T11:24:28+05:30" />
        <meta name="created-date" content="2022-04-22T11:24:28+05:30" />
        <meta name="modified-date" content="2022-04-22T11:24:29+05:30" />
        <meta
          name="twitter:description"
          content="fetch description of the content"
        />
        <meta
          name="twitter:image"
          content="https://dhurina.net/fetch-image-url"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="fetch title of the content" />
        <meta name="twitter:site" content="@Dhurinalive" />
        <meta name="twitter:creator" content="@Dhurinalive" />
        <meta name="twitter:app:id:googleplay" content="com.dhurina" />
        <meta name="twitter:app:id:iphone" content="1479858722" />
        <meta
          name="twitter:app:name:googleplay"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:ipad"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:iphone"
          content="Dhurina - Learning Made Easy"
        />
        <meta name="twitter:domain" content="dhurina.net" />
        <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
        <meta name="og:image:type" content="image/jpeg" />
        <meta
          property="og:description"
          content="fetch description of the content"
        />
        <meta
          property="og:image"
          content="https://dhurina.net/fetch-image-url"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="fetch title of the content" />
        <meta property="og:url" content="URL of Course" />
        <meta property="og:site_name" content="Dhurina" />
        <meta property="og:updated_time" content="2022-01-28T13:36:39+05:30" />
        <meta
          property="article:published_time"
          content="2022-04-22T05:54:28.000Z"
        />
        <meta
          property="article:modified_time"
          content="2022-04-22T05:54:29.000Z"
        />
        <meta property="article:section" content="Fetch Category" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/dhurinalive"
        />
        <meta property="article:author" content="https://fb.com/dhurinalive" />
        <meta property="article:section" content="Online-Course" />
        <meta
          property="al:android:url"
          content="https://dhurina.net/fetch-canonical-url"
        />
        <meta
          property="al:android:app_name"
          content="Dhurina - Learning Made Easy"
        />
        <meta property="al:android:package" content="com.dhurina" />
        <meta property="fb:app_id" content="facebook-app-id-in-number" />
        <meta property="fb:pages" content="facebook-page-in-number" />
      </Helmet>

      <section className="banner-area schoolbanner">
        <Container>
          <Row>
            <Col sm={6}>
              <div className="main-banner-txt banner-inner text-md-start text-center mb-0 mb-sm-5">
                <video
                  className="video mt-0 mt-sm-3 rounded-3"
                  width="100%"
                  controls
                  muted
                >
                  <source src="https://prod-dhurina-static-assets-public.s3.ap-south-1.amazonaws.com/videos/Dhurina+School.mp4" />
                </video>
                <h3 className="mt-3">
                  <span>
                    Dhurina: Shaping The Digital Schools of The Nation
                  </span>
                </h3>
                <p className="f-15 schoolbannerdesp">
                  Turn your school into a well-managed digital school with our
                  best services at budget-friendly prices.
                </p>
              </div>
            </Col>
            <Col sm={6} className="pe-0 text-end d-sm-block d-none">
              <Image
                alt="school banner"
                src={Schoolbanner}
                className="laptop-bannerimg schoolbannerinner"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={2} sm={6} xs={6} className="mb-3">
              <Row>
                <Col xs={4}>
                  <Image alt="school" src={SchoolIcon} />
                </Col>
                <Col xs={8} className="wrap-details">
                  <h6>
                    <Link to="/">5000+</Link>
                  </h6>
                  <p>School Joined</p>
                </Col>
              </Row>
            </Col>
            <Col lg={2} sm={6} xs={6} className="mb-3">
              <Row>
                <Col xs={4}>
                  <Image alt="student" src={StudentIcon} />
                </Col>
                <Col xs={8} className="wrap-details">
                  <h6>
                    <Link to="/">5+ lakh</Link>
                  </h6>
                  <p>Students</p>
                </Col>
              </Row>
            </Col>
            <Col lg={2} sm={6} xs={6} className="mb-3">
              <Row>
                <Col xs={4}>
                  <Image alt="teacher" src={TeacherIcon} />
                </Col>
                <Col xs={8} className="wrap-details">
                  <h6>
                    <Link to="/">10000+</Link>
                  </h6>
                  <p>Teachers</p>
                </Col>
              </Row>
            </Col>
            <Col lg={2} sm={6} xs={6} className="mb-3">
              <Row>
                <Col xs={4}>
                  <Image alt="education" src={EducationIcon} />
                </Col>
                <Col xs={8} className="wrap-details">
                  <h6>
                    <Link to="/">13000+</Link>
                  </h6>
                  <p>Online Certificates</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="trending-courses-area py-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title">
                <h2>Trending Courses</h2>
              </div>
            </Col>
            <Col lg={12}>
              {loading ? <Loading /> : <AllCourse data={courses} />}
            </Col>
          </Row>
        </Container>
      </section>

      <section className="test-series py-5 bg-light-dark">
        <Container className="pb-5 mb-5">
          <Row>
            <Col lg={12}>
              <div className="section-title text-center">
                <p className="text-theme f-18 mb-0 mt-0">
                  Are you Individual Student
                </p>
                <h2>Board Wise Course</h2>
              </div>
            </Col>
            <Col lg={12}>
              {loading ? <Loading /> : <BoardList data={boards} />}
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="joinschool">
                <Row className="justify-content-center">
                  <Col md={3}>
                    <Image alt="schooljoin" src={SchoolJoin} />
                  </Col>
                  <Col md={6}>
                    <h2 className="text-white">Are you a school Owner ?</h2>
                    <h1 className="text-white">Join Dhurina</h1>
                    <Link to="/" className="btn mt-3 btn-danger">
                      <i className="far fa-file-alt"></i> Get Started
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-white">
        <Container>
          <Row>
            <Col md={6} className="py-5">
              <div className="section-title mt-4 mt-sm-0 mb-4 lefth2border">
                <h2 className="text-start">Get Certification Now</h2>
                <h4 className="text-start mt-4 mobsech4">
                  Your child want to improve skill at time of School.
                </h4>
              </div>

              <OwlCarousel
                className="certificate-slider"
                {...certificateslideroptions}
              >
                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certificate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certificate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certificate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certificate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>
              </OwlCarousel>
            </Col>

            <Col md={6}>
              <div className="bg-light p-5 mobserachskill">
                <div className="navbar-area certficationsearch">
                  <div className="single-input-wrap">
                    <Form.Control
                      type="text"
                      className="d-inline-block"
                      placeholder="Select your Interest in Skill & Certificate"
                    />
                    <Button variant="homesearch">
                      <i className="fa fa-sort-down fa-2x"></i>
                    </Button>
                  </div>
                </div>

                <Row className="mt-4 pt-3 mob-skilliconhead">
                  <Col xs={6}>
                    <Row className="textmon-ce text-center">
                      <Col xs={12} className="mb-3">
                        <Image alt="skill" src={Skillo} />
                      </Col>
                      <Col xs={12}>
                        <h5 className="f-18">Computer Science</h5>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={6}>
                    <Row className="textmon-ce text-center">
                      <Col xs={12} className="mb-3">
                        <Image alt="skill" src={Skillt} />
                      </Col>
                      <Col xs={12}>
                        <h5 className="f-18">Data Analytics</h5>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={6}>
                    <Row className="textmon-ce text-center">
                      <Col xs={12} className="mb-3">
                        <Image alt="skill" src={Skillth} />
                      </Col>
                      <Col xs={12}>
                        <h5 className="f-18">Business Management</h5>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={6}>
                    <Row className="textmon-ce text-center">
                      <Col xs={12} className="mb-3">
                        <Image alt="skill" src={Skillf} />
                      </Col>
                      <Col xs={12}>
                        <h5 className="f-18">Biology & Life Science</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="contact-newbg  py-5">
        <Container className="contact-bg">
          <form onSubmit={(e) => handleSubmitForm(e)}>
            <Row className="justify-content-center">
              <Col lg={12} className="text-center">
                <p className="text-theme f-15 mb-0">Connect with us</p>
                <div className="section-title">
                  <h2>Drop us Message for any Query</h2>
                </div>
              </Col>
              <div className="text-center connectoptionmobview">
                <Button variant="online" className="mb-2 mb-sm-4 me-1">
                  <Form.Control
                    type="radio"
                    className="with-gap"
                    name="groupType"
                    id="Student"
                    onChange={() => setType("Student")}
                    checked={check_status}
                  />
                  <Form.Label htmlFor="Student">Student</Form.Label>
                </Button>
                <Button variant="online" className="mb-2 mb-sm-4 me-1">
                  <Form.Control
                    type="radio"
                    className="with-gap"
                    name="groupType"
                    id="School"
                    onChange={() => setType("School")}
                  />
                  <Form.Label htmlFor="School">School Owner</Form.Label>
                </Button>
                <Button variant="online" className="mb-2 mb-sm-4 me-1">
                  <Form.Control
                    type="radio"
                    className="with-gap"
                    name="groupType"
                    id="Personal"
                    onChange={() => setType("Personal")}
                  />
                  <Form.Label htmlFor="Personal">Personal Teacher</Form.Label>
                </Button>
                <Button variant="online" className="mb-2 mb-sm-4 me-1">
                  <Form.Control
                    type="radio"
                    className="with-gap"
                    name="groupType"
                    id="Skill"
                    onChange={() => setType("Skill")}
                  />
                  <Form.Label htmlFor="Skill">Skill Only</Form.Label>
                </Button>
                <Button variant="online" className="mb-2 mb-sm-4 me-1">
                  <Form.Control
                    type="radio"
                    className="with-gap"
                    name="groupType"
                    id="Parents"
                    onChange={() => setType("Parents")}
                  />
                  <Form.Label htmlFor="Parents">Parents</Form.Label>
                </Button>
              </div>
              <Col lg={7}>
                <div className="contactform">
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Form.Select
                          className="form-control"
                          type="select"
                          onChange={(e) => setUserClass(e.target.value)}
                          required
                        >
                          <option>Select Class</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                        </Form.Select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Form.Select
                          className="form-control"
                          onChange={(e) => setUserBoard(e.target.value)}
                          required
                        >
                          <option>Select Board</option>
                          {boards.map((item) => {
                            return (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Your Name"
                          onChange={(e) => setUserName(e.target.value)}
                          name="username"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <Form.Control
                          type="number"
                          min="0"
                          placeholder="Your Phone"
                          onChange={(e) => setUserPhone(e.target.value)}
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Form.Control
                          as="textarea"
                          placeholder="Your Message"
                          onChange={(e) => setUserMessage(e.target.value)}
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <Alert
                        variant="danger"
                        show={show_fail}
                        onClose={() => setShowSucc(false)}
                        dismissible
                        className="px-3 py-2 f-13"
                      >
                        <Alert.Heading className="f-13 mb-0">
                          <i className="fas fa-exclamation-triangle me-2"></i>
                          <strong>Try Again</strong>
                        </Alert.Heading>
                        Failed to submit your query. Refresh the page and try
                        again!
                      </Alert>
                      <Alert
                        variant="success"
                        show={show_succ}
                        onClose={() => setShowFail(false)}
                        dismissible
                        className="px-3 py-2 f-13"
                      >
                        <Alert.Heading className="f-13 mb-0">
                          <i className="fas fa-check me-2"></i>
                          <strong>Thanks</strong>
                        </Alert.Heading>
                        Your query Submitted successfully. We will contact you
                        soon.
                      </Alert>
                    </Col>
                    <Col lg={12}>
                      <Button
                        variant="danger"
                        className="mt-3 f-16 shadow-none"
                        type="submit"
                      >
                        {submitStatus ? (
                          <Spinner as="span" animation="border" size="sm" />
                        ) : (
                          <i className="far fa-paper-plane"> Submit </i>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </section>

      <section className="pt-3 map-bg bg-theme-red">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={3}>
              <Image alt="dhurina" src={MobappImage} />
            </Col>
            <Col md={6} className="downloadappbtnmob mb-4 schoolinner-mob-app">
              <div className="section-title mb-4">
                <h2 className=" text-start text-white pb-2">
                  Download Dhurina App
                </h2>
                <h5 className="text-white fw-medium f-22">
                  Get Amazing Offers, Free Notes and scholarship
                </h5>
              </div>
              <Applink></Applink>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Schoolhome;
