import React from 'react';
import { Link } from "react-router-dom";
import { Button, Container, Col, Row,  Breadcrumb } from 'react-bootstrap';
import Innerheader from '../../include/header/innerHeader';
import Footer from '../../include/footer/footer';
import Mobapk from './../../assets/apk/dhurina-app.apk';
import Mobapk1 from './../../assets/apk/app-arm64-v8a-release.apk';
import Mobapk2 from './../../assets/apk/app-armeabi-v7a-release.apk';
import Mobapk3 from './../../assets/apk/app-x86-release.apk';
import Mobapk4 from './../../assets/apk/app-x86_64-release.apk';




const Downloadfile = () => {

   

  return (
    <>
       <Innerheader></Innerheader> 
         
        
        <section className="breadcrumb-area pb-4 banner-area innerpagesbanner">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={12} className="align-self-center">
                        <Breadcrumb classNameName="mb-1">
                            <Breadcrumb.Item href="/">File</Breadcrumb.Item>
                            <Breadcrumb.Item active>Download file</Breadcrumb.Item>
                        </Breadcrumb>
                        <Row>
                        <Col md={6}>
                           <Link to={Mobapk}  target="_blank" download><Button className="w-100 mt-4 btn-block loginshadowmain">Download App 1</Button> </Link>    
                        </Col>
                        <Col md={6}>
                        <Link to={Mobapk1}  target="_blank" download><Button className="w-100 mt-4 btn-block loginshadowmain">Download App 2</Button> </Link>    
                        </Col>
                        </Row>
                        <Row>
                        <Col md={6}>
                           <Link to={Mobapk2}  target="_blank" download><Button className="w-100 mt-4 btn-block loginshadowmain">Download App 3</Button> </Link>    
                        </Col>
                        <Col md={6}>
                        <Link to={Mobapk3}  target="_blank" download><Button className="w-100 mt-4 btn-block loginshadowmain">Download App 4</Button> </Link>    
                        </Col>
                        </Row>
                        <Row>
                        <Col md={6}>
                           <Link to={Mobapk4}  target="_blank" download><Button className="w-100 mt-4 btn-block loginshadowmain">Download App 5</Button> </Link>    
                        </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
        
         <Footer></Footer>
    </>
  );
}

export default Downloadfile;
