import React, {useState, useEffect, useCallback} from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { Container, Col, Row, Image, Form, Button, Alert, Spinner } from 'react-bootstrap';
import MainLogo from '../../assets/img/logo.png';
import TestImage from './../../assets/img/Test.jpeg';
import PrizeImage from './../../assets/img/prize.jpeg';
import ApiCall from '../../api/callApi';
// import PDF from './../../assets/pdf/sample.pdf';

const TestRegister=()=>{

    
	const [name , setName] = useState('')
	const [otp_modal,setOtpModal]=useState('none')
	const [over_modal,setOverModal]=useState(false)
	const [regsiter_modal,setRegisterModal]=useState('flex')
	const [otp,setOtp]=useState('')
	const [mobile , setMobile] = useState('')
	const [district , setDistrict] = useState('')
	const [checkstatus , setCheckStatus] = useState(true)
    const [submitStatus,setSubmitStatus] = useState(false) 
	const [show_succ, setShowSucc] = useState(false);
	const [show_fail, setShowFail] = useState(false); 
	const [title , setTitle] = useState('')
	const [message , setMessage] = useState('')

	let navigate = useNavigate();
	
    
       
    useEffect(() => {
        getData();
    },[getData]);

    function getData(){
        ApiCall("","get","test_register_status",userdetail);
    };

	const userdetail = useCallback(response => 
        {
        if(response.data.status===200){ 
			if(response.data.register_status===0){
				setOverModal(true)
			}    
			else{
				setOverModal(false)
			}
        }
        else{
                console.log("error");           
        }
    },[])





	function handleSubmitForm(e,name,mobile,district){
        e.preventDefault();

		setShowSucc(false)
		setShowFail(false)

        localStorage.setItem('name', name);
		localStorage.setItem('mobile', mobile);
		localStorage.setItem('district', district);


        var data={
            "phone":mobile,
			"type":'test_register'
        }
		setSubmitStatus(true)
		ApiCall(data,"post","send_otp",sendotpcallback);
       
    }

	

	

	const sendotpcallback = useCallback(response => 
		{
			setTitle(response.data.title)
			setMessage(response.data.message)
			if(response.data.Status==='Success'){
				setRegisterModal('none')
				setOtpModal('flex')
			}
			else{
				setShowFail(true)
				setShowSucc(false)
			}
			 setSubmitStatus(false)
		},[])


	
    const test_user_register = useCallback(response => 
    {
        setTitle(response.data.title)
        setMessage(response.data.message)

        if(response.data.status===200){
            setShowSucc(true)
			let userId=response.data.data.id;
			navigate("/user-detail/"+userId);
        }
        else{
            setShowFail(true)
        }
        setSubmitStatus(false)
    },[])

	function verify_otp(){
		const body ={
			"phone":mobile,
			"otp":otp
		}
		ApiCall(body,"post","verify_otp",verifyotpcallback);
    }

	const resend_otp = ()=>{
		var data={
            "phone":mobile,
			"type":'test_register'
        }
		setSubmitStatus(true)
		ApiCall(data,"post","send_otp",sendotpcallback);
	}

	


	const verifyotpcallback = useCallback(response => 
		{
		   
			setMessage(response.data.message)
			if(response.data.status==='true'){
				setRegisterModal('none')
				  setSubmitStatus(true)	
				  var data={
					"mobile":localStorage.getItem('mobile'),
					"name":localStorage.getItem('name'),
					"district":localStorage.getItem('district')
				}
			
        		  ApiCall(data,"post","test_user_register",test_user_register);
			}
			else{
				setShowFail(true)
				setShowSucc(false)
			}
			
		},[])


  

  return (
	

	
    <form onSubmit={(e)=>handleSubmitForm(e,name,mobile,district)}>
	 <Container fluid className="login-bg minheightlogin">
	{!over_modal ?
		<div>
			<div className="row" style={{display:regsiter_modal}}>
				<Row  className="align-items-center">
					<Col md={6}>
						<Image src={TestImage} alt="img" style={{marginBottom:'20%'}} />
					</Col>
					<Col md={6}>
						<div className="p-3 p-sm-5 my-2 m-sm-5 bg-white loginboxshadow">
							<Image src={MainLogo} alt="img" className="mb-2" />
							{/* <h3 className="f-25 mt-4 mb-4">Create New Account!<br /><span className="text-muted f-18">Join Dhurina to accelerate your Skills.</span></h3> */}

								<div className="row">
									<Col md={12}>
										<div className="mb-2">
											<Form.Label className="f-12 fw-medium mb-1">Name <span className="text-danger">*</span></Form.Label>
											<div className="login-form">
												<Form.Control type="text" placeholder="Enter your Full Name" onChange={(e)=>setName(e.target.value)} required/>
												<i className="fa fa-user-circle"></i>
											</div> 
										</div>  
									</Col>
									
									<Col md={12}>
										<div className="mb-2">
											<Form.Label className="f-12 fw-medium mb-1">Mobile No <span className="text-danger">*</span></Form.Label>
											<div className="login-form">
												<Form.Control type="number" min="0" placeholder="Enter your Mobile No "  onChange={(e)=>setMobile(e.target.value)} required/>
												<i className="fa fa-mobile-alt"></i>
											</div> 
										</div>  
									</Col>
									<Col md={12}>
										<div className="mb-2">
											<Form.Label className="f-12 fw-medium mb-1">District</Form.Label>
											<div className="login-form">
												<Form.Control type="text" placeholder="Enter your district"  onChange={(e)=>setDistrict(e.target.value)} required/>
												<i className="fa fa-envelope-open-text"></i>
											</div> 
										</div>  
									</Col>
								</div>
							
							<div className="single-form-check form-check loginrember">
								<Form.Control className="form-check-input p-0" type="checkbox"  id="Accept" checked={checkstatus}  onChange={(e)=>setCheckStatus(!checkstatus)}/>
								<Form.Label className="form-check-label" htmlFor="Accept">I Accept the <Link to="/#" className="fw-bold" >Terms And Conditions</Link></Form.Label>
							</div>

							<Alert variant="success"  show={show_succ} onClose={() => setShowSucc(false)} dismissible className="px-3 py-2 f-13">
								<Alert.Heading className="f-13 mb-0">
									<i className="fas fa-exclamation-triangle me-2"></i><strong>{title}</strong>
								</Alert.Heading>
								{message}
							</Alert>
							<Alert variant="danger"  show={show_fail} onClose={() => setShowFail(false)} dismissible className="px-3 py-2 f-13">
								<Alert.Heading className="f-13 mb-0">
									<i className="fas fa-check me-2"></i><strong>{title}</strong>
								</Alert.Heading>
								{message}
							</Alert>

							<Button variant="base-light" className="w-100 mt-4 btn-block loginshadowmain" type="submit">
								{submitStatus?<Spinner as="span" animation="border" size="sm" />:<i className="far fa-paper-plane"> Register </i> }
							</Button>&nbsp;&nbsp;
							<div>
							<Image src={PrizeImage} alt="img" />
							</div>
							
							{/* <p className="f-12 text-center mt-4 text-muted">
								Already have an account? <a href="https://admin.dhurina.net/login" className="fw-bold f-13">Log In</a> 
							</p>  */}
						</div>  
					</Col> 
				</Row> 
			</div>

			<div className="row" style={{display:otp_modal}}>
				<Row  className="align-items-center">
					<Col md={6}>
						<Image src={TestImage} alt="img" />
					</Col>
					<Col md={6}>
						<div className="p-3 p-sm-5 my-2 m-sm-5 bg-white loginboxshadow">
							<Image src={MainLogo} alt="img" className="mb-2" />
							{/* <h3 className="f-25 mt-4 mb-4">Create New Account!<br /><span className="text-muted f-18">Join Dhurina to accelerate your Skills.</span></h3> */}

								<div className="row">
									<Col md={12}>
										<div className="mb-2">
											<Form.Label className="f-12 fw-medium mb-1">Enter OTP <span className="text-danger">*</span></Form.Label>
											<div className="d-flex justify-content-between">
												<Form.Control onKeyPress={(event) => {
													if (!/[0-9]/.test(event.key)) {
													event.preventDefault();
													}
												}} maxlength="5"  onChange={(e)=>setOtp(e.target.value)} />
											</div> 
										</div>  
									</Col>
									
									<Alert variant="danger"  show={show_fail} onClose={() => setShowFail(false)} dismissible className="px-3 py-2 f-13">
										<Alert.Heading className="f-13 mb-0">
											<i className="fas fa-exclamation-triangle me-2"></i><strong>{title}</strong>
										</Alert.Heading>
										{message}
									</Alert>
									<Alert variant="success"  show={show_succ} onClose={() => setShowSucc(false)} dismissible className="px-3 py-2 f-13">
										<Alert.Heading className="f-13 mb-0">
											<i className="fas fa-check me-2"></i><strong>{title}</strong>
										</Alert.Heading>
										{message}
									</Alert>
								</div>
							
							<Button variant="base-light" className="w-100 mt-4 btn-block loginshadowmain" onClick={()=>verify_otp()}>Verify</Button> 
								<p className="f-12 text-center mt-4 text-muted"></p>
								<Button variant="base-light" className="w-100 mt-4 btn-block loginshadowmain" onClick={()=>resend_otp()}>Resend Otp</Button> 
							<div>&nbsp;&nbsp;
							<Image src={PrizeImage} alt="img" />
							</div>
							
							{/* <p className="f-12 text-center mt-4 text-muted">
								Already have an account? <a href="https://admin.dhurina.net/login" className="fw-bold f-13">Log In</a> 
							</p>  */}
						</div>  
					</Col> 
				</Row> 
			</div>
		</div>:
		
		<div className="row">
				<Row  className="align-items-center">
					<Col md={6}>
						<Image src={TestImage} alt="img" style={{marginBottom:'20%'}} />
					</Col>
					<Col md={6}>
						<div className="p-3 p-sm-5 my-2 m-sm-5 bg-white loginboxshadow" style={{textAlign:'center'}}>
							<Image src={MainLogo} alt="img" className="mb-2" />
							<div>

							</div>

							<h3 style={{textAlign:'center'}}>Registration Over</h3>

							{/* <a href={PDF}><Button variant="base-light" className="w-100 mt-4 btn-block loginshadowmain">
					        	<i className="far fa-eye"> View Result </i> 
							</Button></a> */}
							<div>
							<Image src={PrizeImage} alt="img"   style={{marginTop:'20%'}}/>
							</div>
							
							{/* <p className="f-12 text-center mt-4 text-muted">
								Already have an account? <a href="https://admin.dhurina.net/login" className="fw-bold f-13">Log In</a> 
							</p>  */}
						</div>  
					</Col> 
				</Row> 
			</div>
	}

	</Container>
	
	

	</form>
  );
}

export default TestRegister;

    
    
	
     
     
      