import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  forwardRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Image, Col, Row, Button, Navbar } from "react-bootstrap";
import Innertopmenu from "./innerTopMenu";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const Innerheader = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const innertopchild = useRef();
  const { onSearch } = props;
  const [innerCartArray, setInnerCartArray] = useState([]);

  const innerHeadFunction = (cartData) => {
    innertopchild.current.innerTopFunction(cartData);
  };

  useImperativeHandle(ref, () => ({
    innerHeadFunction: innerHeadFunction,
  }));

  useEffect(() => {
    setInnerCartArray(props.cartData);
  }, []);

  const backFunction = (e) => {
    navigate(-1);
  };

  // console.log("check in the inner header ",innerCartArray)

  return (
    <>
      <header className="navbar-area">
        <Innertopmenu
          onSearch={(e) => onSearch(e)}
          cartData={innerCartArray}
          ref={innertopchild}
        ></Innertopmenu>

        <Navbar
          expand="lg"
          fixed="top"
          className="d-block d-lg-none mob-header mob-headercourseinner"
        >
          <Container className="nav-container d-block">
            <Row className="align-items-center">
              <Col xs={6}>
                <Button variant="mobile-icon">
                  <span onClick={(e) => backFunction(e)}></span>
                </Button>
              </Col>

              <Col xs={6} className="text-end pt-1">
                <Link to="/">
                  <Image
                    src={Image_URL + "img/home.png"}
                    alt="dhurina app image"
                    width="20px"
                  />
                </Link>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </header>
    </>
  );
});

export default Innerheader;
