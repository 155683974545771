import React from "react";

const Disclaimer = () => {
  window.scrollTo(0, 0);
  return (
    <div className="py-5 py-4">
      <div className="custom_container container">
        <h2 className="fw-bold font_size text_gradient ff_inter fs-1 mb-0">
          Interpretation and Definitions
        </h2>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural. Definitions
        </p>
        <p className="ff_inter fw-bold text_dark_grey fs-6 mb-0 mt-4">
          For the purposes of this Disclaimer:
        </p>

        <ul className="mt-1">
          <li className="pt-2">
            <a href="#">
              Company (referred to as either "the Company", "We", "Us" or "Our"
              in this Disclaimer) refers to DHURINA VENTURES PRIVATE LIMITED,
              Dhurina, Near Sirdhan Road, Dhingsara, Fatehabad, Haryana, 125053.
            </a>
          </li>
          <li className="pt-2">
            <a href="#"> Service refers to the Website.</a>
          </li>
          <li className="pt-2">
            <a href="#">
              You means the individual accessing the Service, or the company, or
              other legal entity on behalf of which such individual is accessing
              or using the Service, as applicable.
            </a>
          </li>
          <li className="pt-2">
            <a href="#">
              Website refers to Dhurina, accessible from 
              <a className="text_gradient fw-bold" href="#" target="_black">
                https://dhurina.net/
              </a>
            </a>
          </li>
        </ul>
        <h2 className="fw-bold font_size mt-4 text_gradient ff_inter fs-3 mb-0">
          Disclaimer
        </h2>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          The information contained on the Service is for general information
          purposes only. The Company assumes no responsibility for errors or
          omissions in the contents of the Service.
        </p>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          In no event shall the Company be liable for any special, direct,
          indirect, consequential, or incidental damages or any damages
          whatsoever, whether in an action of contract, negligence or other
          tort, arising out of or in connection with the use of the Service or
          the contents of the Service. The Company reserves the right to make
          additions, deletions, or modifications to the contents on the Service
          at any time without prior notice.
        </p>
        <h2 className="fw-bold font_size mt-4 text_gradient ff_inter fs-3 mb-0">
          External Links Disclaimer
        </h2>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          The Service doesn’t contain links to external websites that are
          provided or maintained by or in any way affiliated with the Company.
        </p>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          Please note that the Company does not guarantee the accuracy,
          relevance, timeliness, or completeness of any information on these
          external websites.
        </p>
        <h2 className="fw-bold font_size mt-4 text_gradient ff_inter fs-3 mb-0">
          Errors and Omissions Disclaimer
        </h2>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          The information given by the Service is for general guidance on
          matters of interest only. Even if the Company takes every precaution
          to ensure that the content of the Service is both current and
          accurate, errors can occur. Plus, given the changing nature of laws,
          rules and regulations, there may be delays, omissions or inaccuracies
          in the information contained on the Service.
        </p>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          The Company is not responsible for any errors or omissions, or for the
          results obtained from the use of this information.
        </p>
        <h2 className="fw-bold font_size mt-4 text_gradient ff_inter fs-3 mb-0">
          Fair Use Disclaimer
        </h2>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          The Company may use copyrighted material which has not always been
          specifically authorized by the copyright owner. The Company is making
          such material available for criticism, comment, news reporting,
          teaching, scholarship, or research.
        </p>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          The Company believes this constitutes a "fair use" of any such
          copyrighted material as provided for in section 107 of the United
          States Copyright law.
        </p>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          If You wish to use copyrighted material from the Service for your own
          purposes that go beyond fair use, You must obtain permission from the
          copyright owner.
        </p>
        <h2 className="fw-bold font_size mt-4 text_gradient ff_inter fs-3 mb-0">
          Views Expressed Disclaimer
        </h2>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          The Service may contain views and opinions which are those of the
          authors and do not necessarily reflect the official policy or position
          of any other author, agency, organization, employer or company,
          including the Company.
        </p>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          Comments published by users are their sole responsibility and the
          users will take full responsibility, liability and blame for any libel
          or litigation that results from something written in or as a direct
          result of something written in a comment. The Company is not liable
          for any comment published by users and reserves the right to delete
          any comment for any reason whatsoever.
        </p>
        <h2 className="fw-bold font_size mt-4 text_gradient ff_inter fs-3 mb-0">
          No Responsibility Disclaimer
        </h2>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          The information on the Service is provided with the understanding that
          the Company is not herein engaged in rendering legal, accounting, tax,
          or other professional advice and services. As such, it should not be
          used as a substitute for consultation with professional accounting,
          tax, legal or other competent advisers.
        </p>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          In no event shall the Company or its suppliers be liable for any
          special, incidental, indirect, or consequential damages whatsoever
          arising out of or in connection with your access or use or inability
          to access or use the Service.
        </p>
        <h2 className="fw-bold font_size mt-4 text_gradient ff_inter fs-3 mb-0">
          Use at Your Own Risk" Disclaimer
        </h2>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          All information in the Service is provided "as is", with no guarantee
          of completeness, accuracy, timeliness or of the results obtained from
          the use of this information, and without warranty of any kind, express
          or implied, including, but not limited to warranties of performance,
          merchantability and fitness for a particular purpose.
        </p>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-3">
          The Company will not be liable to You or anyone else for any decision
          made or action taken in reliance on the information given by the
          Service or for any consequential, special or similar damages, even if
          advised of the possibility of such damages.
        </p>

        <h2 className="fw-bold font_size mt-5 text_gradient ff_inter fs-3 mb-0">
          Contact Us
        </h2>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-1">
          If you have any questions about this Disclaimer, You can contact Us:
        </p>
        <p className="ff_inter text_dark_grey fs-6 mb-0 mt-1">
          By email: 
          <a
            className="text_gradient fw-bold"
            href="mailTo:support@dhurina.net"
          >
            support@dhurina.net
          </a>
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
