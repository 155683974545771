import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Image,
  Modal,
  Row,
  Col,
  Tabs,
  Tab,
  Button,
  Form,
} from "react-bootstrap";
import { initialize, makePayment } from "../../components/Payment";
import ApiCall from "../../api/callApi";
const BOOK_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "hardcopy/image/";

const CartModal = (props) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [userstate, setUserState] = useState("");
  const [pincode, setPincode] = useState("");
  const [landmark, setLandMark] = useState("");
  const [addresslist, setAddressList] = useState([]);
  const [slelectedAddress, setslelectedAddress] = useState("");
  const [currentTab, setCurrentTab] = useState("existing");
  const [addresscheck, setAddressChecked] = useState(false);
  const [p_detail, setPricingDetail] = useState("");
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [weight_slab, setWeightSlab] = useState("[]");
  const [cartData, setCartData] = useState({
    data: [],
    order_total: 0,
    grand_total: 0,
    shipping_charges: 0,
    totalpackingcost: 0,
    total_weight: 0,
  });

  const [paymentData, setPaymentData] = useState({
    token: "",
    order: "",
    mid: "",
    amount: "",
  });

  const navigate = useNavigate();
  const [states, setState] = useState([]);
  const myCartFunction = (e) => {
    setAddressChecked(true);
    setslelectedAddress(e);
    calculateShipping1(cartData);
  };

  const calculateShippingCharges = (weight_slab, charge) => {
    let price;
    weight_slab?.map((item) => {
      if (charge <= item.to && charge >= item.from) {
        price = Number(item.price);
      }
    });
    return price;
  };

  const calculateShipping1 = (cartData) => {

    const price_details = p_detail;
    var pricing = price_details;
    var TotalWeight = 0;
    var weight = 0;
    var GrandTotal = 0;
    var pack_cost = 0;
    var ship_cost = 0;
    var order_total = cartData.order_total;

    cartData.data.map((item) => {
      weight = parseInt(item.type_data.weight);
      TotalWeight = TotalWeight + weight;
    });

    // calculate packaging cost
    TotalWeight = parseFloat(TotalWeight);
    var PackingCost = parseFloat(pricing.packaging_cost);
    if (parseFloat(TotalWeight) < 2000) {
      pack_cost = parseFloat(PackingCost);
    } else {
      var quotient = parseInt(parseFloat(TotalWeight) / parseFloat(2000));
      var remainder = parseFloat(TotalWeight) % parseFloat(2000);
      if (remainder === 0) {
        pack_cost = parseFloat(PackingCost) * parseFloat(quotient);
      } else {
        var cost = parseFloat(PackingCost) * parseFloat(quotient);
        pack_cost = PackingCost + cost;
      }
    }

    // calculate shipping cost
    var InitialCost = parseFloat(pricing.intial_cost);
    var IncrementCost = parseFloat(pricing.increment_cost);

    if (parseFloat(TotalWeight) < 500) {
      ship_cost = InitialCost;
    } else {
      var Weight = parseFloat(TotalWeight) - 500;
      var quotient = parseInt(Weight / 500);
      var remainder = parseFloat(Weight) % 500;

      if (remainder === 0) {
        ship_cost = InitialCost + parseFloat(IncrementCost) * parseFloat(quotient);
      } else {
        var cost = parseFloat(IncrementCost) * parseFloat(quotient);
        ship_cost = parseFloat(InitialCost) + parseFloat(cost) + parseFloat(IncrementCost);
      }
    }

    GrandTotal =parseFloat(order_total) + parseFloat(ship_cost) + parseFloat(pack_cost);
    GrandTotal = Math.round(GrandTotal);
    setCartData({
      ...cartData,
      order_total: order_total,
      grand_total: GrandTotal,
      shipping_charges: ship_cost,
      totalpackingcost: pack_cost,
      total_weight: TotalWeight,
      data: cartData.data,
    });

    let price = parseFloat(GrandTotal);
    price = Math.round(price);
    initialize(
      initializeCallback,
      price,
      localStorage.getItem("mobile"),
      localStorage.getItem("id")
    );
  };

  useEffect(() => {

    if (localStorage.getItem("id") !== "") {
      let user_id = localStorage.getItem("id");
      let body = {
        user_id: user_id,
      };
      ApiCall(body, "post", "get_cart", getcartcallback);
    }
    ApiCall("", "get", "states", get_states);
  }, [currentTab]);

  const get_states = useCallback((response) => {
    if (response.data.status === 200) {
      setState(response.data.data);
    } else {
      console.log("error");
    }
  });

  const getcartcallback = useCallback((response) => {
    setLoading(false)
    setCurrentTab("existing");
    if (response.data.address.length > 0) {
      setPricingDetail(response.data.pricing);
      var temp = [];
      response.data.address.map((item, index) => {
        let itemobj = item;
        let obj = JSON.parse(item.address);
        itemobj.address = obj;
        temp.push(itemobj);
      });
      setAddressList(temp);
    } else {
      setAddressChecked(false);
    }
    if (response.data.cart_data.length > 0) {
      setWeightSlab(response.data.weight_slab);
      var total = 0;
      var total_weights = 0;
      let arr = [...response.data.cart_data];
      arr.map((item, index) => {
        total = total + item.price * item.quantity;
        total_weights = total_weights + item?.type_data?.weight * item.quantity;
      });
      setCartData({
        ...cartData,
        data: arr,
        order_total: total,
        shipping_charges: calculateShippingCharges(
          JSON.parse(response.data.weight_slab),
          total_weights
        ),
        grand_total:
          total +
          calculateShippingCharges(
            JSON.parse(response.data.weight_slab),
            total_weights
          ),
      });
    }
  });

  function handleSubmitForm(e) {
    e.preventDefault();

    var obj = Object();
    obj.name = name;
    obj.phone = phone;
    obj.address = address;
    obj.address2 = address2;
    obj.city = city;
    obj.state = userstate;
    obj.pincode = pincode;
    obj.landmark = landmark;

    if (localStorage.getItem("id") !== null) {
      var user_id = localStorage.getItem("id");
    }

    var data = {
      user_id: user_id,
      address: JSON.stringify(obj),
    };
    setLoading(true)
    ApiCall(data, "post", "add_address", addresscallback);
  }

  const addresscallback = useCallback((response) => {
    if (response.data.status === true) {
      props.notify(response.data.message);
      setAddressList(response.data.data);
      if (localStorage.getItem("id") !== null) {
        let user_id = localStorage.getItem("id");
        let body = {
          user_id: user_id,
        };
        setCurrentTab("existing");
        ApiCall(body, "post", "get_cart", getcartcallback);
      }
    } else {
      setLoading(false)
      alert("error");
    }
  });

  var user_id = "";
  const deleteCart = (id, index) => {
    setDeleteIndex(index);
    deleteCartItem(index);
    if (localStorage.getItem("id")) {
      user_id = localStorage.getItem("id");
    } else {
      user_id = "";
    }
    let body = {
      user_id: user_id,
      cart_id: String(id),
      index: index,
    };
    ApiCall(body, "post", "delete_cart", deletecartCallback);
  };

  const deletecartCallback = useCallback((response) => {
    if (response.data.status === true) {
      props.notify("Book item delete successfully");
    } else {
      alert("error");
    }
  });

  const deleteAddress = (address_id) => {
    var body = {
      user_id: localStorage.getItem("id"),
      address_id: address_id,
    };
    setLoading(true)
    ApiCall(body, "post", "delete_address", deleteaddresscallback);
  };

  const deleteaddresscallback = useCallback((response) => {
    setLoading(false)
    if (response.data.status === true) {
      props.notify(response.data.message);
      if (response.data.address.length > 0) {
        setPricingDetail(response.data.pricing);
        var temp = [];
        response.data.address.map((item, index) => {
          let itemobj = item;
          let obj = JSON.parse(item.address);
          itemobj.address = obj;

          temp.push(itemobj);
        });
        setAddressList(temp);
      } else {
        setAddressList([]);
        setAddressChecked(false);
      }
    } else {
      alert("error");
    }
  });

  const deleteCartItem = (index) => {
    const {
      data,
      total_weight,
      packaging_cost,
      shipping_charges,
      order_total,
      grand_total,
    } = cartData;

    const newOrderTotal = order_total - parseInt(data[index].price);

    let newData;

    if (data.length === 1) {
      newData = [];
    } else {
      newData = [...data.slice(0, index), ...data.slice(index + 1)];
    }

    var total = 0;
    var total_weights = 0;
    var arr = [...newData];
    arr.map((item, index) => {
      total = total + item.price * item.quantity;
      total_weights = total_weights + item?.type_data?.weight * item.quantity;
    });
    setCartData({
      data: newData,
      total_weight,
      packaging_cost,
      shipping_charges: calculateShippingCharges(
        JSON.parse(weight_slab),
        total_weights
      ),
      order_total: newOrderTotal,
      grand_total:
        total +
        calculateShippingCharges(JSON.parse(weight_slab), total_weights),
    });
    return;

    const price_details = p_detail;
    let price = parseInt(data[index].price);
    let weight = parseFloat(data[index].weight);

    var pricing = price_details;
    var TotalWeight = total_weight;

    var GrandTotal = 0;
    var pack_cost = 0;
    var ship_cost = 0;
    var OrderTotal = parseInt(order_total);
    OrderTotal = OrderTotal - price;

    TotalWeight = TotalWeight - weight;

    var arr = [...data];
    if (arr.length === 1) {
      arr = [];
    } else {
      arr.splice(index, 1);
    }
    //

    // calculate packaging cost
    TotalWeight = parseFloat(TotalWeight);
    var PackingCost = parseFloat(price_details.packaging_cost);
    if (parseFloat(TotalWeight) < 2000) {
      pack_cost = parseFloat(PackingCost);
    } else {
      var quotient = parseInt(parseFloat(TotalWeight) / parseFloat(2000));
      var remainder = parseFloat(TotalWeight) % parseFloat(2000);
      if (remainder === 0) {
        pack_cost = parseFloat(PackingCost) * parseFloat(quotient);
      } else {
        var cost = parseFloat(PackingCost) * parseFloat(quotient);
        pack_cost = PackingCost + cost;
      }
    }

    // calculate shipping cost
    var InitialCost = parseFloat(price_details.intial_cost);
    var IncrementCost = parseFloat(price_details.increment_cost);

    if (parseFloat(TotalWeight) < 500) {
      ship_cost = InitialCost;
    } else {
      var Weight = parseFloat(TotalWeight) - 500;
      var quotient = parseInt(Weight / 500);
      var remainder = parseFloat(Weight) % 500;
      if (remainder === 0) {
        ship_cost =
          InitialCost + parseFloat(IncrementCost) * parseFloat(quotient);
      } else {
        var cost = parseFloat(IncrementCost) * parseFloat(quotient);
        ship_cost =
          parseFloat(InitialCost) +
          parseFloat(cost) +
          parseFloat(IncrementCost);
      }
    }

    GrandTotal =
      parseFloat(OrderTotal) + parseFloat(ship_cost) + parseFloat(pack_cost);
    GrandTotal = Math.round(GrandTotal);
    setCartData({
      ...cartData,
      order_total: OrderTotal,
      grand_total: GrandTotal,
      shipping_charges: ship_cost,
      totalpackingcost: pack_cost,
      total_weight: TotalWeight,
      data: arr,
      shipping_charges: 0,
      grand_total: 0,
    });

    price = GrandTotal;
    initialize(
      initializeCallback,
      price,
      localStorage.getItem("mobile"),
      localStorage.getItem("id")
    );
  };

  const updateQuantity = (quantity, type_id) => {
    let user_id = localStorage.getItem("id");
    var body = {
      user_id: user_id,
      quantity: quantity,
      type_id: type_id,
      pages: 5,
    };
    ApiCall(body, "post", "quantity_update", updatequantitycallback);
  };

  const updatequantitycallback = useCallback((response) => {
    if (response.data.status === 200) {
      props.notify(response.data.message);
      if (localStorage.getItem("id") !== null) {
        let user_id = localStorage.getItem("id");
        let body = {
          user_id: user_id,
        };
        ApiCall(body, "post", "get_cart", getcartcallback);
      }
    } else {
      alert("error");
    }
  });

  const initializeCallback = useCallback((response) => {
    let obj = {
      token: response.token,
      order: response.order,
      mid: response.mid,
      amount: response.amount,
    };
    setPaymentData(obj);
  });

  const updatepayment = (response) => {
    var data = {
      status: response.STATUS,
      price: response.TXNAMOUNT,
      order_id: paymentData.order,
    };
    ApiCall(data, "post", "update_payment", updatepaymentcallback);
  };

  const updatepaymentcallback = useCallback((response) => {
    props.viewLoader(false);
    if (response.data.status == 200) {
      var state = {
        txnid: response.data.course_data.txnid,
        amount: response.data.course_data.amount,
        course_name: response.data.course_name,
        transaction_status: response.data.transaction_status,
      };
      navigate("/payment-verification", { state });
    }
    if (response.data.status == 500) {
      var state = {
        txnid: "",
        amount: "",
        course_name: "",
        transaction_status: 0,
      };
      navigate("/payment-verification", { state });
    }
  });

  const myCallback = useCallback((response, status) => {
    if (status) {
      if (response.STATUS === "TXN_SUCCESS") {
        updatepayment(response);
      } else {
        updatepayment(response);
      }
    } else {
       setLoading(false);
    }
  });

  const makeBookPayment = () => {
    props.viewLoader(true);
    webhookInsert();
  };

  const webhookInsert = () => {
    var arr = [];
    cartData.data.map((item) => {
      var b_obj = new Object();
      b_obj.type = item.type;
      b_obj.type_id = item.type_id;
      b_obj.amount = item.price;
      b_obj.quantity = item.quantity;
      arr.push(b_obj);
    });

    let price = parseFloat(cartData.grand_total);
    price = Math.round(price);

    let charges = Object();
    charges.ship_charge = cartData.shipping_charges;
    charges.pack_charge = cartData.totalpackingcost;
    charges.order_total = cartData.order_total;

    let addressStr = `${slelectedAddress.address.name} , ${slelectedAddress.address.address} , ${slelectedAddress.address.landmark} , ${slelectedAddress.address.address2} , ${slelectedAddress.address.city} , ${slelectedAddress.address.state} - ${slelectedAddress.address.pincode}, Phone : ${slelectedAddress.address.phone}`;

    var body = {
      type_id: JSON.stringify(arr),
      type: "hardcopy",
      amount: price,
      details: JSON.stringify(charges),
      address: addressStr,
      txnid: paymentData.order,
      gateway: "paytm",
      user_id: localStorage.getItem("id"),
    };

    ApiCall(body, "post", "checkout_order", checkoutcallback);
  };

  const checkoutcallback = useCallback((response) => {
    if (response.data.status === "true") {
      makePayment(paymentData, myCallback);
    } else {
      alert(response.data.message);
    }
  });

  return (
    <>
      <Modal
        show={props.cartshow}
        onHide={props.onHide}
        cartitem={props.cartItem}
        cartval="0"
        className="checkoutpopup"
      >
        <Modal.Header className="ms-3 pb-2 ps-0 border-bottom" closeButton>
          <Modal.Title>My Cart</Modal.Title>
        </Modal.Header>
        {cartData.data.length > 0 && (
          <Modal.Body className="mt-4 flex-column">
            {cartData.data.length > 0 &&
              cartData.data.map((item, index) => {
                return (
                  <div className="border-bottom pb-3 mb-3" key={item.id}>
                    <Row>
                      <Col xs={3}>
                        <Image
                          src={`${BOOK_IMAGE_URL}${item?.type_data?.image}`}
                          alt="homebook"
                        />
                      </Col>
                      <Col xs={8} className="ps-2">
                        <div className="single-course-wrap mb-0">
                          <div className="wrap-details p-0 border-0">
                            <Link to="/">
                              <h6>{item?.type_data?.title}</h6>
                            </Link>
                            <div className="price-wrap">
                              <div className="align-items-center row">
                                <div className="col-7">
                                  <span className="text-theme f-18 fw-semi-bold me-2">
                                    <i className="fa fa-rupee"></i>
                                    {item.price * item.quantity}
                                  </span>
                                </div>
                                <div className="col-5 cartselectqty">
                                  Qty:
                                  <select
                                    onChange={(e) =>
                                      updateQuantity(
                                        e.target.value,
                                        item.type_id
                                      )
                                    }
                                    value={item?.quantity}
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={1} className="pe-0">
                        <i
                          className="far fa-trash-alt text-danger"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => deleteCart(item.id, index)}
                        ></i>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </Modal.Body>
        )}

        {isLoading === true && (
          <Modal.Body className="mt-8 flex-column">
            <div className="vh_70">
              <div id="preloader">
                <div id="loader"></div>
              </div>
            </div>
          </Modal.Body>
        )}

        {cartData.data.length == 0 && (
          <Modal.Body className="mt-8 flex-column">
            No Data Found
          </Modal.Body>
        )}

        {cartData.data.length > 0 && (
          <Modal.Body>
            <Tabs
              defaultActiveKey={currentTab}
              id="Checkoutaddress"
              className="edl-nav nav-pills new-trending-course"
            >
              <Tab eventKey="existing" title="Existing Address">
                <div className="">
                  {addresslist.length > 0 &&
                    addresslist.map((value, index) => {
                      return (
                        <div className="single-course-wrap mb-3 bookscrousel mt-0" key={value.id}>
                          <div
                            className={`wrap-details ${
                              value === slelectedAddress
                                ? "wrap-details-selected"
                                : ""
                            }`}
                          >
                            <div className="pe-2">
                              <Row onClick={(e) => myCartFunction(value)}>
                                <Col xs={1}>
                                  <input
                                    type="radio"
                                    className="with-gap"
                                    name="extingadd"
                                    id={"addressonme1" + index}
                                  />
                                  <label htmlFor={"addressonme1" + index}></label>
                                </Col>
                                <Col xs={10}>
                                  <Link to="#">
                                    <h6 className="h-auto mb-2">
                                      {value.address.name}
                                    </h6>
                                    <p className="text-black-50 f-15 fw-medium mb-2">
                                      <i className="fas fa-phone-alt"></i>
                                      <span className="ps-1">
                                        {value.address.phone}
                                      </span>
                                    </p>
                                    <p className="text-black-50 f-15 fw-medium me-1">
                                      <i className="fas fa-map-marker-alt"></i>
                                      <span className="ps-1">
                                        {value.address.address},
                                        {value.address.address2},
                                        {value.address.city},
                                        {value.address.state},
                                        {value.address.pincode}
                                      </span>
                                    </p>
                                  </Link>
                                </Col>
                                <Col xs={1} className="pe-0">
                                  <i
                                    className="far fa-trash-alt text-danger pe-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => deleteAddress(value.id)}
                                  ></i>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  <div className="border-top pt-3 mt-auto">
                    <Row>
                      <Col xs={12}>
                        <div className="price-wrap">
                          <p className="f-12 fw-semi-bold d-block">
                            Total Order : <i className="fa fa-rupee"></i>
                            {cartData.order_total}
                          </p>
                          <p className="f-12 fw-semi-bold d-block">
                            Delivery Charge :<i className="fa fa-rupee"></i>
                            {cartData.shipping_charges}
                          </p>

                          <p className="f-16 fw-semi-bold d-block">
                            Grand Total :<i className="fa fa-rupee"></i>
                            {cartData.grand_total}
                          </p>
                          {/* <span className="f-20 fw-semi-bold d-block"><i className="fa fa-rupee"></i>{total}</span> */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        {addresscheck === true ? (
                          <button
                            className="btn btn-danger"
                            onClick={makeBookPayment}
                          >
                            Continue
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={() =>
                              props.notify("Please select address first")
                            }
                          >
                            Continue
                          </button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="addnew" title="Add New Address">
                <div className="contactform p-0 shadow-none ">
                  <form onSubmit={(e) => handleSubmitForm(e)}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="House No. Building Name"
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Road Name, Area Colony"
                      onChange={(e) => setAddress2(e.target.value)}
                      required
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      onChange={(e) => setCity(e.target.value)}
                    />
                    <Form.Select
                      className="form-control"
                      onChange={(e) => setUserState(e.target.value)}
                      required
                    >
                      <option>Select State</option>
                      {states.map((value) => {
                        return (
                          <option value={value.id} key={value.id}>
                            {value.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pincode"
                      onKeyPress={(e) => {
                        if (
                          !/[0-9]/.test(e.key) ||
                          e.target.value.length >= 6
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => setPincode(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      max="10"
                      placeholder="10 Digit Mobile Number"
                      onKeyPress={(e) => {
                        if (
                          !/[0-9]/.test(e.key) ||
                          e.target.value.length >= 10
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Landmark"
                      onChange={(e) => setLandMark(e.target.value)}
                    />
                    <div className="border-top pt-3 mt-auto">
                      <Row>
                        <Col xs={12}>
                          <Button
                            variant="base-light"
                            className="bg_gradient cursor_pointer mx-auto border-0 text-white ff_inter fs-6 d-block text-center px-3 py-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </form>
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default CartModal;
