import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Image,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import LoginImage from "./../../assets/img/login.jpg";
import MainLogo from "../../assets/img/logo.png";
// import OtpVerify from './OTP-verify';
import ApiCall from "../../api/callApi";

const Register = () => {
  const [states, setState] = useState([]);
  const [name, setName] = useState("");
  const [ref, setRef] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [user_state, setUserState] = useState("");
  const [checkstatus, setCheckStatus] = useState(true);
  const [submitStatus, setSubmitStatus] = useState(false);
  // const [show_succ, setShowSucc] = useState(false);
  // const [show_fail, setShowFail] = useState(false);
  // const [title , setTitle] = useState('')
  // const [message , setMessage] = useState('')

  useEffect(() => {
    getData();
  }, []);

  function getData(params) {
    ApiCall("", "get", "states", get_states);
  }

  function handleSubmitForm(e) {
    e.preventDefault();

    // setShowSucc(false)
    // setShowFail(false)

    var data = {
      name: name,
      ref_code: ref,
      number: mobile,
      email: email,
      address: address,
      password: password,
      state: user_state,
    };
    setSubmitStatus(true);
    ApiCall(data, "post", "add_address", addresscallback);
  }

  const addresscallback = useCallback((response) => {
    // setTitle(response.data.status)
    // setMessage(response.data.message)
    // setSubmitStatus(false)
    console.log(response);
  }, []);

  const get_states = useCallback((response) => {
    if (response.data.status === 200) {
      setState(response.data.data);
    } else {
      console.log("error");
    }
  });

  return (
    <form onSubmit={(e) => handleSubmitForm(e)}>
      <Container fluid className="login-bg minheightlogin">
        <Row className="align-items-center">
          <Col md={6} className="d-none d-sm-block text-center">
            <Image src={LoginImage} alt="logo image" />
            <p className="f-11 mb-0 mt-5  text-muted">
              © 2021 Dhurina Ventures Pvt Ltd. All Right Reserved.
            </p>
          </Col>
          <Col md={6}>
            <div className="p-3 p-sm-5 my-2 m-sm-5 bg-white loginboxshadow">
              <Image src={MainLogo} alt="logo image" className="mb-2" />
              <h3 className="f-25 mt-4 mb-4">
                Create New Account!
                <br />
                <span className="text-muted f-18">
                  Join Dhurina to accelerate your Skills.
                </span>
              </h3>

              <div className="row">
                <Col md={6}>
                  <div className="mb-2">
                    <Form.Label className="f-12 fw-medium mb-1">
                      Name <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="login-form">
                      <Form.Control
                        type="text"
                        placeholder="Enter your Full Name"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      <i className="fa fa-user-circle"></i>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-2">
                    <Form.Label className="f-12 fw-medium mb-1">
                      Reference
                    </Form.Label>
                    <div className="login-form">
                      <Form.Control
                        type="text"
                        placeholder="Enter your Reference Code"
                        onChange={(e) => setRef(e.target.value)}
                      />
                      <i className="fa fa-text-height"></i>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-2">
                    <Form.Label className="f-12 fw-medium mb-1">
                      Mobile No <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="login-form">
                      <Form.Control
                        type="number"
                        min="0"
                        placeholder="Enter your Mobile No "
                        onChange={(e) => setMobile(e.target.value)}
                        required
                      />
                      <i className="fa fa-mobile-alt"></i>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-2">
                    <Form.Label className="f-12 fw-medium mb-1">
                      Email-Id
                    </Form.Label>
                    <div className="login-form">
                      <Form.Control
                        type="text"
                        placeholder="Enter your Email-Id"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <i className="fa fa-envelope-open-text"></i>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-2">
                    <Form.Label className="f-12 fw-medium mb-1">
                      Address <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="login-form">
                      <Form.Control
                        type="text"
                        placeholder="Enter your Address"
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                      <i className="fa fa-map-marked-alt"></i>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-2">
                    <Form.Label className="f-12 fw-medium mb-1">
                      Password <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="login-form">
                      <Form.Control
                        type="text"
                        placeholder="Enter your Password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <i className="fa fa-lock"></i>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-2">
                    <Form.Label className="f-12 fw-medium mb-1">
                      Select State <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="login-form">
                      <Form.Select
                        className="form-control"
                        onChange={(e) => setUserState(e.target.value)}
                        required
                      >
                        <option>Select State</option>
                        {states.map((value) => {
                          return (
                            <option value={value.id} key={value.id}>
                              {value.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <i className="fa fa-map-marker-alt"></i>
                    </div>
                  </div>
                </Col>
              </div>

              <div className="single-form-check form-check loginrember">
                <Form.Control
                  className="form-check-input p-0"
                  type="checkbox"
                  id="Accept"
                  checked={checkstatus}
                  onChange={(e) => setCheckStatus(!checkstatus)}
                />
                <Form.Label className="form-check-label" htmlFor="Accept">
                  I Accept the{" "}
                  <Link to="/#" className="fw-bold">
                    Terms And Conditions
                  </Link>
                </Form.Label>
              </div>

              {/* <Alert variant="danger"  show={show_fail} onClose={() => setShowSucc(false)} dismissible className="px-3 py-2 f-13">
						<Alert.Heading className="f-13 mb-0">
							<i className="fas fa-exclamation-triangle me-2"></i><strong>{title}</strong>
						</Alert.Heading>
						{message}
					</Alert>
					<Alert variant="success"  show={show_succ} onClose={() => setShowFail(false)} dismissible className="px-3 py-2 f-13">
						<Alert.Heading className="f-13 mb-0">
							<i className="fas fa-check me-2"></i><strong>{title}</strong>
						</Alert.Heading>
						{message}
					</Alert> */}

              <Button
                variant="base-light"
                className="w-100 mt-4 btn-block loginshadowmain"
                type="submit"
              >
                {submitStatus ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  <i className="far fa-paper-plane"> Submit </i>
                )}
              </Button>

              <p className="f-12 text-center mt-4 text-muted">
                Already have an account?{" "}
                <a
                  href="https://admin.dhurina.net/login"
                  className="fw-bold f-13"
                >
                  Log In
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default Register;
