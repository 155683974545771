import React from "react";
import { Company } from "../helper/Helper";
import { Courses } from "../helper/Helper";
import { Products } from "../helper/Helper";
import { Coaching } from "../helper/Helper";
// import { MockTests } from "../helper/Helper";
import { Link } from "react-router-dom";

const Image_URL = process.env.REACT_APP_Bucket_URL;

const Footer = ({ location }) => {
  const pathParts = location.pathname.split("/");
  const playTestParam = pathParts[pathParts.length - 1];
  return (
    <>
      {playTestParam === "play-test" ? null : (
        <>
          <div className="bg_dark_blue">
            <div className="custom_container container py-2">
              <div className="row mt-3 pt-5">
                <div className="col-6 col-md-3">
                  <p className="ff_inter text_gradient fw-bold mb-0 fs-5">
                    Company
                  </p>
                  <ul className="ps-0 list_style_none">
                    {Company.map((item, index) => {
                      return (
                        <li key={index} className="mt-2">
                          <Link
                            className="ff_inter fs-6 text-light fw-light"
                            to={item.url}
                          >
                            {item.label}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="col-6 col-md-3 mt_footer_courses">
                  <p className="ff_inter text_gradient fw-bold fs-5 mb-0">
                    Courses
                  </p>

                  <ul className="ps-0 list_style_none">
                    {Courses.map((item, index) => {
                      return (
                        <li key={index} className="mt-2">
                          <a
                            className="ff_inter fs-6 text-light fw-light"
                            href={item.url}
                          >
                            {item.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-6 col-md-3">
                  <p className="ff_inter text_gradient fw-bold fs-5 mt-3 mt-md-0">
                    Products
                  </p>

                  <ul className="ps-0 list_style_none">
                    {Products.map((item, index) => {
                      return (
                        <li key={index} className="mt-2">
                          <Link
                            className="ff_inter fs-6 text-light fw-light"
                            to={item.url}
                          >
                            {item.label}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                  {/* <ul className="ps-0 mt-4 list_style_none">
                    <p className="ff_inter text_gradient fw-bold fs-5 mt-3 mt-md-0 mb-0">
                      Latest Mock Tests
                    </p>

                    {MockTests.map((item, index) => {
                      return (
                        <li key={index} className="mt-2">
                          <a
                            href={item.url}
                            className="ff_inter fs-6 text-light fw-light"
                          >
                            {item.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul> */}
                </div>
                <div className="col-6 col-md-3">
                  <p className="ff_inter text_gradient fw-bold mt-3 fs-5 mt-md-0 mb-0">
                    Blogs
                  </p>

                  <ul className="ps-0 list_style_none">
                    {Coaching.map((item, index) => {
                      return (
                        <li key={index} className="mt-2">
                          <a
                            href={item.url}
                            target="_blank"
                            className="ff_inter fs-6 text-light fw-light"
                          >
                            {item.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="mt-4 mt-lg-5 d-flex flex-column align-items-start justify-content-between">
                <Link to="/">
                  <img
                    width={150}
                    height={133}
                    className=""
                    src={Image_URL + "website/dhurina_footer_logo.png"}
                    alt="FbIcon"
                  />
                </Link>
                <p className="ff_inter fs-6 text-light fw-light mt-3 mb-0 footer_address">
                  Dhurina Ventures Pvt Ltd. Ground Floor, MGF Metropolis Mall,
                  Mehrauli-Gurgaon Rd, Gurugram-Haryana 122002.
                </p>
                <p className="ff_inter fs-6 text-light fw-light mt-2 mb-0 footer_address">
                  Helpline - 98121-60061
                </p>
                {/* <p>Email - Support@dhurina.net</p> */}
                <a
                  className="ff_inter fs-6 text-light fw-light mt-2 footer_address"
                  href="mailto:Support@dhurina.net"
                >
                  Email - Support@dhurina.net
                </a>
                <div className="mt-0 mt-2">
                  <p className="ff_inter fs-6 text-light fw-light mb-2">
                    Follow us On:
                  </p>
                  <a
                    href="https://www.facebook.com/Dhurinaclass?mibextid=ZbWKwL "
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      width={50}
                      className="footer_icon"
                      src={Image_URL + "website/facebook_icon.png"}
                      alt="FbIcon"
                    />
                  </a>
                  <a
                    href="https://twitter.com/Dhurinalive"
                    target="_blank"
                    className="ms-2"
                    rel="noreferrer"
                  >
                    <img
                      className="footer_icon"
                      src={Image_URL + "website/twitter_icon.png"}
                      alt="TwitterIcon"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/edu.dhurina_/"
                    target="_blank"
                    className="ms-2"
                    rel="noreferrer"
                  >
                    <img
                      className="footer_icon"
                      src={Image_URL + "website/instagram_icon.png"}
                      alt="InstaIcon"
                    />
                  </a>
                  <a
                    href="https://t.me/dhurina"
                    target="_blank"
                    className="ms-2"
                    rel="noreferrer"
                  >
                    <img
                      className="footer_icon"
                      src={Image_URL + "website/telegram_icon.png"}
                      alt="TeleIcon"
                    />
                  </a>
                  <a
                    href="https://youtube.com/@DhurinaEducation?si=p328uDGXdk26vXLo"
                    target="_blank"
                    className="ms-2"
                    rel="noreferrer"
                  >
                    <img
                      width={80}
                      height={80}
                      className="footer_icon bg-white px-2 py-2 rounded-1"
                      src="https://icon-library.com/images/play-white-png-icon/play-white-png-icon-27.jpg"
                      alt="YoutubeIcon"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/dhurina/"
                    target="_blank"
                    className="ms-2"
                    rel="noreferrer"
                  >
                    <img
                      width={80}
                      height={80}
                      className="footer_icon bg-white px-2 py-2 rounded-1"
                      src="https://www.iconpacks.net/icons/1/free-linkedin-icon-112-thumb.png"
                      alt="LinkedIcon"
                    />
                  </a>
                </div>
              </div>
              <p className="ff_inter fs-6 text_gradient text-center mb-0 mt-5">
                © 2023 Dhurina Ventures Pvt Ltd. All Right Reserved.
              </p>
              <p className="ff_inter fs-6 text-light fw-lighter text-center mb-0 mt-2">
                MADE WITH{" "}
                <img
                  width={30}
                  src="https://static.vecteezy.com/system/resources/previews/011/729/441/non_2x/red-heart-icon-free-png.png"
                  alt="footer_heart"
                />{" "}
                IN INDIA
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
