import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Innerheader from "../../include/header/innerHeader";
import Footer from "../../include/footer/footer";
import { Helmet } from "react-helmet";

function Privacypolicy() {
  var page_url = window.location.href;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Innerheader></Innerheader>  */}
      <Helmet>
        <title>Privacy Policy - Dhurina.net</title>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link rel="canonical" href={page_url} />
        <meta name="title" content="Privacy Policy - Dhurina.net" />
        <meta
          name="description"
          content=" Read about the privacy policy and security. Also, it includes the terms, rules, and guidelines for users before using the website."
        />
        <meta
          name="twitter:description"
          content=" Read about the privacy policy and security. Also, it includes the terms, rules, and guidelines for users before using the website."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy - Dhurina.net" />
        <meta name="twitter:site" content="@Dhurinalive" />
        <meta name="twitter:creator" content="@Dhurinalive" />
        <meta name="twitter:app:id:googleplay" content="com.dhurina" />
        <meta name="twitter:app:id:iphone" content="1479858722" />
        <meta
          name="twitter:app:name:googleplay"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:ipad"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:iphone"
          content="Dhurina - Learning Made Easy"
        />
        <meta name="twitter:domain" content="https://dhurina.net" />
        <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
        <meta
          property="og:description"
          content=" Read about the privacy policy and security. Also, it includes the terms, rules, and guidelines for users before using the website."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Privacy Policy - Dhurina.net" />
        <meta property="og:url" content={page_url} />
        <meta property="og:site_name" content="Dhurina" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/dhurinaclass"
        />
        <meta
          property="article:author"
          content="https://www.facebook.com/dhurinaclass"
        />
        <meta property="al:android:url" content={page_url} />
        <meta
          property="al:android:app_name"
          content="Dhurina - Learning Made Easy"
        />
        <meta property="al:android:package" content="com.dhurina" />
        <meta property="fb:app_id" content="facebook-app-id-in-number" />
        <meta property="fb:pages" content="facebook-page-in-number" />
      </Helmet>

      <section>
        <div className="custom_container container pb-5">
          <Row>
            <Col md={12} className="mt-5">
              <div className="section-title lefth2border">
                <h2 className="text-start">Privacy Policy</h2>
              </div>

              <div>
                <p>
                  This Privacy Policy (“Policy”) (together with our terms and
                  conditions and any other documents referred to on it) sets out
                  the basis on which any personal data Dhurina (“We”/ “Us” /
                  “Our”) collects from the User (“You” / “Your”), or that you
                  provide to us, will be processed by us. Please read the
                  following carefully to understand our views and practices
                  regarding your personal data and how we will treat it. Your
                  use of our Services/Application (“our Application”) or your
                  registrations with us through any modes or usage of any
                  products including through SD cards, tablets or other
                  storage/transmitting device shall signify your acceptance and
                  consent to the practices described in this policy and to be
                  legally bound by the same. If you do not agree with the terms
                  of this Policy, please do not use the Application our products
                  or avail any of our Services.{" "}
                </p>
              </div>

              <div>
                <h5>Information we may collect from you</h5>

                <h6>
                  We may collect and process the following data about you:
                </h6>

                <p>
                  i. Information you give us. You may give us information about
                  you by filling in forms on our Application or by corresponding
                  with us by phone, e-mail or otherwise. This includes
                  information you provide when you register to use our
                  Application, subscribe to any of our services, search for a
                  product, place an order on our Application, participate in
                  discussion boards or other social media functions on our
                  Application, enter a competition, promotion or survey and when
                  you report a problem with our Application. The information you
                  give us may include your name, address, e-mail address and
                  phone number, financial and credit card information.
                </p>

                <p>
                  ii. The said information collected from the users could be
                  categorized as “Personal Information”, “Sensitive Personal
                  Information” and “Associated Information”. Personal
                  Information, Sensitive Personal Information and Associated
                  Information (each as individually defined under this
                  Information Technology (Reasonable security practices and
                  procedures and sensitive personal data or information) Rules,
                  2011 (the “Data Protection Rules”)) shall collectively be
                  referred to as 'Information' in this Policy.
                </p>
                <p>
                  iii. We may use the Information to contact you from time to
                  time, to provide you with the Services, important information,
                  required notices and marketing promotions. We will ask you
                  when we need more information that personally identifies you
                  (personal information) or allows us to contact you.
                </p>
                <p>
                  iv. We will not differentiate between who is using the device
                  to access the Application, Services or products, so long as
                  the log in/access credentials match with yours. In order to
                  make the best use of the Application/Services/products and
                  enable your Information to be captured accurately on the
                  Application/Services/products, it is essential that you have
                  logged in using your own credentials.
                </p>
                <h6>
                  Information we collect about you. With regard to each of your
                  visits to our Application we may automatically collect the
                  following information:
                </h6>
                <p>
                  i. Technical information, including the Internet protocol
                  (“IP”) address used to connect your computer to the Internet,
                  your login information, browser type and version, time zone
                  setting, browser plug-in types and versions, operating system
                  and platform;
                </p>
                <p>
                  ii. IInformation about your visit, including the full Uniform
                  Resource Locators (“URL”) clickstream to, through and from our
                  Application (including date and time); products you viewed or
                  searched for; page response times, download errors, length of
                  visits to certain pages, page interaction information (such as
                  scrolling, clicks, and mouse-overs), and methods used to
                  browse away from the page and any phone number used to call
                  our customer service number.
                </p>
              </div>

              <div>
                <h5>Cookies</h5>
                <p>
                  Our Application uses cookies to distinguish you from other
                  users of our Application. Cookies are used to track your
                  preferences, help you login faster, and aggregated to
                  determine user trends. This helps us to provide you with a
                  good experience when you browse our Application and also
                  allows us to improve our Application.
                </p>
              </div>

              <div>
                <h5>Uses made of the information</h5>

                <h6>
                  We use information held about you in the following ways:
                </h6>

                <p>Information you give to us. We will use this information:</p>

                <p>
                  1. to carry out our obligations arising from any contracts
                  entered into between you and us and/or between you and other
                  User(s) and to provide you with the information, products and
                  services that you request from us;
                </p>

                <p>
                  2. to provide you with information about other goods and
                  services we offer that are similar to those that you have
                  already purchased or enquired about;
                </p>

                <p>
                  3. to provide you, or permit selected third parties to provide
                  you, with information about goods or services we feel may
                  interest you. If you are an existing customer, we will only
                  contact you by electronic means (e-mail or SMS) with
                  information about goods and services similar to those which
                  were the subject of a previous sale or negotiations of a sale
                  to you. If you are a new customer, and where we permit
                  selected third parties to use your data, we (or they) will
                  contact you by electronic means only if you have consented to
                  this. If you do not want us to use your data in this way, or
                  to pass your details on to third parties for marketing
                  purposes, please tick the relevant unsubscribe box situated on
                  the form or email on which we collect your data;
                </p>

                <p>4. to notify you about changes to our service</p>

                <p>
                  5. to ensure that content from our Application is presented in
                  the most effective manner for you and for your computer.
                </p>

                <p>
                  ii. Information we collect about you. We will use this
                  information:
                </p>

                <p>
                  1. to administer our Application and for internal operations,
                  including troubleshooting, data analysis, testing, research,
                  statistical and survey purposes;
                </p>

                <p>
                  2. to improve our Application to ensure that content is
                  presented in the most effective manner for you and for your
                  computer;
                </p>

                <p>
                  3. to allow you to participate in interactive features of our
                  service, when you choose to do so;
                </p>

                <p>
                  4. as part of our efforts to keep our Application safe and
                  secure;
                </p>

                <p>
                  5. to measure or understand the effectiveness of advertising
                  we serve to you and others, and to deliver relevant
                  advertising to you;
                </p>

                <p>
                  6. to make suggestions and recommendations to you and other
                  users of our Application about goods or services that may
                  interest you or them.
                </p>
              </div>

              <div>
                <p>
                  We also collect, use and share Aggregated Data such as
                  statistical or demographic data for any purpose. Aggregated
                  Data may be derived from your personal data but is not
                  considered personal data in law as this data does not directly
                  or indirectly reveal your identity. For example, we may
                  aggregate your Usage Data to calculate the percentage of users
                  accessing a specific Application feature. However, if we
                  combine or connect Aggregated Data with your personal data so
                  that it can directly or indirectly identify you, we treat the
                  combined data as personal data which will be used in
                  accordance with this privacy notice.
                </p>
              </div>

              <div>
                <p>
                  We do not collect any Special Categories of Personal Data
                  about you (this includes details about your race or ethnicity,
                  religious or philosophical beliefs, sex life, sexual
                  orientation, political opinions, trade union membership,
                  information about your health and genetic and biometric data).
                  Nor do we collect any information about criminal convictions
                  and offences.
                </p>
              </div>

              <div>
                <p>
                  Where we need to collect personal data by law, or under the
                  terms of a contract we have with you and you fail to provide
                  that data when requested, we may not be able to perform the
                  contract we have or are trying to enter into with you (for
                  example, to provide you with goods or services). In this case,
                  we may have to cancel a product or service you have with us
                  but we will notify you if this is the case at the time.
                </p>
              </div>

              <div>
                <h5>Disclosure of your information</h5>

                <p>
                  a. We may share your personal information with any member of
                  our group, which means our subsidiaries, our ultimate holding
                  company and its subsidiaries.
                </p>

                <p>
                  b. We may share your information with selected third parties
                  including:
                </p>

                <p>
                  i. Business partners, suppliers and sub-contractors for the
                  performance of any contract we enter into with them or you.
                </p>

                <p>
                  ii. Advertisers and advertising networks that require the data
                  to select and serve relevant adverts to you and others. We do
                  not disclose information about identifiable individuals to our
                  advertisers, but we may provide them with aggregate
                  information about our users. We may also use such aggregate
                  information to help advertisers reach the kind of audience
                  they want to target. We may make use of the personal data we
                  have collected from you to enable us to comply with our
                  advertisers' wishes by displaying their advertisement to that
                  target audience. When we share personal data with these
                  companies, we require them to keep it safe, and they must not
                  use your personal data for their own marketing purposes.
                </p>

                <p>
                  Analytics and search engine providers that assist us in the
                  improvement and optimization of our Application.
                </p>

                <p>
                  Credit reference agencies for the purpose of assessing your
                  credit score where this is a condition of us entering into a
                  contract with you.
                </p>

                <p>
                  c. We may disclose your personal information to third parties:
                </p>

                <p>
                  i. In the event that we sell or buy any business or assets, in
                  which case we may disclose your personal data to the
                  prospective seller or buyer of such business or assets. If the
                  transfer or sale goes ahead, the organisation receiving your
                  personal data can use your data in the same way as us.
                </p>

                <p>
                  ii. If Dhurina or substantially all of its assets are acquired
                  by a third party, in which case personal data held by it about
                  its customers will be one of the transferred assets. If we are
                  under a duty to disclose or share your personal data in order
                  to comply with any legal obligation, or in order to enforce or
                  apply our terms of use or terms and conditions of supply and
                  other agreements; or to protect the rights, property, or
                  safety of Dhurina, our customers, or others. This includes
                  exchanging information with other companies and organisations
                  for the purposes of fraud protection and credit risk
                  reduction.
                </p>
              </div>
              <div>
                <h5>Links</h5>
                <p>
                  Our Application may contain links to other Applications that
                  are not under our direct control. These Applications may have
                  their own policies regarding privacy. We have no control of or
                  responsibility for linked Applications and provide these links
                  solely for the convenience and information of our visitors.
                  You accessing such linked Applications shall be at your own
                  risk. These Applications are not subject to this Privacy
                  Policy. You should check the privacy policies, if any, of
                  those individual Applications to see how the operators of
                  those third-party Applications will utilize your personal
                  information.
                </p>
              </div>
              <div>
                <h5>Security</h5>
                <p>
                  We are concerned about safeguarding the confidentiality of
                  your Information. We provide physical, electronic, and
                  procedural safeguards to protect Information we process and
                  maintain. For example, we limit access to this Information to
                  authorized employees only who need to know that information in
                  order to operate, develop or improve our
                  Application/Services/products. Further, we conduct internal
                  reviews of our data collection, storage and processing
                  practices and security measures, including appropriate
                  encryption and physical security measures to guard against
                  unauthorized access to systems where we store personal data.
                  All information gathered on our Mobile Application is securely
                  stored within our controlled database. The database is stored
                  on servers secured behind a firewall; access to the servers is
                  password-protected and is strictly limited.{" "}
                </p>

                <p>
                  Please be aware that, although we endeavor to provide
                  reasonable security for information we process and maintain,
                  no security system can prevent all potential security
                  breaches.
                </p>
              </div>

              <div>
                <h5>Duration of retention of User Data</h5>
                <p>
                  We shall retain user data while an account is active and for
                  at least three years afterward. We may alter this practice
                  according to legal and business requirements. For example, we
                  may lengthen the retention period for some data if needed to
                  comply with law or voluntary codes of conduct. Unless
                  otherwise prohibited, we may shorten the retention period for
                  some types of data if needed to free up storage space.
                </p>
              </div>

              <div>
                <h5>Changes to our privacy policy</h5>
                <p>
                  This version was last updated on ____ and historic versions
                  can be obtained by contacting us. Any changes we may make to
                  our privacy policy in the future will be posted on this page
                  and, where appropriate, notified to you by email. Please check
                  back frequently to see any updates or changes to our privacy
                  policy.
                </p>
              </div>
              <div>
                <h5>Contact</h5>
                <p>
                  Questions, comments, grievances and requests regarding this
                  privacy policy are welcomed and should be addressed to :
                  support@dhurina.net
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* <Footer></Footer> */}
    </>
  );
}

export default Privacypolicy;
