/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import ApiCall from "../../api/callApi";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { Col, Modal, Row } from "react-bootstrap";
import CartModal from "../../components/cartmodal";
import { Parser } from "html-to-react";

const BOOK_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "hardcopy/image/";

const Image_URL = process.env.REACT_APP_Bucket_URL;
const SingleBook = ({ setLoginPopup }) => {
  var { id, bookId } = useParams();
  const [cartValue, setCartValue] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [books, setBooks] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [isImageZoomed, setIsImageZoomed] = useState(false);

  useEffect(() => {
      if (localStorage.getItem("cart") != null && localStorage.getItem("cart") != "" && localStorage.getItem("cart") != undefined && localStorage.getItem("token")!=null && localStorage.getItem("token")!="") {
        ApiCall(JSON.parse(localStorage.getItem("cart")), "post", "cart", cartcallback);
      }
      ApiCall("", "get", `book_detail/${bookId}`, bookdetail);
  }, [id, show]);

  const htmlParser = new Parser();
  const htmlString = `<p className="ff_inter fw-normal text_grey fs_xxl pe-lg-5">${books.description}</p>`;
  const parsedDesc = htmlParser.parse(htmlString);
  
  const bookdetail = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setBooks(response.data.data);
      // console.log(response.data);
      setCartValue(response.data.cart_data);
    } else {
      console.log("error");
    }
  });

  const notify = (message) =>{
    toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
  }


  const addToCart = (e, price) => {
    var body = {
      type: "book",
      type_id: e,
      quantity: 1,
      user_id: localStorage.getItem("id"),
      price: price,
      pages: 5,
    };

    if (localStorage.getItem("token")) {
      ApiCall(body, "post", "cart", cartcallback);
    } else {
      localStorage.setItem("cart", JSON.stringify(body));
      setLoginPopup(true);
      document.body.parentElement.classList.toggle("overflow-hidden");
    }
  };

  const cartcallback = useCallback((response) => {
    if (response.data.status === "true") {
      localStorage.removeItem("cart");
      setCartValue(response.data.data);
      setShow(true);
      notify(response.data.message);
    } else {
      notify("Error");
    }
  });

  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }

  return (
    <div className="custom_container container">
      <div className="py-5">
        <div className="row">
          <div className="col-lg-8">
            <h2 className="ff_inter fw-semibold fs_8xl mb-5 mb-lg-0 pe-lg-5 course_heading">
              {books.title}
            </h2>

            <div className="border_light_brown d-block d-lg-none mt-5 mt-lg-0">
              <div className="bg_classes">
                <div className="position-relative">
                  <div
                    onClick={() => setIsImageZoomed(true)}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <img
                      className="book_fit1 "
                      src={`${BOOK_IMAGE_URL}${books.image}`}
                      alt={books.title}
                    />
                  </div>
                </div>
              </div>
              <Modal className="" show={isImageZoomed} centered>
                {isImageZoomed && (
                  <div
                    className="close-icon"
                    onClick={() => setIsImageZoomed(false)}
                  >
                    <img
                      className="bg-white rounded-4 px-2 py-2"
                      width={60}
                      src="https://png.pngtree.com/png-clipart/20210311/original/pngtree-hand-painted-cross-brush-effect-png-image_6015163.jpg"
                      alt=""
                    />
                  </div>
                )}
                <Modal.Body>
                  <img
                    className="modal-image"
                    src={`${BOOK_IMAGE_URL}${books.image}`}
                    alt={books.title}
                  />
                </Modal.Body>
              </Modal>

              <div className="border_light_brown mx-4 my-3">
                <p className="ff_inter fw-semibold text_grey px-3 fs-6 mt-2">
                  {books.title}
                </p>
                <div className="p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="mb-0 text_gradient fw-bold mb-0">
                        ₹{books.hardcopy_price}
                      </span>
                      <span className="ms-1 text_grey ff_inter text-decoration-line-through mb-0">
                        {books.dup_price}
                      </span>
                    </div>
                    {books.demo_file ? (
                      <>
                        <button className="border_gradient">
                          <a
                            href={
                              books.demo_file &&
                              `https://pubcdn.dhurina.net/hardcopy/${books.demo_file}`
                            }
                            target="_blank"
                            className=" fw-bold cursor_pointer border-0 text_gradient ff_inter fs_sm text-center px-3 py-2"
                          >
                            Download Book Demo
                          </a>
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {books.cart_status === true ? (
                    <button
                      onClick={(e) => {
                        setShow(true);
                      }}
                      className="bg_gradient cursor_pointer mt-3 border-0 text-white ff_inter fs-6 d-block text-center px-3 py-2"
                    >
                      View Cart
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        addToCart(books.id, books.hardcopy_price);
                      }}
                      className="bg_gradient cursor_pointer mt-3 border-0 text-white ff_inter fs-6 d-block text-center px-3 py-2"
                    >
                      Add to Cart
                    </button>
                  )}

                  {show && cartValue.length > 0 ? (
                    <CartModal
                      cartshow={show}
                      onHide={setShow}
                      notify={notify}
                      cartItem={cartValue}
                      deleteCartValue={(value) => setCartCount(value)}
                      viewLoader={(status) => setMainLoading(status)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {/* <h3 className="ff_inter text_gradient fw-semibold fs_8xl mb-0 py-3 mt-4">
              Book Highlights
            </h3>
            <p className="ff_inter fw-normal text_grey fs_xxl pe-lg-5">
              {books.description}
            </p> */}
            <div className="course_highlight mt-lg-5 mt-4 pb-2">
              <div className="bg_light_orange course_des">
                <h4 className="ff_inter text_gradient fw-semibold fs_8xl mb-0 py-3 text-center ">
                  Book Highlights
                </h4>
              </div>
              <p className="ff_inter fw-normal text_grey fs_xxl mt-3 mx-3 pe-lg-5">
                {parsedDesc}
              </p>
            </div>
          </div>
          <div className="col-lg-4 mt-5 mt-lg-0">
            <div className="border_light_brown  d-lg-block d-none">
              <div className="bg_classes">
                <div className="position-relative">
                  <div
                    onClick={() => setIsImageZoomed(true)}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <img
                      className="book_fit1 "
                      src={`${BOOK_IMAGE_URL}${books.image}`}
                      alt={books.title}
                    />
                  </div>
                </div>
              </div>
              <Modal className="" show={isImageZoomed} centered>
                {isImageZoomed && (
                  <div
                    className="close-icon"
                    onClick={() => setIsImageZoomed(false)}
                  >
                    <img
                      className="bg-white rounded-4 px-2 py-2"
                      width={60}
                      src="https://png.pngtree.com/png-clipart/20210311/original/pngtree-hand-painted-cross-brush-effect-png-image_6015163.jpg"
                      alt=""
                    />
                  </div>
                )}
                <Modal.Body>
                  <img
                    className="modal-image"
                    src={`${BOOK_IMAGE_URL}${books.image}`}
                    alt={books.title}
                  />
                </Modal.Body>
              </Modal>

              <div className="border_light_brown mx-4 my-3">
                <p className="ff_inter fw-semibold text_grey px-3 fs-6 mt-2">
                  {books.title}
                </p>
                <div className="p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="mb-0 text_gradient fw-bold mb-0">
                        ₹{books.hardcopy_price}
                      </span>
                      <span className="ms-1 text_grey ff_inter text-decoration-line-through mb-0">
                        {books.dup_price}
                      </span>
                    </div>
                    {books.demo_file ? (
                      <>
                        <button className="border_gradient">
                          <a
                            href={
                              books.demo_file &&
                              `https://pubcdn.dhurina.net/hardcopy/${books.demo_file}`
                            }
                            target="_blank"
                            className=" fw-bold cursor_pointer border-0 text_gradient ff_inter fs_sm text-center px-3 py-2"
                          >
                            Download Book Demo
                          </a>
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {books.cart_status === true ? (
                    <button
                      onClick={(e) => {
                        setShow(true);
                      }}
                      className="bg_gradient cursor_pointer mt-3 border-0 text-white ff_inter fs-6 d-block text-center px-3 py-2"
                    >
                      View Cart
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        addToCart(books.id, books.hardcopy_price);
                      }}
                      className="bg_gradient cursor_pointer mt-3 border-0 text-white ff_inter fs-6 d-block text-center px-3 py-2"
                    >
                      Add to Cart
                    </button>
                  )}

                  {show && cartValue.length > 0 ? (
                    <CartModal
                      cartshow={show}
                      onHide={setShow}
                      notify={notify}
                      cartItem={cartValue}
                      deleteCartValue={(value) => setCartCount(value)}
                      viewLoader={(status) => setMainLoading(status)}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <img
              className="mt-3"
              src={Image_URL + "website/Poster2.png"}
              alt="bpsc_crack"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBook;
