import React, { useState, useEffect, useCallback } from "react";
import { Container, Col, Row, Image, Breadcrumb } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import Footer from "../../include/footer/footer";
import ApiCall from "../../api/callApi";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
import Innerheader from "../../include/header/innerHeader";
import VideoJS from "./VideoJsPlayer";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const Livestream = () => {
  var { id, course_id, subject_id } = useParams();

  const [videos, setVideos] = useState([]);
  const [video, setVideo] = useState({ title: "Loading...", video_url: "" });
  const [searchInput, setSearchInput] = useState("");
  const [hlsurl, SetHls] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("token") === "" ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined ||
      localStorage.getItem("token") === null
    ) {
      navigate("/login");
    } else {
      getData();
    }
  }, []);

  function getData() {
    ApiCall(
      { id: id, course_id: course_id, subject_id: subject_id },
      "post",
      "get_video",
      courseareacallback
    );
  }

  const courseareacallback = useCallback((response) => {
    setVideos(response.data.videos);
    if (response.data.status === "true") {
      setVideo(response.data.data);
      response.data.data.video_url.map((vdata) => {
        if (vdata.type == "application/vnd.apple.mpegurl") {
          SetHls(vdata.file);
        }
      });
    }
  });

  if (hlsurl == "") {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/png/dhurina-logo.png"}
          style={{ alignSelf: "center" }}
        />
      </div>
    );
  }

  const RouteChange = (e) => {
    navigate("/search-result?keyword=" + searchInput, {
      state: { course: searchInput },
    });
  };

  return (
    <>
      <Innerheader onSearch={(e) => RouteChange(e)}></Innerheader>

      <section className="banner-area booksheadbanner course-detailinner">
        <Container>
          <Row>
            <Col md={12}>
              <Breadcrumb className="mb-3">
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item
                  href={"/course-videos/" + course_id + "/" + subject_id}
                >
                  Course Videos
                </Breadcrumb.Item>
                <Breadcrumb.Item active>View Video</Breadcrumb.Item>
              </Breadcrumb>
            </Col>

            <Col xs={12}>
              <div className="main-banner-txt banner-inner course-detailbanner">
                <div className="section-title mb-2 mb-sm-3">
                  <h5 className="fw-medium f-18">{video.title}</h5>
                </div>
              </div>
            </Col>

            <Col sm={8} xs={12}>
              <div className="main-banner-txt banner-inner course-detailbanner">
                {hlsurl != "" ? <VideoJS url={hlsurl} video={video} /> : null}
              </div>
            </Col>

            <Col sm={4} xs={12}>
              <div className="recomdednd-section d-none d-sm-block">
                <p>Latest Videos</p>

                {videos.length < 1 ? (
                  <h6 className="mt-4 mb-3" style={{ margin: "5%" }}>
                    No more videos found!!
                  </h6>
                ) : (
                  videos.map((value, key) => {
                    return (
                      <Link
                        to="#"
                        key={key}
                        onClick={() =>
                          (window.location.href =
                            "/view-video/" +
                            value.id +
                            "/" +
                            course_id +
                            "/" +
                            subject_id)
                        }
                      >
                        <Row>
                          <Col xs={4} className="pe-0">
                            <div className="single-course-wrap video-bgdemo recommendclass">
                              <div className="thumb">
                                <Image src={`${value.thumbnail}`} alt="img" />
                              </div>
                            </div>
                          </Col>
                          <Col xs={8} className="ps-0">
                            <div className="single-course-wrap video-bgdemo recommendclass">
                              <div className="wrap-details">
                                <h6 className="mb-1">{value.title}</h6>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Link>
                    );
                  })
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Livestream;
