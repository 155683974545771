import React, { useEffect, useState, useCallback } from "react";
import ApiCall from "../../api/callApi";
import { Col, Form, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";

const Image_URL = process.env.REACT_APP_Bucket_URL;

const Welcome = () => {
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userState, setUserState] = useState("");
  const [ref, setRef] = useState("");
  const [mobile, setMobile] = useState("");
  // const [goal, setGoal] = useState("");
  const location = useLocation();
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");

  // const Goals = [
  //   { name: "Compitition Exam" },
  //   { name: "JEE/NEET" },
  //   { name: "K1-K12" },
  //   { name: "Skill" },
  // ];

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    ApiCall("", "get", "states", getStates);
  }

  function handleSubmitForm(e) {
    e.preventDefault();

    var data = {
      name: name,
      ref_code: ref,
      number: phoneNumber,
      email: email,
      address: address,
      password: password,
      state: userState,
    };
    setSubmitStatus(true);
    ApiCall(data, "post", "signup", addressCallback);
  }

  const getStates = useCallback((response) => {
    if (response.data.status === 200) {
      setStates(response.data.data);
    } else {
      console.log("error");
    }
  }, []);

  const addressCallback = useCallback((response) => {
    if (response.data.status === "true") {
      localStorage.setItem("name", name);
      navigate(-1);
      console.log("Signup successful");
      console.log(response);
    } else {
      console.log("Signup failed");
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const phone = params.get("phone");
    if (phone && phone !== phoneNumber) {
      setphoneNumber(phone);
      params.delete("phone");
      navigate(
        {
          pathname: location.pathname,
          search: "",
        },
        { replace: true }
      );
    }
  }, [location.search]);

  return (
    <div className="container custom_container py-5">
      <div className="row align-items-center flex-column-reverse flex-lg-row">
        <div className="col-lg-6 text-center mt-4 mt-lg-0">
          <p className="text_gradient fs_8xl fw-semibold ff_inter pb-4 mb-2">
            Welcome
          </p>

          <form onSubmit={handleSubmitForm}>
            <div className="row text-start">
              <Col md={6}>
                <div className="mb-3">
                  <Form.Label className="f-12  fw-medium bg-transparent mb-2">
                    Name <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="login-form">
                    <Form.Control
                      type="text"
                      placeholder="Enter your Full Name"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <i className="fa fa-user-circle"></i>
                  </div>
                </div>
              </Col>
              {/* <Col md={6}>
                <div className="mb-3">
                  <Form.Label className="f-12 fw-medium mb-1">
                    Reference
                  </Form.Label>
                  <div className="login-form">
                    <Form.Control
                      type="text"
                      placeholder="Enter your Reference Code"
                      onChange={(e) => setRef(e.target.value)}
                    />
                    <i className="fa fa-text-height"></i>
                  </div>
                </div>
              </Col> */}
              <Col md={6}>
                <div className="mb-3">
                  <Form.Label className="f-12 fw-medium mb-2">
                    Mobile No <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="login-form">
                    <Form.Control
                      type="number"
                      min="0"
                      placeholder="Enter your Mobile No "
                      // onChange={(e) => setMobile(e.target.value)}
                      value={phoneNumber}
                      required
                    />
                    <i className="fa fa-mobile-alt"></i>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Form.Label className="f-12 fw-medium mb-2">
                    Email-Id
                  </Form.Label>
                  <div className="login-form">
                    <Form.Control
                      type="text"
                      placeholder="Enter your Email-Id"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <i className="fa fa-envelope-open-text"></i>
                  </div>
                </div>
              </Col>
              {/*<Col md={12}>
                <div className="mb-3">
                  <Form.Label className="f-12 fw-medium mb-1">
                    Address <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="login-form">
                    <Form.Control
                      type="text"
                      placeholder="Enter your Address"
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    />
                    <i className="fa fa-map-marked-alt"></i>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Form.Label className="f-12 fw-medium mb-1">
                    Password <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="login-form">
                    <Form.Control
                      type="text"
                      placeholder="Enter your Password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <i className="fa fa-lock"></i>
                  </div>
                </div>
              </Col> */}
              <Col md={6}>
                <div className="mb-3">
                  <Form.Label className="f-12 fw-medium mb-2">
                    Select State <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="login-form">
                    <Form.Select
                      className="form-control"
                      onChange={(e) => setUserState(e.target.value)}
                      required
                    >
                      <option>Select State</option>
                      {states.map((value) => {
                        return (
                          <option value={value.id} key={value.id}>
                            {value.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <i className="fa fa-map-marker-alt"></i>
                  </div>
                </div>
              </Col>
            </div>
            {/* <p className="text_gradient fs_lg mb-1 fw-semibold ff_inter text-start mt-4">
              Select your goal
            </p>
            <div className="d-flex flex-wrap">
              {Goals.map((item, index) => (
                <button
                  key={index}
                  className={`${
                    goal === item.name
                      ? "bg_gradient text-white"
                      : "bg_light_orange2 color_black2"
                  } me-3 border-0  px-3 py-2 fw-normal rounded-1 mt-3 fw-semibold ff_inter`}
                  onClick={() => setGoal(item.name)}
                >
                  {item.name}
                </button>
              ))}
            </div> */}
            <button
              className="mt-4 w-100 py-2 text-white fs_7xl ff_inter fw-normal border-0 login_btn"
              type="submit"
            >
              {submitStatus ? (
                <Spinner as="span" animation="border" size="sm" />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
        <div className="col-lg-6 text-center">
          <img
            className="w-100"
            src={Image_URL + "website/welcome_image.png"}
            alt="WelcomeImg"
          />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
