import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import Cbse from "./../../assets/img/cbse.png";
import OwlCarousel from "react-owl-carousel";
const Image_URL = process.env.REACT_APP_Bucket_URL + "combo/";
const boardsliderInoptions = {
  loop: true,
  margin: 18,
  nav: true,
  dots: true,
  smartSpeed: 500,
  autoplay: true,
  responsive: {
    0: {
      items: 2,
      margin: 10,
    },
    768: {
      items: 4,
    },
    1100: {
      items: 6,
    },
  },
};
const BoardList = (props) => {
  const { data } = props;
  // console.log("board",data);
  return (
    <OwlCarousel className="board-sliderinner mt-2" {...boardsliderInoptions}>
      {data.map((item) => {
        return (
          <div className="item" key={item.id}>
            <Link to="/">
              <div className="board-seriers single-course-wrap text-center">
                <div className="board-img thumb">
                  <Image
                    alt={item.name}
                    src={
                      item.image === ""
                        ? `${Image_URL}${item.image}`
                        : `${Cbse}`
                    }
                  />
                </div>
                <h6>{item.name}</h6>
                <p className="mb-0">
                  <strong>className I-12</strong>
                </p>
                <p>English Medium</p>
                <div className="testaction">
                  <i className="fa fa-arrow-right"></i>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </OwlCarousel>
  );
};
export default BoardList;
