import React, {useEffect, useState} from 'react';
import { Link,useNavigate } from "react-router-dom"; 
import { Col, Row, Button, FormControl, Modal, Accordion } from 'react-bootstrap';


const MenumobPopup = (props) => {
    const [checkuserlogin,setUserLogin]=useState(false)
    
    const navigate = useNavigate();
    
    const Logout = () =>{ 
        localStorage.clear();
        setTimeout(() => {
            navigate('/login')
         }, 1000)
    }

    useEffect(() => {
        if(localStorage.getItem('token')){
          setUserLogin(true)
       }
    },[]);


    return (
        <> 

        <Modal size="lg" id="mobhome-menu" {...props}>        
            <Modal.Header closeButton>
                {checkuserlogin ? <p className="f-12 fw-bold text-red-theme float-end  pt-2" onClick={(e)=>Logout(e)}>Logout</p> : <p className="f-12 fw-bold text-red-theme float-end  pt-2">{localStorage.getItem('name')}</p> }
            </Modal.Header>        
            <Modal.Body>
                <Row className="align-items-center mobmenu-padding"> 
                        <Col md={12}> 
                            <p className="mb-1  normal-line-height f-15"></p>
                            <h4 className="f-28 mb-4  normal-line-height fw-bolder text-uppercase"></h4> 
                            <h2 className="f-22 fw-medium  mb-3"></h2>

                            <Accordion flush>
                                <Accordion.Item eventKey="0"> 
                                    <Link className="accordion-button" to="/">Home</Link>
                                </Accordion.Item>

                                <Accordion.Item eventKey="0"> 
                                    <Link className="accordion-button" to="/all-course">Courses</Link>
                                </Accordion.Item>

                                <Accordion.Item eventKey="0"> 
                                    <Link className="accordion-button" to="/test-series">Test Series</Link>
                                </Accordion.Item>

                                <Accordion.Item eventKey="0"> 
                                    <Link className="accordion-button" to="/books">Notes/Books</Link>
                                </Accordion.Item>

                                {/* <Accordion.Item eventKey="1">
                                    <Accordion.Header>Courses</Accordion.Header>
                                    <Link to="/all-course">Courses</Link>
                                    <Accordion.Body className="mobmenu-courselist">
                                        <Row>
                                            <Col xs={12}>
                                                <h5 className="f-14">UP Courses</h5> 
                                                <ListGroup as="ul">
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> UP RO and ARO 10 Model Paper Booklet</Link></ListGroup.Item>
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> Allahabad High Court RO and ARO 15 Model Paper</Link></ListGroup.Item>
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> UP Sub Inspector 10 Model Paper Booklet</Link></ListGroup.Item>
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> Language 10 Model Paper</Link></ListGroup.Item>
                                                </ListGroup> 
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="2">
                                    <Accordion.Header>Book & Notes</Accordion.Header>
                                    <Accordion.Body className="mobmenu-courselist">
                                        <Row>
                                            <Col xs={12}>
                                                <ListGroup as="ul">
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> UPSE Test Series</Link></ListGroup.Item>
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> JEE Test Series</Link></ListGroup.Item>
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="3">
                                    <Accordion.Header>Test Series</Accordion.Header>
                                    <Accordion.Body className="mobmenu-courselist">
                                        <Row>
                                            <Col xs={12}>
                                                <ListGroup as="ul">
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> UPSE Test Series</Link></ListGroup.Item>
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> JEE Test Series</Link></ListGroup.Item>
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                
                                {/* <Accordion.Item eventKey="4">
                                    <Accordion.Header>Defence Courses</Accordion.Header>
                                    <Accordion.Body className="mobmenu-courselist">
                                        <Row>
                                            <Col xs={12}>
                                                <ListGroup as="ul">
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> UPSE Test Series</Link></ListGroup.Item>
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> JEE Test Series</Link></ListGroup.Item>
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="5">
                                    <Accordion.Header>Entrance Courses</Accordion.Header>
                                    <Accordion.Body className="mobmenu-courselist">
                                        <Row>
                                            <Col xs={12}>
                                                <ListGroup as="ul">
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> UPSE Test Series</Link></ListGroup.Item>
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> JEE Test Series</Link></ListGroup.Item>
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="6">
                                    <Link className="accordion-button" to="/">School</Link>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="7">
                                    <Accordion.Header>Skill & Certificates</Accordion.Header>
                                    <Accordion.Body className="mobmenu-courselist">
                                        <Row>
                                            <Col xs={12}>
                                                <ListGroup as="ul">
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> UPSE Test Series</Link></ListGroup.Item>
                                                    <ListGroup.Item as="li"><Link to="/"><i class="fa fa-chevron-right"></i> JEE Test Series</Link></ListGroup.Item>
                                                </ListGroup>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                            </Accordion> 
                        </Col>  
                </Row>
                
                <div className="navbar-area certficationsearch mt-4 mb-2 mob-searchleft">
                    <div className="single-input-wrap">
                        <FormControl className="ps-3 f-12 d-inline-block" type="text" placeholder="Search your Interest etc..." />
                        <Button><i class="fa fa-search"></i></Button>
                    </div>
                    <p className="mb-0 f-10 text-center mt-2">&copy; 2021 Dhurina Ventures Pvt Ltd. All Right Reserved.</p>
                </div>

            </Modal.Body>
        </Modal>

      </>
  );
}


export default MenumobPopup; 
