import React, { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Image,
  Breadcrumb,
  Pagination,
} from "react-bootstrap";
import Innerheader from "../../include/header/innerHeader";
import Footer from "../../include/footer/footer";
import Applink from "../../include/applink/googleApple";
import Coursefacultyimg from "./../../assets/img/review1.png";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
import MobappImage from "./../../assets/img/mob-app.png";
import Loading from "../../components/Loader";
import ApiCall from "../../api/callApi";
import { Helmet } from "react-helmet";
const COURSE_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "course/";
const Image_URL = process.env.REACT_APP_Bucket_URL;

var page = 1;

const RandomCourses = () => {
  var page_url = window.location.href;

  var myarr = [];
  const navigate = useNavigate();
  const [loadmorecount, seTLoadMoreCount] = useState(1);
  const [allCourse, setAllCourse] = useState(myarr);
  const [mainloading, setMainLoading] = useState(true);

  function filterCourse(arr, type, event, filtertype) {
    const body = {
      state: filtertype === "state" ? String(event) : String(""),
      sorting: filtertype === "sorting" ? event : "",
      course: type === "course" ? arr : [],
      teachers: type === "teacher" ? arr : [],
      page: page,
      random: "true",
    };

    ApiCall(body, "post", "all_course", filtercoursecallback);
  }

  const filtercoursecallback = useCallback((response) => {
    if (response.data.status === 200) {
      if (response.data.total_page !== page && response.data.total_page !== 0) {
        page = page + 1;
      }
      setAllCourse(...allCourse, response.data.all_course);
      setMainLoading(false);
    } else {
      seTLoadMoreCount(2);
      console.log("error");
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    const body = { page: page, random: "true" };
    ApiCall(body, "post", "all_course", coursecallback);
  }

  const coursecallback = useCallback((response) => {
    if (response.data.status === 200) {
      if (response.data.total_page !== page && response.data.total_page !== 0) {
        page = page + 1;
      }
      setAllCourse(...allCourse, response.data.all_course);
      setMainLoading(false);
    } else {
      seTLoadMoreCount(2);
      console.log("error");
    }
  }, []);

  const RouteChange = (e) => {
    navigate("/search-result?course=" + e, { state: { course: e } });
  };

  return (
    <div>
      {mainloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src={Image_URL + "website/priloader_icon.svg"}
            style={{ alignSelf: "center" }}
          />{" "}
        </div>
      ) : (
        <>
          <Innerheader onSearch={(e) => RouteChange(e)}></Innerheader>

          <Helmet>
            <title>Dhurina Course - Dhurina.net</title>
            <meta
              name="robots"
              content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
            />
            <link rel="canonical" href={page_url} />
            <meta name="title" content="All Course - Dhurina.net" />
            <meta
              name="description"
              content="This article discusses everything about Dhurina's competitive recorded and live courses provided to students by experts."
            />
            <meta
              name="twitter:description"
              content="This article discusses everything about Dhurina's competitive recorded and live courses provided to students by experts."
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="All Course - Dhurina.net" />
            <meta name="twitter:site" content="@Dhurinalive" />
            <meta name="twitter:creator" content="@Dhurinalive" />
            <meta name="twitter:app:id:googleplay" content="com.dhurina" />
            <meta name="twitter:app:id:iphone" content="1479858722" />
            <meta
              name="twitter:app:name:googleplay"
              content="Dhurina - Learning Made Easy"
            />
            <meta
              name="twitter:app:name:ipad"
              content="Dhurina - Learning Made Easy"
            />
            <meta
              name="twitter:app:name:iphone"
              content="Dhurina - Learning Made Easy"
            />
            <meta name="twitter:domain" content="https://dhurina.net" />
            <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
            <meta
              property="og:description"
              content="This article discusses everything about Dhurina's competitive recorded and live courses provided to students by experts."
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="All Course - Dhurina.net" />
            <meta property="og:url" content={page_url} />
            <meta property="og:site_name" content="Dhurina" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/dhurinaclass"
            />
            <meta
              property="article:author"
              content="https://www.facebook.com/dhurinaclass"
            />
            <meta property="al:android:url" content={page_url} />
            <meta
              property="al:android:app_name"
              content="Dhurina - Learning Made Easy"
            />
            <meta property="al:android:package" content="com.dhurina" />
            <meta property="fb:app_id" content="facebook-app-id-in-number" />
            <meta property="fb:pages" content="facebook-page-in-number" />
          </Helmet>
          <section className="breadcrumb-area pb-4 banner-area innerpagesbanner">
            <Container>
              <Row className="justify-content-center">
                <Col lg={12} className="align-self-center">
                  <Breadcrumb classNameName="mb-1">
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Dhurina Courses</Breadcrumb.Item>
                  </Breadcrumb>
                  <Row>
                    <Col md={12}>
                      <h3 className="mb-0 f-25mob">Dhurina Courses</h3>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="trending-courses-area py-5">
            <main>
              <Container>
                <Row>
                  <Col lg={12}>
                    <Row>
                      {allCourse.length === 0 ? (
                        <Loading />
                      ) : (
                        allCourse.map((item) => {
                          console.log(item);
                          return (
                            <Col md={3}>
                              <Link
                                to={
                                  "/course-detail/" +
                                  item.course_url +
                                  "/" +
                                  item.id
                                }
                              >
                                <div className="single-course-wrap">
                                  <div className="thumb">
                                    {item.live_status === 1 ? (
                                      <span className="cat cat-green">
                                        Live
                                      </span>
                                    ) : (
                                      <span className="cat cat-red">
                                        Recorded
                                      </span>
                                    )}
                                    <Image
                                      src={`${COURSE_IMAGE_URL}${item.image}`}
                                      alt={item.name}
                                    />
                                  </div>
                                  <div className="wrap-details">
                                    <h6>{item.name}</h6>
                                    <div className="price-wrap pt-0">
                                      <Row className="align-items-center">
                                        <Col
                                          xs={6}
                                          className="d-flex align-items-center"
                                        >
                                          <Image
                                            src={Coursefacultyimg}
                                            className="thumbimg"
                                            alt={item.teacher}
                                          />
                                          <span className="f-11 mt-1 ms-1 text-muted fw-bold mob-linefacultyheight">
                                            {item.teacher}
                                          </span>
                                        </Col>
                                        <Col xs={6} className="text-end ps-0">
                                          <del className="text-black-50 f-15 me-0 me-sm-1">
                                            <i className="fa fa-rupee"></i>
                                            {item.discount}
                                          </del>
                                          <span className="text-theme f-18 fw-semi-bold">
                                            <i className="fa fa-rupee"></i>
                                            {item.price}
                                          </span>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </Col>
                          );
                        })
                      )}
                    </Row>

                    {loadmorecount === 1 && (
                      <Pagination className="text-center d-block">
                        <button
                          className="btn-load-more mt-3 f-16 shadow-none btn btn-danger"
                          onClick={() => filterCourse("", "", "", "")}
                        >
                          Load More
                        </button>
                      </Pagination>
                    )}
                  </Col>
                </Row>
              </Container>
            </main>
          </section>

          <section className="pt-3 map-bg bg-theme-red">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col md={3}>
                  <Image alt="dhurina app image" src={MobappImage} />
                </Col>
                <Col
                  md={6}
                  className="downloadappbtnmob mb-4 schoolinner-mob-app"
                >
                  <div className="section-title mb-4">
                    <h2 className=" text-start text-white pb-2">
                      Download Dhurina App
                    </h2>

                    <h5 className="text-white fw-medium f-22">
                      Get Amazing Offers, Free Notes and scholarship.
                    </h5>
                  </div>
                  <Applink></Applink>
                </Col>
              </Row>
            </Container>
          </section>

          <Footer></Footer>
        </>
      )}
    </div>
  );
};

export default RandomCourses;
