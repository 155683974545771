import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Col, Row, Image, Form } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import Skillheader from "../../include/header/skillHeader";
import Footer from "../../include/footer/footer";
import Applink from "../../include/applink/googleApple";
import Skillbanner from "./../../assets/img/skillbanner.jpg";
import SkillSearch from "./../../assets/img/skill-search.png";
import CertifiImage from "./../../assets/img/cert.jpg";
import SkillNew from "./../../assets/img/skill-new.png";
import Whydhurina from "./../../assets/img/why.png";
import MobappImage from "./../../assets/img/mob-app.png";
import ApiCall from "../../api/callApi";
import Loading from "../../components/Loader";
import AllCourse from "../utility/allCourse";
import { Helmet } from "react-helmet";

const boardsliderInoptions = {
  loop: true,
  margin: 18,
  nav: true,
  dots: true,
  smartSpeed: 500,
  autoplay: true,
  responsive: {
    0: {
      items: 2,
      margin: 10,
    },
    768: {
      items: 4,
    },
    1100: {
      items: 6,
    },
  },
};

const Skillhome = () => {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    ApiCall("", "post", "skill_home", skill_home);
  }

  const skill_home = useCallback((response) => {
    if (response.data.status === 200) {
      setCourses(response.data.all_course);
      setLoading(false);
      //    console.log("data",response)
      //    console.log("school data",response.data)
    } else {
      console.log("error");
    }
  }, []);

  return (
    <>
      <Skillheader></Skillheader>
      <Helmet>
        <title>Skill Course - Develop New Skills and Power Your Future</title>
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <link rel="canonical" href="https://dhurina.net/skill" />
        <meta
          name="title"
          content="Skill Course - Develop New Skills and Power Your Future"
        />
        <meta
          name="description"
          content="Dhurina brings awesome skill course for you. Now you can enhance your skill or you can also add new skill as per your interest."
        />
        <meta name="publish-date" content="2022-04-22T11:24:28+05:30" />
        <meta name="created-date" content="2022-04-22T11:24:28+05:30" />
        <meta name="modified-date" content="2022-04-22T11:24:29+05:30" />
        <meta
          name="twitter:description"
          content="Dhurina brings awesome skill course for you. Now you can enhance your skill or you can also add new skill as per your interest."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Skill Course - Develop New Skills and Power Your Future"
        />
        <meta name="twitter:site" content="@Dhurinalive" />
        <meta name="twitter:creator" content="@Dhurinalive" />
        <meta name="twitter:app:id:googleplay" content="com.dhurina" />
        <meta name="twitter:app:id:iphone" content="1479858722" />
        <meta
          name="twitter:app:name:googleplay"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:ipad"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:iphone"
          content="Dhurina - Learning Made Easy"
        />
        <meta name="twitter:domain" content="dhurina.net" />
        <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
        <meta name="og:image:type" content="image/jpeg" />
        <meta
          property="og:description"
          content="Dhurina brings awesome skill course for you. Now you can enhance your skill or you can also add new skill as per your interest."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Skill Course - Develop New Skills and Power Your Future"
        />
        <meta property="og:url" content="URL of Course" />
        <meta property="og:site_name" content="Dhurina" />
        <meta property="og:updated_time" content="2022-01-28T13:36:39+05:30" />
        <meta
          property="article:published_time"
          content="2022-04-22T05:54:28.000Z"
        />
        <meta
          property="article:modified_time"
          content="2022-04-22T05:54:29.000Z"
        />
        <meta property="article:section" content="Skill" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/dhurinalive"
        />
        <meta property="article:author" content="https://fb.com/dhurinalive" />
        <meta property="article:section" content="Online-Course" />
        <meta property="al:android:url" content="https://dhurina.net/skill" />
        <meta
          property="al:android:app_name"
          content="Dhurina - Learning Made Easy"
        />
        <meta property="al:android:package" content="com.dhurina" />
        <meta property="fb:app_id" content="facebook-app-id-in-number" />
        <meta property="fb:pages" content="facebook-page-in-number" />
      </Helmet>

      <section className="pt-5">
        <Image src={Skillbanner} alt="skill banner" />
      </section>

      <section className="trending-courses-area py-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title">
                <h2>Trending Courses</h2>
              </div>
            </Col>
            <Col lg={12}>
              {loading ? <Loading /> : <AllCourse data={courses} />}
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-light-dark py-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="test-newbg">
                <Row className="justify-content-center">
                  <Col md={3}>
                    <Image src={SkillSearch} alt="skill search" />
                  </Col>
                  <Col md={6} className="text-center">
                    <h3 className="f-25mob">Search your Interested skills</h3>
                    <div className="navbar-area certficationsearch mt-3">
                      <div className="single-input-wrap">
                        <Form.Control
                          className="d-inline-block"
                          type="text"
                          placeholder="Select your Interest in Skill & Get Certificate"
                        />
                        <Button variant="homesearch">
                          <i className="fa fa-sort-down fa-2x"></i>
                        </Button>
                      </div>
                    </div>
                    <Link to="/" className="btn mt-3 mt-sm-5 btn-warning">
                      <i className="far fa-file-alt"></i> Get Enroll
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className=" bg-white">
        <Container>
          <Row>
            <Col md={12} className="py-5">
              <div className="section-title">
                <h2 className="">Get Certification Now</h2>
                <h4 className="text-center mt-4 mobsech4">
                  Your child want to improve skill at time of School.
                </h4>
              </div>

              <OwlCarousel
                className="board-sliderinner skillcertificate"
                {...boardsliderInoptions}
              >
                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certicicate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certicicate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certificate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certificate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certificate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certificate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certificate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>

                <div className="item">
                  <Link to="/">
                    <div className="thumb">
                      <Image alt="certificate" src={CertifiImage} />
                    </div>
                  </Link>
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>

        <Container className="mb-5">
          <Row>
            <Col md={12}>
              <div className="test-newbg">
                <Row className="justify-content-center">
                  <Col md={6} className="text-center">
                    <h3>
                      <Image src={SkillNew} width="150" alt="skill new" />
                    </h3>
                    <h3 className="f-25mob">
                      Your Child want to improve the skill on time before School
                    </h3>
                    <Link to="/" className="btn mt-3 btn-warning">
                      <i className="fa fa-book-open"></i> View All Skill Courses
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-light py-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title">
                <h2 className="">Why Dhurina</h2>
                <p className="text-center mt-2 f-18">
                  Join Dhurina Today and get Benefits
                </p>
              </div>
              <Image src={Whydhurina} alt="dhurina" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-3 map-bg position-relative">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={3} className="skillbackground">
              <Image src={MobappImage} alt="" />
            </Col>
            <Col
              md={5}
              className="downloadappbtnmob mb-4 ps-3 ps-sm-5 schoolinner-mob-app"
            >
              <div className="section-title mb-4 lefth2border">
                <h2 className="text-start mb-4 pb-3">Download Dhurina App</h2>
                <h5 className="fw-medium f-22">
                  Get Amazing Offers, Free Notes and scholarship
                </h5>
              </div>
              <Applink></Applink>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Skillhome;
