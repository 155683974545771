import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import ApiCall from "../../api/callApi";
import toast from 'react-hot-toast';

const Contact = () => {
  // const initialFormData = {
  //   firstName: "",
  //   email: "",
  //   // phone: "",
  //   message: "",
  //   // otp: "",
  // };

  // const [formData, setFormData] = useState(initialFormData);
  // const [otpSent, setOtpSent] = useState(false);
  // const [otpVerified, setOtpVerified] = useState(false);
  // const [seconds, setSeconds] = useState("SEND OTP");
  // const [error, setError] = useState("");

  // useEffect(() => {
  //   getData();
  // }, []);

  // useEffect(() => {
  //   let interval;
  //   if (seconds > 0 && !isNaN(seconds)) {
  //     interval = setInterval(() => {
  //       setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
  //     }, 1000);
  //   } else if (seconds === 0) {
  //     setSeconds("Resend OTP");
  //   }

  //   return () => clearInterval(interval);
  // }, [seconds]);

  // const params = useParams();
  // const url = params.id ? `contactus/${params.id}` : "contactus";

  // function getData() {
  //   ApiCall("", "get", url, contactuscallback);
  // }

  // const contactuscallback = (response) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     email: response.data.email,
  //     phone: response.data.phone,
  //   }));
  // };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const handleSendOTP = useCallback(async () => {
  //   if (formData.phone.length === 10) {
  //     setError("");
  //     setOtpSent(true);
  //     setSeconds(60); // Start timer
  //     const data = {
  //       phone: formData.phone,
  //     };

  //     await ApiCall(data, "post", "send_otp", sendOtpCallback);
  //     toast.success("OTP sent successfully!");
  //   } else {
  //     setError("Please Enter Valid Number");
  //   }
  // }, [formData.phone]);

  // const sendOtpCallback = useCallback((response) => {
  //   // Handle the response here
  // }, []);

  // const handleVerifyOTP = useCallback(async () => {
  //   if (formData.otp.length === 5) {
  //     setError("");
  //     const data = {
  //       phone: formData.phone,
  //       otp: formData.otp,
  //     };

  //     await ApiCall(data, "post", "verify_otp", verifyOtpCallback);
  //   } else {
  //     setError("Please Enter Valid OTP");
  //   }
  // }, [formData.phone, formData.otp]);

  // const verifyOtpCallback = useCallback((response) => {
  //   if (response.data.status === "true") {
  //     setOtpVerified(true); // Update state to hide "SEND OTP" button
  //     toast.success("OTP verified successfully!");
  //   } else {
  //     setError("Invalid OTP");
  //     toast.error("Invalid OTP");
  //   }
  // }, []);

  // const handleResendOTP = useCallback(async () => {
  //   if (formData.phone.length === 10) {
  //     setError("");
  //     setOtpSent(true);
  //     setSeconds(60); // Reset timer when resending OTP
  //     const data = {
  //       phone: formData.phone,
  //     };

  //     await ApiCall(data, "post", "send_otp", sendOtpCallback);
  //     toast.success("OTP resent successfully!");
  //   } else {
  //     setError("Please Enter Valid Number");
  //   }
  // }, [formData.phone]);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!otpVerified) {
  //     setError("Please verify the OTP before submitting.");
  //     toast.error("Please verify the OTP before submitting.");
  //     return;
  //   }

  //   if (formData.firstName && formData.email && formData.message) {
  //     try {
  //       await ApiCall(formData, "post", "job_apply", jobApplyCallback);
  //     } catch (error) {
  //       console.error("Error submitting contact form:", error);
  //       alert("An error occurred. Please try again later.");
  //     }
  //   } else {
  //     setError("Please fill in all the required fields.");
  //     toast.error("Please fill in all the required fields.");
  //   }
  // };

  // const jobApplyCallback = useCallback((response) => {
  //   if (response.status === 200) {
  //     console.log(response);
  //     setFormData(initialFormData);

  //     alert("Your form has been submitted successfully.");
  //   } else {
  //     console.log("API error:", response.status);
  //   }
  // }, []);
  const initialFormData = {
    f_name: "",
    email: "",
    phone: "",
    message: "",
    otp: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [seconds, setSeconds] = useState("SEND OTP");
  const [error, setError] = useState("");

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let interval;
    if (seconds > 0 && !isNaN(seconds)) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
      }, 1000);
    } else if (seconds === 0) {
      setSeconds("Resend OTP");
    }

    return () => clearInterval(interval);
  }, [seconds]);

  const params = useParams();
  const url = params.id ? `contactus/${params.id}` : "contactus";

  function getData() {
    ApiCall("", "get", url, contactuscallback);
  }

  const contactuscallback = (response) => {
    setFormData((prevData) => ({
      ...prevData,
      email: response.data.email,
      phone: response.data.phone,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSendOTP = useCallback(async () => {
    if (formData.phone.length === 10) {
      setError("");
      setOtpSent(true);
      setSeconds(60); // Start timer
      const data = {
        phone: formData.phone,
      };

      await ApiCall(data, "post", "send_otp", sendOtpCallback);
      toast.success("OTP sent successfully!");
    } else {
      setError("Please Enter Valid Number");
    }
  }, [formData.phone]);

  const sendOtpCallback = useCallback((response) => {
    // Handle the response here
  }, []);

  const handleVerifyOTP = useCallback(async () => {
    if (formData.otp.length === 5) {
      setError("");
      const data = {
        phone: formData.phone,
        otp: formData.otp,
      };

      await ApiCall(data, "post", "verify_otp", verifyOtpCallback);
    } else {
      setError("Please Enter Valid OTP");
    }
  }, [formData.phone, formData.otp]);

  const verifyOtpCallback = useCallback((response) => {
    if (response.data.status === "true") {
      setOtpVerified(true); // Update state to hide "SEND OTP" button
      toast.success("OTP verified successfully!");
    } else {
      setError("Invalid OTP");
      toast.error("Invalid OTP");
    }
  }, []);

  const handleResendOTP = useCallback(async () => {
    if (formData.phone.length === 10) {
      setError("");
      setOtpSent(true);
      setSeconds(60); // Reset timer when resending OTP
      const data = {
        phone: formData.phone,
      };

      await ApiCall(data, "post", "send_otp", sendOtpCallback);
      toast.success("OTP resent successfully!");
    } else {
      setError("Please Enter Valid Number");
    }
  }, [formData.phone]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!otpVerified) {
      setError("Please verify the OTP before submitting.");
      toast.error("Please verify the OTP before submitting.");
      return;
    }

    if (formData.f_name && formData.email && formData.message) {
      try {
        await ApiCall(formData, "post", "job_apply", jobApplyCallback);
      } catch (error) {
        console.error("Error submitting contact form:", error);
        alert("An error occurred. Please try again later.");
      }
    } else {
      setError("Please fill in all the required fields.");
      toast.error("Please fill in all the required fields.");
    }
  };

  const jobApplyCallback = useCallback((response) => {
    if (response.status === 200) {
      console.log(response);
      setFormData(initialFormData); // Reset form data
      setOtpSent(false); // Reset OTP state
      setOtpVerified(false); // Reset OTP verified state
      setSeconds("SEND OTP"); // Reset OTP button state
      toast.success("Your form has been submitted successfully.");
    } else {
      console.log("API error:", response.status);
    }
  }, []);

  return (
    <>
      <section className="pb-5">
        <div className="custom_container container">
          <Row>
            <Col md={12} className="mt-5">
              <div className="section-title lefth2border">
                <h2 className="text-start">Contact Us</h2>
              </div>

              <div>
                <h4>Phone No.</h4>
                <p>
                  Hello students, welcome to Dhurina. For any queries related to
                  your courses you can contact us at our official customer care
                  number.
                </p>
                <div className="d-flex flex-column mt-2">
                  <a className="fw-semibold" href="#">
                    Enquiry Call: 98121-60061
                  </a>
                  <a className="fw-semibold mt-2" href="#">
                    WhatsApp Number: +91 74049 60061
                  </a>
                </div>
              </div>

              <div className="mt-4">
                <h4>Email</h4>
                <p>
                  Dear user, for any unsolved enquiry you can drop us a mail
                  at&nbsp;
                  <a className="text_blue" href="mailto:support@dhurina.net">
                    support@dhurina.net
                  </a>
                  <br />
                  Anytime and our team will try to solve your query within 24
                  hours.
                </p>
              </div>
            </Col>
          </Row>
          <div className="card p-md-5 p-3 w-100 mt-5">
            <h6 className="ff_inter text_dark_grey fs-4 fs-normal fw-semibold mb-0">
              Start a conversation | Contact Dhurina
            </h6>

            <form onSubmit={handleSubmit}>
              <div className="row mt-4">
                <div className="">
                  <input
                    required
                    className="w-100 careers_input"
                    type="text"
                    placeholder="Full Name"
                    name="f_name"
                    value={formData.f_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="d-flex gap-4 mt-4 align-items-center">
                <div className="w-100">
                  <input
                    required
                    className="w-100 careers_input"
                    type="number"
                    placeholder="+(91) Phone Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                </div>
                {!otpVerified && (
                  <button
                    type="button"
                    className="w-25 btn_login text-white ff_inter bg_gradient border-0 p-2 px-lg-4 py-lg-2"
                    onClick={handleSendOTP}
                    disabled={otpSent}
                  >
                    {seconds < 10 && !isNaN(seconds)
                      ? `00:0${seconds}`
                      : !isNaN(seconds)
                      ? `00:${seconds}`
                      : seconds}
                  </button>
                )}
              </div>
              {otpSent && !otpVerified && (
                <p
                  style={{ cursor: "pointer", color: "#fd7e14" }}
                  className="text-end"
                  onClick={handleResendOTP}
                  disabled={seconds > 0}
                >
                  {seconds === 0 ? "Resend OTP" : ""}
                </p>
              )}
              {otpSent ? (
                <>
                  <div className="d-flex gap-4 mt-4 align-items-center">
                    <div className="w-100">
                      <input
                        required
                        className="w-100 careers_input"
                        type="number"
                        placeholder="Enter OTP"
                        name="otp"
                        value={formData.otp}
                        onChange={handleInputChange}
                      />
                    </div>
                    <button
                      type="button"
                      className="w-25 btn_login text-white ff_inter bg_gradient border-0 p-2 px-lg-4 py-lg-2"
                      onClick={handleVerifyOTP}
                    >
                      Verify OTP
                    </button>
                    {error && <p>{error}</p>}
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="w-100 mt-4">
                <input
                  required
                  className="w-100 careers_input"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <textarea
                className="careers_input w-100 mt-4"
                name="message"
                rows={8}
                placeholder="Message"
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
              <div className="d-flex align-items-start gap-3 mt-4">
                <input
                  type="checkbox"
                  id="promotionalConsent"
                  name="promotionalConsent"
                />
                <label
                  style={{ cursor: "pointer" }}
                  htmlFor="promotionalConsent"
                >
                  I agree to receive Promotional & Informational Communication
                  from Dhurina through Emails or Call or SMS Messages.
                </label>
              </div>
              <div className="d-flex align-items-start gap-3 mt-2">
                <input
                  type="checkbox"
                  id="termsConditions"
                  name="termsConditions"
                />
                <label style={{ cursor: "pointer" }} htmlFor="termsConditions">
                  I accept all{" "}
                  <a
                    className="fw-semibold"
                    style={{ color: "blue" }}
                    target="_blank"
                    href="https://dhurina.net/terms-condition"
                  >
                    Terms & Conditions
                  </a>
                  .
                </label>
              </div>

              <div className="">
                <button
                  type="submit"
                  className="mt-5 w-25 btn_login text-white w-100 ff_inter bg_gradient border-0 px-4 px-lg-5 py-lg-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
