import React,{useEffect} from 'react'; 
import { Container, Col, Row } from 'react-bootstrap';



function AppRefund () {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
        
        <section>
            <Container>
                <Row>
                    <Col md={12} className="InnerContentArea" style={{maringTop:'50px'}}>

                        <div className="section-title lefth2border">
                            <h2 className='text-start'>Refund and Cancellation Policy</h2>
                        </div>

                        <div>  
                        
                            <p>1. You (“user”, “your”, “you”) give your unequivocal and absolute assent to all the clauses of the said Cancellation &amp; Refund Policy (“policy”),
                                as also iterated in the Terms of Use of Dhurina (“we”, “our”, “us”, “Company”).</p>

                            <p>2. If you wish to cancel a confirmed enrolment made via the Application, after enrolment to the Course, you may do so by contacting us via the contact link in the Contact Section.</p>

                            <p>3. All courses, products and services listed on the application are Non-Refundable and once enrolled in/obtained/subscribed to/ordered shall not be subject to any refunds or returns.</p>

                            <p>4. A refund shall be made to the user only in the event a repeat payment (payment made twice for the same course) has been made by the user. Such refund shall be made for the amount paid in excess of the fee of the course only and not the entire amount paid. The refund shall be credited to the bank account of the User within 7 business days. If payment is made via credit card, refunds shall be issued to the original credit card provided at the time of purchase.</p>
                                
                                 
                        </div>
 
                             

                    </Col>
                </Row>
            </Container>
        </section>
            
        
         
         
    
    </>
  );
}

export default AppRefund;
