import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams, useNavigate, Outlet } from "react-router-dom";
import {
  Button,
  Container,
  Col,
  Row,
  Image,
  Breadcrumb,
  ListGroup,
} from "react-bootstrap";
import BookDetailHeader from "../../include/header/bookDetailHeader";
import Footer from "../../include/footer/footer";
import Applink from "../../include/applink/googleApple";
import MobappImage from "./../../assets/img/mob-app.png";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
import ApiCall from "../../api/callApi";
import CartModal from "../../components/cartmodal";
import { Helmet } from "react-helmet";
import { Parser } from "html-to-react";
import toast from 'react-hot-toast';
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
const BOOK_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "hardcopy/image/";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const Bookdetail = (props) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  let { id } = useParams();
  const navigate = useNavigate();

  const notify = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [text, setTextData] = useState("");
  const [cartshow, cartsetShow] = useState(false);
  const carthandleClose = () => cartsetShow(false);
  const carthandleShow = () => {
    cartsetShow(true);
  };

  const [cartValue, setCartValue] = useState(0);
  const [bookDetail, setBookDetail] = useState("");
  const [mainloading, setMainLoading] = useState(true);
  const [cartButtonStatus, setCartButtonStatus] = useState(false);
  const [cartData, setCartData] = useState([]);
  let user_id = "";

  var book_url = window.location.href;

  const addToCart = (e, price) => {
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    } else {
      var body = {
        type: "hardcopy",
        type_id: e,
        quantity: 1,
        user_id: localStorage.getItem("id"),
        price: price,
        pages: 5,
      };
      ApiCall(body, "post", "cart", cartcallback);
    }
  };

  const cartcallback = useCallback((response) => {
    if (response.data.status === "true") {
      setCartValue(response.data.cart_count);
      notify(response.data.message);
    } else {
      notify("Error");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    let url = "";
    if (localStorage.getItem("id")) {
      user_id = localStorage.getItem("id");
      url = "book_detail/" + id + "/" + user_id;
    } else {
      url = "book_detail/" + id;
    }

    getData(url);

    const interval = setInterval(() => getTime(), 1000);

    return () => clearInterval(interval);
  }, []);

  //Api function
  function getData(url) {
    ApiCall("", "get", url, bookCallback);
  }

  const bookCallback = useCallback((response) => {
    window.scrollTo(0, 0);
    if (response.data.status === 200) {
      if (response.data.cart_data !== null) {
        setCartValue(response.data.cart_count);
        setCartData(response.data.cart_data);
      }
      setTextData(response.data.book_detail.description);
      setMainLoading(false);
      if (response.data.cart_status === true) {
        setCartButtonStatus(true);
      }
      setBookDetail(response.data.book_detail);
    } else {
      console.log("error");
    }
  });

  const getTime = () => {
    let time = new Date("2023-01-27 05:00:00").getTime();
    let currentTime = new Date().getTime();
    let timerTime = time - currentTime;

    setDays(Math.floor(timerTime / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((timerTime / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((timerTime / 1000 / 60) % 60));
    setSeconds(Math.floor((timerTime / 1000) % 60));
  };

  return (
    <div>
      {mainloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src={Image_URL + "website/priloader_icon.svg"}
            style={{ alignSelf: "center" }}
          />
        </div>
      ) : (
        <>
          <BookDetailHeader openModal={carthandleShow} cartcount={cartValue} />
          <Outlet />

          <Helmet>
            <title>{bookDetail.title}</title>
            <meta
              name="robots"
              content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
            />
            <link rel="canonical" href={book_url} />
            <meta name="title" content={bookDetail.title} />
            <meta name="description" content={Parser().parse(text)} />
            <meta name="twitter:description" content={Parser().parse(text)} />
            <meta
              name="twitter:image"
              content={`${BOOK_IMAGE_URL}${bookDetail.image}`}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={bookDetail.title} />
            <meta name="twitter:site" content="@Dhurinalive" />
            <meta name="twitter:creator" content="@Dhurinalive" />
            <meta name="twitter:app:id:googleplay" content="com.dhurina" />
            <meta name="twitter:app:id:iphone" content="1479858722" />
            <meta
              name="twitter:app:name:googleplay"
              content="Dhurina - Learning Made Easy"
            />
            <meta
              name="twitter:app:name:ipad"
              content="Dhurina - Learning Made Easy"
            />
            <meta
              name="twitter:app:name:iphone"
              content="Dhurina - Learning Made Easy"
            />
            <meta name="twitter:domain" content="dhurina.net" />
            <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
            <meta name="og:image:type" content="image/jpeg" />
            <meta property="og:description" content={Parser().parse(text)} />
            <meta
              property="og:image"
              content={`${BOOK_IMAGE_URL}${bookDetail.image}`}
            />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={bookDetail.title} />
            <meta property="og:url" content={book_url} />
            <meta property="og:site_name" content="Dhurina" />
            <meta property="article:section" content="Books" />
            <meta
              property="article:publisher"
              content="https://www.facebook.com/dhurinaclass"
            />
            <meta
              property="article:author"
              content="https://www.facebook.com/dhurinaclass"
            />
            <meta property="article:section" content="Books" />
            <meta property="al:android:url" content={book_url} />
            <meta
              property="al:android:app_name"
              content="Dhurina - Learning Made Easy"
            />
            <meta property="al:android:package" content="com.dhurina" />
            <meta property="fb:app_id" content="facebook-app-id-in-number" />
            <meta property="fb:pages" content="facebook-page-in-number" />
          </Helmet>

          <section className="banner-area booksheadbanner course-detailinner">
            <main>
              <Container>
                <article>
                  <Row>
                    <Col md={12}>
                      <Breadcrumb className="mb-3">
                        <Breadcrumb.Item href="#">
                          <Link to="/">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="/#">
                          <Link to="/books">Books</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active></Breadcrumb.Item>
                      </Breadcrumb>
                    </Col>

                    <Col sm={{ span: 6, order: 2 }} xs={{ span: 12, order: 2 }}>
                      <div className="main-banner-txt banner-inner course-detailbanner">
                        <div className="section-title mb-2 mb-sm-3">
                          <h5 className="fw-medium f-18 mb-0"></h5>
                          <h2>{bookDetail.title}</h2>
                        </div>

                        {text !== null && (
                          <p
                            className="text schoolbannerdesp mb-4 d-none d-sm-block"
                            style={{
                              color: "#27282c",
                              lineHeight: "22px",
                              fontSize: "15px",
                            }}
                          >
                            {Parser().parse(text)}
                          </p>
                        )}

                        <footer>
                          <Row className="align-items-end">
                            <Col
                              xs={12}
                              className="course-price-strike d-none d-sm-block"
                            >
                              <span>₹ {bookDetail.price}</span>
                              <del>₹{bookDetail.dup_price}</del>
                              <br />
                              <p style={{ fontSize: "10px !important" }}>
                                {days}:{hours}:{minutes}:{seconds}
                              </p>
                              <Button
                                className="btn btn-warning mt-2"
                                onClick={(e) =>
                                  addToCart(
                                    bookDetail.id,
                                    bookDetail.hardcopy_price
                                  )
                                }
                              >
                                Add To Cart
                              </Button>
                            </Col>
                            <Col xs={12} className="mt-2 mt-sm-4">
                              <span className="cou-share-new">
                                <i className="fa fa-share-alt mr-2"></i> Share
                                Book
                              </span>
                              <ListGroup
                                as="ul"
                                className="social-link cou-share-newl d-block"
                              >
                                <FacebookShareButton
                                  url={book_url}
                                  quote="Dhurina"
                                  hashtag="#course"
                                >
                                  <FacebookIcon
                                    logoFillColor="white"
                                    size={35}
                                  />
                                </FacebookShareButton>
                                <span>&nbsp;&nbsp;</span>

                                <WhatsappShareButton
                                  url={book_url}
                                  quote="Dhurina"
                                  hashtag="#course"
                                >
                                  <WhatsappIcon
                                    logoFillColor="white"
                                    size={35}
                                  />
                                </WhatsappShareButton>
                                <span>&nbsp;&nbsp;</span>
                                <TelegramShareButton
                                  url={book_url}
                                  imageURL={`${BOOK_IMAGE_URL}${bookDetail.image}`}
                                  quote="Dhurina"
                                  hashtag="#course"
                                >
                                  <TelegramIcon
                                    logoFillColor="white"
                                    size={35}
                                  />
                                </TelegramShareButton>
                                <span>&nbsp;&nbsp;</span>
                                <EmailShareButton
                                  url={book_url}
                                  imageURL={`${BOOK_IMAGE_URL}${bookDetail.image}`}
                                  quote="Dhurina"
                                  hashtag="#course"
                                >
                                  <EmailIcon logoFillColor="white" size={35} />
                                </EmailShareButton>
                              </ListGroup>
                            </Col>
                          </Row>
                        </footer>
                      </div>
                    </Col>

                    <Col
                      className="text-center"
                      sm={{ span: 6, order: 1 }}
                      xs={{ span: 12, order: 1 }}
                    >
                      <InnerImageZoom
                        src={`${BOOK_IMAGE_URL}${bookDetail.image}`}
                        width={200}
                        height={250}
                        hasSpacer={true}
                        zoomSrc={`${BOOK_IMAGE_URL}${bookDetail.image}`}
                        zoomType="hover"
                        zoomPreload={true}
                        fullscreenOnMobile={true}
                      />
                    </Col>
                  </Row>
                </article>
              </Container>
            </main>
          </section>

          <section className="d-block d-sm-none mob-price-section pt-2">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-between mt-2 course-price-strike">
                    <span>
                      <span>₹ {bookDetail.price}</span>
                      <del>₹{bookDetail.dup_price}</del>
                      <br />
                    </span>
                    <Button
                      className="btn btn-warning mt-2"
                      onClick={(e) =>
                        addToCart(bookDetail.id, bookDetail.hardcopy_price)
                      }
                    >
                      Add To Cart
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="pt-3 map-bg">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col md={3}>
                  <Image src={MobappImage} alt="dhurina app image" />
                </Col>
                <Col
                  md={5}
                  className="downloadappbtnmob mb-4 schoolinner-mob-app"
                >
                  <div className="section-title mb-4 lefth2border">
                    <h2 className=" text-start mb-3 pb-3">
                      Download Dhurina App
                    </h2>
                    <h5 className=" fw-medium f-22">
                      Get Amazing Offers, Free Notes and scholarship
                    </h5>
                  </div>
                  <Applink></Applink>
                </Col>
              </Row>
            </Container>
          </section>

          {cartshow && (
            <CartModal
              cartshow={cartshow}
              onHide={carthandleClose}
              notify={notify}
              cartItem={cartData}
              deleteCartValue={(value) => setCartValue(value)}
              viewLoader={(status) => setMainLoading(status)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Bookdetail;
