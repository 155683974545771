import { HeroBihar } from "../../include/helper/Helper";
import Hero from "../../components/statedata/Hero";
import RecordedVideos from "../../components/statedata/RecordedVideos";
import Register from "../../components/statedata/Register";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ApiCall from "../../api/callApi";
import { useCallback } from "react";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const AllRecorded = () => {
  const [classes, setClasses] = useState([]);
  const { id } = useParams();
  const location = useLocation();
  const [mainloading, setMainLoading] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");

  useEffect(() => {
    var body = {type_id: id};
    if (type === "sub") {
        body.type="sub_category"
    } else {
      body.type="course_area"
    }
    ApiCall(body, "post", "all_data", category_data);
  }, [id]);

  const category_data = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setClasses(response.data.courses);
    } else {
      console.log("error");
    }
  });
  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }
  return (
    <>
      <div className="custom_container container py-5">
        <div className="row mt-5 justify-content-between">
          <div className="col-lg-8 border-end">
            <Hero display={false} Hero={HeroBihar} />
            <RecordedVideos display={false} classes={classes} />
          </div>
          <div className="col-lg-4">
            <Register />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllRecorded;
