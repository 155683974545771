import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Prev from "../../assets/img/slider-prev.svg";
import Next from "../../assets/img/slider-next.svg";
import Ellipse1 from "../../assets/img/orange-ellipse.png";
import Ellipse2 from "../../assets/img/orange-ellipse2.png";
import Dots from "../../assets/img/our-student-dots.png";
import Polygon from "../../assets/img/our-student-polygon.png";
import Polygon2 from "../../assets/img/our-student-polygon2.png";
import Quotes from "../../assets/img/quotes.svg";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const Students = () => {
  const sliderRef = useRef(null);

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="bg_students position-relative">
      <div className="custom_container container py-5">
        <img
          className="cursor_pointer arrows position-absolute prev"
          onClick={() => sliderRef.current.slickPrev()}
          src={Prev}
          alt="prev"
        />
        <img
          className="cursor_pointer arrows position-absolute next"
          onClick={() => sliderRef.current.slickNext()}
          src={Next}
          alt="Next"
        />
        <img
          className="position-absolute orange_ellipse"
          src={Ellipse1}
          alt="ellipse1 "
        />
        <img
          className="position-absolute orange_ellipse2"
          src={Ellipse2}
          alt="ellipse2"
        />
        <img className="position-absolute orange_dots" src={Dots} alt="dots" />
        <img
          className="position-absolute students_polygon"
          src={Polygon}
          alt="polygon"
        />
        <img
          className="position-absolute students_small_polygon top-50 translate-middle"
          src={Polygon2}
          alt="small_polygon"
        />
        <h2 className="ff_inter color_dark_blue text-center fw-bold mb-0 position-relative our_students_line">
          What Our Students <span className="text_gradient">Say?</span>
        </h2>
        <Slider {...settings} ref={sliderRef}>
          <div className="row d-flex align-items-center justify-content-center my-5 py-4">
            <div className="col-lg-6">
              <img
                className="our_student mx-auto"
                src={Image_URL + "website/students/Students_image.png"}
                alt="OurStudent"
              />
            </div>
            <div className="col-lg-6">
              <img
                className="text-start mt-4 mt-lg-0"
                src={Quotes}
                alt="quotes"
              />
              <h3 className="ff_inter fw-bold text_dark_grey mt-2 mb-0">
                Shailja vijaylaxmi
              </h3>
              <p className="ff_inter fs_desc mt-2 text_grey mb-0">
                मेरा नाम शैलजा विजयलक्ष्मी है और कुम्हेर (भरतपुर) राजस्थान में
                VDO पोस्ट पर मेरी जॉब लगी है और मैं अपनी सफलता का श्रेय अपने
                माता-पिता के अटूट समर्थन को देती हूं, और मैं श्री सुभाष सर और
                उनकी टीम को उनके अमूल्य मार्गदर्शन के लिए दिल से धन्यवाद देती
                हूं। टीम धुरिना, आपकी व्यावसायिकता और मेरी उपलब्धियों में योगदान
                के लिए धन्यवाद। 🙏
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center my-5 py-4">
            <div className="col-lg-6">
              <img
                className="our_student mx-auto"
                src={Image_URL + "website/Gayatri.png"}
                alt="OurStudent"
              />
            </div>
            <div className="col-lg-6">
              <img
                className="text-start mt-4 mt-lg-0"
                src={Quotes}
                alt="quotes"
              />
              <h3 className="ff_inter fw-bold text_dark_grey mt-2 mb-0">
                Gayatri Sharma
              </h3>
              <p className="ff_inter fs_desc mt-2 text_grey mb-0">
                सर reet परीक्षा के लिए मैंने आपका साइंस व मैथ का कोर्स लिया था,
                मैं एक गृहणी हूँ और घर से बाहर जा नहीं सकती थी, कभी ऐसा लगा नहीं
                था की शादी के बाद पढ़ाई जारी रख पाऊँगी, लेकिन आपने मेरी इस
                समस्या का निवारण बनाया आपका ऑनलाइन कोर्स, कोटि कोटि धन्यवाद
                गुरुजी
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center my-5 py-4">
            <div className="col-lg-6">
              <img
                className="our_student mx-auto"
                src={Image_URL + "website/YogeYogesh.png"}
                alt="OurStudent"
              />
            </div>
            <div className="col-lg-6">
              <img
                className="text-start mt-4 mt-lg-0"
                src={Quotes}
                alt="quotes"
              />
              <h3 className="ff_inter fw-bold text_dark_grey mt-2 mb-0">
                Yogesh Ranga
              </h3>
              <p className="ff_inter fs_desc mt-2 text_grey mb-0">
                सर मैं RAS प्री की तैयारी कर रहा था सेलेबस को देख कर कभी ऐसा लगा
                ही नहीं था की कभी पूरा कर पाऊँगा, लेकिन आपकी ऐप पर लाइव कोर्स जब
                लिया तो उसके बाद ऐसे लगने लगा कि आसानी से पूरा हो सकता है,
                रेगुलर क्लासेज़ लेने के बाद मैंने मॉक टेस्ट शुरू किए तो 120-125
                ओवरऑल स्कोर करने लगा हूँ। आपका धन्यवाद सर
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center my-5 py-4">
            <div className="col-lg-6">
              <img
                className="our_student mx-auto"
                src={Image_URL + "website/Bharat.png"}
                alt="OurStudent"
              />
            </div>
            <div className="col-lg-6">
              <img
                className="text-start mt-4 mt-lg-0"
                src={Quotes}
                alt="quotes"
              />
              <h3 className="ff_inter fw-bold text_dark_grey mt-2 mb-0">
                Bharat Singh
              </h3>
              <p className="ff_inter fs_desc mt-2 text_grey mb-0">
                सर राजस्थान gk पहाड़ जैसे लगता था ऐसे लगता था बहुत मुश्किल है,
                मुझे समझ नहीं आएगा, लेकिन आपके ओर बाक़ी गुरुजी के पढ़ाने के
                ट्रिक से अब मैंने उँगलियों पे घोट दिया है। जल्दी सरकारी नौकरी
                लगकर आपसे मिलने आऊँगा।
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center my-5 py-4">
            <div className="col-lg-6">
              <img
                className="our_student mx-auto"
                src={Image_URL + "website/Yuvraj.png"}
                alt="OurStudent"
              />
            </div>
            <div className="col-lg-6">
              <img
                className="text-start mt-4 mt-lg-0"
                src={Quotes}
                alt="quotes"
              />
              <h3 className="ff_inter fw-bold text_dark_grey mt-2 mb-0">
                Yuvraj Rathore Medtiya
              </h3>
              <p className="ff_inter fs_desc mt-2 text_grey mb-0">
                गुरुजी नमस्कार! मैं एक निम्न परिवार का छात्र हूँ, पारिवारिक
                ज़िम्मेवारी के कारण मुझे पढ़ाई के साथ साथ निजी शिक्षण संस्था में
                नौकरी करनी पड़ रही है, स्कूल के बाद मैंने आपके ऑनलाइन कोर्स से
                तैयारी शुरू की, और धीरे धीरे मैं समय प्रबंधन करके नियमित पढ़ाई
                करने लगा हूँ।
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center my-5 py-4">
            <div className="col-lg-6">
              <img
                className="our_student mx-auto"
                src={Image_URL + "website/students/Students_image1.jpg"}
                alt="OurStudent"
              />
            </div>
            <div className="col-lg-6">
              <img
                className="text-start mt-4 mt-lg-0"
                src={Quotes}
                alt="quotes"
              />
              <h3 className="ff_inter fw-bold text_dark_grey mt-2 mb-0">
                Manoj Kumar
              </h3>
              <p className="ff_inter fs_desc mt-2 text_grey mb-0">
                सर मैंने कुछ समय पहले धुरीना एप्प पर उपलब्ध RAS प्री कोर्स व
                test series के द्वारा अपनी तैयारी को शुरू किया। पहले मुझे यूँ
                लगता था की RAS Pre पास करना पहाड़ सा कार्य है। लेकिन आपने जिस
                प्रकार अध्यापन करवाया मैंने RAS Pre को क्वालीफाई किया और साथ ही
                राजस्थान सब-इंस्पेक्टर लिखित परीक्षा भी पास की। आप का सदैव
                धन्यवाद। Thank You Dhurina।
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center my-5 py-4">
            <div className="col-lg-6">
              <img
                className="our_student mx-auto"
                src={Image_URL + "website/students/Students_image3.jpg"}
                alt="OurStudent"
              />
            </div>
            <div className="col-lg-6">
              <img
                className="text-start mt-4 mt-lg-0"
                src={Quotes}
                alt="quotes"
              />
              <h3 className="ff_inter fw-bold text_dark_grey mt-2 mb-0">
                Sanju Chauhan
              </h3>
              <p className="ff_inter fs_desc mt-2 text_grey mb-0">
                सर मेरे भैया ने पारिवारिक परिस्थितियों के चलते 12 साल पहले अपनी
                पढ़ाई को छोड़ दिया था। फिर एक दिन मैंने आपके PTI Online कोर्स का
                पोस्टर देखा तो मैंने भैया से तैयारी करने के लिए कहा, दरअसल आपका
                ऑनलाइन कोर्स नाममात्र के शुल्क में उपलब्ध था। भैया ने उस से
                पढ़ना शुरू किया और एग्जाम पास किया। धन्यवाद गुरूजी आपकी वजह से
                मेरे भाई PTI के पद पर चयनित हैं। धुरीना ऐप बहुत से सपनों को
                साकार करने का ज़रिया बन रही है।
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center my-5 py-4">
            <div className="col-lg-6">
              <img
                className="our_student mx-auto"
                src={Image_URL + "website/students/Students_image2.jpg"}
                alt="OurStudent"
              />
            </div>
            <div className="col-lg-6">
              <img
                className="text-start mt-4 mt-lg-0"
                src={Quotes}
                alt="quotes"
              />
              <h3 className="ff_inter fw-bold text_dark_grey mt-2 mb-0">
                Sweta Mishra
              </h3>
              <p className="ff_inter fs_desc mt-2 text_grey mb-0">
                गुरुजी मैंने आपका दिल्ली पुलिस का कोर्स लिया था और मैंने उस
                कोर्स के साथ साथ आपकी वीडियो यूट्यूब पर देखनी शुरू की, और उनसे
                मेरा जीके पढ़ने का इंटरस्ट बन ने लगा। और अभी मैंने दिल्ली पुलिस
                का written एग्जाम क्लियर कर लिया है। थैंक यू सर, इतना अच्छा जीके
                बेस बनाने के लिये।
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center my-5 py-4">
            <div className="col-lg-6">
              <img
                className="our_student mx-auto"
                src={Image_URL + "website/students/Students_image5.jpg"}
                alt="OurStudent"
              />
            </div>
            <div className="col-lg-6">
              <img
                className="text-start mt-4 mt-lg-0"
                src={Quotes}
                alt="quotes"
              />
              <h3 className="ff_inter fw-bold text_dark_grey mt-2 mb-0">
                Sonu Kumar
              </h3>
              <p className="ff_inter fs_desc mt-2 text_grey mb-0">
                सर मैं राजगढ़ चूरु का निवासी हूँ मेरे बड़े भाई साहब बैंक में
                कार्यरत है जिन्होंने मुझे आपके नोट्स लाकर दिये व आपकी ऑनलाइन
                कोर्स कक्षाओं के बारे में बताया। मैं इन दोनों के अध्ययन से
                राजस्थान पुलिस व रेलवे के एग्जाम की तैयारी करने लगा। अंततः मेरा
                रेलवे में सिलेक्शन हो गया। आपके मार्गदर्शन के लिए आपका बहुत बहुत
                आभा
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center justify-content-center my-5 py-4">
            <div className="col-lg-6">
              <img
                className="our_student mx-auto"
                src={Image_URL + "website/students/Students_image4.jpg"}
                alt="OurStudent"
              />
            </div>
            <div className="col-lg-6">
              <img
                className="text-start mt-4 mt-lg-0"
                src={Quotes}
                alt="quotes"
              />
              <h3 className="ff_inter fw-bold text_dark_grey mt-2 mb-0">
                Mohit Mehta
              </h3>
              <p className="ff_inter fs_desc mt-2 text_grey mb-0">
                नमस्कार सर, मैं एक साधारण क्षेत्र से Belong करती हूँ, जहां
                परिवार की विपरीत स्थितियों के कारण मैं ऑफलाइन ना पढ़कर ऑनलाइन
                आपकी कक्षाएँ लेने लगी जिसके परिणामस्वरूप मेरा RAS Pre एग्जाम
                क्वालीफाई हुआ। आपकी कक्षाओं से जो जोश मिला वो हमेशा मंज़िल को और
                खींच कर ले जाने को कामयाब रहता है। आपका बहुत बहुत धन्यवाद।
              </p>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Students;
