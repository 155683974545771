import React,{useState,useEffect,useCallback} from 'react';
import { Link,useParams, useNavigate } from "react-router-dom";
import { Container, Col, Row, Accordion, Image } from 'react-bootstrap'; 
import { Parser } from 'html-to-react'
import MainLoaderImage from './../../../assets/img/loading-buffering.gif'
import ApiCall from './../../../api/callApiCSC';
const time_table = process.env.REACT_APP_Bucket_URL+"course/time_table/";

const MyCourses=()=>{

	const navigate = useNavigate();
    if(localStorage.getItem('token')=='' || localStorage.getItem('token')==null){
        navigate('/skill-login');
	}

    var token=localStorage.getItem('token');

    // console.log(localStorage.getItem('token'));

    const [status, setStatus] = useState(1);
    const [data,setData] = useState([])
    const [mainloading,setMainLoading]=useState(true)

    useEffect(() => {
        getData()
    },[]);

    const getData=async()=>{
        ApiCall("","get","skill_my_courses",courseareacallback,token);
    }

    const courseareacallback = useCallback(response =>{
        setMainLoading(false);
        if(response.data.status==='true'){
            // console.log(response.data.data);
            setData(response.data.data);
        }
        else{
            setStatus(0);
        }
    })

    if (mainloading===true) {
        return(
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><img src={MainLoaderImage} style={{ alignSelf: 'center' }}/></div>
        )
    }

    return (
        <>       

            <section className="breadcrumb-area pb-4 banner-area innerpagesbanner justify-content-center">
                <Container>
                    <div style={{ textAlign:"center" }}>    
                        <Row className="justify-content-center">
                            <Col lg={12} className="text-sm-end">
                                {/* <Link to={"/live-classes-web/"+token} className="btn btn-danger mt-sm-0"> */}
                                    <i className="fa fa-desktop"></i> My Courses
                                {/* </Link> */}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            
            
            <section className="trending-courses-area py-5">
                <Container>
                    <Row>
                        <Col lg={12} className="sidebar-area">
                            <div className="widget widget-accordion-inner">
                                {status==0? <h6 className="mt-4 mb-3" style={{margin: '5%'}}>No course found!. Buy courses from Dhurina App/Website to watch live courses here......</h6>:
                                    <Accordion defaultActiveKey="0">
                                        {data.map((value,key)=>{
                                            return(
                                            <Accordion.Item eventKey="0" key={key}>
                                                <Accordion.Header>
                                                    <i className="fa fa-book me-2"></i> {value.name}
                                                </Accordion.Header>
                                                <Accordion.Body className="widget-select-inner"> 
                                                    <p>{Parser().parse(value.description)}</p>

                                                    {value.time_table!=''?
                                                        <>
                                                        <div className="border-bottom my-4"></div>
                                                        <h6 className="mt-4 mb-3">Time Schedule</h6>
                                                        <a href={`${time_table}${value.time_table}`} target="_blank" className="mb-2 me-1 btn btn-online">View Schedule</a>
                                                        </>:null
                                                    }



                                                    <div className="border-bottom my-4"></div>
                                                    <h6 className="mt-4 mb-3">Videos</h6>

                                                    {value.subjects.map((subject,subkey)=>{
                                                        return(
                                                            <Link to={"/skill-course-videos-web/"+token+"/"+value.id+"/"+subject.id} className="mb-2 me-1 btn btn-online" key={subkey}>{subject.name}</Link>
                                                        )
                                                    })}

                                                    {/* <div className="border-bottom my-4"></div>
                                                    <h6 className="mt-4 mb-3">Notes/PDF</h6>
                                                    <Link to="/" className="mb-2 me-1 btn btn-online">Science</Link>
                                                    <Link to="/" className="mb-2 me-1 btn btn-online">English</Link>
                                                    <Link to="/" className="mb-2 me-1 btn btn-online">Math</Link>
                                                    <Link to="/" className="mb-2 me-1 btn btn-online">GK</Link>

                                                    <div className="border-bottom my-4"></div>

                                                    <h6 className="mt-4 mb-3">Online Test</h6>
                                                    <Link to="/" className="mb-2 me-1 btn btn-online">Full Month</Link>
                                                    <Link to="/" className="mb-2 me-1 btn btn-online">Weekly</Link> */}
                                                </Accordion.Body>
                                            </Accordion.Item>)
                                        })}
                                    </Accordion> 
                                }                               
                            </div> 
                        </Col>
                        
                    </Row>
                </Container>
            </section>
            

        </>
    );
}

export default MyCourses;
