// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useEffect, useCallback } from "react";
// import ApiCall from "../../api/callApi";
// import { useNavigate } from "react-router";

// const Image_URL = process.env.REACT_APP_Bucket_URL;

// const Login = ({ setLoginPopup }) => {
//   const navigate = useNavigate();

//   const [phoneNumber, setPhoneNumber] = useState("");
//   console.log("phonenumber ---->", phoneNumber);
//   const [otp, setOtp] = useState("");
//   const [loginInputPlaceHolder, setLoginInputPlaceHolder] =
//     useState("Enter Phone Number");

//   const [seconds, setSeconds] = useState("SEND OTP");
//   const [error, setError] = useState("");

//   useEffect(() => {
//     if (seconds === "SEND OTP") {
//       setPhoneNumber(otp);
//     }
//   }, [otp]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (seconds > 0) {
//         setSeconds(seconds - 1);
//       }
//       if (seconds === 0) {
//         clearInterval(interval);
//         setSeconds("Resend OTP");
//       }
//     }, 1000);

//     return () => {
//       clearInterval(interval);
//     };
//   }, [seconds]);

//   // Function to handle sending OTP
//   const handleSendOTP = useCallback(async () => {
//     if (phoneNumber.length === 10) {
//       setError("");
//       if (isNaN(seconds)) {
//         setSeconds(60);
//         setLoginInputPlaceHolder("Enter OTP");
//         setOtp("");

//         const data = {
//           phone: phoneNumber,
//         };

//         await ApiCall(data, "post", "send_otp", sendOtpCallback);
//       }
//     } else {
//       setError("Please Enter Valid Number");
//     }
//   }, [seconds, otp, phoneNumber, error]);

//   // Callback function for sending OTP API call
//   const sendOtpCallback = useCallback((response) => {
//     // Handle the response here
//   }, []);

//   // Function to handle OTP verification
//   const handleVerifyOTP = useCallback(async () => {
//     if (otp.length === 5) {
//       setError("");
//       const data = {
//         phone: phoneNumber,
//         otp: otp,
//       };
//       // Make API call to verify OTP
//       await ApiCall(data, "post", "verify_otp", verifyOtpCallback);
//     } else if (!phoneNumber.length === 10 || phoneNumber === "") {
//       setError("Please Verify The Phone Number");
//     } else {
//       setError("Please Enter Valid OTP");
//     }
//   }, [phoneNumber, otp, error]);

//   // Callback function for verifying OTP API call
//   const verifyOtpCallback = useCallback((response) => {
//     if (response.data.status === "true") {
//       localStorage.setItem("token", response.data.token);
//       localStorage.setItem("name", response.data.user.name);
//       localStorage.setItem("id", response.data.user.id);
//       localStorage.setItem("mobile", response.data.user.number);
//       // window.location.reload(); // Reload the page after redirecting
//     } else {
//       setError("Invalid OTP");
//     }
//   }, []);

//   // Function to handle resending OTP
//   const handleResendOTP = useCallback(async () => {
//     const data = {
//       phone: phoneNumber,
//     };

//     // Make API call to resend OTP
//     await ApiCall(data, "post", "send_otp", sendOtpCallback);
//   }, [phoneNumber]);

//   // Function to check user existence
//   const checkUserApi = useCallback(async () => {
//     const data = {
//       phone: phoneNumber,
//     };

//     await ApiCall(data, "post", "check_user", checkUserCallback);
//   }, [phoneNumber]);

//   // Callback function for checking user API call
//   const checkUserCallback = useCallback((response) => {
//     if (response.data.status === "true" && error === "") {
//       if (response.data.message === "exist") {
//         navigate("/");
//       } else if (response.data.message === "not_exist") {
//         console.log(phoneNumber);
//         navigate("/welcome?phone=" + phoneNumber);
//       }
//     } else {
//       setError("Something went wrong");
//     }
//   }, []);

//   const handleClosePopup = () => {
//     if ((phoneNumber.length === 10) & (otp.length === 5) && error === "") {
//       setLoginPopup(false);
//       document.body.parentElement.classList.toggle("overflow-hidden");
//     } else {
//       if (!phoneNumber.length === 10) {
//         setError("Please Enter Valid Number");
//       } else if (!otp.length === 5) {
//         console.log("Please Enter Valid Otp");
//       }
//     }
//   };
//   // const login =()=>{
//   //   if()
//   // }
//   const CloseLoginPopUp = () => {
//     setLoginPopup(false);
//     document.body.parentElement.classList.toggle("overflow-hidden");
//   };
//   return (
//     <div className="text-end py-3">
//       {/* <img
//         className="cursor_pointer"
//         onClick={CloseLoginPopUp}
//         src={Image_URL + "website/svg/popup-close-icon.svg"}
//         alt="popup_close"
//       /> */}
//       <img
//         width={30}
//         className="cursor_pointer"
//         onClick={CloseLoginPopUp}
//         src="https://icons.veryicon.com/png/o/miscellaneous/medium-thin-linear-icon/cross-23.png"
//         alt="popup_close"
//       />
//       <div className="row align-items-end flex-column-reverse flex-lg-row py-3">
//         <div className="col-lg-6 text-center mt-4 mt-lg-0">
//           <p className="color_dark_blue fs_8xl fw-normal ff_inter mb-0">
//             Get direction to{" "}
//           </p>
//           <p className="text_gradient fs_8xl fw-bold ff_inter pb-md-4 mb-2">
//             Prepare for your exams
//           </p>
//           <p className="fs_xxl text_gradient ff_inter fw-semi-bold mb-5 pb-md-4">
//             JOIN
//           </p>
//           <div className="position-relative">
//             <input
//               className="w-100 login_input pb-2 fs_xxl color_grey "
//               type="number"
//               name="number"
//               id="number"
//               placeholder={loginInputPlaceHolder}
//               value={otp}
//               onChange={(e) => {
//                 setOtp(e.target.value);
//               }}
//             />
//             <button
//               onClick={handleSendOTP}
//               className="position-absolute ff_inter top-0 end-0 border-0 bg-transparent text_gradient fw-semi-bold fs_xxl"
//             >
//               {seconds < 10 && !isNaN(seconds)
//                 ? `00:0${seconds}`
//                 : !isNaN(seconds)
//                 ? `00:${seconds}`
//                 : seconds}
//             </button>
//             <img
//               width={30}
//               className="position-absolute mobile_icon start-0 "
//               src={Image_URL + "website/mobile_icon.png"}
//               alt="MobileIcon"
//             />
//           </div>
//           <p className="mb-0">{error}</p>
//           <button
//             onClick={async () => {
//               await handleVerifyOTP();
//               handleClosePopup();
//               checkUserApi();
//             }}
//             // onClick={login}
//             className="mt-5 w-100 py-2 text-white fs_xxl ff_inter fw-normal border-0 login_btn"
//           >
//             LOGIN
//           </button>
//         </div>
//         <div className="col-lg-6 col-10 mx-auto mx-lg-0 text-center">
//           <img
//             className="img-fluid w-75"
//             src={Image_URL + "website/login-img.png"}
//             alt={"LoginImg"}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import ApiCall from "../../api/callApi";
import { useNavigate } from "react-router";

const Image_URL = process.env.REACT_APP_Bucket_URL;

const Login = ({ setLoginPopup }) => {
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [loginInputPlaceHolder, setLoginInputPlaceHolder] =
    useState("Enter Phone Number");
  const [seconds, setSeconds] = useState("SEND OTP");
  const [error, setError] = useState("");

  useEffect(() => {
    if (seconds === "SEND OTP") {
      setPhoneNumber(otp);
    }
  }, [otp]);

  useEffect(() => {
    if (isNaN(seconds)) return;

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(interval);
          return "Resend OTP";
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const handleSendOTP = useCallback(async () => {
    if (phoneNumber.length !== 10) {
      setError("Please Enter Valid Number");
      return;
    }

    setError("");
    if (isNaN(seconds)) {
      setSeconds(60);
      setLoginInputPlaceHolder("Enter OTP");
      setOtp("");

      const data = { phone: phoneNumber };
      await ApiCall(data, "post", "send_otp", sendOtpCallback);
    }
  }, [phoneNumber, seconds]);

  const sendOtpCallback = useCallback((response) => {
    // Handle the response here
  }, []);

  const handleVerifyOTP = useCallback(async () => {
    if (otp.length !== 5) {
      setError("Please Enter Valid OTP");
      return;
    }

    setError("");
    const data = { phone: phoneNumber, otp };
    await ApiCall(data, "post", "verify_otp", verifyOtpCallback);
  }, [phoneNumber, otp]);

  const verifyOtpCallback = useCallback(
    (response) => {
      // alert(response?.data?.status);
      if (response?.data?.status === "true") {
        if (response?.data?.register_status === 1) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("name", response.data.user.name);
          localStorage.setItem("id", response.data.user.id);
          localStorage.setItem("mobile", response.data.user.number);
          window.location.reload();
        } else {
          setLoginPopup(false);
          navigate(`/welcome?phone=${phoneNumber}`);
        }
      } else {
        setError("Invalid OTP");
      }
    },
    [phoneNumber]
  );

  const handleResendOTP = useCallback(async () => {
    const data = { phone: phoneNumber };
    await ApiCall(data, "post", "send_otp", sendOtpCallback);
  }, [phoneNumber]);

  const checkUserApi = useCallback(async () => {
    const data = { phone: phoneNumber };
    await ApiCall(data, "post", "check_user", checkUserCallback);
  }, [phoneNumber]);

  const checkUserCallback = useCallback(
    (response) => {
      if (response.data.status === "true" && !error) {
        navigate(
          response.data.message === "exist"
            ? "/"
            : `/welcome?phone=${phoneNumber}`
        );
      } else {
        setError("Something went wrong");
      }
    },
    [phoneNumber, error]
  );

  const handleClosePopup = () => {
    if (phoneNumber.length === 10 && otp.length === 5 && !error) {
      setLoginPopup(false);
      document.body.parentElement.classList.toggle("overflow-hidden");
    } else {
      setError(
        phoneNumber.length !== 10
          ? "Please Enter Valid Number"
          : "Please Enter Valid OTP"
      );
    }
  };

  const CloseLoginPopUp = () => {
    setLoginPopup(false);
    document.body.parentElement.classList.toggle("overflow-hidden");
  };

  return (
    <div className="text-end py-3">
      <img
        width={30}
        className="cursor_pointer"
        onClick={CloseLoginPopUp}
        src="https://icons.veryicon.com/png/o/miscellaneous/medium-thin-linear-icon/cross-23.png"
        alt="popup_close"
      />
      <div className="row align-items-end flex-column-reverse flex-lg-row py-3">
        <div className="col-lg-6 text-center mt-4 mt-lg-0">
          <p className="color_dark_blue fs_8xl fw-normal ff_inter mb-0">
            Get direction to{" "}
          </p>
          <p className="text_gradient fs_8xl fw-bold ff_inter pb-md-4 mb-2">
            Prepare for your exams
          </p>
          <p className="fs_xxl text_gradient ff_inter fw-semi-bold mb-5 pb-md-4">
            JOIN
          </p>
          <div className="position-relative">
            <input
              className="w-100 login_input pb-2 fs_xxl color_grey"
              type="number"
              name="number"
              id="number"
              placeholder={loginInputPlaceHolder}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <button
              onClick={handleSendOTP}
              className="position-absolute ff_inter top-0 end-0 border-0 bg-transparent text_gradient fw-semi-bold fs_xxl"
            >
              {seconds < 10 && !isNaN(seconds)
                ? `00:0${seconds}`
                : !isNaN(seconds)
                ? `00:${seconds}`
                : seconds}
            </button>
            <img
              width={30}
              className="position-absolute mobile_icon start-0"
              src={`${Image_URL}website/mobile_icon.png`}
              alt="MobileIcon"
            />
          </div>
          <p className="mb-0">{error}</p>
          <button
            onClick={async () => {
              await handleVerifyOTP();
              // handleClosePopup();
              // checkUserApi();
            }}
            className="mt-5 w-100 py-2 text-white fs_xxl ff_inter fw-normal border-0 login_btn"
          >
            LOGIN
          </button>
        </div>
        <div className="col-lg-6 col-10 mx-auto mx-lg-0 text-center">
          <img
            className="img-fluid w-75"
            src={`${Image_URL}website/login-img.png`}
            alt="LoginImg"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
