import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Innerheader from "../../include/header/innerHeader";
import Footer from "../../include/footer/footer";

const Refund = () => {
  var page_url = window.location.href;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Innerheader></Innerheader>  */}
      <Helmet>
        <title>Refund Policy - Dhurina.net</title>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link rel="canonical" href={page_url} />
        <meta name="title" content="Refund Policy - Dhurina.net" />
        <meta
          name="description"
          content="Return policies are non-refundable for courses once enrolled, acquired, subscribed to or ordered, and our refund policy is non-refundable."
        />
        <meta
          name="twitter:description"
          content="Return policies are non-refundable for courses once enrolled, acquired, subscribed to or ordered, and our refund policy is non-refundable."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Refund Policy - Dhurina.net" />
        <meta name="twitter:site" content="@Dhurinalive" />
        <meta name="twitter:creator" content="@Dhurinalive" />
        <meta name="twitter:app:id:googleplay" content="com.dhurina" />
        <meta name="twitter:app:id:iphone" content="1479858722" />
        <meta
          name="twitter:app:name:googleplay"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:ipad"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:iphone"
          content="Dhurina - Learning Made Easy"
        />
        <meta name="twitter:domain" content="https://dhurina.net" />
        <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
        <meta
          property="og:description"
          content="Return policies are non-refundable for courses once enrolled, acquired, subscribed to or ordered, and our refund policy is non-refundable."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Refund Policy - Dhurina.net" />
        <meta property="og:url" content={page_url} />
        <meta property="og:site_name" content="Dhurina" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/dhurinaclass"
        />
        <meta
          property="article:author"
          content="https://www.facebook.com/dhurinaclass"
        />
        <meta property="al:android:url" content={page_url} />
        <meta
          property="al:android:app_name"
          content="Dhurina - Learning Made Easy"
        />
        <meta property="al:android:package" content="com.dhurina" />
        <meta property="fb:app_id" content="facebook-app-id-in-number" />
        <meta property="fb:pages" content="facebook-page-in-number" />
      </Helmet>

      <section>
        <div className="custom_container container pb-5">
          <Row>
            <Col md={12} className="mt-5">
              <div className="section-title lefth2border">
                <h2 className="text-start">Refund and Cancellation Policy</h2>
              </div>

              <div>
                <p>
                  1. You (“user”, “your”, “you”) give your unequivocal and
                  absolute assent to all the clauses of the said Cancellation
                  &amp; Refund Policy (“policy”), as also iterated in the Terms
                  of Use of Dhurina (“we”, “our”, “us”, “Company”).
                </p>

                <p>
                  2. If you wish to cancel a confirmed enrolment made via the
                  Application, after enrolment to the Course, you may do so by
                  contacting us via the contact link in the Contact Section.
                </p>

                <p>
                  3. All courses, products and services listed on the
                  application are Non-Refundable and once enrolled
                  in/obtained/subscribed to/ordered shall not be subject to any
                  refunds or returns.
                </p>

                <p>
                  4. A refund shall be made to the user only in the event a
                  repeat payment (payment made twice for the same course) has
                  been made by the user. Such refund shall be made for the
                  amount paid in excess of the fee of the course only and not
                  the entire amount paid. The refund shall be credited to the
                  bank account of the User within 7 business days. If payment is
                  made via credit card, refunds shall be issued to the original
                  credit card provided at the time of purchase.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* <Footer></Footer> */}
    </>
  );
};

export default Refund;
