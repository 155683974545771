import React from 'react';

import { Container, Col, Row, Image} from 'react-bootstrap';


import TestImage from './../../assets/img/Test.jpeg';
import PrizeImage from './../../assets/img/prize.jpeg';

const TestResult=()=>{

  return (
	 <Container fluid className="login-bg minheightlogin">
		<div>
			<div className="row">
				<Row  className="align-items-center">
					<Col md={6}>
						<Image src={TestImage} alt="img" style={{marginBottom:'20%'}} />
					</Col>
                    <Col md={6}>
                    <Image src={PrizeImage} alt="img" style={{marginBottom:'20%'}} />
                      
					</Col>
					
				</Row> 
			</div>

		
		</div>
	</Container>
  );
}

export default TestResult;

    
    
	
     
     
      