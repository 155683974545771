import React,{ useState,useEffect,useCallback} from "react"; 
import { Container, Row, Pagination} from 'react-bootstrap'; 
import { useParams} from "react-router-dom";
import MainLoaderImage from './../../../assets/img/loading-buffering.gif'
import Video from './video_component';
import ApiCall from './../../../api/callApiCSC';
import Innerheader from '../../../include/header/SchoolSkillHeader';

function CourseVideos(){

    var {course_id,subject_id,token}=useParams();

    const myarr=[];
    const [status, setStatus] = useState(1);
    const [page, setPage] = useState(1);
    const [data,setData] = useState(myarr)
    const [mainloading,setMainLoading]=useState(true)
    const [loadMoreText,setLoadMoreText]=useState('Load More')

    useEffect(() => {
        getData()
    },[]);

    const getData=async()=>{

        setLoadMoreText('Loading wait...');
        ApiCall({course_id:course_id,subject_id:subject_id,page:page},"post","csc_course_videos",courseareacallback,token);
    }

    const courseareacallback = useCallback(response =>{
        setMainLoading(false);
        setLoadMoreText('Load More');

        if(response.data.status==='true'){
            const res_data=response.data.data.data
            setData([...data, ...res_data]);
            setPage(page+1)

            if (res_data.length<8) {
                setStatus(0);
            }
        }
        else{
            setStatus(0);
        }
    })

    if (mainloading===true) {
        return(
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><img src={MainLoaderImage} style={{ alignSelf: 'center' }}/></div>
        )
    }

    return (
        <>        

            <Innerheader></Innerheader> 
            
            <section className="trending-courses-area py-5">
                <Container>
                {data.length<1? <h6 className="mt-4 mb-3" style={{margin: '5%'}}>No videos found!!.</h6>:
                    <>
                    <h6 className="mt-4 mb-3" style={{margin: '5%'}}>Course Videos</h6>
                    <Row>
                        {data.map((video)=>{
                            video.token=token;
                            video.subject_id=subject_id;
                            video.course_id=course_id;
                            return <Video video={video} key={video.id}/>
                        })}
                    </Row>
                    </>
                }
                {status===1?
                    <Pagination className="text-center d-block"> 
                        <button className="btn-load-more mt-3 f-16 shadow-none btn btn-danger" onClick={() => getData()}>{loadMoreText}</button>
                    </Pagination>
                :null}
                </Container>
            </section>
            
        </>
    );
}

export default CourseVideos;
