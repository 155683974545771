import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Col, Row, Image, Form } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import Defenceheader from "../../include/header/defenceHeader";
import Footer from "../../include/footer/footer";
import Applink from "../../include/applink/googleApple";
import Item from "../../components/item/index";
import ApiCall from "../../api/callApi";
import Loading from "../../components/Loader";
import DefenceImage from "./../../assets/img/defence.png";
import MobappImage from "./../../assets/img/mob-app.png";
import { Helmet } from "react-helmet";

const courseSlideroptions = {
  loop: true,
  margin: 30,
  nav: true,
  dots: false,
  smartSpeed: 1500,
  autoplay: true,
  navText: [
    "<i class='fa fa-arrow-left'></i>",
    "<i class='fa fa-arrow-right'></i>",
  ],
  responsive: {
    0: {
      items: 1.5,
      dots: true,
      margin: 10,
    },
    575: {
      items: 2,
      margin: 20,
      dots: true,
    },
    769: {
      items: 4,
      dots: false,
    },
  },
};

const Defencehome = () => {
  const [defenceCourse, setDefenceCourse] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    ApiCall("", "get", "defence_course", defencecourse);
  }

  const defencecourse = useCallback((response) => {
    if (response.data.status === 200) {
      setDefenceCourse(response.data.defence_course);
    } else {
      console.log("error");
    }
  });

  return (
    <>
      <Defenceheader></Defenceheader>
      <Helmet>
        <title>Dhurina - Defence Home</title>
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <link rel="canonical" href="https://dhurina.net/fetch-canonical-url" />
        <meta name="title" content="fetch title of the content" />
        <meta name="description" content="fetch description of the content" />
        <meta name="publish-date" content="2022-04-22T11:24:28+05:30" />
        <meta name="created-date" content="2022-04-22T11:24:28+05:30" />
        <meta name="modified-date" content="2022-04-22T11:24:29+05:30" />
        <meta
          name="twitter:description"
          content="fetch description of the content"
        />
        <meta
          name="twitter:image"
          content="https://dhurina.net/fetch-image-url"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="fetch title of the content" />
        <meta name="twitter:site" content="@Dhurinalive" />
        <meta name="twitter:creator" content="@Dhurinalive" />
        <meta name="twitter:app:id:googleplay" content="com.dhurina" />
        <meta name="twitter:app:id:iphone" content="1479858722" />
        <meta
          name="twitter:app:name:googleplay"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:ipad"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:iphone"
          content="Dhurina - Learning Made Easy"
        />
        <meta name="twitter:domain" content="dhurina.net" />
        <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
        <meta name="og:image:type" content="image/jpeg" />
        <meta
          property="og:description"
          content="fetch description of the content"
        />
        <meta
          property="og:image"
          content="https://dhurina.net/fetch-image-url"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="fetch title of the content" />
        <meta property="og:url" content="URL of Course" />
        <meta property="og:site_name" content="Dhurina" />
        <meta property="og:updated_time" content="2022-01-28T13:36:39+05:30" />
        <meta
          property="article:published_time"
          content="2022-04-22T05:54:28.000Z"
        />
        <meta
          property="article:modified_time"
          content="2022-04-22T05:54:29.000Z"
        />
        <meta property="article:section" content="Fetch Category" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/dhurinalive"
        />
        <meta property="article:author" content="https://fb.com/dhurinalive" />
        <meta property="article:section" content="Online-Course" />
        <meta
          property="al:android:url"
          content="https://dhurina.net/fetch-canonical-url"
        />
        <meta
          property="al:android:app_name"
          content="Dhurina - Learning Made Easy"
        />
        <meta property="al:android:package" content="com.dhurina" />
        <meta property="fb:app_id" content="facebook-app-id-in-number" />
        <meta property="fb:pages" content="facebook-page-in-number" />
      </Helmet>

      <section className="banner-area defencemain-banner">
        <Container>
          <Row className="position-relative zindexover justify-content-end">
            <Col md={6} className="mt-5">
              <div className="main-banner-txt banner-inner text-md-start text-center mb-0 mb-sm-5 defence-banner">
                <div className="section-title lefth2border mb-3">
                  <h5 className="fw-medium text-start text-white f-22">
                    Now Girls can
                  </h5>
                  <h2 className="text-start text-white">Join Defence Easy</h2>
                </div>
                <p className="f-15 text-white text-start schoolbannerdesp">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque{" "}
                </p>
                <div className="download-btn mt-4 text-start">
                  <p className="f-15 text-white">
                    <span>Download App Now</span>
                  </p>
                  <p>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.dhurina"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-google-play ms-0"></i>
                    </a>{" "}
                    <a
                      href="https://apps.apple.com/in/app/dhurina/id1479858722"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-apple"></i>
                    </a>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="trending-courses-area py-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title">
                <h2>Top Defence Courses</h2>
              </div>
            </Col>
            <Col lg={12}>
              <OwlCarousel className="course-slider" {...courseSlideroptions}>
                {defenceCourse.length < 1 ? (
                  <Loading />
                ) : (
                  defenceCourse.map((item) => {
                    return <Item item={item} />;
                  })
                )}
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mb-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="joinschool mt-0">
                <Row className="justify-content-center">
                  <Col md={3}>
                    <Image src={DefenceImage} alt="Defence Image" />
                  </Col>
                  <Col md={6}>
                    <h2 className="text-white">Distance Learning Program</h2>
                    <div className="navbar-area certficationsearch mt-3">
                      <div className="single-input-wrap">
                        <Form.Control
                          type="text"
                          className="d-inline-block"
                          placeholder="Search Your Program"
                        />
                        <Button varaint="homesearch">
                          <i className="fa fa-sort-down fa-2x"></i>
                        </Button>
                      </div>
                    </div>
                    <Link to="/" className="btn mt-4 mt-sm-5 btn-danger">
                      <i className="far fa-file-alt"></i> Get Started
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-light-dark py-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="test-newbg">
                <Row className="justify-content-center">
                  <Col md={6} className="text-center">
                    <h3>Granted Best Program</h3>
                    <Link to="/" className="btn mt-3 btn-warning">
                      <i className="far fa-file-alt"></i> Register Now
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-3 map-bg">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={3}>
              <Image alt="Mobile Image" src={MobappImage} />
            </Col>
            <Col md={5} className="downloadappbtnmob mb-4 schoolinner-mob-app">
              <div className="section-title mb-4 lefth2border">
                <h2 className=" text-start mb-3 pb-3">Download Dhurina App</h2>
                <h5 className=" fw-medium f-22">Get Free Test Series Quiz</h5>
              </div>
              <Applink></Applink>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Defencehome;
