import React from "react";
import { Link } from "react-router-dom";
import { Col, Image } from "react-bootstrap";
const Image_URL = process.env.REACT_APP_Bucket_URL + "combo/";

const Item = (props) => {
  const { data } = props;
  // console.log("test series",data);
  return data.map((item) => {
    return (
      <Col md={4} xs={6} key={item.id}>
        <Link to={"/test-series/" + item.test_series_url + "/" + item.id}>
          <div className="board-seriers single-course-wrap text-center">
            <div className="board-img thumb">
              <Image alt={item.title} src={`${Image_URL}${item.image}`} />
            </div>
            <h6 className="mb-3">{item.title}</h6>
            <div className="testaction">
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
        </Link>
      </Col>
    );
  });
};

export default Item;
