/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const BOOK_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "hardcopy/image/";

const Books = ({ display, books }) => {
  const { id } = useParams();
  const { stateName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");

  const viewAllBooks = () => {
    if (type === "sub") {
      navigate(`/${stateName}/${id}/all-books?type=sub`);
      window.scrollTo(0, 0);
    } else {
      navigate(`/${stateName}/${id}/all-books`);
      window.scrollTo(0, 0);
    }
  };
  const BookseBooks = [];

  const filteredTests = books.filter((test, index) => {
    if (display && (index === 0 || index === 1)) {
      BookseBooks.push(test);
    } else if (!display) {
      BookseBooks.push(test);
    }
  });
  const SingleBook = (title, bookId) => {
    // Replace "/" with " " in the input string
    const modifiedString = title.replace(/\//g, " ");
    navigate(`/book-detail/${modifiedString}/${bookId}`);
    window.scrollTo(0, 0);
  };
  return (
    <div id="books" className="custom_container container py-5">
      {display ? (
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="text_gradient ff_inter fw-bold fs_4xl">
            Books & EBooks
          </h2>
          <span
            onClick={() => {
              viewAllBooks();
            }}
            className={`ff_inter fw-semibold text_gradient mb-0 cursor_pointer ${
              books.length <= 2 ? "disabled" : ""
            }`}
          >
            View All <span>&rarr;</span>{" "}
          </span>
        </div>
      ) : (
        ""
      )}
      <div className="row">
        {books.length === 0 ? (
          <p className="mb-0 ff_inter mt-3">No books available.</p>
        ) : (
          BookseBooks.map((book, index) => (
            <div key={index} className="col-md-6 mt-4 h-100">
              <div
                className="cursor_pointer  h-100"
                onClick={() => SingleBook(book.title, book.id)}
              >
                <div className="border_light_brown  h-100">
                  <div className="bg_books">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        className="book_fit obj_fit w-100"
                        src={`${BOOK_IMAGE_URL}${book.image}`}
                        alt={book.title}
                      />
                    </div>
                  </div>
                  <h2 className="ff_inter fw-bolder fs-6 text_gradient mb-0 px-4 mt-3">
                    {book.title}
                  </h2>

                  <div className="mt-3 d-flex align-items-center justify-content-between px-4 pb-3">
                    <p className="mb-0 text_gradient fw-bold mb-0">
                      {" "}
                      Price :{" "}
                      <span className="mb-0 text_gradient fw-bold mb-0">
                        ₹{book.hardcopy_price}{" "}
                      </span>
                    </p>

                    <div>
                      <span className="fs_desc text-capitalize fw-bold text_grey ff_inter  mb-0">
                        {book.sub_type}
                      </span>
                      <span className="fs_desc text_grey ff_inter text-decoration-line-through mb-0">
                        {book.dup_price}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Books;
