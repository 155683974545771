import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Image,
  Modal,
  Button,
} from "react-bootstrap";
const IMAGE_URL = process.env.REACT_APP_Bucket_URL;

const FreeMock = ({ testSerie, setLoginPopup, webhook, setMainLoading }) => {
  const [testAvailable, setTestAvailable] = useState("");
  const navigate = useNavigate();
  const [slides, setSlides] = useState(1);
  const { stateName } = useParams();
  const [testData, setTestData] = useState({
    id: "",
    name: "",
    instruction: "",
  });
  const [instructionmodelStatus, SetInstructionModelStatus] = useState(false);
  const [instructions, SetInstructions] = useState("");

  useEffect(() => {
    setTestAvailable(
      testSerie && testSerie.tests.some((item) => item.price === 0)
    );
    const zeroPriceCount =
      testSerie && testSerie.tests.filter((item) => item.price === 0).length;

    // Determine the number of slides to show based on the count
    const slidesToShow = zeroPriceCount >= 3 ? 3 : zeroPriceCount;
    setSlides(slidesToShow);
  }, [testSerie]);

  const showInstructions = (id, name, instruction) => {
    setTestData({ id: id, name: name, instruction: instruction });
    SetInstructionModelStatus(true);
  };

  return (
    <>
      <div
        className={`position-relative ${testAvailable ? "d-block" : "d-none"}`}
      >
        <div
          className={`custom_container container py-5 ${
            testSerie?.tests?.length < 4 ? "gradient_after1" : "gradient_after"
          }`}
        >
          <h2 className="ff_inter fw-bold fs-3 text-white">
            {stateName}: Mock Tests
          </h2>

          <div className="row ">
            {testAvailable && testSerie?.tests?.length >= 3 ? (
              testSerie.tests.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 mt-3">
                  <div className="mock_box mx-3 p-3 d-flex flex-column justify-content-between">
                    <div className="mb-0">
                      <div key={index}>
                        <h2
                          className="ff_inter fw-bold text_gradient fs-6 mb-0"
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            WebkitLineClamp: 3,
                            cursor: "pointer",
                            position: "relative",
                          }}
                          title={item.name}
                        >
                          {item.name}
                        </h2>
                        <p className="mb-0 text_grey border_brown_bottom pb-3 ff_inter mt-1 mb-2"></p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>
                          <div className="d-flex justify-content-between">
                            <p className="mb-0 mt-2 ff_inter text_grey fw-semibold">
                              Questions
                            </p>
                            <p className="mb-0 mt-2 ff_inter text_grey fw-semibold">
                              {item.no_of_questions}
                            </p>
                          </div>
                          {item.access === 0 ? (
                            <div className="d-flex justify-content-between">
                              <p className="mb-0 ff_inter text_grey fw-semibold">
                                Max Marks
                              </p>
                              <p className="mb-0 ff_inter text_grey fw-semibold">
                                {item.marks}
                              </p>
                            </div>
                          ) : null}
                          <div className="d-flex justify-content-between">
                            <p className="mb-0 ff_inter text_grey fw-semibold">
                              Time
                            </p>
                            <p className="mb-0 ff_inter text_grey fw-semibold">
                              {item.duration} Mins
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="text-center col-12">
                        {testSerie.purchase_status === "false" && testSerie.price>0 ? (
                          item.access === 0 ? (
                            <button
                              onClick={() => {
                                if (
                                  localStorage.getItem("token") === "" ||
                                  localStorage.getItem("token") === "null" ||
                                  localStorage.getItem("token") === undefined ||
                                  localStorage.getItem("token") === null
                                ) {
                                  setLoginPopup(true);
                                  document.body.parentElement.classList.toggle(
                                    "overflow-hidden"
                                  );
                                } else {
                                  SetInstructions(
                                    "Test should be submit on time."
                                  );
                                  showInstructions(
                                    item.id,
                                    item.name,
                                    "Test should be submit on time."
                                  );
                                }
                              }}
                              className="ff_inter bg_gradient mt-3 px-4 py-1 text-white border-0 fs-6 bg-transparent"
                            >
                              Attempt
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                if (
                                  localStorage.getItem("token") === "" ||
                                  localStorage.getItem("token") === "null" ||
                                  localStorage.getItem("token") === undefined ||
                                  localStorage.getItem("token") === null
                                ) {
                                  setLoginPopup(true);
                                  document.body.parentElement.classList.toggle(
                                    "overflow-hidden"
                                  );
                                } else {
                                  setMainLoading(true);
                                  webhook(testSerie.price);
                                }
                              }}
                              className="ff_inter bg_gradient mt-3 px-4 py-1 text-white border-0 fs-6 bg-transparent d-flex mx-auto justify-content-center align-items-center"
                            >
                              Unlock Now
                              <img
                                className="ms-1"
                                src={IMAGE_URL + "website/unlock_icon.png"}
                                alt="unlock_test"
                              />
                            </button>
                          )
                        ) : (
                          <button
                            onClick={() => {
                              if (
                                localStorage.getItem("token") === "" ||
                                localStorage.getItem("token") === "null" ||
                                localStorage.getItem("token") === undefined ||
                                localStorage.getItem("token") === null
                              ) {
                                setLoginPopup(true);
                                document.body.parentElement.classList.toggle(
                                  "overflow-hidden"
                                );
                              } else {
                                SetInstructions(
                                  "Test should be submit on time."
                                );
                                showInstructions(
                                  item.id,
                                  item.name,
                                  "Test should be submit on time."
                                );
                              }
                            }}
                            className="ff_inter bg_gradient mt-3 px-4 py-1 text-white border-0 fs-6 bg-transparent"
                          >
                            Attempt
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="ff_inter fw-semibold pb-5 text-white fs-5">
                No free mock tests available.
              </p>
            )}
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        id="switchpopup"
        show={instructionmodelStatus}
        onHide={() => SetInstructionModelStatus(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col md={12} className="switchlnguageox">
              <h4 className="f-25 mb-0 f-22mob">Instructions</h4>
              {/* <div dangerouslySetInnerHTML={{ __html: "instructions" }} /> */}
              <ol type="1" className="ps-0">
                <li>
                  1. There is only one correct answer to each question. Click on
                  the most appropriate option to mark it as your answer.
                </li>
                <li>
                  2. You can change your answer by clicking on some other
                  option.
                </li>
                <li>
                  3. You can move on the next question by clicking on
                  the"Next"button.
                </li>
                <li>
                  4. You can access the questions in any order within a section
                  or across sections by clicking on the question number given on
                  the number list.
                </li>
                <li>
                  5. You can use rough sheets while taking the test. Do not use
                  calculators, log tables, dictionaries, or any other
                  printed/online reference material during the test.
                </li>
                <li>
                  6. Do not click the button "Submit Test" before
                  completing the test.
                </li>
              </ol>
              <div className="text-center">
                <Button
                  variant="base-light"
                  className="ff_inter bg_gradient mt-3 px-4 py-1 text-white border-0 fs-6 bg-transparent rounded-4"
                  onClick={() => {
                    navigate("/play-test", { state: testData });
                    window.scrollTo(0, 0);
                  }}
                >
                  Play Test
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FreeMock;
