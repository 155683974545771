import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const LearnEnglish = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div className="custom_container container py-2 py-sm-5">
      <Slider {...settings}>
        <div className="px-sm-3 px-1 mt-3">
          <div className="d-flex align-items-center learn py-lg-0">
            <div className="col-5 col-lg-4 position-relative">
              <img
                className="w-100"
                src={Image_URL + "website/learn-english-ellipse.png"}
                alt="BgEllipse"
              />
              <img
                className="position-absolute janhavi_img start-50 translate-middle-x "
                src={Image_URL + "website/teachers/subhash_sir.png"}
                alt="JanhaviImg"
              />
            </div>
            <div className="col-4 col-lg-6">
              <h2 className="ff_inter fw-bold fs_8xl text_gradient mb-0 text-center text-lg-start mt-2 mt-lg-0">
                Rajasthan ASI (महिला सहायक उप निरीक्षक) Live Batch
              </h2>
              <p className="ff_inter fw-semi-bold fs_6xl color_dark_blue mb-0 text-center text-lg-start">
                Rajasthan Exams Courses
              </p>
              <p className="ff_inter fs_md color_dark_blue mb-0 text-center text-lg-start">
                By Subhash Charan Sir
              </p>
            </div>
            <div className="col-3 col-lg-2 text-center text-lg-start">
              <Link to="/course-detail/rajasthan-asi-running-batch-by-subhash-charan-sir-2023-b-14/6061">
                <button className="fs_xl text-white ff_inter bg_gradient border-0 px-2 py-1 px-sm-4 py-sm-2 cursor_pointer">
                  Check Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="px-sm-3 px-1 mt-3">
          <div className="d-flex align-items-center learn py-lg-0">
            <div className="col-5 col-lg-4 position-relative">
              <img
                className="w-100"
                src={Image_URL + "website/learn-english-ellipse.png"}
                alt="BgEllipse"
              />
              <img
                className="position-absolute janhavi_img start-50 translate-middle-x "
                src={Image_URL + "website/JANHAVI-PNG.png"}
                alt="JanhaviImg"
              />
            </div>
            <div className="col-4 col-lg-6">
              <h2 className="ff_inter fw-bold fs_8xl text_gradient mb-0 text-center text-lg-start mt-2 mt-lg-0">
                Learn English{" "}
              </h2>
              <p className="ff_inter fw-semi-bold fs_6xl color_dark_blue mb-0 text-center text-lg-start">
                Spoken Courses & Language Courses
              </p>
              <p className="ff_inter fs_md color_dark_blue mb-0 text-center text-lg-start">
                By Wonder Girl Janvavi Panwar
              </p>
            </div>
            <div className="col-3 col-lg-2 text-center text-lg-start">
              <Link to="/course-detail/basic-spoken-english-course-by-janhavi-panwar/3931">
                <button className="fs_xl text-white ff_inter bg_gradient border-0 px-2 py-1 px-sm-4 py-sm-2 cursor_pointer">
                  Check Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="px-sm-3 px-1 mt-3">
          <div className="d-flex align-items-center learn py-lg-0">
            <div className="col-5 col-lg-4 position-relative">
              <img
                className="w-100"
                src={Image_URL + "website/learn-english-ellipse.png"}
                alt="BgEllipse"
              />
              <img
                className="position-absolute janhavi_img start-50 translate-middle-x "
                src={Image_URL + "website/teachers/Pardeep_Sir (2).png"}
                alt="JanhaviImg"
              />
            </div>
            <div className="col-4 col-lg-6">
              <h2 className="ff_inter fw-bold fs_8xl text_gradient mb-0 text-center text-lg-start mt-2 mt-lg-0">
                शौर्य बैच HSSC CET Group-D SPL LIVE+VOD Batch
              </h2>
              <p className="ff_inter fw-semi-bold fs_6xl color_dark_blue mb-0 text-center text-lg-start">
                Haryana Courses
              </p>
              <p className="ff_inter fs_md color_dark_blue mb-0 text-center text-lg-start">
                By Pardeep Sir
              </p>
            </div>
            <div className="col-3 col-lg-2 text-center text-lg-start">
              <Link to="/course-detail/-hssc-cet-group-d-spl-livevod-batch/6202">
                <button className="fs_xl text-white ff_inter bg_gradient border-0 px-2 py-1 px-sm-4 py-sm-2 cursor_pointer">
                  Check Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="px-sm-3 px-1 mt-3">
          <div className="d-flex align-items-center learn py-lg-0">
            <div className="col-5 col-lg-4 position-relative">
              <img
                className="w-100"
                src={Image_URL + "website/learn-english-ellipse.png"}
                alt="BgEllipse"
              />
              <img
                className="position-absolute janhavi_img start-50 translate-middle-x "
                src={Image_URL + "website/teachers/smrutit mam.png"}
                alt="JanhaviImg"
              />
            </div>
            <div className="col-4 col-lg-6">
              <h2 className="ff_inter fw-bold fs_8xl text_gradient mb-0 text-center text-lg-start mt-2 mt-lg-0">
                JSSC CGL SI (Paper I & III) Pargati Batch
              </h2>
              <p className="ff_inter fw-semi-bold fs_6xl color_dark_blue mb-0 text-center text-lg-start">
                Jharkhand Exam Courses
              </p>
              <p className="ff_inter fs_md color_dark_blue mb-0 text-center text-lg-start">
                By Smriti Mam
              </p>
            </div>
            <div className="col-3 col-lg-2 text-center text-lg-start">
              <Link to="/course-detail/jssc-cgl-si-paper-i-iii-pargati-batch/5652">
                <button className="fs_xl text-white ff_inter bg_gradient border-0 px-2 py-1 px-sm-4 py-sm-2 cursor_pointer">
                  Check Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="px-sm-3 px-1 mt-3">
          <div className="d-flex align-items-center learn py-lg-0">
            <div className="col-5 col-lg-4 position-relative">
              <img
                className="w-100"
                src={Image_URL + "website/learn-english-ellipse.png"}
                alt="BgEllipse"
              />
              <img
                className="position-absolute janhavi_img1 start-50 translate-middle-x "
                src={Image_URL + "website/teachers/vipin_sir.png"}
                alt="JanhaviImg"
              />
            </div>
            <div className="col-4 col-lg-6">
              <h2 className="ff_inter fw-bold fs_8xl text_gradient mb-0 text-center text-lg-start mt-2 mt-lg-0">
                Learn Advanced Maths
              </h2>
              <p className="ff_inter fw-semi-bold fs_6xl color_dark_blue mb-0 text-center text-lg-start">
                Bihar Exams Courses
              </p>
              <p className="ff_inter fs_md color_dark_blue mb-0 text-center text-lg-start">
                By Vipin Sir and Maths Masti Team
              </p>
            </div>
            <div className="col-3 col-lg-2 text-center text-lg-start">
              <Link to="/course-detail/complete-math-live-class-liverecorded-batch-by-vipin-sir/6302">
                <button className="fs_xl text-white ff_inter bg_gradient border-0 px-2 py-1 px-sm-4 py-sm-2 cursor_pointer">
                  Check Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default LearnEnglish;
