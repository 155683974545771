import React, { useRef, useState, useEffect } from "react";
import Android from "../../assets/img/android-icon.svg";
import AndroidOff from "../../assets/img/android-icon-off.svg";
import Apple from "../../assets/img/apple-icon.svg";
import Apple2 from "../../assets/img/apple-icon-orange.svg";
import Mobile from "../../assets/img/dhurina-android.png";
import Iphone from "../../assets/img/dhurina-iphone.png";
import Icon1 from "../../assets/img/timeline-orange-icon.svg";
import Icon2 from "../../assets/img/timeline-grey-icon.svg";
import Ellipse from "../../assets/img/enroll-ellipse.png";
import ReactPlayer from "react-player";

const video_URL = process.env.REACT_APP_Bucket_URL;

const Enroll = () => {
  const [isOn, setIsOn] = useState(false);
  const handleToggle = () => {
    setIsOn(!isOn);
  };
  const [colors, setColors] = useState([
    "text_dark_grey",
    "text_dark_grey",
    "text_dark_grey",
    "text_dark_grey",
    "text_dark_grey",
  ]);
  const [animationColor, setAnimationColor] = useState([
    "text_gradient",
    "text_dark_grey",
    "text_dark_grey",
    "text_dark_grey",
    "text_dark_grey",
  ]);
  const [svgColor, setSvgColor] = useState([
    "svgColorChange",
    "svgColorDark",
    "svgColorDark",
    "svgColorDark",
    "svgColorDark",
  ]);
  useEffect(() => {
    function animation() {
      for (let i = 0; i < 5; i++) {
        setTimeout(() => {
          const updatedColors = [...colors];
          svgColor[i] = "svgColorChange";
          updatedColors[i] = "text_gradient";
          setSvgColor(svgColor);
          setAnimationColor(updatedColors);

          if (i === 4) {
            setTimeout(() => {
              setSvgColor([
                "svgColorChange",
                "svgColorDark",
                "svgColorDark",
                "svgColorDark",
                "svgColorDark",
              ]);
              animation();
            }, 2800);
          }
        }, i * 2800); // Change color every 2.8 seconds
      }
    }
    animation();
  }, []);

  return (
    <div className="custom_container container py-5">
      <div className="d-lg-flex justify-content-between align-items-center">
        <h2 className="ff_inter fw-bold color_dark_blue mb-0 text-center text-lg-start">
          How to Enroll in <span className="text_gradient"> Dhurina </span>
        </h2>
        {/* <div className="mb-0 d-flex align-items-center justify-content-center mt-4 mt-lg-0">
          {isOn ? (
            <img className="android_icon" src={AndroidOff} alt="android_icon" />
          ) : (
            <img className="android_icon" src={Android} alt="android_icon" />
          )}
          <div className="toggle-switch mx-2">
            <input
              type="checkbox"
              className="toggle-switch-checkbox"
              checked={isOn}
              onChange={handleToggle}
              id="toggle-switch"
            />
            <label
              className="toggle-switch-label bg_gradient"
              htmlFor="toggle-switch"
            >
              <span className="toggle-switch-inner" />
              <span className="toggle-switch-switch" />
            </label>
          </div>
          {isOn ? (
            <img className="apple_icon" src={Apple2} alt="off" />
          ) : (
            <img className="apple_icon" src={Apple} alt="on" />
          )}
        </div> */}
      </div>
      <div className="row flex-column-reverse flex-lg-row mt-5 py-4 justify-content-between align-items-center">
        <div className="col-lg-6 mt-5 mt-lg-0 text-center text-lg-start">
          <div className="timeline">
            <div className="py-0 px-4 ms-3 position-relative">
              <h2 className={`ff_inter fw-bold fs-4 mb-0 ${animationColor[0]}`}>
                Choose your exam
              </h2>
              {animationColor[0] === "text_gradient" ? (
                <p className="mt-2 ff_inter text_grey mb-0">
                  Select your State Exam Category
                </p>
              ) : (
                ""
              )}
              <div
                className={`position-absolute top-0 start-0 translate-middle-x  ${svgColor[0]}`}
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="10.0864"
                    cy="10.5758"
                    r="9.48154"
                    fill="#545454"
                    stroke="#545454"
                  />
                  <path
                    d="M14.2533 10.5767L8.00134 14.1862L8.00134 6.96709L14.2533 10.5767Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div className="py-0 px-4 mt-5 ms-3 position-relative">
              <h2 className={`ff_inter fw-bold fs-4 mb-0 ${animationColor[1]}`}>
                Select your desired course
              </h2>
              {animationColor[1] === "text_dark_grey" ? (
                ""
              ) : (
                <p className="mt-2 ff_inter text_grey mb-0">
                  Choose your course that contains the syllabus of exam you want
                  to study by watching Demo video for free.
                </p>
              )}
              <div
                className={`position-absolute top-0 start-0 translate-middle-x  ${svgColor[1]}`}
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="10.0864"
                    cy="10.5758"
                    r="9.48154"
                    fill="#545454"
                    stroke="#545454"
                  />
                  <path
                    d="M14.2533 10.5767L8.00134 14.1862L8.00134 6.96709L14.2533 10.5767Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div className="py-0 px-4 mt-5 ms-3 position-relative">
              <h2 className={`ff_inter fw-bold fs-4 mb-0 ${animationColor[2]}`}>
                Make the purchase
              </h2>

              {animationColor[2] === "text_dark_grey" ? (
                ""
              ) : (
                <p className="mt-2 ff_inter text_grey mb-0">
                  Click on Buy Now. Choose your payment method & make a purchase
                </p>
              )}
              <div
                className={`position-absolute top-0 start-0 translate-middle-x  ${svgColor[2]}`}
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="10.0864"
                    cy="10.5758"
                    r="9.48154"
                    fill="#545454"
                    stroke="#545454"
                  />
                  <path
                    d="M14.2533 10.5767L8.00134 14.1862L8.00134 6.96709L14.2533 10.5767Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <div className="py-0 px-4 mt-5 pb-5 ms-3 position-relative">
              <h2 className={`ff_inter fw-bold fs-4 mb-0 ${animationColor[3]}`}>
                Navigate to My Purchased Courses
              </h2>
              {animationColor[3] === "text_dark_grey" ? (
                ""
              ) : (
                <p className="mt-2 ff_inter text_grey mb-0">
                  Navigate on your account panel and find your course in the
                  <br className="d-lg-block d-none"></br> "My purchase section"
                </p>
              )}
              <div
                className={`position-absolute top-0 start-0 translate-middle-x  ${svgColor[3]}`}
              >
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="10.0864"
                    cy="10.5758"
                    r="9.48154"
                    fill="#545454"
                    stroke="#545454"
                  />
                  <path
                    d="M14.2533 10.5767L8.00134 14.1862L8.00134 6.96709L14.2533 10.5767Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="py-0 px-4 ms-3 position-relative">
            <h2 className={`ff_inter fw-bold fs-4 mb-0 ${animationColor[4]}`}>
              Enjoy Your Course With Dhurina
            </h2>
            {animationColor[4] === "text_dark_grey" ? (
              ""
            ) : (
              <p className="mt-2 ff_inter text_grey mb-0">
                Embark your exam preparation with Dhurina
              </p>
            )}
            <div
              className={`position-absolute top-0 start-0 translate-middle-x  ${svgColor[4]}`}
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10.0864"
                  cy="10.5758"
                  r="9.48154"
                  fill="#545454"
                  stroke="#545454"
                />
                <path
                  d="M14.2533 10.5767L8.00134 14.1862L8.00134 6.96709L14.2533 10.5767Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center text-center text-lg-end position-relative">
          {/* <img
            className="position-absolute start-50 enroll_ellipse"
            src={Ellipse}
            alt="ellipse"
          />
          {isOn ? (
            <img className="mobile_size" src={Iphone} alt="off" />
          ) : (
            <img className="mobile_size" src={Mobile} alt="on" />
          )} */}

          <ReactPlayer
            className="custom_video_width h-100"
            url={video_URL + "website/Kodu_Course.mp4"}
            controls={false}
            playing={true}
            loop
            muted={true}
            playsinline={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Enroll;
