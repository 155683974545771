import React from 'react';
import { Link } from "react-router-dom";
import { Image } from 'react-bootstrap';
const Image_URL = process.env.REACT_APP_Bucket_URL;

function Toplogo () {
  return (
    <> 
      <Link className="main-logo" to="/"><Image src={Image_URL+"logo.png"} alt="img" /></Link>
    </>
  );
}

export default Toplogo;
