import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Loginbtn = () => {
  const [loginbutton, setLoginButton] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setLoginButton(false);
    }
  }, []);

  return (
    <>
      {loginbutton === true ? (
        <Link to="/login" className="btn btn-base-light btn-login text-white">
          <i class="far fa-user"></i>Login
        </Link>
      ) : (
        <Link to="/#" className="btn btn-base-light btn-login text-white">
          <i class="far fa-user"></i> {localStorage.getItem("name")}
        </Link>
      )}
    </>
  );
};

export default Loginbtn;
