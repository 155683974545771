/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import ApiCall from "../../api/callApi";
import rightArrow from "../../assets/img/rightArrow.png";
const COURSE_AREA_IMAGE_URL =
  process.env.REACT_APP_Bucket_URL + "course/areas/";
const EXAM_CATEGORY_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "category/";
const Image_URL = process.env.REACT_APP_Bucket_URL;
var page = 1;

const TestSeriesDropdown = ({ setTestSeriesDropdownVisible }) => {
  const navigate = useNavigate();
  const [TestSeries, setTestSeries] = useState([]);
  const [loading, setLoading] = useState(true);

  const [latestTestSeries, setLatestTestSeries] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  function getData() {
    ApiCall("", "get", "home_categories", home_categories);
  }

  const home_categories = useCallback((response) => {
    setLoading(false);
    if (response.data.status === 200) {
      setTestSeries(response.data.test_series_category);
      setLatestTestSeries(response.data.latest_sub_category);
    } else {
      console.log("error");
    }
  });

  const getCourseData = (item) => {
    const sub_category = item.name;
    const id = item.id;
    const catId = item.cat_id;
    setTestSeriesDropdownVisible(false);
    navigate(
      `/${sub_category}/${id}/all-test-series?type=test-series&catId=${catId}`
    );
    window.scrollTo(0, 0);
  };
  const SubCategories = (id, sub_category, catId) => {
    setTestSeriesDropdownVisible(false);
    navigate(
      `/${sub_category}/${id}/all-test-series?type=test-series&catId=${catId}`
    );
    window.scrollTo(0, 0);
  };

  return (
    <div className="px-3 rounded-3">
      <div className="container custom_container py-5 my-3 batches">
        {loading ? (
          <>
            {" "}
            <div className="vh_70">
              <div id="preloader">
                <div id="loader"></div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row accordion_scroll_bar">
              <div className="col-md-4 col-lg-3 vh_70 overflow-auto position-relative">
                <Accordion className="w-75 mx-auto" defaultActiveKey="0">
                  {TestSeries &&
                    TestSeries.map((item, index) => (
                      <Accordion.Item
                        eventKey={index.toString()}
                        key={index}
                        className="my-2"
                      >
                        <Accordion.Header>
                          <img
                            className="mx-2 mx-md-0 mx-lg-1 exam_category_logo"
                            src={`${EXAM_CATEGORY_IMAGE_URL}${item.image}`}
                            alt={item.image}
                          />

                          <p className="ff_inter fw-semi-bold fs_desc mb-0 me-2">
                            {item.name}
                          </p>
                        </Accordion.Header>

                        <Accordion.Body>
                          <ul className="ps_0">
                            {item.sub_category.map((subcategory) => (
                              <div key={subcategory.id}>
                                <li
                                  onClick={() =>
                                    SubCategories(
                                      subcategory.id,
                                      subcategory.name,
                                      item.id
                                    )
                                  }
                                  className="ff_inter cursor_pointer fw-semi-bold fs_desc text-black sub_category_link mt-1"
                                >
                                  {subcategory.name}
                                </li>
                              </div>
                            ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </div>
              <div className="col-md-8 col-lg-9">
                <div className="row mt-2 align-items-center">
                  <h4 className="ff_inter fw-bold color_dark_blue">
                    Latest <span className="text_gradient"> Test Series </span>
                  </h4>
                  {latestTestSeries &&
                    latestTestSeries.map((item, index) =>
                      index < 9 ? (
                        <div className="w-20 text-center" key={index}>
                          <div
                            className="mt-1 cursor_pointer"
                            onClick={() => getCourseData(item)}
                          >
                            {/* <img
                          className="batches_icon"
                          src={`${EXAM_CATEGORY_IMAGE_URL}${item.image}`}
                          alt={item.image}
                        /> */}
                            <img
                              width={30}
                              className="rounded-3"
                              src={Image_URL + "website/notes_icon.jpg"}
                            />
                            <p className="ff_inter fs_desc color_light_black fw-semi-bold lh-sm mt-1">
                              {item.name}
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  {latestTestSeries.length > 9 ? (
                    <div className="w-20 text-center">
                      <div
                        className="mt-1 cursor_pointer"
                        onClick={() => {
                          setTestSeriesDropdownVisible(false);
                          navigate("/latest-test-series");
                          window.scrollTo(0, 0);
                        }}
                      >
                        <p className="ff_inter view-all-test-series fw-semi-bold lh-sm mt-1">
                          <span className="text_gradient">
                            {" "}
                            View All &rarr;
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TestSeriesDropdown;
