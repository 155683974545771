import React from 'react';
import { Link,useNavigate} from "react-router-dom";
import { Image, Modal, Row, Col, Tabs, Tab} from 'react-bootstrap';



const CModal = (props) =>{
    const navigate=useNavigate();
    const cartshow = true;
  

return (
        
     <Modal show={cartshow} onClick={() => navigate(-1)} className="checkoutpopup">
        <Modal.Header className="ms-3 pb-2 ps-0 border-bottom" closeButton >
        <Modal.Title>My Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-4 flex-column">
            <div className="border-bottom pb-3 mb-3">
                            <Row>
                            <Col xs={3}>
                                    <Image src="" alt="homebook" />
                                </Col>
                                <Col xs={8} className="ps-2">
                                    <div class="single-course-wrap mb-0">
                                        <div class="wrap-details p-0 border-0">
                                            <Link to="/">
                                                <h6></h6>
                                            </Link>
                                            <div class="price-wrap">
                                                <div class="align-items-center row">
                                                    <div class="col-7">
                                                        <span class="text-theme f-18 fw-semi-bold me-2"><i class="fa fa-rupee"></i></span>
                                                    </div>
                                                    <div class="col-5 cartselectqty">
                                                        Qty:
                                                        <select>
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={1} className="pe-0">
                                    <i class="far fa-trash-alt text-danger" style={{cursor:"pointer"}} ></i>
                                </Col>
                            </Row> 
                        </div>
        
          
            
        </Modal.Body> 

        <Modal.Body>
            <Tabs defaultActiveKey='' id="Checkoutaddress" className="edl-nav nav-pills new-trending-course">
                <Tab eventKey="existing" title="Existing Address">
                    <div className="">
                        
                       
                        
                        <div className="border-top pt-3 mt-auto"> 
                            <Row>
                                <Col xs={12}>
                                   
                                </Col>
                            </Row> 	
                            <Row>
                                
                            </Row>	
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="addnew"  title="Add New Address">
                
                   
                    
                </Tab> 
            </Tabs>
        </Modal.Body> 
      </Modal>


)
}

export default CModal;
