import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Navbar,
  Container,
  ListGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Innermobheader from "./innermobHeader";
import Loginbtn from "../headercommonlink/loginbtn";
import Switchicon from "../headercommonlink/switchicon";
import Toplogo from "../headercommonlink/toplogo";
import Homelink from "../headercommonlink/homelink";

function Testseriesheader() {
  const [scroll, setScroll] = useState(false);
  const [loginstatus, setLoginStatus] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });

    if (localStorage.getItem("token") !== null) {
      setLoginStatus(true);
    }
  }, []);

  return (
    <>
      <header className="navbar-area">
        <Navbar
          expand="lg"
          fixed="top"
          className={
            "innerHeader d-none d-lg-block " + (scroll ? "darkHeader" : "")
          }
        >
          <Container className="nav-container">
            <div className="logo">
              <Toplogo></Toplogo>
            </div>

            <Navbar.Collapse
              className="test-seriesbanner"
              id="themefie_main_menu"
            >
              <div className="single-input-wrap">
                <FormControl
                  type="text"
                  className="d-inline-block"
                  placeholder="Search your best courses"
                />
                <Button variant="headersearchbtn">
                  <i class="fa fa-search"></i>
                </Button>
              </div>
              <ListGroup as="ul" className="navbar-nav menu-open text-end">
                <ListGroup.Item as="li">
                  <Homelink></Homelink>
                </ListGroup.Item>
                <ListGroup.Item as="li" className="menu-item-has-children">
                  <Link to="/books">Book Notes</Link>
                  {/* <ListGroup as="ul" className="sub-menu">
                                <ListGroup.Item as="li"><Link to="/">UPSEC Test Series</Link></ListGroup.Item>
                                <ListGroup.Item as="li"><Link to="/">JEE Test Series</Link></ListGroup.Item> 
                            </ListGroup> */}
                </ListGroup.Item>
                <ListGroup.Item as="li" className="menu-item-has-children">
                  <a
                    href="https://dhurina.net/en/blog/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </a>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Switchicon></Switchicon>
                </ListGroup.Item>
              </ListGroup>
            </Navbar.Collapse>
            <div className="nav-right-part nav-right-part-desktop">
              <Navbar.Collapse id="themefie_main_menu">
                <ListGroup as="ul" className="navbar-nav menu-open text-end">
                  <ListGroup.Item as="li" className="menu-item-has-children">
                    <Loginbtn></Loginbtn>
                    {loginstatus && (
                      <ListGroup
                        as="ul"
                        className="sub-menu text-left"
                        style={{ width: "150px" }}
                      >
                        <ListGroup.Item as="li" className="logoutbtn">
                          <button onClick={logout}>Logout</button>
                        </ListGroup.Item>
                      </ListGroup>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Navbar.Collapse>
            </div>
          </Container>
        </Navbar>

        <Innermobheader></Innermobheader>
      </header>
    </>
  );
}

export default Testseriesheader;
