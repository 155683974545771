import React, { useState, useCallback } from "react";
import {
  Button,
  Container,
  Col,
  Row,
  Form,
  Accordion,
  ListGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Bookheader from "../include/header/bookHeader";
import Footer from "../include/footer/footer";
import MainLoaderImage from "./../assets/img/loading-buffering.gif";
import ApiCall from "../api/callApi";
import { initialize, makePayment } from "../components/Payment";

const DCWU = () => {
  const navigate = useNavigate();

  const [mainloading, setMainLoading] = useState(false);
  const [price, setPrice] = useState(0);

  const [neetstudents, setNeetStudents] = useState(0);
  const [skillstudents, setSkillStudents] = useState(0);
  const [computerstudents, setComputerStudents] = useState(0);

  const [neetcontent, setNeetContent] = useState([]);
  const [skillcontent, setSkillContent] = useState([]);
  const [computercontent, setComputerContent] = useState([]);

  const [neetchecked, setNeetChecked] = useState(false);
  const [skillchecked, setSkillChecked] = useState(false);
  const [computerchecked, setComputerChecked] = useState(false);

  var paymentData = {
    token: "",
    order: "",
    mid: "",
    amount: "",
  };

  // useEffect(() => {
  //     getData()
  // },[]);

  function webhook() {
    if (price < 1) {
      notify("Please select proper information!!");
    } else {
      initialize(
        initializeCallback,
        price,
        localStorage.getItem("mobile"),
        localStorage.getItem("id")
      );
    }
  }

  const initializeCallback = useCallback((response) => {
    let obj = {
      token: response.token,
      order: response.order,
      mid: response.mid,
      amount: response.amount,
    };

    paymentData = obj;
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    } else {
      var data = {
        phone: localStorage.getItem("mobile"),
        type: "DCWU",
        order_id: obj.order,
        price: price,
      };
      ApiCall(data, "post", "webhook", checkphonecallback);
    }
  });

  const checkphonecallback = useCallback((response) => {
    if (response.data.status === 200) {
      setMainLoading(true);
      makePayment(paymentData, myCallback);
    } else {
      notify(response.data.message);
    }
  });

  const myCallback = useCallback((response, status) => {
    if (status) {
      updatepayment(response);
    } else {
      setMainLoading(false);
    }
  });

  const updatepayment = (response) => {
    var data = {
      status: response.STATUS,
      price: response.TXNAMOUNT,
      order_id: paymentData.order,
    };
    ApiCall(data, "post", "update_payment", updatepaymentcallback);
  };

  const updatepaymentcallback = useCallback((response) => {
    var state = {
      txnid: "",
      amount: "",
      course_name: "",
      transaction_status: 0,
    };

    if (response.data.status === 200) {
      var state = {
        txnid: response.data.course_data.txnid,
        amount: response.data.course_data.amount,
        course_name: "Dhurina collabration with you",
        transaction_status: response.data.transaction_status,
      };
    }
    navigate("/payment-verification", { state });
  });

  const notify = (message) =>
    alert(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const neetcontentchange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setNeetContent([...neetcontent, value]);
    } else {
      setNeetContent(neetcontent.filter((e) => e !== value));
    }
  };

  const skillcontentchange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSkillContent([...skillcontent, value]);
    } else {
      setSkillContent(skillcontent.filter((e) => e !== value));
    }
  };

  const computercontentchange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setComputerContent([...computercontent, value]);
    } else {
      setComputerContent(computercontent.filter((e) => e !== value));
    }
  };

  const typechange = (e) => {
    const { value } = e.target;

    if (value == "neetjee") {
      setNeetChecked(!neetchecked);
    } else if (value == "skill") {
      setSkillChecked(!skillchecked);
    } else {
      setComputerChecked(!computerchecked);
    }
    calculate();
  };

  const calculate = () => {
    var new_price = 0;
    if (neetchecked) {
      new_price = new_price + neetstudents * 1;
      // new_price=new_price + neetstudents * 2500;
    }

    if (skillchecked) {
      new_price = new_price + skillstudents * 1;
      // new_price=new_price + skillstudents * 1000;
    }

    if (computerchecked) {
      new_price = new_price + computerstudents * 1;
      // new_price=new_price + computerstudents * 2500;
    }
    setPrice(new_price);
  };

  return (
    <div>
      {mainloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src={MainLoaderImage}
            style={{ alignSelf: "center" }}
            alt="image"
          />
        </div>
      ) : (
        <>
          <Bookheader></Bookheader>

          <section className="banner-area booksheadbanner">
            <Container fluid>
              <Row>
                <Col sm={{ span: 12, order: 5 }}>
                  <h2 className="fw-bold f-22 mb-4 text-center booktrendheadtop">
                    DCWU Program
                  </h2>
                  <h4 className="f-16 mb-4 text-center booktrendheadtop">
                    Dhurina Collabration With You
                  </h4>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="contact-bg py-5" bg="danger">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="test-newbg">
                    <Row className="justify-content-center">
                      <Col md={12} className="text-center">
                        <Row className="justify-content-center">
                          <Col md={3}>
                            <Button variant="online" className="mb-2 me-1">
                              <Form.Control
                                type="radio"
                                className="with-gap"
                                name="neetjee"
                                id="neetjee"
                                value="neetjee"
                                checked={neetchecked}
                                onClick={typechange}
                              />
                              <Form.Label
                                htmlFor="neetjee"
                                style={{ fontWeight: 500 }}
                              >
                                Neet/JEE
                              </Form.Label>
                            </Button>
                          </Col>
                          <Col md={7} className="text-center">
                            <h5 className="f-25mob">
                              Package Price:{" "}
                              <i className="fa fa-rupee">2500/Student</i>
                            </h5>
                            <Form.Label style={{ fontWeight: 200 }}>
                              The JEE Batch For NEET is for all those students
                              who wish to crack thier upcoming NEET exams with a
                              double boost.
                            </Form.Label>
                          </Col>
                        </Row>

                        <Row className="justify-content-center">
                          <Col md={8} className="text-center">
                            <div className="widget widget-accordion-inner">
                              <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                    <i className="fa fa-book me-2"></i>Content
                                    Access
                                  </Accordion.Header>
                                  <Accordion.Body className="widget-select-inner">
                                    <ListGroup
                                      as="ul"
                                      className="all-courseleftsect"
                                    >
                                      <ListGroup.Item as="li">
                                        <div className="single-form-check form-check">
                                          <Form.Control
                                            className="form-check-input p-0"
                                            name="neet_content"
                                            type="checkbox"
                                            value="video"
                                            id="video_neet"
                                            onChange={neetcontentchange}
                                          />
                                          <Form.Label
                                            className="form-check-label mb-0"
                                            htmlFor="video_neet"
                                          >
                                            Video/Live Classes
                                          </Form.Label>
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item as="li">
                                        <div className="single-form-check form-check">
                                          <Form.Control
                                            className="form-check-input p-0"
                                            name="neet_content"
                                            type="checkbox"
                                            value="test"
                                            id="test_neet"
                                            onChange={neetcontentchange}
                                          />
                                          <Form.Label
                                            className="form-check-label mb-0"
                                            htmlFor="test_neet"
                                          >
                                            Online Test Series(5000+)
                                          </Form.Label>
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item as="li">
                                        <div className="single-form-check form-check">
                                          <Form.Control
                                            className="form-check-input p-0"
                                            name="neet_content"
                                            type="checkbox"
                                            value="pdf"
                                            id="pdf_neet"
                                            onChange={neetcontentchange}
                                          />
                                          <Form.Label
                                            className="form-check-label mb-0"
                                            htmlFor="pdf_neet"
                                          >
                                            PDF/Notes(Soft Copy)
                                          </Form.Label>
                                        </div>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={2}>
                  <Form.Label style={{ fontWeight: 500 }}>
                    No. of students
                  </Form.Label>
                </Col>
                <Col md={3} className="text-center">
                  <input
                    type="text"
                    value={neetstudents}
                    class="form-control"
                    max="5"
                    placeholder="Enter no. of students"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) || e.target.value.length >= 5) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setNeetStudents(e.target.value);
                    }}
                    onKeyUp={calculate}
                    onKeyDown={calculate}
                  />
                </Col>
              </Row>
            </Container>
          </section>

          <section className="contact-bg py-5">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="test-newbg">
                    <Row className="justify-content-center">
                      <Col md={12} className="text-center">
                        <Row className="justify-content-center">
                          <Col md={3}>
                            <Button variant="online" className="mb-2 me-1">
                              <Form.Control
                                type="radio"
                                className="with-gap"
                                name="skill"
                                id="skill"
                                value="skill"
                                checked={skillchecked}
                                onClick={typechange}
                              />
                              <Form.Label
                                htmlFor="skill"
                                style={{ fontWeight: 500 }}
                              >
                                Skill
                              </Form.Label>
                            </Button>
                          </Col>
                          <Col md={7} className="text-center">
                            <h5 className="f-25mob">
                              Package Price:{" "}
                              <i className="fa fa-rupee">1000/Student</i>
                            </h5>
                            <Form.Label style={{ fontWeight: 200 }}>
                              The JEE Batch For NEET is for all those students
                              who wish to crack thier upcoming NEET exams with a
                              double boost.
                            </Form.Label>
                          </Col>
                        </Row>

                        <Row className="justify-content-center">
                          <Col md={8} className="text-center">
                            <div className="widget widget-accordion-inner">
                              <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                    <i className="fa fa-book me-2"></i>Content
                                    Access
                                  </Accordion.Header>
                                  <Accordion.Body className="widget-select-inner">
                                    <ListGroup
                                      as="ul"
                                      className="all-courseleftsect"
                                    >
                                      <ListGroup.Item as="li">
                                        <div className="single-form-check form-check">
                                          <Form.Control
                                            className="form-check-input p-0"
                                            name="skill_content"
                                            type="checkbox"
                                            value="video"
                                            id="video_neet"
                                            onChange={skillcontentchange}
                                          />
                                          <Form.Label
                                            className="form-check-label mb-0"
                                            htmlFor="video_neet"
                                          >
                                            Video/Live Classes
                                          </Form.Label>
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item as="li">
                                        <div className="single-form-check form-check">
                                          <Form.Control
                                            className="form-check-input p-0"
                                            name="skill_content"
                                            type="checkbox"
                                            value="test"
                                            id="test_neet"
                                            onChange={skillcontentchange}
                                          />
                                          <Form.Label
                                            className="form-check-label mb-0"
                                            htmlFor="test_neet"
                                          >
                                            Online Test Series(5000+)
                                          </Form.Label>
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item as="li">
                                        <div className="single-form-check form-check">
                                          <Form.Control
                                            className="form-check-input p-0"
                                            name="skill_content"
                                            type="checkbox"
                                            value="pdf"
                                            id="pdf_neet"
                                            onChange={skillcontentchange}
                                          />
                                          <Form.Label
                                            className="form-check-label mb-0"
                                            htmlFor="pdf_neet"
                                          >
                                            PDF/Notes(Soft Copy)
                                          </Form.Label>
                                        </div>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={2}>
                  <Form.Label style={{ fontWeight: 500 }}>
                    No. of students
                  </Form.Label>
                </Col>
                <Col md={3} className="text-center">
                  <input
                    type="text"
                    value={skillstudents}
                    class="form-control"
                    max="5"
                    placeholder="Enter no. of students"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) || e.target.value.length >= 5) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setSkillStudents(e.target.value);
                    }}
                    onKeyUp={calculate}
                    onKeyDown={calculate}
                  />
                </Col>
              </Row>
            </Container>
          </section>

          <section className="contact-bg py-5">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="test-newbg">
                    <Row className="justify-content-center">
                      <Col md={12} className="text-center">
                        <Row className="justify-content-center">
                          <Col md={3}>
                            <Button variant="online" className="mb-2 me-1">
                              <Form.Control
                                type="radio"
                                className="with-gap"
                                name="computer"
                                id="computer"
                                value="computer"
                                checked={computerchecked}
                                onClick={typechange}
                              />
                              <Form.Label
                                htmlFor="computer"
                                style={{ fontWeight: 500 }}
                              >
                                Coding & Computer
                              </Form.Label>
                            </Button>
                          </Col>
                          <Col md={7} className="text-center">
                            <h5 className="f-25mob">
                              Package Price:{" "}
                              <i className="fa fa-rupee">2500/Student</i>
                            </h5>
                            <Form.Label style={{ fontWeight: 200 }}>
                              The JEE Batch For NEET is for all those students
                              who wish to crack thier upcoming NEET exams with a
                              double boost.
                            </Form.Label>
                          </Col>
                        </Row>

                        <Row className="justify-content-center">
                          <Col md={8} className="text-center">
                            <div className="widget widget-accordion-inner">
                              <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header>
                                    <i className="fa fa-book me-2"></i>Content
                                    Access
                                  </Accordion.Header>
                                  <Accordion.Body className="widget-select-inner">
                                    <ListGroup
                                      as="ul"
                                      className="all-courseleftsect"
                                    >
                                      <ListGroup.Item as="li">
                                        <div className="single-form-check form-check">
                                          <Form.Control
                                            className="form-check-input p-0"
                                            name="computer_content"
                                            type="checkbox"
                                            value="video"
                                            id="video_neet"
                                            onChange={computercontentchange}
                                          />
                                          <Form.Label
                                            className="form-check-label mb-0"
                                            htmlFor="video_neet"
                                          >
                                            Video/Live Classes
                                          </Form.Label>
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item as="li">
                                        <div className="single-form-check form-check">
                                          <Form.Control
                                            className="form-check-input p-0"
                                            name="computer_content"
                                            type="checkbox"
                                            value="test"
                                            id="test_neet"
                                            onChange={computercontentchange}
                                          />
                                          <Form.Label
                                            className="form-check-label mb-0"
                                            htmlFor="test_neet"
                                          >
                                            Online Test Series(5000+)
                                          </Form.Label>
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item as="li">
                                        <div className="single-form-check form-check">
                                          <Form.Control
                                            className="form-check-input p-0"
                                            name="computer_content"
                                            type="checkbox"
                                            value="pdf"
                                            id="pdf_neet"
                                            onChange={computercontentchange}
                                          />
                                          <Form.Label
                                            className="form-check-label mb-0"
                                            htmlFor="pdf_neet"
                                          >
                                            PDF/Notes(Soft Copy)
                                          </Form.Label>
                                        </div>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={2}>
                  <Form.Label style={{ fontWeight: 500 }}>
                    No. of students
                  </Form.Label>
                </Col>
                <Col md={3} className="text-center">
                  <input
                    type="text"
                    value={computerstudents}
                    class="form-control"
                    max="5"
                    placeholder="Enter no. of students"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) || e.target.value.length >= 5) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setComputerStudents(e.target.value);
                    }}
                    onKeyUp={calculate}
                    onKeyDown={calculate}
                  />
                </Col>
              </Row>

              <Row className="justify-content-between">
                <div className="course-price-strike my-3 d-sm-block d-none">
                  <span>
                    <span>₹{price}</span>
                  </span>
                  {localStorage.getItem("token") ? (
                    <Button
                      className="btn btn-warning mt-2"
                      onClick={(e) => webhook()}
                    >
                      Buy Now
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-warning mt-2"
                      onClick={(e) => navigate("/login")}
                    >
                      Buy Now
                    </Button>
                  )}
                </div>
              </Row>
            </Container>
          </section>

          <section className="d-block d-sm-none mob-price-section">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-between mt-2 course-price-strike">
                    <span>
                      <span>₹ {price}</span>
                    </span>
                    {localStorage.getItem("token") ? (
                      <Button
                        className="btn btn-warning mt-2"
                        onClick={(e) => webhook()}
                      >
                        Buy Now
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-warning mt-2"
                        onClick={(e) => navigate("/login")}
                      >
                        Buy Now
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <Footer></Footer>
        </>
      )}
    </div>
  );
};

export default DCWU;
