import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Navbar,
  Container,
  ListGroup,
  Image,
  Modal,
  Row,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";
import Innermobheader from "./innermobHeader";
import Loginbtn from "../headercommonlink/loginbtn";
import Switchicon from "../headercommonlink/switchicon";
import Toplogo from "../headercommonlink/toplogo";
import Homelink from "../headercommonlink/homelink";
const Image_URL = process.env.REACT_APP_Bucket_URL;

function Homeheader(props) {
  const [cartshow, cartsetShow] = useState(false);
  const [loginstatus, setLoginStatus] = useState(false);
  const carthandleClose = () => cartsetShow(false);
  //  const carthandleShow = () => cartsetShow(true);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });

    if (localStorage.getItem("token") !== null) {
      setLoginStatus(true);
    }
  }, []);

  return (
    <>
      <header className="navbar-area homeheadermob">
        <Navbar
          expand="lg"
          fixed="top"
          className={"d-none d-lg-block " + (scroll ? "darkHeader" : "")}
        >
          <Container className="nav-container">
            <div className="logo">
              <Toplogo></Toplogo>
            </div>
            <Navbar.Collapse id="themefie_main_menu">
              <ListGroup as="ul" className="navbar-nav menu-open text-end">
                <ListGroup.Item as="li">
                  <Homelink></Homelink>
                </ListGroup.Item>
                <ListGroup.Item as="li" className="menu-item-has-children">
                  <Link to="/all-course">Courses</Link>
                  {/* <ListGroup as="ul" className="sub-menu">
                                    <ListGroup.Item as="li"><Link to="/all-course">All Course</Link></ListGroup.Item>
                                </ListGroup> */}
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Link to="/test-series">Test Series</Link>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Link to="/books">Notes/Books</Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" className="menu-item-has-children">
                  <a
                    href="https://dhurina.net/en/blog/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </a>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Switchicon></Switchicon>
                </ListGroup.Item>
                {/* <ListGroup.Item as="li" className="position-relative">
                                <a href="#"  onClick={carthandleShow}><Image src={Image_URL+"img/add-to-cart.png"} alt="" /><span className="addnumb">{props.cartCount}</span></a>
                            </ListGroup.Item>  */}
              </ListGroup>
            </Navbar.Collapse>
            <div className="nav-right-part nav-right-part-desktop">
              <Navbar.Collapse id="themefie_main_menu">
                <ListGroup as="ul" className="navbar-nav menu-open text-end">
                  <ListGroup.Item as="li" className="menu-item-has-children">
                    <Loginbtn></Loginbtn>
                    {loginstatus && (
                      <ListGroup
                        as="ul"
                        className="sub-menu text-left"
                        style={{ width: "150px" }}
                      >
                        {/* <ListGroup.Item as="li" className="logoutbtn"><Link to="my-courses">Dashboard</Link></ListGroup.Item> */}
                        <ListGroup.Item as="li" className="logoutbtn">
                          <button onClick={logout}>Logout</button>
                        </ListGroup.Item>
                      </ListGroup>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Navbar.Collapse>
            </div>
          </Container>
        </Navbar>

        <Innermobheader></Innermobheader>
      </header>

      <Modal show={cartshow} onHide={carthandleClose} className="checkoutpopup">
        <Modal.Header className="ms-3 pb-2 ps-0 border-bottom" closeButton>
          <Modal.Title>My Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-4 flex-column">
          <div className="border-bottom pb-3 mb-3">
            <Row>
              <Col xs={3}>
                <Image src={Image_URL + "img/b1.jpg"} alt="homebook" />
              </Col>
              <Col xs={8} className="ps-2">
                <div class="single-course-wrap mb-0">
                  <div class="wrap-details p-0 border-0">
                    <Link to="/">
                      <h6>
                        Varishth Adhyaapak Second Grade Paper-11 ( Hindi sahitya
                        ka itihas))
                      </h6>
                    </Link>
                    <div class="price-wrap">
                      <div class="align-items-center row">
                        <div class="col-7">
                          <span class="text-theme f-18 fw-semi-bold me-2">
                            <i class="fa fa-rupee"></i> 75
                          </span>
                          <del class="text-black-50 f-15 ms-1">
                            <i class="fa fa-rupee"></i> 150
                          </del>
                        </div>
                        <div class="col-5 cartselectqty">
                          Qty:
                          <select>
                            <option>1</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={1} className="pe-0">
                <i class="far fa-trash-alt text-danger"></i>
              </Col>
            </Row>
          </div>
          <div className="border-bottom pb-3 mb-3">
            <Row>
              <Col xs={3}>
                <Image src={Image_URL + "img/b1.jpg"} alt="homebook" />
              </Col>
              <Col xs={8} className="ps-2">
                <div class="single-course-wrap mb-0">
                  <div class="wrap-details p-0 border-0">
                    <Link to="/">
                      <h6>
                        Varishth Adhyaapak Second Grade Paper-11 ( Hindi sahitya
                        ka itihas))
                      </h6>
                    </Link>
                    <div class="price-wrap">
                      <div class="align-items-center row">
                        <div class="col-7">
                          <span class="text-theme f-18 fw-semi-bold me-2">
                            <i class="fa fa-rupee"></i> 75
                          </span>
                          <del class="text-black-50 f-15 ms-1">
                            <i class="fa fa-rupee"></i> 150
                          </del>
                        </div>
                        <div class="col-5 cartselectqty">
                          Qty:
                          <select>
                            <option>1</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={1} className="pe-0">
                <i class="far fa-trash-alt text-danger"></i>
              </Col>
            </Row>
          </div>

          <div className="border-top pt-3 mt-auto">
            <Row>
              <Col xs={6}>
                <div class="price-wrap">
                  <span class="text-theme f-16 fw-semi-bold">Grand Total</span>{" "}
                  <br />
                  <span class="f-20 fw-semi-bold d-block">
                    <i class="fa fa-rupee"></i> 150
                  </span>
                </div>
              </Col>
              <Col xs={6}>
                <a href="#" class="btn btn-danger">
                  Checkout
                </a>
              </Col>
            </Row>
          </div>
        </Modal.Body>

        <Modal.Body>
          <Tabs
            defaultActiveKey="existing"
            id="Checkoutaddress"
            className="edl-nav nav-pills new-trending-course"
          >
            <Tab eventKey="existing" title="Existing Address">
              <div className="">
                <div class="single-course-wrap mb-3 bookscrousel mt-0">
                  <div class="wrap-details">
                    <Row>
                      <Col xs={1}>
                        <input
                          type="radio"
                          class="with-gap"
                          name="extingadd"
                          id="addressonme1"
                          checked="true"
                        />
                        <label for="addressonme1"></label>
                      </Col>
                      <Col xs={11}>
                        <Link to="/">
                          <h6 className="h-auto mb-2">Nishu garg</h6>
                          <p class="text-black-50 f-15 fw-medium mb-2">
                            <i class="fas fa-phone-alt"></i> 9876556789
                          </p>
                          <p class="text-black-50 f-15 fw-medium me-1">
                            <i class="fas fa-map-marker-alt"></i> Plot No. 12,
                            Sector-135, P.L.A. Market, Hissar, Haryana
                          </p>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div class="single-course-wrap mb-3 bookscrousel mt-0">
                  <div class="wrap-details">
                    <Row>
                      <Col xs={1}>
                        <input
                          type="radio"
                          class="with-gap"
                          name="extingadd"
                          id="addressonme"
                        />
                        <label for="addressonme"></label>
                      </Col>
                      <Col xs={11}>
                        <Link to="/">
                          <h6 className="h-auto mb-2">Nishu garg</h6>
                          <p class="text-black-50 f-15 fw-medium mb-2">
                            <i class="fas fa-phone-alt"></i> 9876556789
                          </p>
                          <p class="text-black-50 f-15 fw-medium me-1">
                            <i class="fas fa-map-marker-alt"></i> Plot No. 12,
                            Sector-135, P.L.A. Market, Hissar, Haryana
                          </p>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="border-top pt-3 mt-auto">
                  <Row>
                    <Col xs={6}>
                      <div class="price-wrap">
                        <span class="text-theme f-16 fw-semi-bold">
                          Grand Total
                        </span>{" "}
                        <br />
                        <span class="f-20 fw-semi-bold d-block">
                          <i class="fa fa-rupee"></i> 150
                        </span>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <a href="#" class="btn btn-danger">
                        Continue
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </Tab>
            <Tab eventKey="addnew" title="Add New Address">
              <div className="contactform p-0 shadow-none ">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your Name"
                />
                <input
                  type="text"
                  class="form-control"
                  placeholder="House No. Building Name"
                />
                <input
                  type="text"
                  class="form-control"
                  placeholder="Road Name, Area Colony"
                />
                <input type="text" class="form-control" placeholder="City" />
                <input type="text" class="form-control" placeholder="State" />
                <input type="text" class="form-control" placeholder="Pincode" />
                <input
                  type="text"
                  class="form-control"
                  placeholder="10 Digit Mobile Number"
                />

                <div className="border-top pt-3 mt-auto">
                  <Row>
                    <Col xs={12}>
                      <a href="#" class="btn btn-danger">
                        Save & Continue
                      </a>
                    </Col>
                  </Row>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Homeheader;
