import React from "react"; 
import { Col, Row } from 'react-bootstrap'; 
import { Link} from "react-router-dom";

const Video=({video})=>{

    return(<Col md={3}>
        <Link to={"/view-video-csc/"+video.token+"/"+video.id+"/"+video.course_id+"/"+video.subject_id}>
            <div className="single-course-wrap video-bgdemo">
                <div className="wrap-details">  
                    <Row className="align-items-center">
                        <Col xs={10}> 
                            <p className="f-13 text-black">{video.title}</p>
                        </Col>
                        <Col xs={2}> 
                            <i className="fa fa-play me-4"></i>
                        </Col>
                    </Row>
                </div>
            </div> 
        </Link>
    </Col>)

}

export default Video;
