import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Image,
  Form,
  Button,
  Alert,
  Spinner,
} from "react-bootstrap";
import LoginImage from "./../../assets/img/login.jpg";
import MainLogo from "../../assets/img/logo.png";
import ApiCall from "../../api/callApi";

const Login = (props) => {
  const { initialMinute = 1, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/skill-courses-web/" + localStorage.getItem("token"));
    }

    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [navigate]);

  //mobile no state
  const [phone, SetPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [name, setName] = useState("");
  const [refferaluser, setRefferalUser] = useState("");
  const [ref, setRef] = useState("");

  //popup login, register and otp
  const [loginpopup, setLoginPopUp] = useState("block");
  const [otppopup, setOtpPopUp] = useState("none");
  const [registerpopup, setRegisterPopUp] = useState("none");

  // State
  const [states, setState] = useState([]);
  const [user_state, setUserState] = useState("");

  //state for showing message for errors and success
  const [show, setShow] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);
  const [show_succ, setShowSucc] = useState(false);
  const [show_fail, setShowFail] = useState(false);
  const [message, setMessage] = useState("");

  //Send otp process
  function handleSubmitForm(e) {
    e.preventDefault();
    //check mobile no is valid or not
    var validateMobNum = /^\d*(?:\.\d{1,2})?$/;
    if (validateMobNum.test(phone) && phone.length == 10) {
    } else {
      setShowFail(true);
      setMessage("Invalid Mobile no");
      return false;
    }

    setShowSucc(false);
    setShowFail(false);

    var data = {
      phone: phone,
    };
    setSubmitStatus(true);
    ApiCall(data, "post", "send_otp", sendotpcallback);
  }

  const sendotpcallback = useCallback((response) => {
    setMessage(response.data.message);
    if (response.data.Status === "Success") {
      setLoginPopUp("none");
      setOtpPopUp("block");
    } else {
      setShowFail(true);
      setShowSucc(false);
    }
    setSubmitStatus(false);
  }, []);

  //verify otp process
  function verify_otp() {
    const body = {
      phone: phone,
      otp: otp,
    };
    ApiCall(body, "post", "verify_otp", verifyotpcallback);
  }

  const verifyotpcallback = useCallback((response) => {
    setMessage(response.data.message);
    if (response.data.status === "true") {
      if (response.data.register_status === 1) {
        setRegisterPopUp("none");
        setOtpPopUp("none");
      } else {
        setRegisterPopUp("block");
        setOtpPopUp("none");
      }
      if (response.data.register_status === 0) {
        setState(response.data.state);
        setSubmitStatus(false);
      } else {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("name", response.data.user.name);
        localStorage.setItem("id", response.data.user.id);
        localStorage.setItem("mobile", response.data.user.number);
        navigate("/skill-courses-web/" + response.data.token);
      }
    } else {
      setShowFail(true);
      setShowSucc(false);
    }
  }, []);

  //Resend otp process
  const resend_otp = () => {
    var data = {
      phone: phone,
    };
    setSubmitStatus(true);
    ApiCall(data, "post", "send_otp", sendotpcallback);
  };

  //Send otp process
  function handleSubmitFormRegister(e) {
    e.preventDefault();
    var data = {
      name: name,
      state: user_state,
      ref_code: ref,
      number: phone,
      player_id: "",
      channel: "self",
      otp: otp,
    };
    ApiCall(data, "post", "new_signup", newsignupcallback);
  }

  const newsignupcallback = useCallback((response) => {
    setMessage(response.data.message);
    if (response.data.status === "true") {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("name", response.data.user.name);
      localStorage.setItem("id", response.data.user.id);
      localStorage.setItem("mobile", response.data.user.number);
      navigate(-1);
    } else {
      setShowFail(true);
      setShowSucc(false);
    }
  }, []);

  const getRefferal = (e) => {
    var data = {
      sponser_id: e.target.value,
    };
    setRef(e.target.value);
    ApiCall(data, "post", "sponser_detail", getrefferalcallback);
  };

  const getrefferalcallback = useCallback((response) => {
    if (response.data.status === "true") {
      setRefferalUser(response.data.name);
    } else {
      setRefferalUser("");
    }
  }, []);

  return (
    <>
      <Container fluid className="login-bg minheightlogin">
        <Row className="align-items-center">
          <Col md={7} className="d-none d-sm-block text-center">
            <Image src={LoginImage} alt="login image" />
            <p className="f-11 mb-0 mt-5  text-muted">
              © 2022 Dhurina Ventures Pvt Ltd. All Right Reserved.
            </p>
          </Col>
          <Col md={5}>
            {/* Login popup */}
            <div
              className="p-3 p-sm-5 my-2 m-sm-5 bg-white loginboxshadow"
              style={{ display: loginpopup }}
            >
              {/* <Image src={MainLogo} alt="logo image" className="mb-2" /> */}
              <h3 className="f-25 mt-4 mb-4">
                Welcome Back!
                <br />
                <span className="text-muted f-18">
                  Enter your Phone number to continue OTP will be sent on this
                  number for verfification
                </span>
              </h3>

              <Alert
                variant="danger"
                show={show}
                onClose={() => setShow(false)}
                dismissible
                className="px-3 py-2 f-13"
              >
                <Alert.Heading className="f-13 mb-0">
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  <strong>Register Now!</strong>
                </Alert.Heading>
                Your Mobile No. is not Registered with us.
              </Alert>

              <form onSubmit={(e) => handleSubmitForm(e)}>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Form.Label className="f-12 fw-medium mb-1">
                        Mobile No.<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="login-form">
                        <Form.Control
                          type="number"
                          min="0"
                          placeholder="Enter your Mobile No"
                          onChange={(e) => SetPhone(e.target.value)}
                        />
                        <i className="fa fa-mobile-alt"></i>
                      </div>
                    </div>

                    {/* <Row>
								<Col xs={6}>
									<div className="single-form-check form-check loginrember">
										<Form.Control className="form-check-input p-0" type="checkbox"  id="Remember" checked={remember} onChange={(e)=>SetRemember(!remember)} />
										<Form.Label className="form-check-label mb-0" htmlFor="Remember">Remember Me</Form.Label>
									</div>
								</Col>
								<Col xs={6} className="text-end">
									<Link to="/forget-password" className="f-12">Forget Password?</Link>
								</Col>
							</Row> */}
                    <Alert
                      variant="danger"
                      show={show_fail}
                      onClose={() => setShowFail(false)}
                      dismissible
                      className="px-3 py-2 f-13"
                    >
                      <Alert.Heading className="f-13 mb-0">
                        <i className="fas fa-exclamation-triangle me-2"></i>
                        <strong>Failed</strong>
                      </Alert.Heading>
                      {message}
                    </Alert>
                    <Alert
                      variant="success"
                      show={show_succ}
                      onClose={() => setShowSucc(false)}
                      dismissible
                      className="px-3 py-2 f-13"
                    >
                      <Alert.Heading className="f-13 mb-0">
                        <i className="fas fa-check me-2"></i>
                        <strong>Successfull</strong>
                      </Alert.Heading>
                      {message}
                    </Alert>
                    <Button
                      variant="base-light"
                      className="w-100 mt-4 btn-block loginshadowmain"
                      type="submit"
                    >
                      {submitStatus ? (
                        <Spinner as="span" animation="border" size="sm" />
                      ) : (
                        <span> Proceed </span>
                      )}
                    </Button>

                    {/* <p className="f-12 text-center mt-4 text-muted">
								 Don't have an account? <Link to="/register" className="fw-bold f-13">Register Now</Link> 
							</p> */}
                  </Col>
                </Row>
              </form>
            </div>

            {/* Endlogin popup */}

            {/* Register Popup  */}

            <div
              className="p-3 p-sm-5 my-2 m-sm-5 bg-white loginboxshadow"
              style={{ display: registerpopup }}
            >
              <Image src={MainLogo} alt="logo image" className="mb-2" />
              <h3 className="f-25 mt-4 mb-4">
                Welcome
                <br />
                <span className="text-muted f-18"></span>
              </h3>

              <Alert
                variant="danger"
                show={show}
                onClose={() => setShow(false)}
                dismissible
                className="px-3 py-2 f-13"
              >
                <Alert.Heading className="f-13 mb-0">
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  <strong>Register Now!</strong>
                </Alert.Heading>
                Your Mobile No. is not Registered with us.
              </Alert>

              <form onSubmit={(e) => handleSubmitFormRegister(e)}>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Form.Label className="f-12 fw-medium mb-1">
                        Name<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="login-form">
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        <i className="fa fa-user-circle"></i>
                      </div>
                    </div>
                    <div className="mb-3">
                      <Form.Label className="f-12 fw-medium mb-1">
                        Mobile No.<span className="text-danger">*</span>
                      </Form.Label>
                      <div className="login-form">
                        <Form.Control
                          type="number"
                          min="0"
                          value={phone}
                          readOnly
                        />
                        <i className="fa fa-mobile-alt"></i>
                      </div>
                    </div>
                    <div className="mb-3">
                      <Form.Label className="f-12 fw-medium mb-1">
                        Select State <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="login-form">
                        <Form.Select
                          className="form-control"
                          onChange={(e) => setUserState(e.target.value)}
                          required
                        >
                          <option>Select State</option>
                          {states.map((value) => {
                            return (
                              <option value={value.id} key={value.id}>
                                {value.states}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <i className="fa fa-map-marker-alt"></i>
                      </div>
                    </div>

                    <div className="mb-3">
                      <Form.Label className="f-12 fw-medium mb-1">
                        Refferal Id
                      </Form.Label>
                      <div className="login-form">
                        <Form.Control
                          type="text"
                          placeholder="Refferal Id (optional)"
                          onChange={(e) => getRefferal(e)}
                        />
                        <i className="fa fa-user-circle"></i>
                      </div>
                      {refferaluser !== "" && (
                        <p>
                          <b>Ref. Name:</b> {refferaluser}
                        </p>
                      )}
                    </div>

                    {/* <Row>
								<Col xs={6}>
									<div className="single-form-check form-check loginrember">
										<Form.Control className="form-check-input p-0" type="checkbox"  id="Remember" checked={remember} onChange={(e)=>SetRemember(!remember)} />
										<Form.Label className="form-check-label mb-0" htmlFor="Remember">Remember Me</Form.Label>
									</div>
								</Col>
								<Col xs={6} className="text-end">
									<Link to="/forget-password" className="f-12">Forget Password?</Link>
								</Col>
							</Row> */}
                    <Alert
                      variant="danger"
                      show={show_fail}
                      onClose={() => setShowFail(false)}
                      dismissible
                      className="px-3 py-2 f-13"
                    >
                      <Alert.Heading className="f-13 mb-0">
                        <i className="fas fa-exclamation-triangle me-2"></i>
                        <strong>Failed</strong>
                      </Alert.Heading>
                      {message}
                    </Alert>
                    <Alert
                      variant="success"
                      show={show_succ}
                      onClose={() => setShowSucc(false)}
                      dismissible
                      className="px-3 py-2 f-13"
                    >
                      <Alert.Heading className="f-13 mb-0">
                        <i className="fas fa-check me-2"></i>
                        <strong>Successfull</strong>
                      </Alert.Heading>
                      {message}
                    </Alert>
                    <Button
                      variant="base-light"
                      className="w-100 mt-4 btn-block loginshadowmain"
                      type="submit"
                    >
                      {submitStatus ? (
                        <Spinner as="span" animation="border" size="sm" />
                      ) : (
                        <span> Proceed </span>
                      )}
                    </Button>
                    {/* <p className="f-12 text-center mt-4 text-muted">
								 Don't have an account? <Link to="/register" className="fw-bold f-13">Register Now</Link> 
							</p> */}
                  </Col>
                </Row>
              </form>
            </div>

            {/* End Registger Popup */}

            {/* Otp Popup */}

            <div
              className="p-3 p-sm-5 my-2 m-sm-5 bg-white loginboxshadow"
              style={{ display: otppopup }}
            >
              {/* <Image src={MainLogo} alt="logo image" className="mb-2" /> */}
              <h3 className="f-25 mt-4 mb-4">
                Verify Account!
                <br />
                <span className="text-muted f-18">
                  Enter the 5 digit OTP sent to {phone}
                </span>
              </h3>

              <Alert
                variant="danger"
                show={show}
                onClose={() => setShow(false)}
                dismissible
                className="px-3 py-2 f-13"
              >
                <Alert.Heading className="f-13 mb-0">
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  <strong>Register Now!</strong>
                </Alert.Heading>
                Your Mobile No. is not Registered with us.
              </Alert>

              <form onSubmit={(e) => handleSubmitForm(e)}>
                <Row>
                  <Col md={12}>
                    <Form.Label className="f-12 fw-medium mb-1">
                      Enter OTP <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="d-flex justify-content-between">
                      <Form.Control
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength="5"
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                    {/* <Row>
								<Col xs={6}>
									<div className="single-form-check form-check loginrember">
										<Form.Control className="form-check-input p-0" type="checkbox"  id="Remember" checked={remember} onChange={(e)=>SetRemember(!remember)} />
										<Form.Label className="form-check-label mb-0" htmlFor="Remember">Remember Me</Form.Label>
									</div>
								</Col>
								<Col xs={6} className="text-end">
									<Link to="/forget-password" className="f-12">Forget Password?</Link>
								</Col>
							</Row> */}
                    <Alert
                      variant="danger"
                      show={show_fail}
                      onClose={() => setShowFail(false)}
                      dismissible
                      className="px-3 py-2 f-13"
                    >
                      <Alert.Heading className="f-13 mb-0">
                        <i className="fas fa-exclamation-triangle me-2"></i>
                        <strong>Failed</strong>
                      </Alert.Heading>
                      {message}
                    </Alert>
                    <Alert
                      variant="success"
                      show={show_succ}
                      onClose={() => setShowSucc(false)}
                      dismissible
                      className="px-3 py-2 f-13"
                    >
                      <Alert.Heading className="f-13 mb-0">
                        <i className="fas fa-check me-2"></i>
                        <strong>Successfull</strong>
                      </Alert.Heading>
                      {message}
                    </Alert>
                    <Button
                      variant="base-light"
                      className="w-100 mt-4 btn-block loginshadowmain"
                      onClick={() => verify_otp()}
                    >
                      Verify
                    </Button>
                    <p className="f-12 text-center mt-4 text-muted"></p>
                    {minutes === 0 && seconds === 0 ? (
                      <Button
                        variant="base-light"
                        className="w-100 mt-4 btn-block loginshadowmain"
                        onClick={() => resend_otp()}
                      >
                        Resend Otp
                      </Button>
                    ) : (
                      <p>
                        <b>Resend OTP In:</b> {minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </p>
                    )}

                    {/* <p className="f-12 text-center mt-4 text-muted">
								 Don't have an account? <Link to="/register" className="fw-bold f-13">Register Now</Link> 
							</p> */}
                  </Col>
                </Row>
              </form>
            </div>

            {/* End Otp Popup */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
