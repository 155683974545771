import React, { useCallback, useState, useEffect } from "react";
import ApiCall from "../../api/callApi";
import Register from "../../components/statedata/Register";
import { useNavigate } from "react-router-dom";
import MainLoaderImage from "./../../assets/img/loading-buffering.gif";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const EXAM_CATEGORY_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "category/";
const LatestTestSeries = () => {
  const [mainloading, setMainLoading] = useState(true);
  const navigate = useNavigate();
  const [latestTestSeries, setLatestTestSeries] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);

    getData();
  }, []);

  function getData(params) {
    ApiCall("", "get", "home_categories", home_categories);
  }

  const home_categories = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setLatestTestSeries(response.data.latest_sub_category);
    } else {
      console.log("error");
    }
  });
  const getCourseData = (item) => {
    const sub_category = item.name;
    const id = item.id;
    const catId = item.cat_id;
    navigate(
      `/${sub_category}/${id}/all-test-series?type=test-series&catId=${catId}`
    );
    window.scrollTo(0, 0);
  };

  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }
  return (
    <div className="custom_container container py-5">
      <div className="row mt-5 justify-content-between">
        <div className="col-lg-8 border-end">
          <div className="row align-items-center">
            <h4 className="ff_inter fw-bold color_dark_blue mb-3">
              Latest <span className="text_gradient"> Test Series </span>
            </h4>
            {latestTestSeries &&
              latestTestSeries.map((item, index) => (
                <div className="w-20 text-center" key={index}>
                  <div
                    className="mt-1 cursor_pointer"
                    onClick={() => getCourseData(item)}
                  >
                    {/* <img
                      className="batches_icon"
                      src={`${EXAM_CATEGORY_IMAGE_URL}${item.image}`}
                      alt={item.image}
                    /> */}
                    <img
                      width={30}
                      className="rounded-3"
                      src={Image_URL + "website/notes_icon.jpg"}
                    />
                    <p className="ff_inter fs_desc color_light_black fw-semi-bold lh-sm mt-1">
                      {item.name}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="col-lg-4">
          <Register />
        </div>
      </div>
    </div>
  );
};

export default LatestTestSeries;
