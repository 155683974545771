import React from 'react';
import { Image, Col, Row, Button, FormControl, Modal } from 'react-bootstrap';
import MainLogo from '../../assets/img/logo.png';
import SwitchMainleft from '../../assets/img/switchmain.png';
import { useState } from "react"; 
import  { useNavigate } from 'react-router-dom'


const WebswitchPopup=(props)=> {
     
    const navigate = useNavigate();
    const [ prepval, setPrepVal] = useState('');

    
    
    function set_pre() {

        if (prepval!=='') {

            localStorage.setItem('selectpre',prepval)

            if (prepval==="Competitive") {
               
                if(window.location.pathname==='/'){
                    props.onHide();
                }
                return navigate('/')
            }

            if (prepval==="School") {
                if(window.location.pathname==='/school'){
                    props.onHide();
                }
                return navigate('/school')
            }

            if (prepval==="Skill") {
                if(window.location.pathname==='/skill'){
                    props.onHide();
                }
                return navigate('/skill')
            }
        }
    }

    function handleChange(value) {
        setPrepVal(value)
    }

    return (
        <> 
        <Modal size="lg" id="switchpopup" {...props} >        
            <Modal.Header closeButton></Modal.Header>        
            <Modal.Body>
                <Row className="align-items-center">
                    <Col md={6} className="d-none d-sm-block"> 
                        <Image src={SwitchMainleft} alt="img" className="mt-5"  /> 
                    </Col>
                    <Col md={6} className="switchlnguageox">
                        <Image src={MainLogo} alt="img" className="mb-3 mb-sm-5" />
                        <h4 className="f-25 mb-0 f-22mob">What are you preparing for</h4>
                        <p className="mb-4 mt-1">Click on the below interest and continue</p>
                        
                        <div className="connectoptionmobview bookcourseconnecttop bg-light">
                            <span>
                                <FormControl type="radio" className="with-gap" name="selectpre" id="Competitive" value="Competitive" onChange={()=>handleChange('Competitive')}/>
                                <label htmlFor="Competitive" className="f-22">Competitive</label>
                            </span>  
                        </div>
                        <div className="connectoptionmobview bookcourseconnecttop bg-light">
                            <span>
                                <FormControl type="radio" className="with-gap" name="selectpre" id="School2" value="School" onChange={()=>handleChange('School')}/>
                                <label htmlFor="School2" className="f-22">School</label>
                            </span>  
                        </div>
                        <div className="connectoptionmobview bookcourseconnecttop bg-light">
                            <span>
                                <FormControl type="radio" className="with-gap" name="selectpre" id="NEET" value="Skill" onChange={()=>handleChange('Skill')}/>
                                <label htmlFor="NEET" className="f-22">Skill & Certificate</label>
                            </span>  
                        </div> 
                        <Button variant="base-light" className="w-100 mt-4" onClick={()=>set_pre()}>Continue</Button>
                    </Col> 
                </Row> 

            </Modal.Body>
        </Modal>
      </>
  );
}


export default WebswitchPopup; 
