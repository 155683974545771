import Loader from "react-loader-spinner";
const Loading = () => {
  const style = { textAlign: "center" };
  return (
    <div style={style}>
      <Loader type="Circles" color="red" height={80} width={150} />
    </div>
  );
};
export default Loading;
