import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Col, Row, Image, Form } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import Neetheader from "../../include/header/neetHeader";
import Footer from "../../include/footer/footer";
import Applink from "../../include/applink/googleApple";
import SchoolIcon from "./../../assets/img/schoolicon.png";
import StudentIcon from "./../../assets/img/studenticon.png";
import MobappImage from "./../../assets/img/mob-app.png";
import Item from "../../components/item/index";
import ApiCall from "../../api/callApi";
import Loading from "../../components/Loader";
import { Helmet } from "react-helmet";

const courseSlideroptions = {
  loop: true,
  margin: 30,
  nav: true,
  dots: false,
  smartSpeed: 1500,
  autoplay: true,
  navText: [
    "<i class='fa fa-arrow-left'></i>",
    "<i class='fa fa-arrow-right'></i>",
  ],
  responsive: {
    0: {
      items: 1.5,
      dots: true,
      margin: 10,
    },
    575: {
      items: 2,
      margin: 20,
      dots: true,
    },
    769: {
      items: 4,
      dots: false,
    },
  },
};

const Neethome = () => {
  const [neetCourse, setNeetCourse] = useState([]);
  const [catCourse, setCatCourse] = useState([]);
  const [gateCourse, setGateCourse] = useState([]);
  const [upscCourse, setUpscCourse] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    ApiCall("", "get", "neet_course", neetcourse);
  }

  const neetcourse = useCallback((response) => {
    if (response.data.status === 200) {
      setNeetCourse(response.data.neet_course);
      setCatCourse(response.data.cat_course);
      setGateCourse(response.data.gate_course);
      console.log(response.data.upsc_course);
      setUpscCourse(response.data.upsc_course);
    } else {
      console.log("error");
    }
  });

  return (
    <>
      <Neetheader></Neetheader>
      <Helmet>
        <title>Best Affordable Online Coaching For JEE/NEET Exam</title>
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
        />
        <link rel="canonical" href="https://dhurina.net/neet" />
        <meta
          name="title"
          content="Best Affordable Online Coaching For JEE/NEET Exam."
        />
        <meta
          name="description"
          content="All the major subjects covered in this section are recorded and live classes for the NEET examination."
        />
        <meta name="publish-date" content="2022-04-22T11:24:28+05:30" />
        <meta name="created-date" content="2022-04-22T11:24:28+05:30" />
        <meta name="modified-date" content="2022-04-22T11:24:29+05:30" />
        <meta
          name="twitter:description"
          content="All the major subjects covered in this section are recorded and live classes for the NEET examination."
        />
        <meta
          name="twitter:image"
          content="https://dhurina.net/fetch-image-url"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Best Affordable Online Coaching For JEE/NEET Exam."
        />
        <meta name="twitter:site" content="@Dhurinalive" />
        <meta name="twitter:creator" content="@Dhurinalive" />
        <meta name="twitter:app:id:googleplay" content="com.dhurina" />
        <meta name="twitter:app:id:iphone" content="1479858722" />
        <meta
          name="twitter:app:name:googleplay"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:ipad"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:iphone"
          content="Dhurina - Learning Made Easy"
        />
        <meta name="twitter:domain" content="dhurina.net" />
        <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
        <meta name="og:image:type" content="image/jpeg" />
        <meta
          property="og:description"
          content="All the major subjects covered in this section are recorded and live classes for the NEET examination."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Best Affordable Online Coaching For JEE/NEET Exam."
        />
        <meta property="og:url" content="https://dhurina.net/neet" />
        <meta property="og:site_name" content="Dhurina" />
        <meta property="og:updated_time" content="2022-01-28T13:36:39+05:30" />
        <meta
          property="article:published_time"
          content="2022-04-22T05:54:28.000Z"
        />
        <meta
          property="article:modified_time"
          content="2022-04-22T05:54:29.000Z"
        />
        <meta property="article:section" content="Neet" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/dhurinalive"
        />
        <meta property="article:author" content="https://fb.com/dhurinalive" />
        <meta property="article:section" content="Online-Course" />
        <meta property="al:android:url" content="https://dhurina.net/neet" />
        <meta
          property="al:android:app_name"
          content="Dhurina - Learning Made Easy"
        />
        <meta property="al:android:package" content="com.dhurina" />
        <meta property="fb:app_id" content="facebook-app-id-in-number" />
        <meta property="fb:pages" content="facebook-page-in-number" />
      </Helmet>

      <section className="banner-area neetmainbanner">
        <Container>
          <Row className="position-relative zindexover justify-content-end">
            <Col md={6} className="mt-0 mt-sm-5">
              <div className="main-banner-txt banner-inner text-md-start text-center mb-0 mb-sm-5 defence-banner">
                <div className="section-title lefth2border mb-3">
                  <h5 className="fw-medium text-start text-white f-18">
                    By Aman Aggarwal
                  </h5>
                  <h2 className="text-start text-white">NEET/JEE</h2>
                </div>
                <p className="f-15 text-white text-start schoolbannerdesp">
                  Start your preparation journey today with dhurina. The regular
                  mock tests, pdfs, and guidance provided by top educators from
                  AIIMS/IITs will give a new direction towards your dream
                  college.
                </p>

                <Row className="mt-3 text-start">
                  <Col sm={6} xs={6} className="mb-3">
                    <Row>
                      <Col sm={3} xs={4}>
                        <Image alt="school" src={SchoolIcon} />
                      </Col>
                      <Col sm={9} xs={8} className="wrap-details">
                        <h6 className="text-white ">
                          <Link to="/">5000+</Link>
                        </h6>
                        <p className="text-white ">Syallabus</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={6} xs={6} className="mb-3">
                    <Row>
                      <Col sm={3} xs={4}>
                        <Image alt="student" src={StudentIcon} />
                      </Col>
                      <Col sm={9} xs={8} className="wrap-details">
                        <h6 className="text-white ">
                          <Link to="/">5+ lakh</Link>
                        </h6>
                        <p className="text-white ">Enrolled Students</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="download-btn text-start">
                      <p className="f-15 text-white">
                        <span>Download App Now</span>
                      </p>
                      <p>
                        <Link to="/">
                          <i className="fab fa-google-play ms-0"></i>
                        </Link>{" "}
                        <Link to="/">
                          <i className="fab fa-apple"></i>
                        </Link>
                      </p>
                    </div>
                  </Col>
                  <Col md={6} className="text-start text-sm-end">
                    <Link to="/" className="btn btn-warning mt-3">
                      <i className="fa fa-rupee-sign"></i> Enroll Now
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="trending-courses-area py-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title">
                <h2>Top NEET/JEE Courses</h2>
              </div>
            </Col>
            <Col lg={12}>
              <OwlCarousel className="course-slider" {...courseSlideroptions}>
                {neetCourse.length < 1 ? (
                  <Loading />
                ) : (
                  neetCourse.map((item) => {
                    return <Item item={item} key={item.id} />;
                  })
                )}
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="trending-courses-area py-5 bg-light">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title">
                <h2>Top CAT Courses</h2>
              </div>
            </Col>
            <Col lg={12}>
              <OwlCarousel className="course-slider" {...courseSlideroptions}>
                {catCourse.length < 1 ? (
                  <Loading />
                ) : (
                  catCourse.map((item) => {
                    return <Item item={item} key={item.id} />;
                  })
                )}
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="trending-courses-area py-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title">
                <h2>Top Gate Courses</h2>
              </div>
            </Col>
            <Col lg={12}>
              <OwlCarousel className="course-slider" {...courseSlideroptions}>
                {gateCourse.length < 1 ? (
                  <Loading />
                ) : (
                  gateCourse.map((item) => {
                    return <Item item={item} key={item.id} />;
                  })
                )}
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="trending-courses-area py-5 bg-light">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title">
                <h2>Top UPSC Courses</h2>
              </div>
            </Col>
            <Col lg={12}>
              <OwlCarousel className="course-slider" {...courseSlideroptions}>
                {upscCourse.length < 1 ? (
                  <Loading />
                ) : (
                  upscCourse.map((item) => {
                    return <Item item={item} key={item.id} />;
                  })
                )}
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="contact-newbg  py-5">
        <Container className="contact-bg">
          <Row className="justify-content-center">
            <Col lg={12} className="text-center">
              <p className="text-theme f-15 mb-0">Connect with us</p>
              <div className="section-title">
                <h2>Choose your Program</h2>
              </div>
            </Col>
            <Col lg={7}>
              <div className="contactform">
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Form.Select className="form-control">
                        <option>Select Subject</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Form.Select className="form-control">
                        <option>State CLAT/CAT</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Form.Select className="form-control">
                        <option>Univeristy Exam</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Form.Select className="form-control">
                        <option>Select Gate</option>
                      </Form.Select>
                    </div>
                  </Col>
                  <Col md={12}>
                    <Button variant="danger" className="mt-3f-16 shadow-none">
                      <i className="far fa-paper-plane"></i> Enroll Now
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-3 map-bg">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md={3}>
              <Image alt="dhurina app image" src={MobappImage} />
            </Col>
            <Col md={5} className="downloadappbtnmob mb-4 schoolinner-mob-app">
              <div className="section-title mb-4 lefth2border">
                <h2 className=" text-start mb-3 pb-3">Download Dhurina App</h2>
                <h5 className=" fw-medium f-22">Get Free Test Series Quiz</h5>
              </div>
              <Applink></Applink>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer></Footer>
    </>
  );
};

export default Neethome;
