import React from 'react';
import { useState } from "react"; 
import { Image } from 'react-bootstrap';
import WebswitchPopup from '../../include/popup/switch';
const Image_URL = process.env.REACT_APP_Bucket_URL;

function Switchicon () {

  var show_status=false;
  // if (!localStorage.getItem('selectpre')) {
  //     show_status=true;
  // }



  const [ popupswShow, setswitchPopup] = useState(show_status);
  const swpopClose = () => setswitchPopup(false);
  const swpopShow = () => setswitchPopup(true);

  return (
    <>
      <a href="javasacript:;" onClick={swpopShow}><Image src={Image_URL+"img/switch.png"} alt=""/></a> 
      
      <WebswitchPopup show={popupswShow} onHide={swpopClose}/>
     

    </>
  );
}

export default Switchicon;
