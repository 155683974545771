import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ApiCall from "../../api/callApi";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const IframeCourse = () => {
  const [mainloading, setMainLoading] = useState(true);
  const [courseDetail, setCourseDetail] = useState("");
  const [course_url, setCourseUrl] = useState([]);

  var { name } = useParams();
  const navigate = useNavigate();
  let url = "iframe-course/" + name;

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  function getData(params) {
    ApiCall("", "get", url, courseCallback);
  }

  const courseCallback = useCallback((response) => {
    if (response.data.status === 200 || response.data.status === 301) {
      setCourseDetail(response.data.data);
      setCourseUrl(response.data.course_url);
    } else if (response.data.status === 404) {
      navigate("/page-not-found");
    } else {
      console.log("error");
    }
    console.log("check the response", courseDetail);
    setMainLoading(false);
  });

  return (
    <div style={{ backgroundColor: "#EDF7FE" }}>
      {mainloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img
            src={Image_URL + "website/priloader_icon.svg"}
            style={{ alignSelf: "center" }}
          />
        </div>
      ) : (
        <>
          <a
            href={"/course-detail/" + course_url + "/" + courseDetail.id}
            target="_blank"
          >
            <div style={{ height: "150px" }}>
              <p
                style={{
                  textAlign: "center",
                  color: "#008233",
                  paddingTop: "50px",
                  fontSize: "25px",
                  fontWeight: "700",
                }}
              >
                Click here for exam preparation
              </p>
            </div>
          </a>
        </>
      )}
    </div>
  );
};

export default IframeCourse;
