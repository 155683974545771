import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Image, Col, Row, Button, Navbar } from "react-bootstrap";
import MoblanPopup from "../../include/popup/moblan";
import WebswitchPopup from "../../include/popup/switch";
import MenumobPopup from "../../include/popup/mobmenu";
const Image_URL = process.env.REACT_APP_Bucket_URL;

function Innermobheader() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  const [popuplanShow, setmoblanPopup] = useState(false);
  const mlngpopClose = () => setmoblanPopup(false);
  const mlngpopShow = () => setmoblanPopup(true);

  const [popupswShow, setswitchPopup] = useState(false);
  const swpopClose = () => setswitchPopup(false);
  const swpopShow = () => setswitchPopup(true);
  const [donwloadAppClose, setDownloadPopUpClose] = useState(true);
  const [popupmenShow, setmenumobPopup] = useState(false);
  const menpopClose = () => setmenumobPopup(false);
  const menpopShow = () => setmenumobPopup(true);
  const downloadCloseApp = () => setDownloadPopUpClose(false);

  return (
    <>
      <Navbar
        expand="lg"
        fixed="top"
        className={
          "d-block d-lg-none mob-header " + (scroll ? "darkHeader" : "")
        }
      >
        {donwloadAppClose === true && (
          <Container className="nav-container d-block bg-appdownload">
            <Row className="align-items-center">
              <Col xs={12}>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <div onClick={downloadCloseApp}>
                      <div class="appdownclose">
                        <span class="btn-close"></span>
                      </div>
                    </div>
                    <div>
                      <Image src="img/download-app-icon.png" />
                    </div>
                    <div>
                      <h4>Download the Dhurina App today!</h4>
                      <p>
                        Get upto 5 classes free and other exclusive discounts
                      </p>
                    </div>
                  </div>
                  <div>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.dhurina"
                      target="_blank"
                      rel="noreferrer"
                      class="getapp-btn"
                    >
                      Get App
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
        <Container className="nav-container d-block">
          <Row className="align-items-center">
            <Col xs={1}>
              <Button variant="mobile-icon" onClick={menpopShow}>
                <span></span>
              </Button>
            </Col>
            <Col xs={6}>
              <Link to="/">
                <Image
                  src={Image_URL + "img/logo.png"}
                  className="ms-2 mop-logophr"
                  alt="dhurina app image"
                />
              </Link>
            </Col>
            <Col xs={5} className="text-end">
              <a
                href="javasacript:;"
                onClick={mlngpopShow}
                className="moblanhome"
              >
                <Image
                  src={Image_URL + "img/language-icon.png"}
                  alt="language"
                  width="27px"
                />
              </a>
              <a href="javasacript:;" className="mobhomeicon">
                <Image
                  src={Image_URL + "img/home.png"}
                  alt="dhurina app image"
                  width="20px"
                />
              </a>
              <a href="javasacript:;" onClick={swpopShow}>
                <Image
                  src={Image_URL + "img/switch.png"}
                  alt="dhurina app image"
                  width="24px"
                  className="ms-1"
                />
              </a>
              <a href="/my-courses">
                <Image
                  src={Image_URL + "img/user.png"}
                  alt="dhurina app image"
                  width="17px"
                  className="ms-1"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </Navbar>

      <MoblanPopup show={popuplanShow} onHide={mlngpopClose} />

      <WebswitchPopup show={popupswShow} onHide={swpopClose} />

      <MenumobPopup show={popupmenShow} onHide={menpopClose} />
    </>
  );
}

export default Innermobheader;
