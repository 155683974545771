import React,{useEffect} from 'react'; 
import { Container, Col, Row } from 'react-bootstrap';
 


const AppAbout = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <> 
      
            <Container>
                <Row>
                    <Col md={12} className="InnerContentArea" style={{marginTop:'50px'}}>

                        <div className="section-title lefth2border">
                            <h2 className='text-start'>About Us</h2>
                        </div>

                        <div>  
                        
                            <p>Dhurina is an e-learning mobile app that connects students to experienced state-experts teachers and provides hundreds of well-designed courses for various state and central level competitive exams, school students, entrance exams, and skill-development courses. Our main aim is to provide quality education at affordable prices to millions of students of the country with a limelight on Tier-III and rural students.</p> 

                            <p>Dhurina is working to spread quality and affordable education in PAN- India despite any lack of physical and financial resources. </p>     
                        </div>

                        <div>
                            <h1>Problems we solve:</h1>
                                <p>1. Provide quality content at pocket-friendly prices.</p>
                                <p>2. Take education to rural area students.</p>
                                <p>3. Cut down extra costs of migration & living expenses for students who need to shift away from their hometowns to get quality education. </p>
                                <p>4. Become a one-stop solution to take care of your preparations for competitive exams and other exams and courses from initial level.</p>
                        </div>

                        <div>
                            <h1>Why Choose Dhurina?</h1>
                            <h6>We provide you a one stop solution for your competitive exam preparations. We enhance your learning process with: </h6>
                           

                            <p>- Interactive Live Sessions</p>
                            <p>- Recorded Video Lectures</p>
                            <p>- PDF &  Notes</p>
                            <p>- E-Books</p>
                            <p>- Mock tests</p>
                            <p>- E-papers</p>
                            <p>- Doubt Sessions</p>
                            <p>- And much more. </p>

                        </div>
                        <p>"जीत की तैयारी, विश्वास के साथ!"</p>
 
                             

                    </Col>
                </Row>
            </Container>
       
            
        
         
         
        
       
    </>
  );
}

export default AppAbout;
