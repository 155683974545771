import React, { useCallback, useState, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Login from "../../pages/auth/LoginNew";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import TestSeriesDropdown from "./TestSeriesDropdown";
import AllCoursesDropdown from "./AllCoursesDropdown";
import NotesBooksDropdown from "./NotesBooksDropdown";
import { Link } from "react-router-dom";
import Addtocart from "../../assets/img/add-to-cart.png";
import { Image } from "react-bootstrap";
// import ListGroup from "react-bootstrap";
import ApiCall from "../../api/callApi";

const Image_URL = process.env.REACT_APP_Bucket_URL;
const Header = ({ loginPopup, setLoginPopup, location }) => {
  // const [cartCount, setCartCount] = useState(0);

  const pathParts = location.pathname.split("/");
  const playTestParam = pathParts[pathParts.length - 1];

  const navigate = useNavigate();
  const [isAllCoursesDropdownVisible, setAllCoursesDropdownVisible] =
    useState(false);
  const [isTestSeriesDropdownVisible, setTestSeriesDropdownVisible] =
    useState(false);
  const [isNotesBooksDropdownVisible, setNotesBooksDropdownVisible] =
    useState(false);
  const [myCourses, setMyCourses] = useState(false);
  const [selectedOption, setSelectedOption] = useState("All Courses");
  const [testSeriesOption, setTestSeriesOption] = useState("Test Series");
  const [notesBooks, setNotesBooks] = useState("Notes & Books");
  const [showNav, setShowNav] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showList, setShowList] = useState(false);
  const [loginbutton, setLoginButton] = useState(true);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setLoginButton(false);
    }
  }, []);

  // Dropdown option select
  const handleOptionSelect = (event) => {
    setSelectedOption(event.target.innerHTML);
  };
  const handleClick = () => {
    setShowNav(!showNav);
    setMenuOpen(!menuOpen);
    document.body.classList.toggle("humburger");
    document.body.parentElement.classList.toggle("overflow-hidden");
  };

  const show = () => {
    setShowList(!showList);
  };
  const logout = () => {
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    localStorage.removeItem("mobile");
    localStorage.removeItem("id");
    window.location.reload(false);
  };
  const AllCourseMouseEnter = () => {
    setAllCoursesDropdownVisible(true);
  };

  const AllCourseMouseLeave = () => {
    setAllCoursesDropdownVisible(false);
  };
  const TestSeriesMouseEnter = () => {
    setTestSeriesDropdownVisible(true);
  };

  const TestSeriesMouseLeave = () => {
    setTestSeriesDropdownVisible(false);
  };
  const NotesBooksMouseEnter = () => {
    setNotesBooksDropdownVisible(true);
  };

  const NotesBooksMouseLeave = () => {
    setNotesBooksDropdownVisible(false);
  };
  const onMouseEnterMyCourses = () => {
    setMyCourses(true);
  };
  const onMouseLeaveMyCourses = () => {
    setMyCourses(false);
  };

  // useEffect(() => {
  //   setCartCount(props.cartcount);
  // }, [props.cartcount]);
  const [showInput, setShowInput] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [error, setError] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (searchInput.trim() === "") {
      setSuggestions([]);
      setIsSearching(false);
      return;
    }
    if (searchInput.length >= 3) {
      function filterCourse() {
        const body = {
          keyword: searchInput,
        };
        ApiCall(body, "post", `search_content`, filtercoursecallback);
        setError("");
      }
      setIsSearching(true);
      filterCourse();
    } else {
      setError("No Data Found");
    }
    if (searchInput.length > 0) {
      setIsSearching(true);
    }
  }, [searchInput]);

  const filtercoursecallback = useCallback((response) => {
    if (response.data.status === true) {
      setSuggestions(response.data.data);
    } else {
      setSuggestions([]);
      console.log("Error fetching suggestions:");
    }
  }, []);

  const RouteChange = () => {
    if (searchInput.trim() === "") {
      setError("Search field cannot be empty");
      setSuggestions([]);
      return;
    }

    navigate("/search-result?keyword=" + searchInput, {
      state: { course: searchInput },
    });
  };
  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    RouteChange();
    if (inputRef.current) {
      inputRef.current.focus(); // Focus on the input field
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion.name);
    setSuggestions([]);
    RouteChange();
    // navigate("/search-result?keyword=" + searchInput, {
    //   state: { course: searchInput },
    // });
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      const isOutsideInput = !e.target.closest(".nav_input");
      const isOutsideIcon = !e.target.closest(".fa-search");
      setIsSearching(false);
      setSuggestions([]);
      if (isOutsideInput && isOutsideIcon) {
        setShowInput(true);
        setTimeout(() => {
          setSearchInput("");
        }, 1000);
      }
    };
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <div className="position-relative">
      {isSearching && <div className="gray-overlay"></div>}
      {playTestParam === "play-test" ? null : (
        <>
          <div className="custom_container container  z-3">
            <nav className="mt-2 d-flex justify-content-between align-items-center align-items-md-end position-relative">
              <Link className="d-md-none" to="/">
                <img
                  className="dhurina_logo position-relative"
                  src={Image_URL + "website/footer_logo.png"}
                  alt="Logo"
                />
              </Link>
              <div className="d-flex align-items-end mb-3 mb-md-0 d-none d-md-flex">
                <Link to="/">
                  <img
                    className="dhurina_logo"
                    src={Image_URL + "website/footer_logo.png"}
                    alt="Logo"
                  />
                </Link>
                {showInput ? (
                  <>
                    <div
                      className="Menu"
                      onMouseEnter={AllCourseMouseEnter}
                      onMouseLeave={AllCourseMouseLeave}
                    >
                      <button className="ms-lg-5 ms-4 border-0 border_oval bg-transparent px-2 py-1 position-relative test-series-dropdown MenuDropdown mb-1 fs-normal fs-20 ff_inter fw-semi-bold">
                        All Courses
                      </button>
                      <div
                        className={`pt-2 position-absolute top-100 z-3 start-0 AllCoursesDropdown  w-100 ${
                          isAllCoursesDropdownVisible ? "" : "d-none"
                        }`}
                      >
                        <div className={` bg_light_orange rounded-5 py-2`}>
                          <AllCoursesDropdown
                            setAllCoursesDropdownVisible={
                              setAllCoursesDropdownVisible
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="Menu"
                      onMouseEnter={TestSeriesMouseEnter}
                      onMouseLeave={TestSeriesMouseLeave}
                    >
                      <button className="ms-lg-4 ms-3 border-0 border_oval bg-transparent px-2 py-1 position-relative test-series-dropdown MenuDropdown mb-1 fs-normal fs-20 ff_inter fw-semi-bold">
                        Test Series
                      </button>
                      <div
                        className={`pt-2 position-absolute top-100 z-3  start-0 AllCoursesDropdown w-100 ${
                          isTestSeriesDropdownVisible ? "" : "d-none"
                        }`}
                      >
                        <div className={` bg_light_orange rounded-5  py-2`}>
                          <TestSeriesDropdown
                            setTestSeriesDropdownVisible={
                              setTestSeriesDropdownVisible
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      onMouseEnter={NotesBooksMouseEnter}
                      onMouseLeave={NotesBooksMouseLeave}
                      className="Menu"
                    >
                      <button className="ms-lg-4 ms-3 border-0 border_oval bg-transparent px-2 py-1 position-relative test-series-dropdown MenuDropdown mb-1 fs-normal fs-20 ff_inter fw-semi-bold">
                        Notes & Books
                      </button>
                      <div
                        className={`pt-2 position-absolute top-100 z-3  start-0 AllCoursesDropdown w-100 ${
                          isNotesBooksDropdownVisible ? "" : "d-none"
                        }`}
                      >
                        <div className={` bg_light_orange rounded-5 py-2`}>
                          <NotesBooksDropdown
                            setNotesBooksDropdownVisible={
                              setNotesBooksDropdownVisible
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="ms-lg-4 ms-3 ">
                  <form
                    className={`gap-5 d-flex align-items-center position-relative justify-content-between ${
                      isSearching ? "input-higher-z-index" : ""
                    }`}
                    onSubmit={handleSubmit}
                  >
                    {showInput ? (
                      ""
                    ) : (
                      <input
                        ref={inputRef}
                        className="py-2 px-3 nav_input border-0 cursor_pointer"
                        type="text"
                        placeholder="Search your Best Courses, Tests & Books..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    )}
                    <button
                      className="bg-transparent border-0"
                      type="submit"
                      onClick={() =>
                        showInput ? setShowInput() : handleSubmit
                      }
                    >
                      <i
                        className="fa fa-search fs-4 text_gradient cursor_pointer"
                        aria-hidden="true"
                      ></i>
                    </button>
                    {showInput ? (
                      " "
                    ) : (
                      <>
                        <ul className="bg-white ul_box position-absolute position_data rounded-4 mt-3">
                          {searchInput.length >= 3 &&
                          suggestions.length <= 0 ? (
                            <>no data found </>
                          ) : (
                            <>
                              {suggestions.map((suggestion, index) => (
                                <button
                                  // onClick={() =>
                                  //   handleSuggestionClick(suggestion)
                                  // }
                                  onClick={() => {
                                    if (suggestion.type === "test_series") {
                                      navigate(
                                        `/test-series/${suggestion.name.replace(
                                          /\//g,
                                          " "
                                        )}/${suggestion.id}`
                                      );
                                    } else if (suggestion.type === "book") {
                                      navigate(
                                        `/book-detail/${suggestion.name.replace(
                                          /\//g,
                                          " "
                                        )}/${suggestion.id}`
                                      );
                                    } else if (suggestion.type === "course") {
                                      navigate(
                                        `/course-detail/${suggestion.name.replace(
                                          /\//g,
                                          " "
                                        )}/${suggestion.id}`
                                      );
                                    }
                                    window.scrollTo(0, 0);
                                  }}
                                  className="border-0 bg-transparent mt-2 text-start"
                                  key={index}
                                >
                                  <p
                                    // onClick={() =>
                                    //   setSearchInput(suggestion.name)
                                    // }
                                    className="ff_inter fw-semibold color_dark_blue fs-6l mb-0"
                                  >
                                    {suggestion.name.replace(/\//g, " ")}
                                  </p>
                                </button>
                              ))}
                            </>
                          )}
                        </ul>
                        {error.length > 1 ? (
                          ""
                        ) : (
                          <>
                            <p className="text-danger position-absolute position_data mb-0 ff_inter ms-4 mt-1">
                              {error}
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </form>
                </div>
              </div>

              <div className="d-flex align-items-center  justify-content-center mt-3 mt-md-0 justify-content-md-end">
                {/* <a href="#">
                  <Image src={Addtocart} alt="dhurina app image" /> */}
                {/* <span className="addnumb">{cartCount}</span> */}
                {/* </a> */}

                <div className="d-flex border_gradient px-3 py-1 d-none d-lg-flex  mx-lg-3">
                  <a href="https://wa.me/917404960061">
                    <button className="text_gradient ff_inter border-0 fw-semi-bold fs-sm ">
                      Whatsapp
                    </button>
                    <img
                      className="ms-2 whatsapp_logo"
                      src={Image_URL + "website/whatsapp_icon.png"}
                      alt="WhatsappLogo"
                    />
                  </a>
                </div>
                {loginbutton === true ? (
                  <button
                    onClick={() => {
                      setLoginPopup(true);
                      document.body.parentElement.classList.toggle(
                        "overflow-hidden"
                      );
                    }}
                    className="ms-0 btn_login text-white ff_inter bg_gradient cursor_pointer border-0 p-2 px-lg-3 py-lg-2"
                  >
                    Sign Up/Login
                  </button>
                ) : (
                  <div
                    className="position-relative z_5"
                    onMouseEnter={onMouseEnterMyCourses}
                    onMouseLeave={onMouseLeaveMyCourses}
                  >
                    <button className="ms-0 btn_login text-white ff_inter bg_gradient cursor_pointer border-0 p-2 px-4 px-md-5 py-lg-2">
                      {localStorage.getItem("name")
                        ? localStorage.getItem("name")
                        : "User"}
                    </button>
                    {myCourses ? (
                      <div
                        id="dropdown-content"
                        className="dropdown-content d-flex flex-column w-100 position-absolute top-100 text-center"
                      >
                        <a
                          className="text-nowrap bg_light_orange px-3 py-2 border cursor_pointer border-1  details-content "
                          onClick={() => {
                            setMyCourses(false);
                            navigate("/my-courses");
                          }}
                        >
                          My Courses
                        </a>
                        <a
                          className="text-nowrap bg_light_orange px-3 py-2 border cursor_pointer border-1  details-content "
                          onClick={() => {
                            setMyCourses(false);
                            navigate("/my-test-Series");
                          }}
                        >
                          My Test Series
                        </a>
                        <a
                          onClick={() => {
                            logout();
                            setMyCourses(false);
                          }}
                          className="text-nowrap bg_light_orange px-3 py-2 cursor_pointer border border-1  details-content"
                        >
                          Logout
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {/* Hamburger toggler  */}
                <div
                  onClick={handleClick}
                  className={`hamburger-menu d-flex flex-column d-md-none ms-4 ${
                    menuOpen ? "open" : ""
                  }`}
                >
                  <span className="menu_1"></span>
                  <span className="menu_2"></span>
                  <span className="menu_3"></span>
                </div>
              </div>
            </nav>
            <div
              className={
                showNav
                  ? "navShow w-100 d-md-none"
                  : "width_0 overflow-hidden custom_top end-0 position-absolute"
              }
            >
              <ul className="list_style_none d-flex flex-column mt-5 vh_70">
                <Link to="/" onClick={handleClick}>
                  <li className="d-flex align-items-center mt-3">
                    <img
                      src={
                        Image_URL +
                        "website/navbar-whatsapp-icon/navbar-home-icon.png"
                      }
                      alt="home_icon"
                    />
                    <p className="ff_inter fw-semi-bold color_dark_blue mb-0 ms-2 fs-5">
                      Home
                    </p>
                  </li>
                </Link>
                {/* <li
                  className="d-flex cursor_pointer align-items-center mt-3"
                  onClick={show}
                >
                  <img
                    src={Image_URL + "website/svg/ul-exam.svg"}
                    alt="exam_icon"
                  />
                  <p className="ff_inter fw-semi-bold color_dark_blue mb-0 ms-2 fs-5">
                    Select Exam
                  </p>
                </li> */}
                {/* {showList && (
                  <ul className="ps-5 ms-5" style={{ listStyleType: "disc" }}>
                    <Link to="/ssc" onClick={handleClick}>
                      <li className="ff_inter fw-semi-bold color_dark_blue">
                        SSC
                      </li>
                    </Link>

                    <Link to="/bpsc" onClick={handleClick}>
                      <li className="ff_inter fw-semi-bold color_dark_blue">
                        BPSC
                      </li>
                    </Link>
                    <li className="ff_inter fw-semi-bold color_dark_blue">
                      BANKING
                    </li>
                    <li className="ff_inter fw-semi-bold color_dark_blue">
                      RAILWAY
                    </li>
                  </ul>
                )} */}
                <Link to="/latest-test-series" onClick={handleClick}>
                  <li className="d-flex align-items-center mt-3">
                    <img
                      src={
                        Image_URL +
                        "website/navbar-whatsapp-icon/navbar-test-series-icon.png"
                      }
                      alt="test_icon"
                    />
                    <p className="ff_inter fw-semi-bold color_dark_blue mb-0 ms-2 fs-5">
                      Test Series
                    </p>
                  </li>
                </Link>
                <Link to="/all-book" onClick={handleClick}>
                  <li className="d-flex align-items-center mt-3">
                    <img
                      src={
                        Image_URL +
                        "website/navbar-whatsapp-icon/navbar-test-series-icon.png"
                      }
                      alt="test_icon"
                    />
                    <p className="ff_inter fw-semi-bold color_dark_blue mb-0 ms-2 fs-5">
                      Books
                    </p>
                  </li>
                </Link>
                <Link to="/all-course" onClick={handleClick}>
                  <li className="d-flex align-items-center mt-3">
                    <img
                      src={
                        Image_URL +
                        "website/navbar-whatsapp-icon/navbar-test-series-icon.png"
                      }
                      alt="test_icon"
                    />
                    <p className="ff_inter fw-semi-bold color_dark_blue mb-0 ms-2 fs-5">
                      Courses
                    </p>
                  </li>
                </Link>

                <form
                  className="gap-2 d-flex align-items-center mt-3 gap-3"
                  onSubmit={handleSubmit}
                >
                  <input
                    className="py-2 px-3 nav_input1 border-0"
                    type="text"
                    placeholder="Search your Best Courses, Tests & Books..."
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />

                  <button className="bg-transparent border-0" type="submit">
                    <i
                      className="fa fa-search fs-4 text_gradient cursor_pointer"
                      aria-hidden="true"
                    ></i>
                  </button>
                </form>
              </ul>
              <a href="https://wa.me/917404960061">
                <div className="d-flex col-6 mx-auto bg_gradient align-items-center justify-content-center p-2">
                  <p className="text-white ff_inter border-0 fs-sm mb-0">
                    {" "}
                    Whatsapp{" "}
                  </p>
                  <img
                    className="ms-2 whatsapp_logo"
                    src={
                      Image_URL +
                      "website/navbar-whatsapp-icon/navbar-whatsapp-icon.png"
                    }
                    alt="WhatsappLogo"
                  />
                </div>
              </a>
            </div>
          </div>

          {/* Login Popup */}
          <div className={loginPopup ? "blur" : ""}></div>
          {loginPopup ? (
            <div className="position-fixed top-50 start-50 translate-middle bg-white login_popup w-100 vh_100 px-4 py-2 overflow-auto">
              <Login setLoginPopup={setLoginPopup} />
            </div>
          ) : (
            ""
          )}
          <div className="dropdown-overlay h-100 position-absolute w-100 bg_light_orange z-2 d-none rounded-3"></div>
        </>
      )}
    </div>
  );
};

export default Header;
