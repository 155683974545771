/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import ApiCall from "../../api/callApi";

const COURSE_AREA_IMAGE_URL =
  process.env.REACT_APP_Bucket_URL + "course/areas/";
const EXAM_CATEGORY_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "exam/";

var page = 1;

const AllCoursesDropdown = ({ setAllCoursesDropdownVisible }) => {
  const navigate = useNavigate();
  const [courseAreas, setCourseAreas] = useState([]);
  const [examCategories, setExamCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  function getData(params) {
    ApiCall("", "get", "home_categories", home_categories);
  }

  const home_categories = useCallback((response) => {
    setLoading(false);
    if (response.data.status === 200) {
      setCourseAreas(response.data.course_area);
      setExamCategories(response.data.exam_categories);
    } else {
      console.log("error");
    }
  });

  const getCourseData = (item) => {
    const url = item.name;
    const id = item.id;
    const stateName = url.replace(/\s/g, "-");
    setAllCoursesDropdownVisible(false);
    navigate(`/${stateName}/${id}?type=course_area`);
    window.scrollTo(0, 0);
  };
  const SubCategories = (id, sub_category) => {
    setAllCoursesDropdownVisible(false);
    navigate(`/${sub_category}/${id}?type=sub`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="px-3">
      <div className="container custom_container py-5 my-3 batches">
        <div className="h-100">
          {loading ? (
            <>
              <div className="vh_70">
                <div id="preloader">
                  <div id="loader"></div>
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="row accordion_scroll_bar">
                <div className="col-md-4 col-lg-3 vh_70 overflow-auto position-relative">
                  <Accordion className="w-75 mx-auto" defaultActiveKey="0">
                    {examCategories.map((item, index) => (
                      <Accordion.Item
                        eventKey={index.toString()}
                        key={index}
                        className="my-2"
                      >
                        <Accordion.Header>
                          <img
                            className="mx-2 mx-md-0 mx-lg-1 exam_category_logo"
                            src={`${EXAM_CATEGORY_IMAGE_URL}${item.image}`}
                            alt={item.image}
                          />
                          <p className="ff_inter fw-semi-bold fs_desc mb-0 me-2">
                            {item.name}
                          </p>
                        </Accordion.Header>

                        <Accordion.Body>
                          <ul className="ps_0">
                            {item.sub_category.map((subcategory) => (
                              <div key={subcategory.id}>
                                <li
                                  onClick={() =>
                                    SubCategories(
                                      subcategory.id,
                                      subcategory.name
                                    )
                                  }
                                  className="ff_inter cursor_pointer fw-semi-bold fs_desc text-black sub_category_link mt-1"
                                >
                                  {subcategory.name}
                                </li>
                              </div>
                            ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
                <div className="col-md-8 col-lg-9 mt-4">
                  <div className="row mt-3">
                    {courseAreas.map((item, index) => (
                      <div className="w-20 text-center" key={index}>
                        <div
                          className="mt-1 cursor_pointer"
                          onClick={() => getCourseData(item)}
                        >
                          <img
                            className="batches_icon"
                            src={`${COURSE_AREA_IMAGE_URL}${item.image}`}
                            alt={item.image}
                          />
                          <p className="ff_inter fs_desc color_light_black fw-semi-bold lh-sm mt-1">
                            {item.name}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllCoursesDropdown;
