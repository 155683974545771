import React from "react";
import { ResourceData } from "../../include/helper/Helper";
import { Link } from "react-router-dom";
const Resources = () => {
  return (
    <div className="bg_gradient ">
      <div className="custom_container container py-5">
        <h2 className="ff_inter fw-bold text-white text-center">
          Free Resources
        </h2>
        <div className="row justify-content-center my-5">
          {ResourceData.map((item, index) => {
            return (
              <div
                key={index}
                className="col-4 col-lg-2 mt-4 mt-lg-0 text-center"
              >
                <Link to={item.url}>
                  <img
                    className="w-50 cursor_pointer"
                    src={item.image}
                    alt={item.title}
                  />
                </Link>
                <p className="mb-0 mt-2 text-white fs_xxl fw-semi-bold">
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Resources;
