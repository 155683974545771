import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Image,
  Col,
  Row,
  Button,
  Navbar,
  ListGroup,
  FormControl,
} from "react-bootstrap";
import Mobilhomeicon from "../../assets/img/home.png";
import Loginbtn from "../headercommonlink/loginbtn";
import Switchicon from "../headercommonlink/switchicon";
import Toplogo from "../headercommonlink/toplogo";
import Addtocart from "../../assets/img/add-to-cart.png";

const Innerheader = (props) => {
  const [cartCount, setCartCount] = useState(props.cartcount);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const RouteChange = (e) => {
    navigate("/search-result?keyword=" + searchInput, {
      state: { course: searchInput },
    });
  };

  const searchRecord = (e) => {
    if (e.key === "Enter") {
      navigate("/search-result?keyword=" + searchInput, {
        state: { course: searchInput },
      });
    }
  };

  useEffect(() => {
    setCartCount(props.cartcount);
  }, [props.cartcount]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  return (
    <>
      <header className="navbar-area">
        <Navbar
          expand="lg"
          fixed="top"
          className={
            "innerHeader d-none d-lg-block " + (scroll ? "darkHeader" : "")
          }
        >
          <Container className="nav-container">
            <div className="logo">
              <Toplogo></Toplogo>
            </div>
            <Navbar.Collapse id="themefie_main_menu">
              <div className="single-input-wrap">
                <FormControl
                  type="text"
                  className="d-inline-block"
                  placeholder="Search your best courses"
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={(e) => searchRecord(e)}
                />
                <Button
                  variant="headersearchbtn"
                  onClick={(e) => RouteChange(e)}
                >
                  <i class="fa fa-search"></i>
                </Button>
              </div>
              <ListGroup as="ul" className="navbar-nav menu-open text-end">
                <ListGroup.Item as="li">
                  <Link to="/#">
                    <Image src={Mobilhomeicon} alt="dhurina app image" />
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  <Switchicon></Switchicon>
                </ListGroup.Item>
                <ListGroup.Item as="li" className="position-relative">
                  <a href="#" onClick={props.openModal}>
                    <Image src={Addtocart} alt="dhurina app image" />
                    <span className="addnumb">{cartCount}</span>
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </Navbar.Collapse>
            <div className="nav-right-part nav-right-part-desktop">
              <ListGroup as="ul">
                <ListGroup.Item as="li">
                  <Loginbtn></Loginbtn>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Container>
        </Navbar>

        <Navbar
          expand="lg"
          fixed="top"
          className="d-block d-lg-none mob-header mob-headercourseinner"
        >
          <Container className="nav-container d-block">
            <Row className="align-items-center">
              <Col xs={6}>
                <Button variant="mobile-icon">
                  <span></span>
                </Button>
              </Col>

              <Col xs={5} className="text-end pt-1">
                <Link to="/">
                  <Image
                    src={Mobilhomeicon}
                    alt="dhurina app image"
                    width="20px"
                  />
                </Link>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </header>
      {/* <Outlet /> */}
    </>
  );
};

export default Innerheader;
