import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import ApiCall from "../../api/callApi";
const COURSE_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "ebook/image";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const FreeEbook = ({ setLoginPopup }) => {
  const [searchInput, setSearchInput] = useState("");
  const [error, setError] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [testSeries, setTestSeries] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filterSerach = searchParams.get("filter");
  const type = searchParams.get("type");
  const navigate = useNavigate();

  useEffect(() => {
    if (searchInput.trim() === "") {
      setSuggestions([]);
      setIsSearching(false);
      return;
    }
    if (searchInput.length >= 3) {
      function filterCourse() {
        const body = {
          keyword: searchInput,
        };
        ApiCall(body, "post", `search_content`, filtercoursecallback);
        setError("");
      }
      filterCourse();
    } else {
      setError("No Data Found");
    }
    if (searchInput.length > 0) {
      setIsSearching(true);
    }
  }, [searchInput]);

  const filtercoursecallback = useCallback((response) => {
    if (response.data.status === true) {
      setSuggestions(response.data.data);
    } else {
      setSuggestions([]);
      console.log("Error fetching suggestions:");
    }
  }, []);

  const RouteChange = () => {
    if (searchInput.trim() === "") {
      setError("Search field cannot be empty");
      setSuggestions([]);
      return;
    }
    navigate("/search-result?keyword=" + searchInput, {
      state: { course: searchInput },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    RouteChange();
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion.name);
    setSuggestions([]);
  };
  useEffect(() => {
    const handleDocumentClick = (e) => {
      setIsSearching(false);
    };
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const [latestTestSeries, setLatestTestSeries] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    getTestseries("");
  }, []);

  function getData() {
    ApiCall("", "get", "home_categories", home_categories);
  }

  const home_categories = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setLatestTestSeries(response.data.latest_sub_category);
    } else {
      console.log("error");
    }
  });

  function getTestseries(id) {
    const body = {
      sub_category: id,
      type: "ebooks",
    };
    // let type = "ebooks";
    ApiCall(body, "post", `free_test_ebooks`, test_series);
  }

  const test_series = useCallback((response) => {
    setMainLoading(false);
    if (response.status === 200) {
      setTestSeries(response.data.data);
      // console.log(response.data.data);
    } else {
      console.log("error");
    }
  });
  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }

  // const SingleBook = (title, id) => {
  //   // Replace "/" with " " in the input string
  //   const modifiedString = title.replace(/\//g, " ");
  //   navigate(`/book-detail/${modifiedString}/${id}`);
  //   window.scrollTo(0, 0);
  // };

  window.scrollTo(0, 0);
  const handleLatestTestSeriesClick = (id) => {
    getTestseries(id);
  };

  const handleImageClick = (fileUrl) => {
    // You can use the `download` attribute to force a download
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "filename"; // You can set the desired filename
    link.click();
  };

  return (
    <>
      <div className="custom_container container py-5">
        <h2 className="fw-medium text-center ff_inter fs_2xl mb-0">
          Precision Prep, Future Success:<br></br>
          <span className="fw-bold text_gradient">
            Elevate Your Journey with
          </span>
        </h2>
        <div className="d-flex justify-content-center">
          <form onSubmit={handleSubmit}>
            <div
              className={`d-flex bg-white mx-auto position-relative mx-lg-0 px-3 py-sm-2 stady_input mt-5 align-items-center ${
                isSearching ? "input-higher-z-index" : ""
              }`}
            >
              <input
                className="border-0 bg-transparent pt-1 w-100 ff_inter fs-6"
                type="text"
                placeholder="Search your Best Courses, Tests & Books..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button
                type="submit"
                className="mt-0 d-sm-block d-none bg_gradient text-white border-0 rounded-pill px-3 py-1 px-lg-4 fs_xl"
              >
                Search
              </button>
              <i
                className="fa d-sm-none d-block fa-search fs-4 text_gradient cursor_pointer"
                aria-hidden="true"
              ></i>
            </div>
            <ul className="bg-white ul_box position-relative rounded-4 mt-3">
              {searchInput.length >= 3 && suggestions.length <= 0 ? (
                <>no data found </>
              ) : (
                <>
                  {suggestions.map((suggestion, index) => (
                    <button
                      className="border-0 bg-transparent mt-2 text-start"
                      type="submit"
                      key={index}
                      onClick={() => {
                        if (suggestion.type === "test_series") {
                          navigate(
                            `/test-series/${suggestion.name.replace(
                              /\//g,
                              " "
                            )}/${suggestion.id}`
                          );
                        } else if (suggestion.type === "book") {
                          navigate(
                            `/book-detail/${suggestion.name.replace(
                              /\//g,
                              " "
                            )}/${suggestion.id}`
                          );
                        } else if (suggestion.type === "course") {
                          navigate(
                            `/course-detail/${suggestion.name.replace(
                              /\//g,
                              " "
                            )}/${suggestion.id}`
                          );
                        }
                        window.scrollTo(0, 0);
                      }}
                    >
                      <p className="ff_inter fw-semibold color_dark_blue fs-6l mb-0">
                        {suggestion.name}
                      </p>
                    </button>
                  ))}
                </>
              )}
            </ul>

            <p className="text-danger mb-0 ff_inter ms-4 mt-1">{error}</p>
          </form>
        </div>
        <div className="pt-4">
          <h2 className=" fs_2xl text-center fw-semibold ff_inter">
            Popular Ebooks
          </h2>
        </div>
        <div className="d-flex flex-wrap align-items-center mt-4">
          {latestTestSeries.map((obj, i) => {
            return (
              <>
                <button
                  key={i}
                  onClick={() => handleLatestTestSeriesClick(obj.id)}
                  className="category_bg mx-1 mt-2"
                >
                  {obj.name}
                </button>
              </>
            );
          })}
        </div>
        <div className="row mt-2  justify-content-between">
          {/* <Hero display={true} Hero={HeroBpsc} /> */}
          {/* <LiveClasses display={false} classes={classes} /> */}
          <div className="pt-5">
            {testSeries.length === 0 ? (
              <p className="mb-0 ff_inter fs-3 fw-semibold text-black mt-3">
                No Data Found.
              </p>
            ) : (
              <>
                <div className="ebook_box mt-4">
                  <div className="ebook_bg px-0 py-3 text-center">
                    <h4 className="mb-0 text-white fw-bold ff_inter fs-6">
                      Free Ebooks PDF
                    </h4>
                  </div>
                  {testSeries?.map((item, i) => {
                    return (
                      <>
                        <div key={i} className="col">
                          <div className="d-flex gap-4 px-3 align-items-center my-3 justify-content-between">
                            <h2 className="ff_inter fw-bolder fs-6 text_gradient mb-0 ">
                              {item.title}
                            </h2>
                            {localStorage.getItem("token") ? (
                              <a width="30" target="_blank" href={item.file}>
                                <img
                                  className="cursor-pointer"
                                  width={30}
                                  src="https://cdn-icons-png.flaticon.com/512/3580/3580075.png"
                                  alt=""
                                />
                              </a>
                            ) : (
                              <img
                                onClick={() => {
                                  setLoginPopup(true);
                                  document.body.parentElement.classList.toggle(
                                    "overflow-hidden"
                                  );
                                }}
                                className="cursor-pointer"
                                width={30}
                                src="https://cdn-icons-png.flaticon.com/512/3580/3580075.png"
                                alt=""
                              />
                            )}
                          </div>
                          <div className="border_orange"></div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            )}
            {/* <div className="d-flex justify-content-center">
              {testSeries.length > 2 ? (
                <button
                  className="ff_inter fw-semi-bold text-white fs_md bg_gradient border-0 mt-4 mb-3 mb-lg-0  py-2 px-4"
                  onClick={() => {
                    setPageCount(pageCount + 1);
                  }}
                >
                  View More &nbsp;&nbsp; &rarr;
                </button>
              ) : (
                ""
              )}
            </div> */}
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default FreeEbook;
