import React from "react";
import Innermobheader from "./innermobHeader";
import Innertopmenu from "./innerTopMenu";

function Schoolheader() {
  return (
    <>
      <header className="navbar-area">
        <Innertopmenu></Innertopmenu>
        <Innermobheader></Innermobheader>
      </header>
    </>
  );
}

export default Schoolheader;
