import React from 'react';
import { Image } from 'react-bootstrap';
const Image_URL = process.env.REACT_APP_Bucket_URL;

function Applink () {
  return (
    <>
      <a href="https://play.google.com/store/apps/details?id=com.dhurina" target="_blank" rel="noreferrer" className="mt- mb-3 d-inline-block me-3"><Image alt="dhurina app image" src={Image_URL+"img/google-play-button.png"} /></a>
      <a href="https://apps.apple.com/in/app/dhurina/id1479858722" target="_blank" rel="noreferrer"><Image alt="dhurina app image" src={Image_URL+"img/app-store-button.png"} /></a>
    </>
  );
}

export default Applink;
