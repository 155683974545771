import React from "react";
import Register from "../../components/statedata/Register";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ApiCall from "../../api/callApi";
import { useCallback } from "react";
const COURSE_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "course/";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const TrendingCourses = () => {
  const [pageCount, setPageCount] = useState(1);
  const [allCourse, setAllCourse] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const navigate = useNavigate();
  const [examCategories, setExamCategories] = useState([]);
  const [subcategory, setSubCategory] = useState('');
  useEffect(() => {
    const body = {
      page: pageCount,
      trending: true,
    };
    ApiCall(body, "post", "all_courses", category_data);
  }, [pageCount]);

  const category_data = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setAllCourse(response.data.data.data);
    } else {
      console.log("error");
    }
  });

  useEffect(() => {
    console.log(pageCount);
  }, [pageCount]);
  window.scrollTo(0, 0);
  const [searchKeyword, setSearchKeyword] = useState("");
  let url = "search_course";
  const body = {
    name: searchKeyword,
  };

  useEffect(() => {
    if (searchKeyword) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  function getData() {
    ApiCall(body, "post", url, filterCourseCallback);
  }

  const filterCourseCallback = useCallback((response) => {
    if (response.data.status === 200) {
      setAllCourse(response.data.course);
    } else {
      console.log("error");
    }
  }, []);

  useEffect(() => {
    ApiCall("", "get", "home_categories", category_data1);
  }, [pageCount]);

  const category_data1 = useCallback((response) => {
    // setMainLoading(false);
    if (response.data.status === 200) {
      // console.log(response);
      setExamCategories(response.data.exam_categories);
    } else {
      console.log("error");
    }
  });

  useEffect(() => {
    const body = {
      type: "sub_category",
      type_id: subcategory,
    };

    if (subcategory) {
      ApiCall(body, "post", "all_data", subcategoryData);
    }
  }, [subcategory]);

  const subcategoryData = useCallback((response) => {
    if (response.data.status === 200) {
      setAllCourse(response.data.courses);
      // console.log(response);
    } else {
      console.log("error");
    }
  });
  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }
  return (
    <>
      <div className="custom_container container py-5">
        <h3 className="mb-0 text_gradient fw-bold">Our Trending Courses</h3>
        <div className="row mt-2 border-end justify-content-between">
          {/* <Hero display={true} Hero={HeroBpsc} /> */}
          {/* <LiveClasses display={false} classes={classes} /> */}
          <div className="col-lg-8 mt-3">
            <div className="row ">
              {allCourse.length === 0 ? (
                <p className="mb-0 ff_inter fs-3 fw-semibold text-black mt-3">
                  No Data Found.
                </p>
              ) : (
                allCourse?.map((obj, i) => {
                  return (
                    <div
                      onClick={() => {
                        navigate(`/course-detail/${obj.course_url}/${obj.id}`);
                        window.scrollTo(0, 0);
                      }}
                      className="p-2  col-md-6"
                    >
                      <div
                        key={i}
                        className="border_light_brown h-100 d-flex pb-3 flex-column justify-content-between cursor_pointer"
                      >
                        <div>
                          <div className="d-flex px-0 align-items-center justify-content-center">
                            <img
                              className="courses_image"
                              src={`${COURSE_IMAGE_URL}${obj.image}`}
                              alt={obj.name}
                            />
                          </div>
                          <h2 className="ff_inter fw-bolder fs-6 text_gradient mb-0 px-4 mt-3">
                            {obj.name}
                          </h2>
                        </div>

                        <div className="mt-3 d-flex align-items-center justify-content-between px-4 pb-3">
                          <div>
                            <span className="mb-0 text_gradient fw-bold mb-0">
                              ₹{obj.price}{" "}
                            </span>
                            {/* <span className="fs_desc text_grey ff_inter text-decoration-line-through mb-0">
                      {item.discount}
                    </span> */}
                          </div>
                          <div className="coupon_bg px-2">
                            <p className="mb-0 ff_inter fw-bold fs_sm text-black">
                              <span className="fs_xsm fw-semi-bold ms-1 text-black">
                                {obj.teacher}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
              <div className="d-flex mt-4 justify-content-between">
                <>
                  {/* {allCourse.length > 0 ? (
                    <button
                      className="ff_inter fw-semi-bold text-white fs_md bg_gradient border-0 mt-3 mb-3 mb-lg-0  py-2 px-4"
                      onClick={() => {
                        setPageCount(pageCount + 1);
                      }}
                    >
                      View More &nbsp;&nbsp; &rarr;
                    </button>
                  ) : (
                    ""
                  )} */}
                  {pageCount > 1 && (
                    <button
                      className="ff_inter fw-semi-bold text-white fs_md bg_gradient border-0 mt-3 mr-3 mb-lg-0 py-2 px-4"
                      onClick={() => {
                        setPageCount(pageCount - 1);
                      }}
                    >
                      &larr; Previous
                    </button>
                  )}
                  {allCourse.length > 0 && (
                    <button
                      className="ff_inter fw-semi-bold text-white fs_md bg_gradient border-0 mt-3 mb-lg-0 py-2 px-4"
                      onClick={() => {
                        setPageCount(pageCount + 1);
                      }}
                    >
                      Next &rarr;
                    </button>
                  )}
                </>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-2 ">
            <Register
              setSearchKeyword={setSearchKeyword}
              examCategories={examCategories}
              setSubCategory={setSubCategory}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TrendingCourses;
