import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const Register = ({
  setSearchKeyword,
  examCategories,
  setSubCategory,
  skill,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [stateData, setStateData] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchInput) {
      setSearchKeyword(searchInput);
    }
  };
  useEffect(() => {
    if (examCategories && examCategories.length >= 0) {
      setStateData(examCategories[0]);
    }
  }, [examCategories]);

  return (
    <div className="">
      <div className="bg_register p-4">
        <h2 className="text_gradient ff_inter fw-semi-bold fs-4 mb-3">
          Filter Search
        </h2>
        <div className="position-relative mb-3">
          <form onSubmit={handleSubmit}>
            <button
              type="submit"
              className="position-absolute fliterSearchBtn border-0 px-3 py-2 top-50 translate-middle-y end-0 me-2 ff_inter"
            >
              Search
            </button>

            <input
              className="border-0 fliterSearchInput p-3"
              placeholder="Search Exam Name"
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </form>
        </div>

        {examCategories ? (
          <>
            {" "}
            <div className="accordion_scroll_bar1 mt-4 position-relative">
              <h5 className="ff_inter fw-bolder position-sticky mb-0 w-100 p-2 top-0 choose_button fs-6 text-white">
                Choose Exam Category
              </h5>
              <div className="register_box  accordion_custom ">
                <div className="custom_hight1 px-3 pb-3 pt-2">
                  {examCategories &&
                    examCategories.map((item, index) => {
                      const isActive = item === stateData;
                      return (
                        <>
                          <div
                            className="d-flex align-items-center gap-2"
                            key={index}
                          >
                            <input
                              type="radio"
                              id={`radio-${index}`}
                              name="examCategory"
                              value={item.name}
                              checked={isActive}
                              onChange={() => setStateData(item)}
                            />
                            <label
                              onClick={() => setStateData(item)}
                              className={`ff_inter cur fw-semi-bold cursor_pointer fs_desc mb-0 me-2 mt-1 ${isActive ? "text_gradient" : ""
                                }`}
                              htmlFor={`radio-${index}`}
                            >
                              {item.name}
                            </label>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="accordion_scroll_bar1 mt-4 position-relative">
              <h5 className="ff_inter fw-bolder mb-0 position-sticky w-100 p-2 top-0 choose_button fs-6 text-white">
                Choose Exam
              </h5>
              <div className="register_box  accordion_custom ">
                <div className="custom_hight1 px-3 pb-3 pt-2">
                  {stateData &&
                    stateData.sub_category?.map((item, index) => {
                      return (
                        <div key={index}>
                          <p
                            onClick={() => setSubCategory(item.id)}
                            className="ff_inter cur fw-semi-bold cursor_pointer fs_desc mb-0 me-2 mt-1 "
                          >
                            {item.name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {skill ? (
              <div className="accordion_scroll_bar1 mt-4 position-relative">
                <h5 className="ff_inter fw-bolder position-sticky mb-0 w-100 p-2 top-0 choose_button fs-6 text-white">
                  Choose Category
                </h5>
                <div className="register_box  accordion_custom ">
                  <div className="custom_hight1 px-3 pb-3 pt-2">
                    {skill &&
                      skill.map((item, index) => {
                        const isActive = item === stateData;
                        return (
                          <>
                            <div
                              onClick={() => setSubCategory(item.id)}
                              className="d-flex align-items-center gap-2"
                              key={index}
                            >
                              <input
                                type="radio"
                                id={`radio-${index}`}
                                name="examCategory"
                                value={item.name}
                                checked={isActive}
                                onChange={() => setStateData(item)}
                              />
                              <label
                                onClick={() => setStateData(item)}
                                className={`ff_inter cur fw-semi-bold cursor_pointer fs_desc mb-0 me-2 mt-1 ${isActive ? "text_gradient" : ""
                                  }`}
                                htmlFor={`radio-${index}`}
                              >
                                {item.name}
                              </label>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        )}

        <h2 className="text_gradient ff_inter fw-semi-bold fs-4 mb-3 mt-3">
          Quick Links
        </h2>
        <div>
          <Link
            to="/course-detail/rajasthan-patwar-new-live-batch-by-subhash-charan-sir-2023-b15/6290"
            className="mb-0 ff_inter text_grey fs_desc cursor_pointer"
          >
            Rajasthan Patwar New Live Batch
          </Link>
        </div>
        <div>
          <Link
            to="/course-detail/rajasthan-geography-rajasthan-history-indian-history-special-maharana-new-live-batch-by-subhash-charan-sir-2023-b15/6314"
            className="mb-0 mt-2 ff_inter text_dark_grey fs_desc cursor_pointer"
          >
            Rajasthan Geography + Rajasthan History + Indian History (Special
            Maharana/महाराणा) New Live Batch
          </Link>
        </div>
        <div>
          <Link
            to="/course-detail/rajasthan-gk-new-live-batch-by-subhash-charan-sir-b15/6325"
            className="mb-0 ff_inter text_dark_grey fs_desc cursor_pointer"
          >
            Rajasthan GK New Live Batch
          </Link>
        </div>
        <div>
          <Link
            to="/course-detail/ssc-gd-new-live-from-classroom-batch-by-vipin-sir-2023/6323"
            className="mb-0 ff_inter text_dark_grey fs_desc cursor_pointer"
          >
            SSC GD New ( Live From Classroom)
          </Link>
        </div>
        <div>
          <Link
            to="/course-detail/complete-math-live-class-liverecorded-batch-by-vipin-sir/6302"
            className="mb-0 ff_inter text_dark_grey fs_desc cursor_pointer"
          >
            Complete Math Live Class (Live+Recorded) रक्षाबंधन
          </Link>
        </div>
        <div>
          <Link
            to="/course-detail/htet-pgt-level-3-recorded-batch-by-pardeep-sir-2023/6321"
            className="mb-0 ff_inter text_dark_grey fs_desc cursor_pointer"
          >
            HTET (PGT Level 3) Recorded Batch
          </Link>
        </div>
        <div>
          <Link
            to="/course-detail/htet-tgt-level-2-recorded-batch-by-pardeep-sir-2023/6319"
            className="mb-0 ff_inter text_dark_grey fs_desc cursor_pointer"
          >
            HTET (TGT Level 2) Recorded Batch
          </Link>
        </div>
        <div>
          <Link
            to="/course-detail/htet-new-recorded-batch-complete-course-by-pardeep-sir-and-team/1737"
            className="mb-0 ff_inter text_dark_grey fs_desc cursor_pointer"
          >
            HTET (PRT Level 1) Recorded Batch
          </Link>
        </div>
        <div>
          <Link
            to="/course-detail/reet-level-i-pre-mains-new-live-batch-by-subhash-charan-team-2023-b15/6274"
            className="mb-0 ff_inter text_dark_grey fs_desc cursor_pointer"
          >
            REET Level - I (Pre + Mains) New Live Batch
          </Link>
        </div>
        <div>
          <Link
            to="/course-detail/reet-level-ii-science-pre-mains-new-live-batch-by-subhash-charan-team-2023-b15/6278"
            className="mb-0 ff_inter text_dark_grey fs_desc cursor_pointer"
          >
            REET Level - II Science (Pre + Mains) New Live Batch
          </Link>
        </div>
      </div>

      <div className=" mt-3 col-md-6 col-lg-12">
        {/* <h2 className="text-white ff_inter fw-bold fs_5xl">BPSC CRACK</h2>
        <p className="mb-0 text-white ff_inter fs_4xl crack_bpsc_para">
          krna Hua Bahut Aasan..
        </p>
        <button className="fw-semi-bold ff_inter fs_sm mt-2 register_btn position-relative border-0 py-1 px-3">
          REGISTER
        </button> */}
        <Link to="/free-study-material">
          <img
            className=""
            src={Image_URL + "website/Poster1.png"}
            alt="bpsc_crack"
          />
        </Link>
      </div>
    </div>
  );
};

export default Register;
