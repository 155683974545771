import React, {useState, useEffect, useCallback} from 'react';
import {useParams } from "react-router-dom";
import { Table,Button, Container, Col, Row, Image, Breadcrumb, Form } from 'react-bootstrap';
import SchoolRegisterHeader from '../../include/header/schoolRegisterHeader';
import Footer from '../../include/footer/footer';
import Applink from '../../include/applink/googleApple';
import MobappImage from './../../assets/img/mob-app.png';
import ApiCall from '../../api/callApi';
// import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import { Helmet } from 'react-helmet';





const SchoolDetail = () => {

const  printDocument= ()=> {
    //const input = document.getElementById('divToPrint');
    
        // const doc = new jsPDF();
        
        //get table html
        const pdfTable = document.getElementById('divToPrint');
        //html to pdf format
        var html = htmlToPdfmake(pdfTable.innerHTML);
        
        const documentDefinition = { content: html };
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.createPdf(documentDefinition).download();
        
    }

    const [refstatus,setRefStatus]=useState(true)
    const [schoolname,setSchoolName]=useState('')
    const [mobile,setMobile]=useState('')
    const [state,setStateName]=useState('')
    const [email,setEmail]=useState('')
    const [city,setCity]=useState('')
    const [refferal,setRefferal]=useState('')
    const [address,setAddress]=useState('')

    let params = useParams();

    let url='school_detail/'+params.schoolId
    
    useEffect(() => {
        getData()
    },[getData]);



    function getData(){
        ApiCall("","get",url,schooldetail);
    }

    function handleSubmitForm(e){
        e.preventDefault();
        const body={
            'id':params.schoolId,
            'ref_id':refferal,
        }
        ApiCall(body,"post",'update_ref',refferalupdate);
   }

   const refferalupdate = useCallback(response => 
    {
    if(response.data.status==='true'){
        setRefStatus(false)
        getData();
    }
    else{
                    
    }
},[])

    
    const schooldetail = useCallback(response => 
        {
        if(response.data.status===200){      
			setSchoolName(response.data.data.schoolname)
            setEmail(response.data.data.email)
            setMobile(response.data.data.number)
            setStateName(response.data.data.statename)
            setCity(response.data.data.cityname)
            setAddress(response.data.data.address)
            setRefferal(response.data.data.direct_id)
        }
        else{
                        
        }
    },[])


  return (
    <>
       <SchoolRegisterHeader></SchoolRegisterHeader> 

       <Helmet>
                <title>Dhurina - School Detail</title>
                <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
                <link rel="canonical" href="https://dhurina.net/fetch-canonical-url" />
                <meta name="title" content="fetch title of the content" />
                <meta name="description" content="fetch description of the content" />
                <meta name="publish-date" content="2022-04-22T11:24:28+05:30" />
                <meta name="created-date" content="2022-04-22T11:24:28+05:30" />
                <meta name="modified-date" content="2022-04-22T11:24:29+05:30" />
                <meta name="twitter:description" content="fetch description of the content"/>
                <meta name="twitter:image" content="https://dhurina.net/fetch-image-url"/>
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="fetch title of the content" />
                <meta name="twitter:site" content="@Dhurinalive" />
                <meta name="twitter:creator" content="@Dhurinalive" />
                <meta name="twitter:app:id:googleplay" content="com.dhurina" />
                <meta name="twitter:app:id:iphone" content="1479858722" />
                <meta name="twitter:app:name:googleplay" content="Dhurina - Learning Made Easy" />
                <meta name="twitter:app:name:ipad" content="Dhurina - Learning Made Easy" />
                <meta name="twitter:app:name:iphone" content="Dhurina - Learning Made Easy" />
                <meta name="twitter:domain" content="dhurina.net" />
                <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
                <meta name="og:image:type" content="image/jpeg" />
                <meta property="og:description" content="fetch description of the content" />
                <meta property="og:image" content="https://dhurina.net/fetch-image-url" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="fetch title of the content" />
                <meta property="og:url" content="URL of Course" />
                <meta property="og:site_name" content="Dhurina" />
                <meta property="og:updated_time" content="2022-01-28T13:36:39+05:30" />
                <meta property="article:published_time" content="2022-04-22T05:54:28.000Z" />
                <meta property="article:modified_time" content="2022-04-22T05:54:29.000Z" />
                <meta property="article:section" content="Fetch Category" />
                <meta property="article:publisher" content="https://www.facebook.com/dhurinalive" />
                <meta property="article:author" content="https://fb.com/dhurinalive" />
                <meta property="article:section" content="Online-Course" />
                <meta property="al:android:url" content="https://dhurina.net/fetch-canonical-url" />
                <meta property="al:android:app_name" content="Dhurina - Learning Made Easy" />
                <meta property="al:android:package" content="com.dhurina" />
                <meta property="fb:app_id" content="facebook-app-id-in-number" />
                <meta property="fb:pages" content="facebook-page-in-number" />
            </Helmet>
         
        
        <section className="breadcrumb-area pb-4 banner-area innerpagesbanner">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={12} className="align-self-center">
                        <Breadcrumb classNameName="mb-1">
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>School Detail</Breadcrumb.Item>
                        </Breadcrumb>
                        <Row>
                            <Col md={12}>
                                
                                <Row>
                                    <Col md={12}>
                                     <button class="btn btn-primary" style={{flaot:'right'}} onClick={()=>printDocument()}>Export To PDF</button>
                                    </Col>
                                   
                                </Row>
                                <div id="divToPrint">
                               
                                    <h3 className="mb-0 f-25mob">School Detail</h3> 
                                    <Table striped bordered hover>
                                    <thead>
                                            <tr>
                                            <th>School Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Address</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Refferal Id</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                    
                                            <tr>
                                            <td>{schoolname}</td>
                                            <td>{email}</td>
                                            <td>{mobile}</td>
                                            <td>{address}</td>
                                            <td>{city}</td>
                                            <td>{state}</td>
                                            <td>{refferal}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                               
                            </Col> 
                       
                            {refstatus &&
                            <Row>
                                <form onSubmit={(e)=>handleSubmitForm(e)}>
                                <Col md={6}>
                                    <div className="mb-2">
                                        <Form.Label className="f-12 fw-medium mb-1">Refferal Code<span className="text-danger">*</span></Form.Label>
                                        <div className="login-form">
                                            <Form.Control type="text" placeholder="" value={refferal} onChange={(e)=>setRefferal(e.target.value)}/>
                                            <i className="fa fa-lock"></i>
                                        </div> 
                                    </div>  
                                </Col>
                                <Col md={6}>
                                  <Button variant="base-light" className="w-100 mt-4 btn-block loginshadowmain" type="submit">Update
                                    </Button>
                                </Col>
                                </form>
                            </Row>
                            }

                            <Col md={6}>
                                <a href="https://admin.dhurina.net/login">
                                <Button variant="base-light" className="w-100 mt-4 btn-block btn btn-base-light btn-login" type="button">Login School Here
                                </Button>
                                </a>
                            </Col>
                        
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
        
        
        <section className="trending-courses-area py-5">
            <Container>
                <Row>
                    <Col lg={12}>
                    </Col>
                </Row>
            </Container>
        </section>
        
        
        <section className="pt-3 map-bg bg-theme-red">
            <Container> 
                <Row className="justify-content-center align-items-center"> 
                    <Col md={3}>
                        <Image alt="dhurina app image" src={MobappImage} />
                    </Col>
                    <Col md={6} className="downloadappbtnmob mb-4 schoolinner-mob-app"> 
                        <div className="section-title mb-4"> 
                            <h2 className=" text-start text-white pb-2">Download Dhurina App</h2>
                            <h5 className="text-white fw-medium f-22">Get Amazing Offers, Free Notes and scholarship</h5>
                        </div> 
                        <Applink></Applink>
                    </Col> 
                </Row>
            </Container>
        </section>
        
        <Footer></Footer>
    </>
  );
}

export default SchoolDetail;
