/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ApiCall from "../../api/callApi";

const TEST_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "combo/";

const Test = ({ display, testSeries }) => {
  const { id } = useParams();
  const { stateName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filterSerach = searchParams.get("filter");
  const type = searchParams.get("type");

  const viewAllTests = () => {
    if (type === "sub") {
      navigate(`/${stateName}/${id}/all-test-series?type=sub`);
      window.scrollTo(0, 0);
    } else {
      navigate(`/${stateName}/${id}/all-test-series`);
      window.scrollTo(0, 0);
    }
  };

  const stateTests = [];
  const filteredTests = testSeries.filter((test, index) => {
    if (display && (index === 0 || index === 1)) {
      stateTests.push(test);
    } else if (!display) {
      stateTests.push(test);
    }
  });

  return (
    <div id="tests" className="custom_container container py-5">
      {display ? (
        <div className="d-flex justify-content-between align-content-center">
          <h2 className="text_gradient ff_inter fw-bold fs_4xl">Test Series</h2>
          <span
            onClick={() => {
              viewAllTests();
            }}
            className={`ff_inter fw-semibold text_gradient mb-0 cursor_pointer ${
              testSeries.length <= 2 ? "disabled" : ""
            }`}
          >
            View All <span>&rarr;</span>{" "}
          </span>
        </div>
      ) : (
        ""
      )}
      <div className="row">
        {testSeries.length === 0 ? (
          <p className="mb-0 ff_inter mt-3">No Test Series available.</p>
        ) : (
          stateTests.map((test, index) => (
            <div
              onClick={() => {
                navigate(`/test-series/${test.title}/${test.id}`);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              key={index}
              className="col-md-6 mt-4"
            >
              <div className="border_light_brown cursor_pointer h-100">
                <img
                  className="img-fluid"
                  src={`${TEST_IMAGE_URL}${test.image}`}
                  alt="bpsc_logo "
                />
                <div className="ms-2 mt-3 mb-0">
                  <h2 className="ff_inter fw-bolder fs-6 text_gradient mb-0">
                    {test.title}
                  </h2>
                </div>
                <div className="mt-3 d-flex align-items-center justify-content-between px-3 pb-3">
                  <div>
                    <span className="mb-0 text_gradient fw-bold mb-0">
                      ₹{test.price}{" "}
                    </span>
                    <span className="fs_desc text_grey ff_inter text-decoration-line-through mb-0">
                      {test.discount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Test;
