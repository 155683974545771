// import React from 'react';
// import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Navbar, Container, ListGroup, FormControl, Button } from 'react-bootstrap';
// import Innermobheader from './innermobHeader';
// import Loginbtn from '../headercommonlink/loginbtn';
// import Switchicon from '../headercommonlink/switchicon';
// import Toplogo from '../headercommonlink/toplogo';
// import Homelink from '../headercommonlink/homelink';

// function Neetheader () {

//     const [scroll, setScroll] = useState(false);
//     useEffect(() => {
//     window.addEventListener("scroll", () => {
//         setScroll(window.scrollY > 100);
//     });
//     }, []);

//   return (
//     <>
//        <header className="navbar-area">

//             <Navbar expand="lg" fixed="top" className={"innerHeader d-none d-lg-block " + (scroll ? "darkHeader" : "")}>
//                 <Container className="nav-container">
//                     <div className="logo">
//                         <Toplogo></Toplogo>
//                     </div>

//                     <Navbar.Collapse className="test-seriesbanner" id="themefie_main_menu">
//                         <div className="single-input-wrap" style={{minWidth: '370px'}}>
//                             <FormControl type="text" className="d-inline-block" placeholder="Search your best courses" />
//                             <Button variant="headersearchbtn"><i class="fa fa-search"></i></Button>
//                         </div>
//                         <ListGroup as="ul" className="navbar-nav menu-open text-end">
//                             <ListGroup.Item as="li">
//                                 <Homelink></Homelink>
//                             </ListGroup.Item>
//                             <ListGroup.Item as="li" className="menu-item-has-children">
//                                 <Link to="/">Entrance Courses <i class="fa fa-angle-down"></i></Link>
//                                 <ListGroup as="ul" className="sub-menu">
//                                     <ListGroup.Item as="li"><Link to="/">UPSEC Test Series</Link></ListGroup.Item>
//                                     <ListGroup.Item as="li"><Link to="/">JEE Test Series</Link></ListGroup.Item>
//                                 </ListGroup>
//                             </ListGroup.Item>
//                             <ListGroup.Item as="li" className="menu-item-has-children">
//                                 <a href="https://dhurina.net/en/blog/" target="_blank" rel="noreferrer">Blog</a>
//                             </ListGroup.Item>
//                             <ListGroup.Item as="li">
//                                 <Switchicon></Switchicon>
//                             </ListGroup.Item>
//                         </ListGroup>
//                     </Navbar.Collapse>
//                     <div className="nav-right-part nav-right-part-desktop">
//                         <ListGroup as="ul">
//                             <ListGroup.Item as="li">
//                                 <Loginbtn></Loginbtn>
//                             </ListGroup.Item>
//                         </ListGroup>
//                     </div>
//                 </Container>
//             </Navbar>

//             <Innermobheader></Innermobheader>

//         </header>

//     </>
//   );
// }

// export default Neetheader;
