import React,{ useState,useEffect,useCallback} from 'react';
import { Container, Col, Row, Image} from 'react-bootstrap'; 
import { Link,useParams,useNavigate} from "react-router-dom";
import ApiCall from '../../../api/callApiCSC';
import MainLoaderImage from './../../../assets/img/loading-buffering.gif'
import VideoJS from './../VideoJsPlayer'
import Innerheader from '../../../include/header/CSCHeader';

const Livestream=()=>{

    var {id,course_id,subject_id,token}=useParams();

    const [videos,setVideos] = useState([])
    const [video,setVideo] = useState({title:"Loading...",video_url:''})
    const [hlsurl, SetHls] = useState('')

    useEffect(() => {
        getData()
    },[]);


    function getData(){
        ApiCall({id:id,course_id:course_id,subject_id:subject_id},"post","csc_get_video",courseareacallback,token);
    }

    const courseareacallback = useCallback(response =>{

        setVideos(response.data.videos);
        if(response.data.status==='true'){
            setVideo(response.data.data);
            response.data.data.video_url.map((vdata)=>{
                if (vdata.type=="application/vnd.apple.mpegurl") {
                    SetHls(vdata.file);
                }
            });
        }
    })
        


    if (hlsurl=="") {
        return(
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}><img src={MainLoaderImage} style={{ alignSelf: 'center' }}/></div>
        )
    }


  return (
    <>
        <Innerheader></Innerheader> 
        
        <section className="banner-area booksheadbanner course-detailinner">
            <Container>  
                <Row>
                    <Col xs={12}>
                        <div className="main-banner-txt banner-inner course-detailbanner">
                            <div className="section-title mb-2 mb-sm-3"> 
                                <h5 className="fw-medium f-18">{video.title}</h5>
                            </div> 
                        </div> 
                    </Col>

                    <Col sm={8} xs={12}>
                        <div className="main-banner-txt banner-inner course-detailbanner"> 
                        {hlsurl!=""?<VideoJS url={hlsurl} video={video} />:null}
                        </div> 
                    </Col>

                    <Col sm={4} xs={12}> 
                        <div className="recomdednd-section d-none d-sm-block">
                            <p>Latest Videos</p>
                            
                            {videos.length<1? <h6 className="mt-4 mb-3" style={{margin: '5%'}}>No more videos found!!</h6>:
                                videos.map((value,key)=>{
                                    return (
                                        <Link to="#" key={key} onClick={() => window.location.href="/skill-view-video-csc/"+token+"/"+value.id+"/"+course_id+"/"+subject_id}>
                                            <Row>
                                                <Col xs={4} className="pe-0" >
                                                    <div className="single-course-wrap video-bgdemo recommendclass">
                                                        <div className="thumb"> 
                                                            <Image src={`${value.thumbnail}`} alt="img" />
                                                        </div> 
                                                    </div> 
                                                </Col>
                                                <Col xs={8} className="ps-0">
                                                    <div className="single-course-wrap video-bgdemo recommendclass">
                                                        <div className="wrap-details">
                                                            <h6 className="mb-1">{value.title}</h6>
                                                        </div>
                                                    </div> 
                                                </Col>
                                            </Row>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>                    
            </Container>
        </section>

    </>
  );
}

export default Livestream;
