import React from "react";
import Hero from "../../components/statedata/Hero";
import LiveClasses from "../../components/statedata/LiveClasses";
import RecordedVideos from "../../components/statedata/RecordedVideos";
import Test from "../../components/statedata/Test";
import Books from "../../components/statedata/Books";
import Register from "../../components/statedata/Register";
import { HeroBpsc } from "../../include/helper/Helper";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ApiCall from "../../api/callApi";
import { useCallback } from "react";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const StateData = () => {
  const [classes, setClasses] = useState([]);
  const [books, setBooks] = useState([]);
  const [testSeries, setTestSeries] = useState([]);
  const [mainloading, setMainLoading] = useState(true);
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const [examCategories, setExamCategories] = useState([]);
  const [subcategory, setSubCategory] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [allCourse, setAllCourse] = useState([]);

  useEffect(() => {
    var body = {type_id: id};
    if (type === "sub") {
      body.type="sub_category"
    } else {
      body.type="course_area"
    }
    ApiCall(body, "post", "all_data", category_data);
  }, [id]);

  useEffect(() => {
    if (subcategory!='' && subcategory!=null && subcategory!=undefined) {
      ApiCall({type_id: subcategory,type:"sub_category"}, "post", "all_data", category_data);
    }
  }, [subcategory]);

  const subcategoryData = useCallback((response) => {
    if (response.data.status === 200) {
      setAllCourse(response.data.courses);
    } else {
      console.log("error");
    }
  });

  const category_data = useCallback((response) => {
    setMainLoading(false);
    if (response.data.status === 200) {
      setBooks(response.data.books);
      setTestSeries(response.data.combos);
      setClasses(response.data.courses);
    } else {
      console.log("error");
    }
  });
  const [searchKeyword, setSearchKeyword] = useState("");

  function getData() {
    let url = "search_course";
    const body = {
      name: searchKeyword,
    };
    ApiCall(body, "post", url, serachData);
  }
  const serachData = useCallback((response) => {
    // setMainLoading(false);
    if (response.data.status === 200) {
      setClasses(response.data.course);
    } else {
      console.log("error");
    }
  });
  useEffect(() => {
    if (searchKeyword) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);

  useEffect(() => {
    ApiCall("", "get", "home_categories", category_data1);
  }, [pageCount]);

  const category_data1 = useCallback((response) => {
    if (response.data.status === 200) {
      setExamCategories(response.data.exam_categories);
    } else {
      console.log("error");
    }
  });
  if (mainloading === true) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={Image_URL + "website/priloader_icon.svg"}
          style={{ alignSelf: "center" }}
        />{" "}
      </div>
    );
  }

  return (
    <>
      <div className="custom_container container py-5">
        <div className="row mt-5 justify-content-between">
          <div className="col-lg-8 border-end">
            <Hero display={true} Hero={HeroBpsc} />
            <LiveClasses display={true} classes={classes} />
            <RecordedVideos display={true} classes={classes} />
            <Test display={true} testSeries={testSeries} />
            <Books display={true} books={books} />
          </div>
          <div className="col-lg-4">
            <Register
              setSearchKeyword={setSearchKeyword}
              examCategories={examCategories}
              setSubCategory={setSubCategory}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StateData;
