/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import ApiCall from "../../api/callApi";

const COURSE_AREA_IMAGE_URL =
  process.env.REACT_APP_Bucket_URL + "course/areas/";
const EXAM_CATEGORY_IMAGE_URL = process.env.REACT_APP_Bucket_URL + "exam/";
var page = 1;

const NotesBooksDropdown = ({ setNotesBooksDropdownVisible }) => {
  const navigate = useNavigate();
  const [courseAreas, setCourseAreas] = useState([]);
  const [examCategories, setExamCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  function getData(params) {
    ApiCall("", "get", "home_categories", home_categories);
  }

  const home_categories = useCallback((response) => {
    setLoading(false);
    if (response.data.status === 200) {
      // console.log(response);
      setCourseAreas(response.data.course_area);
      setExamCategories(response.data.exam_categories);
    } else {
      console.log("error");
    }
  });

  const SubCategories = (id, sub_category) => {
    setNotesBooksDropdownVisible(false);
    navigate(`/${sub_category} ${id}/all-books`);
    window.scrollTo(0, 0);
  };
  const [notesBooksData, setNotesBooksData] = useState([]);
  const examCategoriesHasData = [];
  useEffect(() => {
    examCategories.forEach((element) => {
      element.sub_category.every((item) => {
        if (item.book_count > 0) {
          examCategoriesHasData.push(element);
          const sortedData = [...examCategoriesHasData].sort(
            (a, b) => b.sub_category.length - a.sub_category.length
          );
          setNotesBooksData(sortedData);
          return false;
        } else {
          return true;
        }
      });
    });
  }, [examCategories]);

  return (
    <div className="px-3">
      <div className="container custom_container p-5 my-3 batches">
        {loading ? (
          <>
            <div className="vh_70">
              <div id="preloader">
                <div id="loader"></div>
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="accordion_scroll_bar">
              <div className="vh_70 overflow-y-auto">
                <div className="row flex-wrap custom_height_notes_books w-100 flex-column overflow-y-auto">
                  {notesBooksData &&
                    notesBooksData.map((item, index) => (
                      <div key={index} className="my-2 col-3">
                        <div className="d-flex align-items-center ">
                          <p className="ff_inter fw-bold text_gradient notes-books-category-fs mb-0 me-2">
                            {item.name}
                          </p>
                        </div>
                        <div className="custom_scrollbar">
                          <ul className="ps-0 ">
                            {item.sub_category.map((subcategory) =>
                              subcategory.book_count > 0 ? (
                                <div key={subcategory.id}>
                                  <li
                                    onClick={() =>
                                      SubCategories(
                                        subcategory.id,
                                        subcategory.name
                                      )
                                    }
                                    className="ff_inter list-unstyled cursor_pointer fw-semi-bold notes-books-sub-category-fs text-black sub_category_link mt-1"
                                  >
                                    {subcategory.name.replace(/\//g, " ")}
                                  </li>
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotesBooksDropdown;
