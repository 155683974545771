import React, { useState, useEffect } from "react";
import InstitutesData from "../../components/institutesdata/InstitutesData";
import InstitutesAddress from "../../components/institutesdata/InstitutesAddress";

const Our_Institutes = () => {
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const instituteData = [
    {
      heading: "Gurukul Coaching Centre - Taranagar",
      address:
        "📍कोर्ट के सामने, गुरुकुल कॉलोनी, तारानगर Taranagar, Rajasthan 331304 📞 Phone No. - 78771 62267, 97823 46626",
      phoneNo: "tel:+78771 62267",
      lat: 28.668822852829553,
      lng: 75.04891330674617,
      para: "Gurukul Taranagar, led by Subhash Charan Sir & Team, offers affordable coaching for Rajasthan's competitive exams with a nominal Gurudakshina. Here we provide free resources, support underprivileged students, and cover fees for Anuprati Yojana recipients. The institution also runs a special school for children with unique needs, offering free accommodation, transportation, education, and healthcare.",
    },
    {
      heading: "Aryavart Academy - Jaipur",
      address:
        "📍 82, Jai Bharat Nagar, Gopalpura Bypass Rd, near Sultan Nagar, Santi Nagar, Gurjar Ki Thadi, Jaipur, Rajasthan 302019 📞 Phone No. - 096727 96728",
      phoneNo: "tel:+096727 96728",
      lat: 26.881048604274962,
      lng: 75.76912119325381,
      para: " Aryavart Coaching Academy in Jaipur is the ultimate destination for competitive exam preparation. With an impressive team of instructors, including renowned educators like Dr. Mukta Rao Ma’am, Subhash Charan Sir, Manoj Sir, Vijyapal Sir and Ashu Sir, students receive top-notch coaching for RAS, REET, SI, 1st and 2nd Grade exams, and more. The academy goes the extra mile, providing motivational seminars, regular mock tests, and free online resources, ensuring students receive the best possible guidance and support for their exam success.",
    },
    {
      heading: "Maths Masti Coaching Centre - Patna",
      address:
        "📍Address:- Sai Mandir, Chak Musallahpur Rd, opposite SBI ATM, Shareef Colony, Koiritola, Patna, Bihar 800004 📞 Phone No. - 9155330088",
      phoneNo: "tel:+9155330088",
      lat: 25.614421240983948,
      lng: 85.1713420180967,
      para: " Maths Masti Coaching Centre with Vipin Sir, where mathematics is not just a subject, but an exciting adventure. Vipin's genuine passion for mathematics, along with his dynamic teaching style, ensures that even the most challenging topics are made easily understandable. Here they understand the unique challenges faced by students aspiring for competitive exams. For one stop solution of competitive exams Maths Masti Coaching Centre is the perfect choice.",
    },
    {
      heading: "Gurukul Academy - Jharkhand ",
      address:
        "📍Address:- Ravi Medical Building, 3rd Floor, Opp. Block Office, PO-Ramgarh Cantt. (Jharkhand) ‪📞 Phone No. - +91 90310 04446‬",
      phoneNo: "tel:+90310 04446",
      lat: 23.61522664819189,
      lng: 85.52226050523203,
    },
    {
      heading: "SGM Gurukul Coaching Centre - Fatehabad ",
      address:
        "📍Address:- Saguna More, fatehabad thaker basti, near ranza hospital, Fatehabad, Haryana 125050 ‪📞 Phone No. - +91 98123 26299 , 74978 40047 ",
      phoneNo: "tel:+74978 40047",
      lat: 29.51594971947425,
      lng: 75.45076545092559,
      para: " Under the guidance of Pardeep Sir and his dedicated team, SGM Gurukul in Fatehabad offers affordable coaching for Haryana exams by HSSC. We provide free library access, mock tests, and study materials while organizing motivational  seminars. Remarkably, 242 students have secured offline selections through this institute.",
    },
    {
      heading: "Kodu - Hisar",
      address:
        "📍 Address - 113, Ground Floor, DSS, Kaimri- Mangali Rd, Housing Board Colony, Sector 15A, Hisar, Haryana 125001 📞 Phone No. - 097280 97234 ",
      phoneNo: "tel:+097280 97234",
      lat: 29.13347288664896,
      lng: 75.71936285092558,
      para: "Kodu Institute Hisar  is the go-to institute for coding programs, offering both online and offline classes. With a strong commitment to students' success, we provide top-notch education and a 100% job guarantee, with salary packages ranging from 2.5 lakhs to ten lakhs and beyond. Additionally, Here centre nurtures students with personality development classes, coding events, and mock interviews for comprehensive growth.",
    },
    {
      heading: "Kodu - Sirsa",
      address:
        "📍 Address - 2nd Floor, Block 6, FC Complex, Barnala Rd, adjoining Domino's Pizza, Bhagat Singh Colony, Sirsa, Haryana 125055 📞 Phone No. - 091382 56355",
      phoneNo: "tel:+091382 56355",
      lat: 29.540933146136634,
      lng: 75.04747203743322,
      para: "Kodu Sirsa Institute is the preferred choice for coding programs, offering both online and offline classes. Committed to ensuring students' success, Kodu deliver exceptional education and guarantee 100% job placement with salary packages starting from 2.5 lakhs and reaching beyond ten lakhs. Moreover, Kodu also foster students' growth through personality development sessions, coding competitions, and simulated interview experiences.",
    },
    {
      heading: "Kodu - Rohtak",
      address:
        "📍 Address - DSS - 390, 1st Floor, Delhi Road, Behind MINISO Store, Subhash Nagar, Rohtak, Haryana 124001 📞 Phone No. - 086070 00550",
      phoneNo: "tel:+086070 00550",
      lat: 28.89020779772837,
      lng: 76.60611219510498,
      para: "Rohtak's kodu Institute, is a gateway to coding programs, providing a mix of online and in-person classes. Our unwavering dedication to student achievement guarantees job placement, with competitive salary packages ranging from 2.5 lakhs to well beyond ten lakhs. Furthermore, we nurture students with personality development workshops, live projects, coding competitions, and simulated interview experiences to ensure comprehensive growth.",
    },
  ];

  useEffect(() => {
    setSelectedInstitute(instituteData[0]);
  }, []);

  const handleInstituteClick = (index) => {
    setSelectedInstitute(instituteData[index]);
  };
  window.scrollTo(0, 0);
  return (
    <>
      <div className="custom_container container py-5 my-4">
        <div className="row justify-content-between">
          <div className="col-md-4">
            <InstitutesData
              instituteData={instituteData}
              onInstituteClick={handleInstituteClick}
            />
          </div>
          <div className="col-md-7 mt-4 mt-md-0">
            <InstitutesAddress selectedInstitute={selectedInstitute} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Our_Institutes;
