import React,{useState,useEffect,forwardRef} from 'react';
import { Link,useNavigate} from "react-router-dom";
import { Navbar, Container, ListGroup, Image,Button,FormControl} from 'react-bootstrap';
import Loginbtn from '../headercommonlink/loginbtn';
import Switchicon from '../headercommonlink/switchicon';
import Toplogo from '../headercommonlink/toplogo';
const Image_URL = process.env.REACT_APP_Bucket_URL;


const  Innertopmenu =  forwardRef((props,ref) =>
{
    const navigate = useNavigate();
    const [scroll, setScroll] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [loginstatus,setLoginStatus]=useState(false)

    useEffect((props) => {
		window.addEventListener("scroll", () => {
			setScroll(window.scrollY > 100);
		});

        if(localStorage.getItem('token')!==null){
            setLoginStatus(true)
        }
    }, []); 

    const RouteChange = (e) =>{ 
       navigate('/search-result?keyword='+searchInput, { state: { course:searchInput }})        
    }

    const searchRecord = (e) =>{
        if (e.key === 'Enter') {
            navigate('/search-result?keyword='+searchInput, { state: { course:searchInput }})
        }
    }

    const logout= () =>{
       localStorage.clear();
        navigate('/login')
    }

  return (
    <> 
        <Navbar expand="lg" fixed="top" className={"innerHeader d-none d-lg-block " + (scroll ? "darkHeader" : "")}>
            <Container className="nav-container">
                <div className="logo">
                    <Toplogo></Toplogo>
                </div>
                <Navbar.Collapse id="themefie_main_menu">
                    <div className="single-input-wrap">
                        <FormControl type="text" className="d-inline-block" placeholder="Search your best courses" onChange={(e) => setSearchInput(e.target.value)} onKeyDown={(e)=>searchRecord(e)}/>
                          <Button variant="headersearchbtn" onClick = {(e)=>RouteChange(e) }  ><i class="fa fa-search"></i></Button> 
                    </div>
                    <ListGroup as="ul" className="navbar-nav menu-open text-end">  
                        <ListGroup.Item as="li"><Link to="/#"><Image src={Image_URL+"img/home.png"} alt="dhurina app image" /></Link></ListGroup.Item> 
                        <ListGroup.Item as="li"><Switchicon></Switchicon></ListGroup.Item> 
                    </ListGroup>
                </Navbar.Collapse>
                <div className="nav-right-part nav-right-part-desktop"> 
                    <Navbar.Collapse id="themefie_main_menu">
                        <ListGroup as="ul" className="navbar-nav menu-open text-end"> 
                                <ListGroup.Item as="li" className="menu-item-has-children">
                                <Loginbtn></Loginbtn>
                                {loginstatus && 
                                <ListGroup as="ul" className="sub-menu text-left" style={{width:'150px'}}>
                                    <ListGroup.Item as="li" className="logoutbtn"><button onClick={logout}>Logout</button></ListGroup.Item>
                                </ListGroup>
                                }
                            </ListGroup.Item>  
                        </ListGroup>
                    </Navbar.Collapse>
                </div>
            </Container>
        </Navbar>
    </>
  );
})


export default Innertopmenu;