import React, {useState, useEffect, useCallback} from 'react';
import { Container, Col, Row, Image, Form, Button, Alert, Spinner } from 'react-bootstrap';
import MainLogo from '../../assets/img/logo.png';
import ApiCall from '../../api/callApi';
// import PDF from './../../assets/pdf/sample.pdf';

const ExamRegister=()=>{

    
	const [name , setName] = useState('')
    const [address,setAddress]=useState('')
    const [vdorollno,setVdoRollno]=useState('')
	const [mobile , setMobile] = useState('')
    const [submitStatus,setSubmitStatus] = useState(false) 
	const [show_succ, setShowSucc] = useState(false);
	const [show_fail, setShowFail] = useState(false); 
	const [title , setTitle] = useState('')
	const [message , setMessage] = useState('')

	
   
    useEffect(() => {
        
    },[]);


	function handleSubmitForm(e,name,mobile,address,vdorollno){
        e.preventDefault();
        var data={
            "mobile":mobile,
            "name":name,
            "address":address,
            "vdorollno":vdorollno
        }
		setSubmitStatus(true)
		ApiCall(data,"post","exam_register",examregistercallback);
       
    }

	
	const examregistercallback = useCallback(response => 
		{
			setTitle(response.data.title)
			setMessage(response.data.message)
			if(response.data.status==='true'){
                setSubmitStatus(false)
                setShowFail(false)
				setShowSucc(true)
			}
			else{
				setShowFail(true)
				setShowSucc(false)
			}
			 setSubmitStatus(false)
		},[])
  return (
	

	
    <form onSubmit={(e)=>handleSubmitForm(e,name,mobile,address,vdorollno)}>
	 <Container fluid className="login-bg minheightlogin">
	
		<div>
			<div className="row">
				<Row  className="align-items-center">
				    
					<Col md={12}>
						<div className="p-3 p-sm-5 my-2 m-sm-5 bg-white loginboxshadow">
                        <p>
                            प्री-पेपर में आपकी उत्कृष्ट सफलता के लिए बधाई और आपके वीडीओ मेन्स परीक्षा के लिए शुभकामनाएं। मुझे पूरा विश्वास है कि आप अपनी Main's परीक्षा अच्छे अंकों के साथ पास करेंगे।
                            जैसा कि हमने टॉप 100 परफॉर्मर को मुफ्त वीडीओ मेन्स कोर्स और सभी पास होने वाले स्टूडेंट्स को वीडीओ मेन्स टेस्ट सीरीज देने का वादा किया था। हम उस दिशा में काम कर रहे हैं और इसके लिए हमें आपकी जानकारी की जरूरत है।
                        </p>
                       
                        <p>
                            विजेताओं का चयन 2 आधार पर किया जाएगा।
							<p>1. जिन छात्रों ने अपनी वीडीओ प्री परीक्षा उत्तीर्ण की है।</p>
							<p>2. छात्र के वीडीओ प्री पास के डेटा की तुलना विजेता टेस्ट से की जाएगी और उसी के आधार पर टॉप 100 का चयन किया जाएगा।</p>
                        </p>
                       
                        <p>
                         इसका लाभ उठाने के लिए कृपया नीचे दिया गया फॉर्म भरें।
                        </p>
                            <div style={{textAlign:'center'}}>
                                <Image src={MainLogo} alt="img" className="mb-2" />
                            </div>
							
							{/* <h3 className="f-25 mt-4 mb-4">Create New Account!<br /><span className="text-muted f-18">Join Dhurina to accelerate your Skills.</span></h3> */}

								<div className="row">
									<Col md={12}>
										<div className="mb-2">
											<Form.Label className="f-12 fw-medium mb-1">Name <span className="text-danger">*</span></Form.Label>
											<div className="login-form">
												<Form.Control type="text" placeholder="Enter your Full Name" onChange={(e)=>setName(e.target.value)} required/>
												<i className="fa fa-user-circle"></i>
											</div> 
										</div>  
									</Col>
									
									<Col md={12}>
										<div className="mb-2">
											<Form.Label className="f-12 fw-medium mb-1">Mobile No <span className="text-danger">*</span></Form.Label>
											<div className="login-form">
												<Form.Control type="number" min="0" placeholder="Enter your dhurina registered  mobile No "  onChange={(e)=>setMobile(e.target.value)} required/>
												<i className="fa fa-mobile-alt"></i>
											</div> 
										</div>  
									</Col>
									<Col md={12}>
										<div className="mb-2">
											<Form.Label className="f-12 fw-medium mb-1">Address</Form.Label>
											<div className="login-form">
												<Form.Control type="text" placeholder="Enter your address"  onChange={(e)=>setAddress(e.target.value)} required/>
												<i className="fa fa-envelope-open-text"></i>
											</div> 
										</div>  
									</Col>
                                    <Col md={12}>
										<div className="mb-2">
											<Form.Label className="f-12 fw-medium mb-1">VDO PRE-PAPER ROLL NUMBER</Form.Label>
											<div className="login-form">
												<Form.Control type="text" placeholder="Enter your VDO PRE-PAPER ROLL NUMBER"  onChange={(e)=>setVdoRollno(e.target.value)} required/>
												<i className="fa fa-envelope-open-text"></i>
											</div> 
										</div>  
									</Col>
								</div>
							
							{/* <div className="single-form-check form-check loginrember">
								<Form.Control className="form-check-input p-0" type="checkbox"  id="Accept" checked={checkstatus}  onChange={(e)=>setCheckStatus(!checkstatus)}/>
								<Form.Label className="form-check-label" htmlFor="Accept">I Accept the <Link to="/#" className="fw-bold" >Terms And Conditions</Link></Form.Label>
							</div> */}

                            <Alert variant="success"  show={show_succ} onClose={() => setShowSucc(false)} dismissible className="px-3 py-2 f-13">
                                    <Alert.Heading className="f-13 mb-0">
                                        <i className="fas fa-check me-2"></i><strong>{title}</strong>
                                    </Alert.Heading>
                                    {message}
                            </Alert>
							<Alert variant="danger"  show={show_fail} onClose={() => setShowFail(false)} dismissible className="px-3 py-2 f-13">
								<Alert.Heading className="f-13 mb-0">
									<i className="fas fa-exclamation-triangle me-2"></i><strong>{title}</strong>
								</Alert.Heading>
								{message}
							</Alert>

							<Button variant="base-light" className="w-100 mt-4 btn-block loginshadowmain" type="submit">
								{submitStatus?<Spinner as="span" animation="border" size="sm" />:<i className="far fa-paper-plane"> Register </i> }
							</Button>&nbsp;&nbsp;
							<div>
						
							</div>
							
							{/* <p className="f-12 text-center mt-4 text-muted">
								Already have an account? <a href="https://admin.dhurina.net/login" className="fw-bold f-13">Log In</a> 
							</p>  */}
						</div>  
					</Col> 
				</Row> 
			</div>

			
		</div>
	</Container>
	
	

	</form>
  );
}

export default ExamRegister;

    
    
	
     
     
      