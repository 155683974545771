import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Innerheader from "../../include/header/innerHeader";
import Footer from "../../include/footer/footer";
import { Helmet } from "react-helmet";

const About = () => {
  var page_url = window.location.href;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Innerheader></Innerheader> */}

      <Helmet>
        <title>About Us - Dhurina.net</title>
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link rel="canonical" href={page_url} />
        <meta name="title" content="About Us - Dhurina.net" />
        <meta
          name="description"
          content="This article discusses everything about Dhurina, why you should choose Dhurina, and the problems Dhurina solves for students."
        />
        <meta
          name="twitter:description"
          content="This article discusses everything about Dhurina, why you should choose Dhurina, and the problems Dhurina solves for students."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - Dhurina.net" />
        <meta name="twitter:site" content="@Dhurinalive" />
        <meta name="twitter:creator" content="@Dhurinalive" />
        <meta name="twitter:app:id:googleplay" content="com.dhurina" />
        <meta name="twitter:app:id:iphone" content="1479858722" />
        <meta
          name="twitter:app:name:googleplay"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:ipad"
          content="Dhurina - Learning Made Easy"
        />
        <meta
          name="twitter:app:name:iphone"
          content="Dhurina - Learning Made Easy"
        />
        <meta name="twitter:domain" content="https://dhurina.net" />
        <meta name="twitter:google-play-app" content="app-id=com.dhurina" />
        <meta
          property="og:description"
          content="This article discusses everything about Dhurina, why you should choose Dhurina, and the problems Dhurina solves for students."
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="About Us - Dhurina.net" />
        <meta property="og:url" content={page_url} />
        <meta property="og:site_name" content="Dhurina" />
        <meta property="article:section" content="online-course" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/dhurinaclass"
        />
        <meta
          property="article:author"
          content="https://www.facebook.com/dhurinaclass"
        />
        <meta property="article:section" content="Online-Course" />
        <meta property="al:android:url" content={page_url} />
        <meta
          property="al:android:app_name"
          content="Dhurina - Learning Made Easy"
        />
        <meta property="al:android:package" content="com.dhurina" />
        <meta property="fb:app_id" content="facebook-app-id-in-number" />
        <meta property="fb:pages" content="facebook-page-in-number" />
      </Helmet>
      <section>
        <div className="custom_container container pb-5">
          <Row>
            <Col md={12} className="mt-5">
              <div className="section-title lefth2border">
                <h2 className="text-start">About Us</h2>
              </div>

              <div>
                <p>
                  Dhurina is an e-learning mobile app that connects students to
                  expert teachers and provides hundreds of live classes taught
                  by brilliant instructors. Our main aim is to provide quality
                  education at affordable prices to all the state-level
                  government job aspirants of the country with a limelight on
                  Tier-III and rural students.
                </p>

                <p>
                  Dhurina is working to spread education from the biggest cities
                  to the smallest villages of the country.{" "}
                </p>

                <p>
                  Within a time span of two years only, we have expanded in six
                  big states of the country, covering a student-base of 4
                  million+ students and are working to cover PAN-India soon.
                </p>
              </div>

              <div>
                <h4>Problems we solve:</h4>
                <p>Provide quality content at pocket-friendly prices.</p>
                <p>Provide education to rural area students.</p>
                <p>Cut-off extra living expenses of migration.</p>
                <p>
                  A one-stop solution to take care of your preparations of
                  competitive exams from zero level.
                </p>
              </div>

              <div>
                <h4>Why Choose Dhurina?</h4>
                <h6>
                  We provide you a one stop solution for your competitive exam
                  preparations. We provide:
                </h6>
                <p>- Interactive Live Sessions</p>
                <p>- Recorded Video Lectures</p>
                <p>- Pdf Notes</p>
                <p>- E-Books</p>
                <p>- Mock tests</p>
                <p>- E-papers</p>
                <p>- And much more...</p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      {/* <Footer></Footer> */}
    </>
  );
};

export default About;
