import React from "react"; 
import { Col, Row, Image } from 'react-bootstrap'; 
import { Link} from "react-router-dom";

const Video=({video})=>{

    return(<Col md={3}>
        <Link to={"/view-video/"+video.id+"/"+video.course_id+"/"+video.subject_id}>
            <div className="single-course-wrap video-bgdemo">
                <div className="thumb"> 
                    <Image src={`${video.thumbnail}`} alt="img" />
                </div>
                <div className="wrap-details">  
                    <Row className="align-items-center">
                        <Col xs={12}> 
                            <p className="f-13 text-black">{video.title}</p>
                        </Col>
                    </Row>
                </div>
            </div> 
        </Link>
    </Col>)

}

export default Video;
