import React from "react";
import { ExploreProducts } from "../../include/helper/Helper";
import { Link } from "react-router-dom";

const Products = () => {
  window.scrollTo(0, 0);
  return (
    <div className="custom_container container py-5">
      <h2 className="ff_inter fw-bold color_dark_blue">
        Explore By <span className="text_gradient"> Product </span>
      </h2>
      <div className="row mt-3">
        {ExploreProducts.map((item, index) => {
          return (
            <div key={index} className="col-lg-4 col-sm-6 mt-4">
              <div className="product_box h-100 d-flex align-items-center justify-content-between px-3 py-4">
                <div>
                  <p className="color_dark_blue ff_inter fw-semi-bold fs_desc mb-0">
                    {item.title}
                  </p>
                  <p className="ff_inter fw-bold color_dark_blue fs-6 mb-0">
                    {item.description}
                  </p>
                  {item.url === "https://dhurina.net/en/" ? (
                    <a href={item.url} target="_blank">
                      {" "}
                      <button className="ff_inter fw-semi-bold text-white fs_xsm bg_gradient border-0 mt-2 py-2 px-3">
                        Explore
                      </button>
                    </a>
                  ) : (
                    <Link to={item.url ? item.url : ""}>
                      {" "}
                      <button className="ff_inter fw-semi-bold text-white fs_xsm bg_gradient border-0 mt-2 py-2 px-3">
                        Explore
                      </button>
                    </Link>
                  )}
                </div>
                <img className="w-50" src={item.image} alt={item.description} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Products;
