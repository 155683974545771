import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiCall from "../../api/callApi";
const Image_URL = process.env.REACT_APP_Bucket_URL;

const Hero = () => {
  const [searchInput, setSearchInput] = useState("");
  const [error, setError] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  // console.log(Image_URL);
  const navigate = useNavigate();
  useEffect(() => {
    if (searchInput.trim() === "") {
      setSuggestions([]);
      setIsSearching(false);
      return;
    }
    if (searchInput.length >= 3) {
      function filterCourse() {
        const body = {
          keyword: searchInput,
        };
        ApiCall(body, "post", `search_content`, filtercoursecallback);
        setError("");
      }
      filterCourse();
    } else {
      setError("No Data Found");
    }
    if (searchInput.length > 0) {
      setIsSearching(true);
    }
  }, [searchInput]);

  const filtercoursecallback = useCallback((response) => {
    if (response.data.status === true) {
      setSuggestions(response.data.data);
    } else {
      setSuggestions([]);
      console.log("Error fetching suggestions:");
    }
  }, []);

  const RouteChange = () => {
    if (searchInput.trim() === "") {
      setError("Search field cannot be empty");
      setSuggestions([]);
      return;
    }
    navigate("/search-result?keyword=" + searchInput, {
      state: { course: searchInput },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    RouteChange();
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchInput(suggestion.name);
    setSuggestions([]);

    // Determine the type of suggestion and navigate accordingly
  };
  useEffect(() => {
    const handleDocumentClick = (e) => {
      setIsSearching(false);
    };
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  // console.log(suggestions);
  return (
    <>
      {isSearching && <div className="gray-overlay"></div>}
      <div className="custom_container container">
        <div className="row flex-row-reverse justify-content-center mt-5 pt-5">
          <div className="col-md-5 text-center text-md-start position-relative">
            <img
              className="position-absolute hero_ellipse_1"
              src={Image_URL + "website/hero_ellipse_2.png"}
              alt="Ellipse1"
            />
            <img
              className="position-absolute hero_ellipse_2 "
              src={Image_URL + "website/hero_ellipse_1.png"}
              alt="Ellipse2"
            />

            <img
              className="w-100 hero_img"
              src={Image_URL + "website/hero_img1.png"}
              alt="HeroImg"
            />
          </div>
          <div className="col-md-7 col-11 mt-4 text-center text-md-start">
            <h2 className="ff_inter fw-semibold color_dark_blue fs_11xl mb-0">
              Get direction to
            </h2>
            <p className="text_gradient ff_inter fs_11xl fw-bold mb-0 text-lg-nowrap">
              Prepare for your exam
            </p>
            <p className="ff_inter color_dark_blue fs_7xl">
              Watch anywhere, anytime.
            </p>
            <form onSubmit={handleSubmit}>
              <div
                className={`d-flex bg-white mx-auto position-relative mx-lg-0 px-3 py-sm-2 hero_input mt-2 align-items-center ${
                  isSearching ? "input-higher-z-index" : ""
                }`}
              >
                <input
                  className="border-0 bg-transparent pt-1 w-100 ff_inter fs_md"
                  type="text"
                  placeholder="Search your Best Courses, Tests & Books..."
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <button
                  type="submit"
                  className="mt-0 d-sm-block d-none bg_gradient text-white border-0 rounded-pill px-3 py-1 px-lg-4 fs_xl"
                >
                  Search
                </button>
                <i
                  className="fa d-sm-none d-block fa-search fs-4 text_gradient cursor_pointer"
                  aria-hidden="true"
                ></i>
              </div>
              <ul className="bg-white ul_box position-relative rounded-4 mt-3">
                {searchInput.length >= 3 && suggestions.length <= 0 ? (
                  <>no data found </>
                ) : (
                  <>
                    {suggestions.map((suggestion, index) => (
                      <button
                        className="border-0 bg-transparent mt-2 text-start"
                        type="submit"
                        key={index}
                        // onClick={() => setSearchInput(suggestion.name)}
                        onClick={() => {
                          if (suggestion.type === "test_series") {
                            navigate(
                              `/test-series/${suggestion.name.replace(
                                /\//g,
                                " "
                              )}/${suggestion.id}`
                            );
                          } else if (suggestion.type === "book") {
                            navigate(
                              `/book-detail/${suggestion.name.replace(
                                /\//g,
                                " "
                              )}/${suggestion.id}`
                            );
                          } else if (suggestion.type === "course") {
                            navigate(
                              `/course-detail/${suggestion.name.replace(
                                /\//g,
                                " "
                              )}/${suggestion.id}`
                            );
                          }
                          window.scrollTo(0, 0);
                        }}
                      >
                        <p className="ff_inter fw-semibold color_dark_blue fs-6l mb-0">
                          {suggestion.name.replace(/\//g, " ")}
                        </p>
                      </button>
                    ))}
                  </>
                )}
              </ul>

              <p className="text-danger mb-0 ff_inter ms-4 mt-1">{error}</p>
            </form>
            <p className="text-danger mb-0 ff_inter ms-4 mt-1">{error}</p>
            <div className="mt-4 pt-sm-2">
              <a
                href="https://play.google.com/store/apps/details?id=com.dhurina"
                target="_blank"
                rel="noreferrer"
                className="mb-3 d-inline-block me-3"
              >
                <img
                  className="store_icon"
                  alt="Google Play"
                  src={Image_URL + "website/google-play.png"}
                />
              </a>
              <a
                href="https://apps.apple.com/in/app/dhurina/id1479858722"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="store_icon"
                  alt="App Store"
                  src={Image_URL + "website/app-store.png"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
