import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Image, Col, Row, Button, Navbar } from "react-bootstrap";
import Mobilhomeicon from "../../assets/img/logo.png";

const CSCHeader = () => {
  const navigate = useNavigate();

  const backFunction = (e) => {
    navigate(-1);
  };

  return (
    <>
      <header className="navbar-area">
        <Navbar
          expand="lg"
          fixed="top"
          className="d-block d-lg-none mob-header mob-headercourseinner"
        >
          <Container className="nav-container d-block">
            <Row className="align-items-center">
              <Col xs={6}>
                <Button variant="mobile-icon">
                  <span onClick={(e) => backFunction(e)}></span>
                </Button>
              </Col>

              <Col xs={6} className="text-end pt-1">
                <Image
                  src={Mobilhomeicon}
                  alt="dhurina app image"
                  width="50px"
                />
              </Col>
            </Row>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default CSCHeader;
