import React from 'react';
import { Image, Col, Row, Button, FormControl, Modal } from 'react-bootstrap';
import MainLogo from '../../assets/img/logo.png';

function MoblanPopup(props) {
    return (
        <> 

        <Modal size="lg"  id="moblangpopup" {...props} >        
            <Modal.Header closeButton></Modal.Header>        
            <Modal.Body>
                <Row className="align-items-center"> 
                    <Col md={6} className="switchlnguageox">
                        <Image src={MainLogo} alt="img" className="mb-3 mb-sm-5" />
                        <h4 className="f-16 mb-0">Select your Preference Language</h4> 
                        
                        <div className="connectoptionmobview bookcourseconnecttop bg-light">
                            <span>
                                <FormControl type="radio" className="with-gap" name="languagepop" id="English" />
                                <label for="English" className="f-15">English</label>
                            </span>  
                        </div>
                        <div className="connectoptionmobview bookcourseconnecttop bg-light">
                            <span>
                                <FormControl type="radio" className="with-gap" name="languagepop" id="Hindi" />
                                <label for="Hindi" className="f-15">Hindi</label>
                            </span>  
                        </div>
                        <div className="connectoptionmobview bookcourseconnecttop bg-light">
                            <span>
                                <FormControl type="radio" className="with-gap" name="languagepop" id="Marathi"  />
                                <label for="Marathi" className="f-15">Marathi</label>
                            </span>  
                        </div>
                        <div className="connectoptionmobview bookcourseconnecttop bg-light">
                            <span>
                                <FormControl type="radio" className="with-gap" name="languagepop" id="Punjabi"  />
                                <label for="Punjabi" className="f-15">Punjabi</label>
                            </span>  
                        </div>
                        <Button variant="base-light" className="w-100 mt-4">Continue</Button>
                    </Col> 
                </Row> 

            </Modal.Body>
        </Modal>

      </>
  );
}


export default MoblanPopup; 
