import React from "react";

const Affiliated = () => {
  return (
    <div className="bg_light_orange">
      <div className="custom_container container py-5">
        <h2 className="ff_inter fw-bold text-black text-center">
            Affiliated With
        </h2>
        <div className="row justify-content-center my-5">
                <img
                className="w-300 h-200"
                src="img/MESC.png"
                alt="img/MESC.png"
                />
        </div>
      </div>
    </div>
  );
};

export default Affiliated;
