import React, {useState, useEffect, useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Col, Row, Image, Form, Button, Alert, Spinner } from 'react-bootstrap';
import LoginImage from './../../assets/img/login.jpg';
import MainLogo from '../../assets/img/logo.png';
import ApiCall from '../../api/callApi';



const SchoolRegister = () => {
	// const [counter, setCounter] = useState(60);
	const [regsiter_modal,setRegisterModal]=useState('block')
	const [otp_modal,setOtpModal]=useState('none')
	const [detail_modal,setDetailModal]=useState('none')
	const [otp,setOtp]=useState('')
    const [states,setState] = useState([])
	const [name , setName] = useState('')
	const [ref , setRef] = useState('')
	const [phone , setPhone] = useState('')
	const [email , setEmail] = useState('')
	const [address , setAddress] = useState('')
	const [password , setPassword] = useState('')
	const [user_state , setUserState] = useState('')
	const [user_city , setUserCity] = useState('')
    const [submitStatus,setSubmitStatus] = useState(false) 
	const [show_succ, setShowSucc] = useState(false);
	const [show_fail, setShowFail] = useState(false); 
	const [title , setTitle] = useState('')
	const [message , setMessage] = useState('')
	const [state_city,setStateCity]=useState([])

	

	let navigate = useNavigate();

    useEffect(() => {
        getData()
    },[]);

	

    function getData(params){
        ApiCall("","get","states",get_states);
    }

	function verify_otp(){
		const body ={
			"phone":phone,
			"otp":otp
		}
		ApiCall(body,"post","verify_otp",verifyotpcallback);
    }

	const resend_otp = ()=>{
		var data={
            "phone":phone,
        }

		ApiCall(data,"post","send_otp",sendotpcallback);
	}

	
	function handleSubmitForm(e){
        e.preventDefault();

		setShowSucc(false)
		setShowFail(false)

        var data={
            "phone":phone,
        }
        setSubmitStatus(true)
        ApiCall(data,"post","send_otp",sendotpcallback);
    }

	function handleSubmitDetailForm(e){
         e.preventDefault();

		 setShowSucc(false)
		 setShowFail(false)

		 var data={
            "name":name,
            "ref_code":ref,
            "number":phone,
            "address":address,
            "password":password,
			"state":user_state,
            "city":user_city,
			"email":email
        }
        setSubmitStatus(true)
        ApiCall(data,"post","school_register",signup);
    }

	const getCity=(e)=>{
		setUserState(e.target.value)
        let url='book_store_city'
        let type='post'
       const body={
           state_id:e.target.value
       }
       ApiCall(body,type,url,schoolcitycallback);
    }

	const schoolcitycallback = useCallback(response => 
        {
        if(response.data.status===200){      
			setStateCity(response.data.city) 
        }
        else{
                console.log("error");           
        }
    },[])

	

    const sendotpcallback = useCallback(response => 
    {
        setTitle(response.data.title)
        setMessage(response.data.message)

        if(response.data.Status==='Success'){
			setRegisterModal('none')
			setOtpModal('block')
			setDetailModal('none')
        }
        else{
            setShowFail(true)
        }
         setSubmitStatus(false)
    },[])

	const signup = useCallback(response => 
	{
		setTitle(response.data.title)
		setMessage(response.data.message)

		if(response.data.status==='true'){
			let schoolId=response.data.id;
			navigate("/school-detail/"+schoolId);
		}
		else{
			setShowFail(true)
		}
	},[])

	const verifyotpcallback = useCallback(response => 
		{
			setMessage(response.data.message)
			if(response.data.status==='true'){
			
				setRegisterModal('none')
			    setOtpModal('none')
				setDetailModal('flex')
			}
			else{
				setShowFail(true)
			}
			
		},[])

    const get_states = useCallback(response => 
    {
        if(response.data.status===200){
            setState(response.data.data)
        }
        else{
            console.log("error");           
        }
    },[])

  return (
   
	 
	 <Container fluid className="login-bg minheightlogin">
		<Row className="align-items-center">
			<Col md={6} className="d-none d-sm-block text-center"> 
				<Image src={LoginImage} alt="logo image" />
				<p className="f-11 mb-0 mt-5  text-muted">
					© 2021 Dhurina Ventures Pvt Ltd. All Right Reserved.
				</p>
			</Col>
			<Col md={6}>
			
				<div className="p-3 p-sm-5 my-2 m-sm-5 bg-white loginboxshadow">

				<form onSubmit={(e)=>handleSubmitForm(e)}>
					<Image src={MainLogo} alt="img" className="mb-2" />
					<h3 className="f-25 mt-4 mb-4">School Registration!<br /></h3>
					<div className="row" style={{display:regsiter_modal}}>	
					<Col md={12}>
						<div className="mb-2">
							<Form.Label className="f-12 fw-medium mb-1">Mobile No <span className="text-danger">*</span></Form.Label>
							<div className="login-form">
								<Form.Control type="number" min="0" placeholder="Enter your Mobile No "  onChange={(e)=>setPhone(e.target.value)} required/>
								<i className="fa fa-mobile-alt"></i>
							</div> 
						</div>  
					</Col>
					<Alert variant="danger"  show={show_fail} onClose={() => setShowFail(false)} dismissible className="px-3 py-2 f-13">
						<Alert.Heading className="f-13 mb-0">
							<i className="fas fa-exclamation-triangle me-2"></i><strong>{title}</strong>
						</Alert.Heading>
						{message}
					</Alert>
					<Alert variant="success"  show={show_succ} onClose={() => setShowSucc(false)} dismissible className="px-3 py-2 f-13">
						<Alert.Heading className="f-13 mb-0">
							<i className="fas fa-check me-2"></i><strong>{title}</strong>
						</Alert.Heading>
						{message}
					</Alert>
					
					<Button variant="base-light" className="w-100 mt-4 btn-block loginshadowmain" type="submit">
						{submitStatus?<Spinner as="span" animation="border" size="sm" />:<i className="far fa-paper-plane"> Submit </i> }
					</Button>
					</div>
				</form>
				

				{/* otp modal for school registrations  */}
				<Row style={{display:otp_modal}}>
					<Col md={12}>
						<div className="mb-3">
							<Form.Label className="f-12 fw-medium mb-1">Enter OTP <span className="text-danger">*</span></Form.Label>
							<div className="d-flex justify-content-between">
								<Form.Control onKeyPress={(event) => {
									if (!/[0-9]/.test(event.key)) {
									event.preventDefault();
									}
								}} maxlength="5"  onChange={(e)=>setOtp(e.target.value)} />
							</div> 
						</div> 
						<Alert variant="danger"  show={show_fail} onClose={() => setShowFail(false)} dismissible className="px-3 py-2 f-13">
						<Alert.Heading className="f-13 mb-0">
							<i className="fas fa-exclamation-triangle me-2"></i><strong>{title}</strong>
						</Alert.Heading>
						{message}
					</Alert>
					<Alert variant="success"  show={show_succ} onClose={() => setShowSucc(false)} dismissible className="px-3 py-2 f-13">
						<Alert.Heading className="f-13 mb-0">
							<i className="fas fa-check me-2"></i><strong>{title}</strong>
						</Alert.Heading>
						{message}
					</Alert>
						<Button variant="base-light" className="w-100 mt-4 btn-block loginshadowmain" onClick={()=>verify_otp()}>Verify</Button> 
						<p className="f-12 text-center mt-4 text-muted">
					    <Button variant="base-light" className="w-100 mt-4 btn-block loginshadowmain" onClick={()=>resend_otp()}>Resend Otp</Button> 
								
						</p>
					</Col>
				</Row>
				{/* end otp modal  */}
						

				{/* School regtistration modal with detail  */}
				<form onSubmit={(e)=>handleSubmitDetailForm(e)} id="detail_form">
				<div className="row" style={{display:detail_modal}}>
						<Col md={12}>
							<div className="mb-2">
								<Form.Label className="f-12 fw-medium mb-1">School Name <span className="text-danger">*</span></Form.Label>
								<div className="login-form">
									<Form.Control type="text" placeholder="Enter School Name" onChange={(e)=>setName(e.target.value)} required/>
									<i className="fa fa-user-circle"></i>
								</div> 
							</div>  
						</Col>
						<Col md={6}>
							<div className="mb-2">
								<Form.Label className="f-12 fw-medium mb-1">Email-Id</Form.Label>
								<div className="login-form">
									<Form.Control type="text" placeholder="Enter your Email-Id"  onChange={(e)=>setEmail(e.target.value)} required/>
									<i className="fa fa-envelope-open-text"></i>
								</div> 
							</div>  
						</Col>
						<Col md={6}>
							<div className="mb-2">
								<Form.Label className="f-12 fw-medium mb-1">Password <span className="text-danger">*</span></Form.Label>
								<div className="login-form">
									<Form.Control type="password" placeholder="Enter your Password"  onChange={(e)=>setPassword(e.target.value)} required/>
									<i className="fa fa-lock"></i>
								</div> 
							</div>  
						</Col>
						<Col md={12}>
							<div className="mb-2">
								<Form.Label className="f-12 fw-medium mb-1">Address <span className="text-danger">*</span></Form.Label>
								<div className="login-form">
									<Form.Control type="text" placeholder="Enter your Address"  onChange={(e)=>setAddress(e.target.value)} required/>
									<i className="fa fa-map-marked-alt"></i>
								</div> 
							</div>  
						</Col>
						
						<Col md={6}>
							<div className="mb-2">
								<Form.Label className="f-12 fw-medium mb-1">Select State <span className="text-danger">*</span></Form.Label>
								<div className="login-form">
									<Form.Select className="form-control" onChange={(e)=>getCity(e)} required>
										<option>Select State</option>
										{
											states.map((value)=>{
												return <option value={value.id} key={value.id}>{value.name}</option>
											})
										}
									</Form.Select>
									<i className="fa fa-map-marker-alt"></i>
								</div> 
							</div>  
						</Col>
						<Col lg={6}>
						<Form.Label className="f-12 fw-medium mb-1">Select City <span className="text-danger">*</span></Form.Label>
						<div className="login-form">
								<Form.Select className="form-control" onChange={(e)=>setUserCity(e.target.value)} required>
									<option selected>Select City</option>
									{state_city.length > 0  &&
										state_city.map((item)=> {
											return  <option value={item.id}>{item.name}</option>
										})}
								</Form.Select> 
								<i className="fa fa-map-marker-alt"></i>
							</div>
						</Col> 
						<Alert variant="danger"  show={show_fail} onClose={() => setShowFail(false)} dismissible className="px-3 py-2 f-13">
							<Alert.Heading className="f-13 mb-0">
								<i className="fas fa-exclamation-triangle me-2"></i><strong>{title}</strong>
							</Alert.Heading>
							{message}
						</Alert>
						<Alert variant="success"  show={show_succ} onClose={() => setShowSucc(false)} dismissible className="px-3 py-2 f-13">
							<Alert.Heading className="f-13 mb-0">
								<i className="fas fa-check me-2"></i><strong>{title}</strong>
							</Alert.Heading>
							{message}
						</Alert>
						<Button variant="base-light" className="w-100 mt-4 btn-block loginshadowmain" type="submit">Submit
						</Button>
					</div>
				</form>
				{/* End school registarion detail modal  */}
				
				<p className="f-12 text-center mt-4 text-muted">
						Already have an account? <a href="https://admin.dhurina.net/login" className="fw-bold f-13">Log In</a> 
				</p> 
				</div>  
			
				
			</Col> 
		</Row> 
	</Container>
	

  );
}

export default SchoolRegister;

    
    
	
     
     
      